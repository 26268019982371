import { Col, Row } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
function OverAllPerformance() {
  const [chartData, setChartData] = useState([]);
  const flipCardData = useSelector((state) => state.Analytics.data);
  let actionRate =
    (flipCardData?.totalActionTake / flipCardData?.totalViews) * 100;
  if (actionRate >= 100) {
    actionRate = 100;
  }

  let engRate =
    (flipCardData?.totalEngagementTake / flipCardData?.totalViews) * 100;
  if (engRate >= 100) {
    engRate = 100;
  }

  useEffect(() => {
    const data = flipCardData?.performanceChart?.map((item) => {
      return {
        date: moment(item.date).format("YY-MM-DD"),
        "Action Take": item.overallReport.actionTake,
        "Engagement Take": item.overallReport.engagementTake,
        "Total views": item.overallReport.totalView,
      };
    });
    setChartData(data);
  }, [flipCardData]);
  return (
    <>
      <Col span={24}>
        <Row gutter={[10, 10]}>
          <Col {...{ xs: 12, sm: 8, md: 8, lg: 8, xl: 8 }}>
            <Row className="analyticsCenter">
              <h3>Views</h3>
              <h1>
                {flipCardData?.totalViews == 0 ? "-" : flipCardData?.totalViews}
              </h1>
            </Row>
          </Col>
          <Col {...{ xs: 12, sm: 8, md: 8, lg: 8, xl: 8 }}>
            <Row className="analyticsCenter">
              <h3>Engagement</h3>
              <h1>
                {engRate.toFixed(2) == 0 ? "-" : engRate.toFixed(2) + "%"}
              </h1>
            </Row>
          </Col>
          <Col {...{ xs: 12, sm: 8, md: 8, lg: 8, xl: 8 }}>
            <Row className="analyticsCenter">
              <h3>Conversion</h3>
              <h1>
                {actionRate.toFixed(2) == 0 ? "-" : actionRate.toFixed(2) + "%"}
              </h1>
            </Row>
          </Col>
          {/* <Col {...{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
            <Row className="analyticsCenter">
              <h3>Conversation</h3>
              <h1>
                {flipCardData?.totalConversion
                  ? flipCardData?.totalConversion
                  : 0}
              </h1>
            </Row>
          </Col> */}
        </Row>
      </Col>
      <Col span={24}>
        <ResponsiveContainer>
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="Total views"
              stroke="var(--chart-Color-1)"
            />
            <Line
              type="monotone"
              dataKey="Engagement Take"
              stroke="var(--chart-Color-2)"
            />
            <Line
              type="monotone"
              dataKey="Action Take"
              stroke="var(--chart-Color-3)"
            />
          </LineChart>
        </ResponsiveContainer>
      </Col>
    </>
  );
}

export default OverAllPerformance;
