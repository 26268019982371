import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Switch } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { currentSectionSetter } from "../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
import { FlipcardThankYouPage } from "../../../redux/Slices/Flip Cards/FlipCardSlice";
function ThankYouPage() {
  const [shouldSectionChange, setShouldSectionChange] = useState();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.flipCard.thankYouPage);

  const onChange = (e) => {
    let sentData = { ...userData };
    if (e == true || e == false) {
      sentData = { ...userData, showShareSection: e };
    } else {
      const { name, value } = e.target;
      sentData = { ...userData, [name]: value };
    }
    dispatch(FlipcardThankYouPage(sentData));
  };

  useEffect(() => {
    if (userData.title == "" || userData.description == "") {
      setShouldSectionChange(false);
    } else {
      setShouldSectionChange(true);
    }
  }, [userData]);

  return (
    <Row>
      <Col span={24} className="flipcardOutCompGrey">
        <Row className="flipcardInCompWhite">
          <Col span={24}>
            <h2>Thank you screen</h2>
          </Col>
          <Col
            span={24}
            style={{
              marginTop: "var(--sub-margin)",
            }}
          >
            <Form>
              <Form.Item label="Thank You Page Title *">
                <Input
                  type="text"
                  name="title"
                  value={userData.title}
                  onChange={onChange}
                  placeholder="Thank You Page Title"
                  className="inputBx"
                />
              </Form.Item>
              <Form.Item label="Thank You Page Description *">
                <Input
                  type="text"
                  name="description"
                  value={userData.description}
                  onChange={onChange}
                  placeholder="Thank You Page Description"
                  className="inputBx"
                />
              </Form.Item>
              <Form.Item label="Share Flipcard *">
                <Switch
                  name="showShareSection"
                  checked={userData.showShareSection}
                  onChange={onChange}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Col>
      <Col
        span={24}
        style={{
          marginTop: "var(--main-margin)",
          marginBottom: "var(--main-margin)",
        }}
      >
        <Row justify="space-between" align="middle">
          <Button
            size="large"
            className="button"
            onClick={() => {
              dispatch(currentSectionSetter(2));
            }}
          >
            <ArrowLeftOutlined /> Back
          </Button>
          <Button
            size="large"
            className="button"
            disabled={!shouldSectionChange}
            type="primary"
            onClick={() => {
              dispatch(currentSectionSetter(4));
            }}
          >
            Next <ArrowRightOutlined />
          </Button>
        </Row>
      </Col>
    </Row>
  );
}

export default ThankYouPage;
