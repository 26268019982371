export default {
  _id: "create",
  title: "",
  actionPage: {
    title: "Are you ready to take action?",
    description: "You can be part of the solution. Take action now.",
  },
  thankYouPage: {
    title: "Default Thank You Page Title",
    description: "Default Thank You Page Description",
    showShareSection: true,
  },
  publisher: {
    name: "",
    logo: "",
  },
  engagement: {},
  actions: [],
  theme: {
    primaryColor: "#395EF9",
    secondaryColor: "#395EF9",
    tertiaryColor: "#395EF9",
    width: 450,
    height: 450,
    theme: "light",
  },
};
