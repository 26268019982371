import { createSlice } from "@reduxjs/toolkit";
import NewsLetterSignupInitialState from "../../../../Initial States/Actions/NewsLetterSignupInitialState";

const ActNewsLetterSignUpSlice = createSlice({
  name: "ActNewsLetterSignUp",
  initialState: {
    action: NewsLetterSignupInitialState,
  },
  reducers: {
    setActNewsLetterSignUp: (state, action) => {
      state.action = action.payload;
    },
  },
});

export const { setActNewsLetterSignUp } = ActNewsLetterSignUpSlice.actions;
export default ActNewsLetterSignUpSlice.reducer;
