import {
  CommentOutlined,
  MailOutlined,
  ScheduleOutlined,
  DollarOutlined,
  FileTextOutlined,
  LinkOutlined,
  FormOutlined,
} from "@ant-design/icons";
const totalActionsArray = [
  {
    name: "Message Box",
    value: "messageBox",
    icon: <CommentOutlined />,
  },
  {
    name: "Sign A Petition",
    value: "signAPetition",
    icon: <FileTextOutlined />,
  },
  {
    name: "News Letter Signup",
    value: "newsLetterSignup",
    icon: <MailOutlined />,
  },
  {
    name: "Donation",
    value: "donation",
    icon: <DollarOutlined />,
  },
  {
    name: "Link",
    value: "link",
    icon: <LinkOutlined />,
  },
  {
    name: "Dynamic Form",
    value: "dynamicForm",
    icon: <FormOutlined />,
  },
];

export default totalActionsArray;
