import { Col, Row } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Legend, ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
function TopDevices() {
  const flipCardData = useSelector((state) => state.Analytics.data);
  const [firstChartData, setFirstChartData] = useState([]);

  const COLORS = ["#001493", "#4b5bbb", "#8c98e3"];
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  useEffect(() => {
    const data = flipCardData?.devices?.map((item) => {
      return {
        name: item.name,
        totalView: item.overallReport.totalView,
      };
    });
    setFirstChartData(data);
  }, [flipCardData]);
  return (
    <>
      <Col span={24}>
        <h3>Sessions by device</h3>
      </Col>
      <Col span={24}>
        <ResponsiveContainer>
          <PieChart>
            <Legend layout="vertical" verticalAlign="middle" align="right" />
            <Pie
              data={firstChartData}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              innerRadius={20}
              outerRadius={100}
              dataKey="totalView"
            >
              {firstChartData?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Col>
      <Col span={24}>
        <Row
          style={{ width: "100%", opacity: "0.7", textAlign: "center" }}
          align="middle"
          justify="space-around"
        >
          {firstChartData?.map((data, index) => {
            return (
              <Col key={index} style={{ width: "100%", maxWidth: "100px" }}>
                <p>{data?.name}</p>
                <h2>{data?.totalView}</h2>
              </Col>
            );
          })}
        </Row>
      </Col>
    </>
  );
}

export default TopDevices;
