import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const bridgedApi = createApi({
  reducerPath: "bridgedApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.bridged.media/",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["PublisherFlipcard", "userInfo"],
  endpoints: (builder) => ({
    signUp: builder.mutation({
      query: (data) => ({
        url: "/User/Register",
        method: "POST",
        body: data,
      }),
    }),

    login: builder.mutation({
      query: (data) => ({
        url: "/User/Login",
        method: "POST",
        body: data,
      }),
    }),

    ambassadors: builder.query({
      query: () => "/User/ambassadors",
    }),

    verifyEmail: builder.mutation({
      query: (data) => ({
        url: "/User/VerifyEmail",
        method: "POST",
        body: data,
      }),
    }),

    resendVerifyEmail: builder.mutation({
      query: (data) => ({
        url: "/User/ResendVerificationCode",
        method: "POST",
        body: data,
      }),
    }),

    forgotPassword: builder.mutation({
      query: (data) => ({
        url: "/User/ForgotPassword",
        method: "POST",
        body: data,
      }),
    }),

    verifyForgotPasswordCode: builder.mutation({
      query: (data) => ({
        url: "/User/VerifyForgotCode",
        method: "POST",
        body: data,
      }),
    }),

    renewToken: builder.mutation({
      query: (data) => ({
        url: "/User/renewToken",
        method: "POST",
        body: data,
      }),
    }),

    isAthorized: builder.query({
      query: () => "/User/isAuthorized",
    }),

    onBoard: builder.mutation({
      query: (data) => ({
        url: "/User/Onboard",
        method: "POST",
        body: data,
      }),
    }),

    userInfo: builder.query({
      query: () => "/User/Profile",
      providesTags: ["userInfo"],
    }),

    updateUserInfo: builder.mutation({
      query: (data) => ({
        url: "/User/Profile/Update",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["userInfo"],
    }),

    updateUserPhoto: builder.mutation({
      query: (data) => ({
        url: "/User/Profile/UpdatePhoto",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["userInfo"],
    }),

    changePassword: builder.mutation({
      query: (data) => ({
        url: "/User/Profile/ChangePassword",
        method: "POST",
        body: data,
      }),
    }),

    Feedback: builder.mutation({
      query: (data) => ({
        url: "/Dashboard/Feedback",
        method: "POST",
        body: data,
      }),
    }),

    Support: builder.mutation({
      query: (data) => ({
        url: "/Dashboard/Support",
        method: "POST",
        body: data,
      }),
    }),

    dashboard: builder.query({
      query: () => "/Dashboard",
      providesTags: ["PublisherFlipcard"],
    }),

    flipCardAnalytics: builder.mutation({
      query: (data) => ({
        url: "/Dashboard/Analytics",
        method: "POST",
        body: data,
      }),
    }),

    // ----------------------- Fetching Flipcards -----------------------

    // Get Flipcard Action inventories
    flipcardActionInventories: builder.query({
      query: ({ currentPagination, actionSwitcher }) =>
        `/ActionsInventory/ActionInventoriesWithPagination?page=${currentPagination}&actionType=${actionSwitcher}`,
      providesTags: ["PublisherFlipcard"],
    }),

    // Get User Specific Flipcard Action inventories
    flipcardUserActionInventories: builder.query({
      query: () => "/ActionsInventory/UserActionInventories",
      providesTags: ["PublisherFlipcard"],
    }),

    // Post Flipcard Action inventories
    flipcardActionInventoriesPost: builder.mutation({
      query: (data) => ({
        url: "/ActionsInventory",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PublisherFlipcard"],
    }),

    // Delete Flipcard Action inventorie with _id
    flipcardActionInventoriesDelete: builder.mutation({
      query: (_id) => ({
        url: `/ActionsInventory?_id=${_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PublisherFlipcard"],
    }),

    // Edit Flipcard Action inventorie with _id
    flipcardActionInventoriesEdit: builder.mutation({
      query: ({ userData: data, isCheckbox }) => ({
        url: `/ActionsInventory?updateFlipcards=${isCheckbox}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["PublisherFlipcard"],
    }),

    // Get Flipcard Engagement inventories
    flipcardEngagementInventories: builder.query({
      query: (pageNum) => `/EngagementsInventory`,
      providesTags: ["PublisherFlipcard"],
    }),

    // Get User Specific Flipcard Engagement inventories
    flipcardUserEngagementInventories: builder.query({
      query: () => "/EngagementsInventory/UserEngagementInventories",
      providesTags: ["PublisherFlipcard"],
    }),

    // Post Flipcard Engagement inventories
    flipcardEngagementInventoriesPost: builder.mutation({
      query: (data) => ({
        url: "/EngagementsInventory",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PublisherFlipcard"],
    }),

    // Edit Flipcard Engagement inventories
    flipcardEngagementInventoriesEdit: builder.mutation({
      query: (data) => ({
        url: "/EngagementsInventory",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["PublisherFlipcard"],
    }),

    // Delete Flipcard Action inventorie with _id
    flipcardEngagementInventoriesDelete: builder.mutation({
      query: (_id) => ({
        url: `/EngagementsInventory?_id=${_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PublisherFlipcard"],
    }),

    // Get all Un Approved actions
    flipcardUnApprovedActionInventories: builder.query({
      query: () => "/ActionsInventory/UnApprovedActionInventories",
      providesTags: ["PublisherFlipcard"],
    }),

    // Change Status of Un Approved actions
    flipcardUnApprovedActionInventoriesChangeStatus: builder.mutation({
      query: (_id) => ({
        url: `/ActionsInventory/ApproveAction?actionId=${_id}`,
        method: "PATCH",
      }),
      invalidatesTags: ["PublisherFlipcard"],
    }),

    // Draft a Flipcard if not finished
    flipcardDraftMaker: builder.mutation({
      query: (data) => ({
        url: "/Draft/Create",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PublisherFlipcard"],
    }),

    // Get all Drafts
    flipcardDrafts: builder.query({
      query: () => "/Draft/FindAll",
      providesTags: ["PublisherFlipcard"],
    }),

    // Get a Drafts
    flipcardGetOneDraft: builder.mutation({
      query: (_id) => ({
        url: `/Draft/FindById?_id=${_id}`,
        method: "GET",
      }),
      invalidatesTags: ["PublisherFlipcard"],
    }),

    // Delete a Draft
    flipcardDraftDelete: builder.mutation({
      query: (_id) => ({
        url: `/Draft/delete?_id=${_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PublisherFlipcard"],
    }),

    // Post a Flipcard
    sendFlipcard: builder.mutation({
      query: (data) => ({
        url: "/Flipcard",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PublisherFlipcard"],
    }),

    // Update a Flipcard using PUT
    updateFlipcard: builder.mutation({
      query: (data) => ({
        url: "/Flipcard",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["PublisherFlipcard"],
    }),

    // Duplicate a Flipcard using GET
    duplicateFlipcard: builder.mutation({
      query: (_id) => ({
        url: `/Flipcard/Duplicate?flipcardId=${_id}`,
        method: "GET",
      }),
      invalidatesTags: ["PublisherFlipcard"],
    }),

    // --------------------------  custom project and donations --------------------------

    // post request for submitting custom project
    bridgedProjectCreate: builder.mutation({
      query: (data) => ({
        url: "/BridgedProject/Create",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PublisherFlipcard"],
    }),

    // get request for getting all custom projects
    bridgedProjectsFindAll: builder.query({
      query: () => "/BridgedProject/FindAll",
      providesTags: ["PublisherFlipcard"],
    }),

    // get request for getting a single custom project by id
    bridgedProjectFindById: builder.mutation({
      query: (_id) => ({
        url: `/BridgedProject/FindByProjectId?projectId=${_id}`,
        method: "GET",
      }),
      invalidatesTags: ["PublisherFlipcard"],
    }),

    // delete request for deleting a custom project
    bridgedProjectDelete: builder.mutation({
      query: (_id) => ({
        url: `/BridgedProject/delete?_id=${_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PublisherFlipcard"],
    }),

    // update project by project id post request
    bridgedProjectUpdate: builder.mutation({
      query: ({ userData, customDonationMongoDbId }) => ({
        url: `/BridgedProject/Update?_id=${customDonationMongoDbId}`,
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["PublisherFlipcard"],
    }),

    flipCards: builder.query({
      query: () => "/Flipcard",
      providesTags: ["PublisherFlipcard"],
    }),

    singlelipCards: builder.query({
      query: (_id) => `/Flipcard/GetFlipcard/${_id}`,
    }),

    flipCardOverview: builder.mutation({
      query: (data) => ({
        url: "/Flipcard/Overview",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PublisherFlipcard"],
    }),

    flipCardDelete: builder.mutation({
      query: (_id) => ({
        url: `/Flipcard?flipcardId=${_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PublisherFlipcard"],
    }),

    flipCardChangeStatus: builder.mutation({
      query: ({ _id, status }) => ({
        url: `/Flipcard/ChangeFlipcardStatus?flipcardId=${_id}&active=${status}`,
        method: "PATCH",
      }),
      invalidatesTags: ["PublisherFlipcard"],
    }),

    flipCardSendEmailToAudience: builder.mutation({
      query: ({ _id, userData }) => ({
        url: `/Flipcard/SendEmailToAudience?flipcardId=${_id}`,
        method: "POST",
        body: userData,
      }),
    }),

    flipCardFind: builder.mutation({
      query: (_id) => ({
        url: `/Flipcard/Find?flipcardId=${_id}`,
        method: "GET",
      }),
    }),

    // ---------------------- Finding Engagement and actions inventory bt _id ----------------------
    findEngagementById: builder.mutation({
      query: (_id) => ({
        url: `/EngagementsInventory/findWithId?_id=${_id}`,
        method: "GET",
      }),
    }),

    findActionById: builder.mutation({
      query: (_id) => ({
        url: `/ActionsInventory/findWithId?_id=${_id}`,
        method: "GET",
      }),
    }),

    // ----------------------- Wordpress Auth -----------------------

    wordpressAuth: builder.query({
      query: (queryKey) => ({
        url: "/User/WP/gettokens",
        method: "GET",
        headers: {
          "x-api-key": queryKey,
        },
      }),
    }),

    // Get all actions made manually
    flipcardGetAllActionsMadeManually: builder.query({
      query: () => ({
        url: "/ActionsInventory/FindAllManuallyCreated",
        method: "GET",
      }),
      providesTags: ["PublisherFlipcard"],
    }),

    // Fix action images
    flipcardFixActionImage: builder.mutation({
      query: (body) => ({
        url: "/ActionsInventory/fixActionImage",
        method: "POST",
        body,
      }),
      invalidatesTags: ["PublisherFlipcard"],
    }),

    uploadImage: builder.mutation({
      query: (body) => ({
        url: "/Media/image",
        method: "POST",
        body,
      }),
      invalidatesTags: ["PublisherFlipcard"],
    }),
  }),
});

export const {
  useSignUpMutation,
  useLoginMutation,
  useAmbassadorsQuery,
  useVerifyEmailMutation,
  useResendVerifyEmailMutation,
  useForgotPasswordMutation,
  useVerifyForgotPasswordCodeMutation,
  useRenewTokenMutation,
  useIsAthorizedQuery,
  useOnBoardMutation,
  useUserInfoQuery,
  useUpdateUserInfoMutation,
  useUpdateUserPhotoMutation,
  useChangePasswordMutation,
  useFeedbackMutation,
  useSupportMutation,
  // ------------- Flipcard -------------
  useSendFlipcardMutation,
  useUpdateFlipcardMutation,
  useDuplicateFlipcardMutation,
  useFlipCardsQuery,
  useSinglelipCardsQuery,
  useFlipCardOverviewMutation,
  useFlipCardDeleteMutation,
  useFlipCardSendEmailToAudienceMutation,
  useFlipCardChangeStatusMutation,
  useFlipCardFindMutation,
  useDashboardQuery,
  useFlipCardAnalyticsMutation,
  // --------------------------  custom project and donations --------------------------
  useBridgedProjectCreateMutation,
  useBridgedProjectsFindAllQuery,
  useBridgedProjectFindByIdMutation,
  useBridgedProjectDeleteMutation,
  useBridgedProjectUpdateMutation,
  // ----------------------- Fetching Flipcards Inventories -----------------------
  useFlipcardActionInventoriesQuery,
  useFlipcardEngagementInventoriesQuery,
  useFlipcardUserActionInventoriesQuery,
  useFlipcardUserEngagementInventoriesQuery,
  // ----------------------- Drafts -----------------------
  useFlipcardDraftMakerMutation,
  useFlipcardDraftsQuery,
  useFlipcardGetOneDraftMutation,
  useFlipcardDraftDeleteMutation,
  // ----------------------- Posting Flipcards -----------------------
  useFlipcardActionInventoriesPostMutation,
  useFlipcardActionInventoriesDeleteMutation,
  useFlipcardActionInventoriesEditMutation,
  useFlipcardEngagementInventoriesPostMutation,
  useFlipcardEngagementInventoriesEditMutation,
  useFlipcardEngagementInventoriesDeleteMutation,
  // ----------------------- Un Approved actiin -----------------------
  useFlipcardUnApprovedActionInventoriesQuery,
  useFlipcardUnApprovedActionInventoriesChangeStatusMutation,
  // ----------------------- Find Engagement and Action -----------------------
  useFindEngagementByIdMutation,
  useFindActionByIdMutation,
  // ----------------------- Wordpress Auth -----------------------
  useWordpressAuthQuery,
  // ----------------------- All actions created manually ----------------
  useFlipcardGetAllActionsMadeManuallyQuery,
  useFlipcardFixActionImageMutation,
  useUploadImageMutation,
} = bridgedApi;
