import { Col, Row, Button, Input, message, Select, Skeleton } from "antd";
import "./LoginSignup.css";
import React, { useState } from "react";
import Sidebar from "./Sidebar";
import { NavLink, useHistory, useLocation } from "react-router-dom";

import { useAmbassadorsQuery, useSignUpMutation } from "../../Services/service";

import Checkbox from "antd/lib/checkbox/Checkbox";
import { useEffect } from "react";
const { Option } = Select;
function Signup() {
  const search = useLocation().search;
  const queryEmail = new URLSearchParams(search).get("email");
  const history = useHistory();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [ambassadors, setAmbassadors] = useState([]);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    newsLetter: true,
    termsAndCondition: false,
    password: "",
    confimPassword: "",
    ambassador: "",
  });

  useEffect(() => {
    if (queryEmail) {
      setUserData({ ...userData, email: queryEmail });
    }
  }, [queryEmail]);

  const HandleInputVal = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };
  
  const handleCheckbox = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.checked });
  };
  const [_signup] = useSignUpMutation();
  const { data, isLoading, isSuccess } = useAmbassadorsQuery();

  useEffect(() => {
    if (isSuccess) {
      setAmbassadors(data);
    }
  }, [isSuccess]);

  const handleSignupSubmit = async (e) => {
    setSubmitLoading(true);
    e.preventDefault();
    if (
      !userData.name ||
      !userData.email ||
      !userData.password ||
      !userData.confimPassword
    ) {
      message.error("Please fill all the fields");
      setSubmitLoading(false);
    } else if (!userData.termsAndCondition) {
      message.error("Please accept the terms and conditions");
      setSubmitLoading(false);
    } else if (userData.password.length < 8 && userData.password.length < 8) {
      message.error("Password must be atleast 8 characters");
      setSubmitLoading(false);
    } else if (userData.password !== userData.confimPassword) {
      message.error("Password and Confirm Password are not same");
      setSubmitLoading(false);
    } else {
      const data = {
        email: userData.email,
        password: userData.password,
        fullname: userData.name,
        isSignedUpForNewsLetter: userData.newsLetter,
      };
      const response = await _signup(data);
      if (response?.error?.status === 400) {
        message.error("Email already exists");
        setSubmitLoading(false);
      } else if (response?.data?.email) {
        message.success("Please check your email to confirm your account");
        setTimeout(() => {
          history.push(`/confirmemail/${response?.data?.email}`);
        }, 1000);
        setSubmitLoading(false);
      }
    }
  };

  return (
    <div className="LSParent">
      <Row className="LSCard">
        <Sidebar
          content={"A few clicks away from crearting your first action"}
        />
        <Col
          {...{ xs: 24, sm: 24, md: 24, lg: 24, xl: 16 }}
          className="LSMainDiv"
        >
          <Row>
            <Col span={24} style={{ marginTop: "10px" }}>
              <h1>Register</h1>
            </Col>

            {/* <Col span={24} style={{ marginTop: "10px" }}>
              <h2>Add action to the content you create</h2>
            </Col> */}

            <Col
              {...{ xs: 24, sm: 24, md: 20, lg: 20 }}
              style={{ marginTop: "10px" }}
            >
              <p>
                Let's get you all set up so we can verify your personal account
                and begin setting up your profile
              </p>
            </Col>

            <Row gutter={[20, 20]} style={{ marginTop: "20px", width: "100%" }}>
              <Col {...{ xs: 24, sm: 24, md: 12, lg: 12 }}>
                <h4>Full Name</h4>
                <Input
                  style={{ marginTop: "5px" }}
                  type="text"
                  name="name"
                  value={userData.name}
                  onChange={HandleInputVal}
                  className="inputBx"
                />
              </Col>
              <Col {...{ xs: 24, sm: 24, md: 12, lg: 12 }}>
                <h4>Email</h4>
                <Input
                  style={{ marginTop: "5px" }}
                  type="email"
                  name="email"
                  disabled={queryEmail ? true : false}
                  value={userData.email}
                  onChange={HandleInputVal}
                  className="inputBx"
                />
              </Col>
              <Col {...{ xs: 24, sm: 24, md: 12, lg: 12 }}>
                <h4>Password</h4>
                <input
                  style={{
                    outline: "none",
                    marginTop: "5px",
                    border: "1px solid var(--main-Border-Color)",
                  }}
                  type="password"
                  name="password"
                  value={userData.password}
                  onChange={HandleInputVal}
                  className="inputBx"
                />
              </Col>
              <Col {...{ xs: 24, sm: 24, md: 12, lg: 12 }}>
                <h4>Confirm Password</h4>
                <input
                  style={{
                    outline: "none",
                    marginTop: "5px",
                    border: "1px solid var(--main-Border-Color)",
                  }}
                  type="password"
                  name="confimPassword"
                  value={userData.confimPassword}
                  onChange={HandleInputVal}
                  className="inputBx"
                />
              </Col>
              <Col {...{ xs: 24, sm: 24, md: 24, lg: 12 }}>
                {isLoading ? (
                  <>
                    <Skeleton.Button
                      active={true}
                      block={true}
                      style={{
                        height: "15px",
                        borderRadius: "var(--input-Border-Radius3)",
                      }}
                    />
                    <Skeleton.Button
                      active={true}
                      block={true}
                      style={{
                        height: "40px",
                        borderRadius: "var(--input-Border-Radius3)",
                      }}
                    />
                  </>
                ) : (
                  <>
                    <h4>Ambassador</h4>
                    <Select
                      className="antdSelect"
                      size="large"
                      bordered={false}
                      value={userData.ambassador}
                      onChange={(e) => {
                        setUserData({ ...userData, ambassador: e });
                      }}
                    >
                      {ambassadors.map(({ name, email }) => {
                        return <Option value={email}>{name}</Option>;
                      })}
                    </Select>
                  </>
                )}
              </Col>
            </Row>
            <Row gutter={[0, 15]} style={{ marginTop: "20px", width: "100%" }}>
              <Col span={24}>
                <Checkbox
                  name="newsLetter"
                  onChange={handleCheckbox}
                  checked={userData.newsLetter}
                >
                  <p
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: "400",
                      opacity: 1,
                    }}
                  >
                    {" "}
                    I agree to receive occasional emails about promotions, new
                    product features and important updates to keep you in the
                    loop
                  </p>
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox
                  name="termsAndCondition"
                  onChange={handleCheckbox}
                  checked={userData.termsAndCondition}
                >
                  <p
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: "400",
                      opacity: 1,
                    }}
                  >
                    {" "}
                    I agree to the{" "}
                    <a
                      href="https://bridged.media/terms-of-service"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms
                    </a>
                    ,{" "}
                    <a
                      href="https://bridged.media/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </p>
                </Checkbox>
              </Col>
            </Row>
            <Col span={24} style={{ marginTop: "20px" }}>
              <Button
                className="button"
                type="primary"
                size="large"
                style={{ fontWeight: "600" }}
                loading={submitLoading}
                onClick={handleSignupSubmit}
              >
                Create Account
              </Button>
            </Col>
            <Col span={24} style={{ marginTop: "20px" }}>
              <h3>
                Already have a account? <NavLink to="/login">Login</NavLink>
              </h3>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Signup;
