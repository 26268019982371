import { createSlice } from "@reduxjs/toolkit";

const NewFlipcardOverviewSlice = createSlice({
  name: "NewFlipcardOverview",
  initialState: {
    flipcardOverviewData: {},
    _id: "",
    flipcardOverviewDataStatus: {
      isLoading: false,
      isError: false,
    },
    filterData: 7,
    originalTableData: [],
    selectedTableData: [],
    currtableActionType: "all",
    currentTableColumn: [],
    currentExportLabel: [],
  },
  reducers: {
    setFlipcardOverviewData: (state, action) => {
      state.flipcardOverviewData = action.payload;
    },
    setFlipcardOverviewId: (state, action) => {
      state._id = action.payload;
    },
    setFlipcardOverviewDataStatus: (state, action) => {
      state.flipcardOverviewDataStatus = action.payload;
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
    setOriginalTableData: (state, action) => {
      state.originalTableData = action.payload;
    },
    setCurrTableActionType: (state, action) => {
      state.currtableActionType = action.payload;
    },
    setCurrentTableColumn: (state, action) => {
      state.currentTableColumn = action.payload;
    },
    setCurrentExportLabel: (state, action) => {
      state.currentExportLabel = action.payload;
    },
    setSelectedTableData: (state, action) => {
      state.selectedTableData = action.payload;
    },
  },
});

export const {
  setFlipcardOverviewData,
  setFlipcardOverviewId,
  setFlipcardOverviewDataStatus,
  setFilterData,
  setOriginalTableData,
  setCurrTableActionType,
  setCurrentExportLabel,
  setCurrentTableColumn,
  setSelectedTableData,
} = NewFlipcardOverviewSlice.actions;
export default NewFlipcardOverviewSlice.reducer;
