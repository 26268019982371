import { InfoCircleFilled } from "@ant-design/icons";
import { Button, Col, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NewActionModal from "./NewActionModal";
import { FlipCardaction } from "../../../../../redux/Slices/Flip Cards/FlipCardSlice";
const { Option } = Select;
function NewActionPub({ isModalVisible, setIsModalVisible }) {
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Row>
      <Col span={24}>
        <p className="flipcardContentPanelSubHeading">
          Could not find the action of your choice? Submit a request and we'll
          add it for you
        </p>
      </Col>
      <Col
        span={24}
        style={{
          marginTop: "var(--sub-margin)",
          padding: "var(--sub-margin)",
          backgroundColor: "var(--secondary-Text-Color-bg)",
          borderRadius: "var(--input-Border-Radius3)",
        }}
      >
        <p
          style={{
            color: "var(--secondary-Text-Color)",
          }}
        >
          <InfoCircleFilled /> This card will be reviewed by our internal team
          in under 24 hours. You will receive a notification once the card is
          ready for use.
        </p>
      </Col>
      <Col
        span={24}
        style={{
          marginTop: "var(--sub-margin)",
        }}
      >
        <Button
          size="large"
          className="button"
          onClick={() => {
            showModal();
          }}
        >
          Create Action
        </Button>
      </Col>
      <NewActionModal
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
      />
    </Row>
  );
}

export default NewActionPub;
