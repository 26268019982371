import { Col, Table } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

const tableColumn = [
  {
    title: "Name",
    dataIndex: "name",
    ellipsis: true,
    width: "70%",
  },
  {
    title: "Total Views",
    dataIndex: "totalView",
    align: "right",
  },
];
function UsersLocation() {
  const flipCardData = useSelector((state) => state.Analytics.data);

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const data = flipCardData?.sources?.map((item) => {
      const { actionTake, engagementTake, totalView } = item.overallReport;
      return {
        name: item.name,
        actionTake,
        engagementTake,
        totalView,
      };
    });
    setTableData(data);
  }, [flipCardData]);

  return (
    <Col span={24}>
      <Table
        className="analyticsTable"
        columns={tableColumn}
        dataSource={tableData}
        size="small"
        pagination={{
          size: "small",
          pageSize: 9,
        }}
      />
    </Col>
  );
}

export default UsersLocation;
