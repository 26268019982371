import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  EditFilled,
} from "@ant-design/icons";
import { Button, Col, message, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  currentSectionSetter,
  isFlipcardSubmittedSetter,
} from "../../../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
import {
  useFlipcardDraftDeleteMutation,
  useSendFlipcardMutation,
  useUpdateFlipcardMutation,
  useUploadImageMutation,
} from "../../../../../Services/service";
import engagementNameConverter from "../../../../ExtraComponents/engagementNameConverter";
import Customize from "../../../ResusableComponents/EtcComponents/Customize";
import isValidHttpUrl from "../../../../ExtraComponents/isValidHttpUrl";
function Preview({ setFlipcardSubmitId }) {
  const pathname = window.location.href.split("/")[3];
  const { _id: draftId } = useParams();
  const dispatch = useDispatch();
  const currEng = useSelector((state) => state.FlipCardEtc.currEng);
  const flipcardTitle = useSelector((state) => state.flipCard.title);
  const FlipCardData = useSelector((state) => state.flipCard);
  const FlipCardSDGs = useSelector((state) => state.FlipCardEtc.flipcardSDGS);
  const isEngagementSkipped = useSelector(
    (state) => state.FlipCardEtc.isEngagementSkipped
  );

  const [_flipCard, { isLoading }] = useSendFlipcardMutation();
  const [_flipCardUpdate, { isLoading: isLoadingUpdate }] =
    useUpdateFlipcardMutation();
  const [_deleteDraft] = useFlipcardDraftDeleteMutation();
  const [_uploadImage, { isLoading: isLoadingImage }] =
    useUploadImageMutation();

  const handleSubmitFlipcard = async () => {
    let response = "";
    const pathname = window.location.href.split("/")[3];
    // Creates a Object which deletes ID and extracts just ID's of actions
    let sentFlipcardData = { ...FlipCardData };

    if (isEngagementSkipped) {
      sentFlipcardData.engagement = undefined;
    }

    sentFlipcardData = {
      ...sentFlipcardData,
      _id: "",
      actions: FlipCardData.actions.map((actions) => {
        return actions._id;
      }),
    };

    if (pathname == "flipcardedit") {
      sentFlipcardData._id = draftId;
    }

    if (pathname == "flipcardedit") {
      response = await _flipCardUpdate(sentFlipcardData);
    }

    if (pathname == "create" || pathname == "draft") {
      response = await _flipCard(sentFlipcardData);
    }

    if (response?.data) {
      setFlipcardSubmitId(response?.data._id);
      if (pathname == "flipcardedit") {
        message.success("Flipcard Edited successfully");
      } else {
        message.success("Flipcard created successfully");
      }
      dispatch(isFlipcardSubmittedSetter(true));
      dispatch(currentSectionSetter(5));
      if (pathname === "draft") {
        await _deleteDraft(draftId);
      }
    } else {
      message.error("Something went wrong");
    }
  };
  return (
    <>
      <Row className="flipcardOutCompGrey">
        <Col
          span={24}
          className="flipcardInCompWhite"
          style={{
            backgroundColor: "transparent",
          }}
        >
          <h2>
            {flipcardTitle.substring(0, 30)}
            {flipcardTitle.length >= 30 ? "..." : null}
          </h2>
          <p
            style={{
              marginTop: "10px",
            }}
          >
            We are one more step away from creating your new bridged card. Check
            your Bridged card one more time before submitting the design. This
            is how your card will look like in your website.{" "}
          </p>
        </Col>
        <Col
          span={24}
          className="flipcardInCompWhite"
          style={{
            marginTop: "var(--sub-margin)",
          }}
        >
          <Row justify="space-between" align="middle">
            <h2>
              {flipcardTitle.substring(0, 30)}
              {flipcardTitle.length >= 30 ? "..." : null}
            </h2>
            <h2
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(currentSectionSetter(0));
              }}
            >
              <EditFilled /> EDIT
            </h2>
          </Row>
        </Col>
        <Col
          span={24}
          className="flipcardInCompWhite"
          style={{
            marginTop: "var(--sub-margin)",
          }}
        >
          <Row justify="space-between" align="middle">
            <h2>{engagementNameConverter(currEng)}</h2>
            <h2
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(currentSectionSetter(1));
              }}
            >
              <EditFilled /> EDIT
            </h2>
          </Row>
        </Col>
        {pathname === "flipcardedit" ? null : (
          <Col
            span={24}
            className="flipcardInCompWhite"
            style={{
              marginTop: "var(--sub-margin)",
            }}
          >
            <Row justify="space-between" align="middle">
              <h2>Impact</h2>
              <h2
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  dispatch(currentSectionSetter(2));
                }}
              >
                <EditFilled /> EDIT
              </h2>
            </Row>
          </Col>
        )}
      </Row>
      <Row
        className="flipcardOutCompGrey"
        style={{
          marginTop: "var(--main-margin)",
        }}
      >
        <Col span={24} className="flipcardInCompWhite">
          <Row style={{ width: "100%" }}>
            <Customize />
          </Row>
        </Col>
      </Row>
      {/* <Row
        className="flipcardOutCompGrey"
        style={{
          marginTop: "var(--main-margin)",
        }}
      >
        <Col span={24} className="flipcardInCompWhite">
          <Row>
            <Col span={24}>
              <h2>Supported SDGs</h2>
            </Col>
            <Col
              span={24}
              style={{
                marginTop: "10px",
              }}
            >
              {FlipCardSDGs.map((data, index) => {
                let imgUrl = data;
                if (data < 10) {
                  imgUrl = "0" + imgUrl;
                }
                return (
                  <img
                    key={index}
                    src={`https://assets.bridged.media/images/sdgs/${imgUrl}.png`}
                    alt=""
                    style={{
                      width: "100%",
                      maxWidth: "100px",
                    }}
                  />
                );
              })}
            </Col>
            <Col
              span={24}
              style={{
                marginTop: "var(--main-margin)",
              }}
            >
              <p>
                Click{" "}
                <a
                  href="#"
                  style={{
                    fontWeight: "bold",
                    color: "var(--secondary-Text-Color)",
                  }}
                >
                  here
                </a>{" "}
                if you want to know more about the UN Sustainable Development
                Goals.
              </p>
            </Col>
          </Row>
        </Col>
      </Row> */}
      <Row>
        <Col
          span={24}
          style={{
            marginTop: "var(--main-margin)",
            marginBottom: "var(--main-margin)",
          }}
        >
          <Row justify="space-between" align="middle">
            <Button
              size="large"
              className="button"
              onClick={() => {
                dispatch(currentSectionSetter(3));
              }}
            >
              <ArrowLeftOutlined /> Back
            </Button>
            <Button
              size="large"
              className="button"
              type="primary"
              loading={isLoading || isLoadingUpdate || isLoadingImage}
              onClick={handleSubmitFlipcard}
            >
              Next <ArrowRightOutlined />
            </Button>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Preview;
