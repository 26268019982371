import React from "react";
import { Button, Col, Input, Row, Switch } from "antd";
import cardColors from "../../../ExtraComponents/cardColors";
import { useDispatch, useSelector } from "react-redux";
import { FlipcardTheme } from "../../../../redux/Slices/Flip Cards/FlipCardSlice";

function Customize() {
  const dispatch = useDispatch();
  const color = useSelector((state) => state.flipCard.theme);

  return (
    <Col span={24}>
      <h2>Customize your card theme</h2>
      <p
        style={{
          marginTop: "5px",
        }}
      >
        Pick a swatch for your card primary color
      </p>
      <Row
        align="center"
        justify="space-between"
        style={{
          width: "full",
        }}
      >
        {cardColors.map((data, index) => {
          return (
            <label key={index} className="checkBoxLabel colorcheckBoxLabel">
              <input
                value={data.color}
                checked={color.primaryColor == data.color}
                type="checkbox"
                onChange={(e) => {
                  dispatch(
                    FlipcardTheme({
                      ...color,
                      primaryColor: data.color,
                    })
                  );
                }}
                className="checkBox"
              />
              <div
                className="checkBoxInner"
                style={{
                  backgroundColor: data.color,
                }}
              ></div>
            </label>
          );
        })}

        <Input
          type="color"
          className="inputBx"
          placeholder="+"
          value={color.primaryColor}
          onChange={(e) => {
            dispatch(
              FlipcardTheme({
                ...color,
                primaryColor: e.target.value,
              })
            );
          }}
          style={{
            width: "35px",
            height: "35px",
            marginTop: "10px",
            padding: "0px",
          }}
        />
      </Row>
      <Row
        style={{
          marginTop: "var(--sub-margin)",
        }}
        justify="space-between"
        align="middle"
      >
        <h2
          style={{
            marginTop: "var(--sub-margin)",
          }}
        >
          Dark Mode
        </h2>
        <Switch
          name="showShareSection"
          checked={color.theme == "dark"}
          onChange={() => {
            if (color.theme == "dark") {
              dispatch(
                FlipcardTheme({
                  ...color,
                  theme: "light",
                })
              );
            } else {
              dispatch(
                FlipcardTheme({
                  ...color,
                  theme: "dark",
                })
              );
            }
          }}
        />
      </Row>
    </Col>
  );
}

export default Customize;
