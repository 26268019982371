export default [
  {
    name: "Default",
    color: "#395EF9",
  },
  {
    name: "Red",
    color: "#f28b82",
  },
  {
    name: "Orange",
    color: "#fbbc04",
  },
  {
    name: "Yellow",
    color: "#fff475",
  },
  {
    name: "Green",
    color: "#ccff90",
  },
  {
    name: "Teal",
    color: "#a7ffeb",
  },
  {
    name: "Blue",
    color: "#cbf0f8",
  },
  {
    name: "Dark blue",
    color: "#aecbfa",
  },
  {
    name: "Purple",
    color: "#d7aefb",
  },
  {
    name: "Pink",
    color: "#fdcfe8",
  },
  {
    name: "Brown",
    color: "#e6c9a8",
  },
  {
    name: "Gray",
    color: "#e8eaed",
  },
];
