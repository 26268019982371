import { createSlice } from "@reduxjs/toolkit";
import LinkInitialState from "../../../../Initial States/Actions/LinkInitialState";

const ActLinkSlice = createSlice({
  name: "ActLink",
  initialState: {
    action: LinkInitialState,
  },
  reducers: {
    setActLink: (state, action) => {
      state.action = action.payload;
    },
  },
});

export const { setActLink } = ActLinkSlice.actions;
export default ActLinkSlice.reducer;
