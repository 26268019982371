import {
  Button,
  Card,
  Col,
  Dropdown,
  Menu,
  Row,
  Pagination,
  Skeleton,
  Popconfirm,
  message,
} from "antd";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useFlipCardDeleteMutation,
  useFlipCardChangeStatusMutation,
  useDuplicateFlipcardMutation,
} from "../../../Services/service";
import { EllipsisOutlined, PlusOutlined } from "@ant-design/icons";
import engagementNameConverter from "../../ExtraComponents/engagementNameConverter";
import engagementIconFinder from "../../ExtraComponents/engagementIconFinder";
import engagementColorFinder from "../../ExtraComponents/engagementColorFinder";
import DashboardSortingName from "../DashboardReusableFunctions/DashboardSortingName";
import DashboardSearchName from "../DashboardReusableFunctions/DashboardSearchName";

const FlipCards = ({
  data,
  isSuccess,
  isLoading,
  filterData,
  sortData,
  searchKeyword,
}) => {
  const history = useHistory();
  const [_deleteFlipcard] = useFlipCardDeleteMutation();
  const [_flipcardStatusChange] = useFlipCardChangeStatusMutation();
  const [_flipcardDuplicate] = useDuplicateFlipcardMutation();
  const [paginationPage, setPaginationPage] = useState(0);
  const currRole = useSelector((state) => state.auth.role);
  const [paginationPageSize, setPaginationPageSize] = useState(7);
  const [actions, setActions] = useState([{}, {}, {}, {}, {}, {}, {}]);
  useEffect(() => {
    if (isSuccess) {
      setActions(data);
    }
  }, [isSuccess, data]);

  let displayActions = actions.slice(
    paginationPage * paginationPageSize,
    (paginationPage + 1) * paginationPageSize
  );

  const paginationChange = (page, pageSize) => {
    setPaginationPage(page - 1);
    setPaginationPageSize(pageSize);
  };

  const filterDatahandler = (e) => {
    let filters = [];

    const engObj = {
      type: "engagementType",
      value: [...e.filterDataEngagement],
    };
    const actObj = {
      type: "actionTypes",
      value: [...e.filterDataAction],
    };
    const statObj = {
      type: "isActive",
      value: [...e.filterDataStatus],
    };

    // --------------------------------------
    if (e.filterDataEngagement.length !== 0) {
      filters.push(engObj);
    }
    if (e.filterDataAction.length !== 0) {
      filters.push(actObj);
    }
    if (e.filterDataStatus.length !== 0) {
      filters.push(statObj);
    }

    let filteredArr = [...data];

    for (let filter of filters) {
      filteredArr = filteredArr.filter((flipcart) => {
        if (typeof flipcart[filter.type] == "object") {
          filter.value.forEach((e) => {
            if (flipcart[filter.type].includes(e)) {
              return flipcart;
            }
          });
        }
        if (filter.value.includes(flipcart[filter.type])) {
          return flipcart;
        }
      });
    }

    setActions(filteredArr);
  };

  useEffect(() => {
    if (isSuccess) {
      const sortedData = DashboardSortingName(sortData?.sortData[0], data);
      setActions(sortedData);
    }
  }, [isSuccess, sortData]);

  useEffect(() => {
    if (isSuccess) {
      filterDatahandler(filterData);
    }
  }, [isSuccess, filterData]);

  useEffect(() => {
    if (isSuccess) {
      const searchResults = DashboardSearchName(searchKeyword, data);
      setActions(searchResults);
    }
  }, [isSuccess, searchKeyword]);

  const deleteConfirm = async (_id) => {
    try {
      const response = await _deleteFlipcard(_id);
      if (response?.error) {
        message.error("Something went wrong");
      }
      if (response?.data) {
        message.success("Flipcard deleted successfully");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const pauseCardHandler = async (_id, status) => {
    try {
      const response = await _flipcardStatusChange({ _id, status });
      if (response?.error) {
        message.error("Something went wrong");
      }
      if (response?.data) {
        message.success("Flipcard Status changed successfully");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const duplicateCardHandler = async (_id) => {
    try {
      const response = await _flipcardDuplicate(_id);
      if (response?.error) {
        message.error("Something went wrong");
      }
      if (response?.data) {
        message.success("Flipcard duplicated successfully");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const skeleton = () => {
    return (
      <div style={{ padding: "25px" }} className="dashboardMyActionCard">
        <Skeleton.Button active={true} shape="round" />
        <Skeleton
          active={true}
          paragraph={{ rows: 2 }}
          style={{
            marginTop: "var(--main-margin)",
          }}
        />
        <Skeleton
          active={true}
          paragraph={{ rows: 0 }}
          style={{
            marginTop: "var(--sub-margin)",
          }}
        />
      </div>
    );
  };

  return (
    <>
      <Row
        style={{
          marginTop: "var(--main-margin)",
          marginBottom: "var(--main-margin)",
        }}
      >
        <Col
          span={24}
          style={{
            marginTop: "var(--sub-margin)",
          }}
        >
          <Row gutter={[30, 30]} aign="middle" justify="start">
            <Col {...{ xs: 24, sm: 12, md: 12, lg: 12, xl: 6 }}>
              <Button
                type="dashed"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "var(--input-Border-Radius3)",
                }}
                className="dashboardMyActionCard opacity05"
                onClick={() => {
                  if (currRole == "publisher") {
                    history.push("/create");
                  } else {
                    history.push("/createtemplate");
                  }
                }}
              >
                <PlusOutlined
                  style={{
                    fontSize: "2rem",
                  }}
                />
                <h1
                  style={{
                    fontSize: "1.3rem",
                    marginTop: "var(--sub-margin)",
                  }}
                >
                  {currRole == "publisher"
                    ? "Create new Flipcard"
                    : "Create Template"}
                </h1>
              </Button>
            </Col>
            {displayActions.map((data, index) => {
              const {
                name,
                createdDate,
                actionTypes,
                _id,
                isActive,
                engagementType,
                hashtags,
              } = data;
              const titleLength = name?.substring(0, 40);
              const dropdown = (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="1"
                        onClick={() => {
                          history.push(`/flipcardedit/${_id}`);
                        }}
                      >
                        Edit
                      </Menu.Item>
                      <Menu.Item
                        key="2"
                        onClick={() => {
                          duplicateCardHandler(_id);
                        }}
                      >
                        Duplicate
                      </Menu.Item>

                      <Menu.Item
                        key="3"
                        onClick={() => {
                          pauseCardHandler(_id, !isActive);
                        }}
                      >
                        {isActive ? "Pause" : "Resume"}
                      </Menu.Item>
                      <Popconfirm
                        title="Are you sure to delete this Flipcard?"
                        onConfirm={() => {
                          deleteConfirm(_id);
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Menu.Item key="4">Delete</Menu.Item>
                      </Popconfirm>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <EllipsisOutlined style={{ fontSize: "1.4rem" }} />
                </Dropdown>
              );

              return (
                <Col key={index} {...{ xs: 24, sm: 12, md: 12, lg: 12, xl: 6 }}>
                  {isLoading ? (
                    skeleton()
                  ) : (
                    <Card bordered={false} className="dashboardMyActionCard">
                      <Row style={{ width: "100%", height: "100%" }}>
                        <Col span={24}>
                          <Row align="middle" justify="space-between">
                            <Button
                              shape="round"
                              style={{
                                backgroundColor:
                                  engagementColorFinder(engagementType),
                                color: !engagementType ? "#000" : "#fff",
                              }}
                            >
                              {engagementIconFinder(engagementType)}
                              {engagementNameConverter(engagementType)}
                              {!engagementType ? "No Engagement" : null}
                            </Button>
                            {_id ? dropdown : ""}
                          </Row>
                        </Col>
                        <Row
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            history.push(`/flipcardoverview/${_id}`);
                          }}
                        >
                          <Col
                            span={24}
                            style={{
                              marginTop: "15px",
                            }}
                          >
                            <a
                              style={{
                                color: "var(--secondary-Text-Color)",
                              }}
                            >
                              <b>{actionTypes?.length}</b> action
                            </a>
                          </Col>
                          <Row
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              marginTop: "var(--main-margin)",
                            }}
                          >
                            <Col span={24}>
                              <Row justify="start" align="middle">
                                <Col
                                  span={24}
                                  className="dashboardMyActionCardTitle"
                                >
                                  <h1
                                    style={{
                                      color: "#192b9d",
                                      fontSize: "1.7rem",
                                      fontWeight: "200",
                                      height: "85px",
                                    }}
                                  >
                                    {name?.substring(0, 32)}
                                    {name ? null : "Refresh The Page"}
                                    {titleLength?.length > 32 ? "..." : ""}
                                  </h1>
                                </Col>
                                <Col
                                  span={24}
                                  style={{
                                    marginTop: "15px",
                                  }}
                                >
                                  <Row
                                    align="middle"
                                    justify="start"
                                    className="dashboardMyActionCardHashTags"
                                  >
                                    <p>
                                      {hashtags?.map((data, index) => {
                                        return data + " ";
                                      })}
                                    </p>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Col
                            span={24}
                            style={{
                              marginTop: "var(--sub-margin)",
                            }}
                          >
                            <Row align="middle" justify="space-between">
                              <p>{moment(createdDate).format("MMM Do YYYY")}</p>
                              <p>{/* <UsergroupAddOutlined /> 30% */}</p>
                            </Row>
                          </Col>
                        </Row>
                      </Row>
                    </Card>
                  )}
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      {actions.length > 7 ? (
        <Col
          span={24}
          style={{
            marginTop: "var(--main-margin)",
            marginBottom: "var(--main-margin)",
          }}
        >
          <Row align="middle" justify="end">
            <Pagination
              className="dashboardPagnaition"
              size="small"
              defaultPageSize={7}
              onChange={paginationChange}
              total={actions.length}
            />
          </Row>
        </Col>
      ) : (
        ""
      )}
    </>
  );
};
export default FlipCards;
