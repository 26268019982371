import { Col, Row, Table } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  AreaChart,
  XAxis,
  YAxis,
  Area,
  CartesianGrid,
  BarChart,
  Legend,
  Bar,
} from "recharts";

function AquireUsers() {
  const flipCardData = useSelector((state) => state.Analytics.data);
  const [chartData, setChartData] = useState([]);

  const COLORS = ["#001493", "#4b5bbb", "#8c98e3"];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  useEffect(() => {
    const data = [];
    flipCardData?.sources?.forEach((item) => {
      let name = "";
      if (item.name === "LocalOrigin") {
        name = "LocalHost";
      } else {
        name = new URL(item.name).hostname;
      }
      const obj = {
        name: name,
        actionTake: item.overallReport.actionTake,
        engagementTake: item.overallReport.engagementTake,
        totalView: item.overallReport.totalView,
      };
      const currentIndex = data.find((item) => item.name == name);
      if (currentIndex) {
        currentIndex.actionTake += item.overallReport.actionTake;
        currentIndex.engagementTake += item.overallReport.engagementTake;
        currentIndex.totalView += item.overallReport.totalView;
      } else {
        data.push(obj);
      }
    });
    setChartData(data);
  }, [flipCardData]);
  return (
    <>
      <Col span={24}>
        <h3>Source</h3>
      </Col>
      <Col span={24}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={chartData}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={150}
              dataKey="totalView"
            >
              {flipCardData?.sources?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </Col>
    </>
  );
}

export default AquireUsers;
