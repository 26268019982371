export default {
  title: "Poll Title",
  description: "Poll Description",
  engagementData: {
    engagementType: "sliderPoll",
    options: [
      {
        title: "Option 1",
        mark: true,
      },
      {
        title: "option2",
        mark: false,
      },
      {
        title: "option3",
        mark: false,
      },
      {
        title: "option4",
        mark: false,
      },
      {
        title: "option5",
        mark: false,
      },
      {
        title: "option6",
        mark: false,
      },
      {
        title: "option7",
        mark: false,
      },
      {
        title: "option8",
        mark: false,
      },
      {
        title: "Option 2",
        mark: true,
      },
    ],
  },
};
