import { Col } from "antd";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React from "react";
import mapData from "@highcharts/map-collection/custom/world.geo.json";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function WorldMap() {
  const flipCardData = useSelector((state) => state.Analytics.data);
  const [userMapData, setUserMapData] = useState([]);

  useEffect(() => {
    const userData = flipCardData?.countries?.map((item) => [
      item.name.toLowerCase(),
      item.overallReport.totalView,
      ,
    ]);
    setUserMapData(userData);
  }, [flipCardData]);

  const mapOptions = {
    title: {
      text: "",
    },
    colorAxis: {
      min: 0,
      stops: [
        [0.4, "#8c98e3"],
        [0.65, "#4b5bbb"],
        [1, "#001493"],
      ],
    },
    mapNavigation: {
      enabled: true,
      enableDoubleClickZoomTo: true,
      buttonOptions: {
        verticalAlign: "bottom",
      },
    },

    series: [
      {
        mapData,
        name: "World",
        data: userMapData,
      },
    ],
  };
  return (
    <Col span={24}>
      <div>
        <HighchartsReact
          options={mapOptions}
          constructorType={"mapChart"}
          highcharts={Highcharts}
        />
      </div>
    </Col>
  );
}

export default WorldMap;
