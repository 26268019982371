import { Col, Row, Skeleton } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const COLORS = ["#001493", "#4b5bbb", "#8c98e3"];
const FirstSection = ({ flipCardData, isLoading }) => {
  let actionRate =
    (flipCardData?.totalActionTake / flipCardData?.totalViews) * 100;
  if (actionRate >= 100) {
    actionRate = 100;
  }
  let engRate =
    (flipCardData?.totalEngagementTake / flipCardData?.totalViews) * 100;
  if (engRate >= 100) {
    engRate = 100;
  }
  const [firstChartData, setFirstChartData] = useState([]);
  const [secondChartData, setSecondChartData] = useState([]);
  useEffect(() => {
    const data = flipCardData?.performanceChart?.map((item) => {
      return {
        date: moment(item.date).format("YYYY-MM-DD"),
        actionTake: item.overallReport.actionTake,
        engagementTake: item.overallReport.engagementTake,
        totalView: item.overallReport.totalView,
      };
    });
    setFirstChartData(data);
    const data2 = flipCardData?.sources?.map((item) => {
      let name = "";
      if (item.name === "LocalOrigin") {
        name = "LocalHost";
      } else {
        name = new URL(item.name).hostname;
      }

      return {
        name: name,
        actionTake: item.overallReport.actionTake,
        engagementTake: item.overallReport.engagementTake,
        totalView: item.overallReport.totalView,
      };
    });
    setSecondChartData(data2);
  }, [flipCardData]);

  return (
    <Row gutter={[20, 20]} style={{ marginTop: "10px" }}>
      <Col {...{ xs: 24, sm: 24, md: 24, lg: 24, xl: 12 }}>
        <Row>
          <Col span={24}>
            <h2>Overall Performance</h2>
          </Col>
        </Row>
        {isLoading ? (
          <Skeleton.Button
            active={true}
            block={true}
            style={{
              height: "515px",
              borderRadius: "var(--input-Border-Radius3)",
              marginTop: "10px",
            }}
          />
        ) : (
          <Row
            style={{
              marginTop: "10px",
              backgroundColor: "#fff",
              padding: "10px",
              borderRadius: "var(--input-Border-Radius3)",
            }}
          >
            <Col span={24} style={{ marginTop: "10px" }}>
              <Row gutter={[10, 10]}>
                <Col {...{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
                  <Row className="analyticsCenter">
                    <h3>Views</h3>
                    <h1>
                      {flipCardData?.totalViews ? flipCardData?.totalViews : 0}
                    </h1>
                  </Row>
                </Col>
                <Col {...{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
                  <Row className="analyticsCenter">
                    <h3>Engagement</h3>
                    <h1>
                      {engRate.toFixed(1) == "NaN" ? "0" : engRate.toFixed(1)}%
                    </h1>
                  </Row>
                </Col>
                <Col {...{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
                  <Row className="analyticsCenter">
                    <h3>Action</h3>
                    <h1>
                      {actionRate.toFixed(1) == "NaN"
                        ? "0"
                        : actionRate.toFixed(1)}
                      %
                    </h1>
                  </Row>
                </Col>
                <Col {...{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
                  <Row className="analyticsCenter">
                    <h3>Conversation</h3>
                    <h1>
                      {flipCardData?.totalConversion
                        ? flipCardData?.totalConversion
                        : 0}
                    </h1>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24} style={{ marginTop: "10px", height: "400px" }}>
              <ResponsiveContainer>
                <LineChart width={500} height={300} data={firstChartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="Total views"
                    stroke="var(--chart-Color-1)"
                  />
                  <Line
                    type="monotone"
                    dataKey="Engagement takes"
                    stroke="var(--chart-Color-2)"
                  />
                  <Line
                    type="monotone"
                    dataKey="Action takes"
                    stroke="var(--chart-Color-3)"
                  />
                </LineChart>
              </ResponsiveContainer>
            </Col>
          </Row>
        )}
      </Col>
      <Col {...{ xs: 24, sm: 24, md: 24, lg: 24, xl: 12 }}>
        <Row>
          <Col span={24}>
            <h2>How do you aquire users?</h2>
          </Col>
        </Row>
        {isLoading ? (
          <Skeleton.Button
            active={true}
            block={true}
            style={{
              height: "515px",
              borderRadius: "var(--input-Border-Radius3)",
              marginTop: "10px",
            }}
          />
        ) : (
          <Row
            style={{
              marginTop: "10px",
              backgroundColor: "#fff",
              padding: "10px",
              borderRadius: "var(--input-Border-Radius3)",
            }}
          >
            <Col span={24} style={{ marginTop: "10px" }}>
              <Row justify="start" align="middle">
                <h3 style={{ marginLeft: "var(--sub-margin)" }}>Source</h3>
              </Row>
            </Col>
            <Col span={24} style={{ marginTop: "10px", height: "450px" }}>
              <Row style={{ width: "100%", height: "100%" }}>
                <Col span={24}>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      {/* <Legend
                        layout="vertical"
                        verticalAlign="middle"
                        align="right"
                      /> */}
                      <Pie
                        data={secondChartData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={150}
                        dataKey="totalView"
                      >
                        {flipCardData?.sources?.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};
export default FirstSection;
