import { Button, Col, Row, Skeleton, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import actionNameConverter from "../ExtraComponents/actionNameConverter";
import engagementColorFinder from "../ExtraComponents/engagementColorFinder";
import engagementIconFinder from "../ExtraComponents/engagementIconFinder";
import engagementNameConverter from "../ExtraComponents/engagementNameConverter";
function LeftSection() {
  const data = useSelector(
    (state) => state.NewFlipcardOverview.flipcardOverviewData
  );
  const etcData = useSelector(
    (state) => state.NewFlipcardOverview.flipcardOverviewDataStatus
  );
  const { isLoading } = etcData;
  const titleLength = data?.name?.substring(0, 100);
  const title = data?.name?.substring(0, 100);
  const descLength = data?.description?.substring(0, 300);
  const desc = data?.description?.substring(0, 300);
  return (
    <Col {...{ xs: 24, sm: 24, md: 9, lg: 9, xl: 9 }}>
      <Row>
        <Col span={24} style={{ marginTop: "var(--sub-margin)" }}>
          {isLoading ? (
            <div>
              <Skeleton.Button
                active={true}
                shape="round"
                style={{ marginRight: "10px" }}
              />

              <Skeleton active={true} paragraph={{ rows: 3 }} />
            </div>
          ) : (
            <>
              <Button
                shape="round"
                style={{
                  backgroundColor: engagementColorFinder(data?.engagementType),
                  color: "#fff",
                }}
              >
                {engagementIconFinder(data?.engagementType)}
                {engagementNameConverter(data?.engagementType)}
              </Button>
              <Tooltip title={data?.name}>
                <h1
                  style={{ fontWeight: "300", marginTop: "var(--main-margin)" }}
                >
                  {title}
                  {titleLength?.length >= 100 ? "..." : ""}
                </h1>
              </Tooltip>
              <Tooltip title={desc}>
                <p
                  style={{
                    marginTop: "10px",
                  }}
                >
                  {desc}
                  {descLength?.length >= 300 ? "..." : ""}
                </p>
              </Tooltip>
              <p
                style={{
                  marginTop: "10px",
                  color: "var(--third-Text-Color)",
                  fontWeight: "300",
                }}
              >
                Created date -{" "}
                {moment(data?.createdDate).format("MMM DD, YYYY")}
              </p>

              <Row
                gutter={[10, 30]}
                style={{
                  textAlign: "center",
                  width: "100%",
                  maxWidth: "400px",
                  marginTop: "var(--main-margin)",
                }}
              >
                <Col span={12}>
                  <Row className="cardOverviewLeftSectionCenter">
                    <h1>{data?.flipcardOveralReport?.totalViews}</h1>
                    <p style={{ opacity: "0.7" }}>Total views</p>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row className="cardOverviewLeftSectionCenter">
                    <h1>
                      {data?.flipcardOveralReport?.engagementRate == 0
                        ? "-"
                        : data?.flipcardOveralReport?.engagementRate + "%"}
                    </h1>
                    <p style={{ opacity: "0.7" }}>Engagement rate</p>
                  </Row>
                </Col>
                {data?.flipcardOveralReport?.totalActionTakes?.map(
                  (data, index) => {
                    let actionRate = data?.rate;

                    if (actionRate == 0 || actionRate == null) {
                      actionRate = "-";
                    } else {
                      actionRate = actionRate + "%";
                    }

                    return (
                      <>
                        <Col key={index} span={12}>
                          <Row className="cardOverviewLeftSectionCenter">
                            <h1>{data?.actionTake}</h1>
                            <p style={{ opacity: "0.7" }}>
                              {actionNameConverter(data?.actionType)} Take
                            </p>
                          </Row>
                        </Col>
                        <Col key={index} span={12}>
                          <Row className="cardOverviewLeftSectionCenter">
                            <h1>{actionRate}</h1>
                            <p style={{ opacity: "0.7" }}>
                              {actionNameConverter(data?.actionType)} rate
                            </p>
                          </Row>
                        </Col>
                      </>
                    );
                  }
                )}
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Col>
  );
}

export default LeftSection;
