import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, message, Row, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { setActLink } from "../../../../redux/Slices/Flip Cards/Actions/ActLinkSlice";
import { AdminActionObject } from "../../../../redux/Slices/Flip Cards/AdminFlipCardSlice";
import isValidHttpUrl from "../../../ExtraComponents/isValidHttpUrl";

import { shouldActSecChangeSetter } from "../../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
import { isImage } from "../../../ExtraComponents/isImage";
import ActionsTTI from "../../../ExtraComponents/Components/ActionsTTI";
function Link() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.ActLink.action);
  // Pushing Code to Redux Store
  useEffect(() => {
    dispatch(setActLink(userData));
    dispatch(AdminActionObject(userData));
  }, []);

  // Checking if the required Fields are filled or not
  useEffect(() => {
    if (
      userData.title == "" ||
      userData.description == "" ||
      userData.imageURL == "" ||
      userData.actionData.buttonText == "" ||
      userData.actionData.buttonLink == ""
    ) {
      dispatch(shouldActSecChangeSetter(false));
    } else {
      dispatch(shouldActSecChangeSetter(true));
    }
  }, [userData]);

  // Uploading Image
  const uploadImage = async (file) => {
    const fileType = file?.type.substring(file?.type.indexOf("/") + 1);
    const sizeofImg = file?.size;
    if (!(fileType === "jpeg" || fileType === "png" || fileType === "jpg")) {
      return message.error("Image type should be jpeg,png,jpg");
    }
    if (sizeofImg >= 1500000) {
      return message.error("Image size should be less than 1.5MB");
    }

    if (file?.status == "removed") {
      file = "";
    }
    let sentData = { ...userData, imageURL: file };
    dispatch(setActLink(sentData));
    dispatch(AdminActionObject(sentData));
  };

  // onChange Handler for Inputs
  const onChangeHandler = (e) => {
    let sentData = { ...userData };
    const { name, value } = e.target;
    if (name == "buttonLink" || name == "buttonText") {
      sentData = {
        ...sentData,
        actionData: { ...sentData.actionData, [name]: value },
      };
    } else {
      sentData = { ...sentData, [name]: value };
    }
    dispatch(setActLink(sentData));
    dispatch(AdminActionObject(sentData));
  };

  return (
    <Col span={24}>
      <Row style={{ width: "100%", marginTop: "var(--sub-margin)" }}>
        <Col span={24}>
          <Form.Item label="Title *">
            <Input
              type="text"
              placeholder="Link Title"
              className="inputBx"
              value={userData.title}
              name="title"
              onChange={onChangeHandler}
            />
          </Form.Item>
          <Form.Item label="Description *">
            <Input
              type="text"
              placeholder="Link Description"
              className="inputBx"
              value={userData.description}
              name="description"
              onChange={onChangeHandler}
            />
          </Form.Item>
          <Form.Item label="Button Link *">
            <Input
              type="url"
              placeholder="Button Link"
              className="inputBx"
              value={userData.actionData.buttonLink}
              name="buttonLink"
              onChange={onChangeHandler}
            />
          </Form.Item>
          <Form.Item label="Button Text *">
            <Input
              type="text"
              placeholder="Button Text"
              className="inputBx"
              value={userData.actionData.buttonText}
              name="buttonText"
              onChange={onChangeHandler}
            />
          </Form.Item>
          <Form.Item label="Image * (maximum mage size should be 1.5MB and file format should be jpeg, png, jpg)">
            <Upload
              listType="picture-card"
              name="file"
              maxCount={1}
              onPreview={() => false}
              onChange={(e) => {
                uploadImage(e?.file);
              }}
              beforeUpload={() => false}
            >
              {isImage(userData?.imageURL) ? null : <PlusOutlined />}
            </Upload>
            {isValidHttpUrl(userData?.imageURL) && (
              <img
                src={userData?.imageURL}
                style={{
                  width: "100%",
                  height: "100%",
                  maxWidth: "200px",
                  marginTop: "var(--sub-margin)",
                  marginBottom: "var(--sub-margin)",
                }}
              />
            )}
          </Form.Item>
          <ActionsTTI />
        </Col>
      </Row>
    </Col>
  );
}

export default Link;
