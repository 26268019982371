import {
  CommentOutlined,
  MailOutlined,
  ScheduleOutlined,
  FileTextOutlined,
  DollarOutlined,
  LinkOutlined,
  FormOutlined,
} from "@ant-design/icons";
export default function actionIconFinder(name) {
  if (name === "messageBox") {
    return <CommentOutlined />;
  }
  if (name === "signAPetition") {
    return <FileTextOutlined />;
  }
  if (name === "newsLetterSignup") {
    return <MailOutlined />;
  }
  if (name === "donation") {
    return <DollarOutlined />;
  }
  if (name === "link") {
    return <LinkOutlined />;
  }
  if (name === "dynamicForm") {
    return <FormOutlined />;
  }
}
