import React, { useEffect, useState } from "react";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  EditFilled,
} from "@ant-design/icons";
import { Button, Col, message, Row, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { currentSectionSetter } from "../../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
import Checkbox from "antd/lib/checkbox/Checkbox";

import {
  useFlipcardActionInventoriesPostMutation,
  useFlipcardEngagementInventoriesPostMutation,
  useFlipcardEngagementInventoriesEditMutation,
  useFlipcardActionInventoriesEditMutation,
  useUploadImageMutation,
} from "../../../../Services/service";
import { useParams } from "react-router-dom";
import SDGs from "../../../ExtraComponents/SDGs";
import isValidHttpUrl from "../../../ExtraComponents/isValidHttpUrl";
function Preview({ currentTemplateType, inventory, setInventory }) {
  const { _id } = useParams();

  const pathname = window.location.href.split("/")[3];
  const [shouldPrevChange, setShouldPrevChange] = useState(false);
  const adminFlipCardData = useSelector((state) => state.AdminFlipCard);
  const [isLoading, setIsLoading] = useState();
  const [isCheckbox, setIsCheckbox] = useState(false);
  const topicsArr = [
    "POLITICS",
    "STARTUPS",
    "BUSINESS",
    "HABITAT",
    "GLOBAL HEALTH",
    "TECHNOLOGY",
    "TRAVEL",
    "GENDER EQUALITY",
    "ART AND CULTURE",
    "SOCIAL MOVEMENTS",
    "CLIMATE CRISIS",
    "SCIENCE",
    "HUMAN RIGHTS",
    "MENTAL HEALTH",
    "BIOTECH",
    "EDUCATION",
    "CRYPTOCURRENCY",
  ];

  const dispatch = useDispatch();

  const handleTopics = (e) => {
    if (e.target.checked) {
      setInventory({
        ...inventory,
        topics: [...inventory.topics, e.target.value],
      });
    } else {
      setInventory({
        ...inventory,
        topics: inventory.topics.filter((topic) => topic !== e.target.value),
      });
    }
  };

  const handleSDGS = (e) => {
    const targetval = parseInt(e.target.value);
    if (e.target.checked) {
      setInventory({
        ...inventory,
        SDGs: [...inventory.SDGs, targetval],
      });
    } else {
      setInventory({
        ...inventory,
        SDGs: inventory.SDGs.filter((sdg) => sdg !== targetval),
      });
    }
  };

  const handleHashTags = (e) => {
    setInventory({ ...inventory, hashtags: e });
  };

  useEffect(() => {
    const { SDGs, topics, hashtags } = inventory;
    if (SDGs.length > 0 && topics.length > 0 && hashtags.length > 0) {
      setShouldPrevChange(true);
    } else {
      setShouldPrevChange(false);
    }
  }, [inventory]);
  const [_actionInventories] = useFlipcardActionInventoriesPostMutation();
  const [_editActionInventories] = useFlipcardActionInventoriesEditMutation();
  const [_uploadImage] = useUploadImageMutation();

  const [_engagementInventories] =
    useFlipcardEngagementInventoriesPostMutation();

  const [_editEngagementInventories] =
    useFlipcardEngagementInventoriesEditMutation();

  const submitHandler = async () => {
    setIsLoading(true);
    const { actionObject, engagementObject } = adminFlipCardData;
    let response = "";
    let userData = {};
    if (currentTemplateType == "action") {
      userData = { ...actionObject, _id, inventory };
      if (!isValidHttpUrl(actionObject?.imageURL)) {
        let formData = new FormData();
        formData.append("file", actionObject?.imageURL);
        let imageSrc = await _uploadImage(formData);
        userData = { ...userData, imageURL: imageSrc?.data?.url };
      }

      if (pathname === "templateedit") {
        response = await _editActionInventories({ userData, isCheckbox });
      } else {
        userData = { ...userData, _id: undefined };
        response = await _actionInventories(userData);
      }
    }
    if (currentTemplateType == "engagement") {
      if (pathname === "templateedit") {
        userData = { ...engagementObject, inventory, _id };
        response = await _editEngagementInventories(userData);
      } else {
        userData = { ...engagementObject, inventory };
        response = await _engagementInventories(userData);
      }
    }

    if (response?.data) {
      dispatch(currentSectionSetter(3));
      if (pathname === "templateedit") {
        message.success("Template Updated Successfully");
      }
    } else {
      message.error("Something went wrong");
    }
    setIsLoading(false);
  };

  return (
    <>
      <Row className="flipcardOutCompGrey">
        <Col
          span={24}
          className="flipcardInCompWhite"
          style={{
            backgroundColor: "transparent",
          }}
        >
          <p>
            We are one step away from creating your Bridged card. Carefully
            review all the details and press Next{" "}
          </p>
        </Col>
        <Col
          span={24}
          className="flipcardInCompWhite"
          style={{
            marginTop: "var(--sub-margin)",
          }}
        >
          <Row justify="space-between" align="middle">
            <h2>Edit your {currentTemplateType}</h2>
            <h2
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(currentSectionSetter(1));
              }}
            >
              <EditFilled /> EDIT
            </h2>
          </Row>
        </Col>
      </Row>
      <Row
        className="flipcardOutCompGrey"
        style={{
          marginTop: "var(--main-margin)",
        }}
      >
        <Col span={24} className="flipcardInCompWhite">
          <h2>Select Supported SDGs</h2>
          <p className="opacity05">
            Click on the SDGs you are supporting with your Bridged card
          </p>
          <div
            style={{
              marginTop: "var(--sub-margin)",
              overflow: "scroll",
              display: "flex",
            }}
          >
            {SDGs.map((data, index) => {
              return (
                <label
                  key={index}
                  className="checkBoxLabel adminSDGcheckBoxLabel"
                >
                  <input
                    value={data.digitValue}
                    checked={inventory.SDGs.includes(data.digitValue)}
                    onChange={handleSDGS}
                    type="checkbox"
                    className="checkBox"
                  />
                  <div className="checkBoxInner">
                    <img
                      src={`https://assets.bridged.media/${data.imgSrc}`}
                      alt=""
                    />
                  </div>
                </label>
              );
            })}
          </div>
          <p
            className="opacity05"
            style={{
              marginTop: "var(--sub-margin)",
            }}
          >
            Click{" "}
            <a
              href="https://sdgs.un.org/goals"
              target="blank"
              style={{
                fontWeight: "bold",
                color: "var(--secondary-Text-Color)",
              }}
            >
              here
            </a>{" "}
            if you want to know more about the UN Sustainable Development Goals.
          </p>
        </Col>
        <Col
          style={{
            marginTop: "var(--sub-margin)",
          }}
          span={24}
          className="flipcardInCompWhite"
        >
          <h2>Select the Topics</h2>
          <Row
            style={{
              marginTop: "var(--sub-margin)",
            }}
          >
            {topicsArr.map((data, index) => {
              return (
                <label key={index} className="checkBoxLabel admincheckBoxLabel">
                  <input
                    type="checkbox"
                    value={data}
                    name={data}
                    checked={inventory.topics.includes(data)}
                    onChange={handleTopics}
                    className="checkBox"
                  />
                  <div className="checkBoxInner">
                    <div className="checkBoxName">{data}</div>
                  </div>
                </label>
              );
            })}
          </Row>
          <Row
            style={{
              marginTop: "var(--main-margin)",
            }}
          >
            <Col span={24}>
              <h2>Add Hashtags</h2>
            </Col>
            <Col
              span={24}
              style={{
                marginTop: "var(--sub-margin)",
              }}
            >
              <Select
                mode="tags"
                onChange={(e) => {
                  handleHashTags(e);
                }}
                value={inventory.hashtags}
                dropdownStyle={{
                  display: "none",
                }}
                size="large"
                placeholder="Type in..."
                className="antdSelect"
                bordered={false}
              ></Select>
            </Col>
            <Col
              span={24}
              style={{
                marginTop: "var(--sub-margin)",
              }}
            >
              <Checkbox
                name="newsLetter"
                checked={isCheckbox}
                onChange={() => {
                  setIsCheckbox(!isCheckbox);
                }}
              >
                <p
                  style={{
                    fontSize: "0.9rem",
                    fontWeight: "400",
                    opacity: 1,
                  }}
                >
                  {" "}
                  Update all flip cards that contain this action
                </p>
              </Checkbox>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col
          span={24}
          style={{
            marginTop: "var(--main-margin)",
            marginBottom: "var(--main-margin)",
          }}
        >
          <Row justify="space-between" align="middle">
            <Button
              size="large"
              className="button"
              onClick={() => {
                dispatch(currentSectionSetter(1));
              }}
            >
              <ArrowLeftOutlined /> Back
            </Button>
            <Button
              size="large"
              className="button"
              loading={isLoading}
              type="primary"
              disabled={!shouldPrevChange}
              onClick={() => {
                submitHandler();
              }}
            >
              Next <ArrowRightOutlined />
            </Button>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Preview;
