import { EyeFilled } from "@ant-design/icons";
import { Button, Col, Input, Pagination, Row, Select, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEngFactCheck } from "../../../../../redux/Slices/Flip Cards/Engagements/EngFactCheckSlice";
import { setEngSliderPoll } from "../../../../../redux/Slices/Flip Cards/Engagements/EngSliderPollSlice";
import { setEngQuiz } from "../../../../../redux/Slices/Flip Cards/Engagements/EngQuizSlice";
import {
  currEngSetter,
  optionsAdderSetter,
} from "../../../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
import { FlipCardengagement } from "../../../../../redux/Slices/Flip Cards/FlipCardSlice";
import { useFlipcardEngagementInventoriesQuery } from "../../../../../Services/service";
import totalEngagementsArray from "../../../../ExtraComponents/totalEngagementsArray";
import engagementIconFinder from "../../../../ExtraComponents/engagementIconFinder";

const { Option } = Select;
function SuggestedEng({ setCollapseActive }) {
  const pathname = window.location.href.split("/")[3];
  const [currentPagination, setCurrentPagination] = useState(1);
  const dispatch = useDispatch();
  const [AllSelector, setAllSelector] = useState(true);
  const flipcardCurrEngagement = useSelector(
    (state) => state.flipCard.engagement
  );

  useEffect(() => {
    pathname === "templateedit" && setAllSelector(false);
  }, []);
  const currEng = useSelector((state) => state.FlipCardEtc.currEng);
  const [suggestedEngData, setSuggestedEngData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const { data, isSuccess, isLoading } =
    useFlipcardEngagementInventoriesQuery(currentPagination);

  useEffect(() => {
    setSuggestedEngData(data);
  }, [isLoading, , isSuccess, data]);

  // Adds data to redux as per engagement type
  const engClickHandler = (data) => {
    const { title, description, engagementData } = data;
    const { engagementType, options } = engagementData;
    dispatch(currEngSetter(engagementType));
    if (options) {
      if (options.length <= 2) {
        dispatch(optionsAdderSetter(0));
      } else if (options.length === 3) {
        dispatch(optionsAdderSetter(1));
      } else if (options.length === 4) {
        dispatch(optionsAdderSetter(2));
      }
    }
    let userData = {
      title,
      description,
      engagementData,
    };
    if (engagementType == "factcheck") {
      dispatch(setEngFactCheck(userData));
      dispatch(FlipCardengagement(userData));
    }
    if (engagementType == "quiz") {
      dispatch(setEngQuiz(userData));
      dispatch(FlipCardengagement(userData));
    }
    if (engagementType == "sliderPoll") {
      dispatch(setEngSliderPoll(userData));
      dispatch(FlipCardengagement(userData));
    }
  };

  // Filters Data as per engagementType and searchQuery
  const filterDataHandler = () => {
    if (AllSelector && searchQuery.length > 0) {
      const filteredData = suggestedEngData?.filter((data) =>
        data?.title?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      );
      setDisplayData(filteredData);
    } else if (AllSelector) {
      setDisplayData(suggestedEngData);
    } else {
      const filterByEngType = suggestedEngData?.filter((data) => {
        return data.engagementData.engagementType === currEng;
      });
      setDisplayData(filterByEngType);
      if (searchQuery) {
        const filteredData = filterByEngType?.filter((data) =>
          data?.title?.toLowerCase()?.includes(searchQuery?.toLowerCase())
        );
        setDisplayData(filteredData);
      }
    }
  };

  // Runs when search query, engagement type and suggestedEngData changes
  useEffect(() => {
    filterDataHandler();
  }, [searchQuery, currEng, suggestedEngData, AllSelector]);

  const isActiveHandler = (data) => {
    if (
      data?.engagementData?.engagementType ===
        flipcardCurrEngagement?.engagementData?.engagementType &&
      data?.title === flipcardCurrEngagement?.title &&
      data?.description === flipcardCurrEngagement?.description &&
      data?.engagementData?.options ===
        flipcardCurrEngagement?.engagementData?.options
    ) {
      return "primary";
    } else {
      return "default";
    }
  };

  return (
    <Row>
      <Col span={24}>
        <p className="flipcardContentPanelSubHeading">
          Look up the cause you care about and select from the available
          engagement styles
        </p>
      </Col>
      <Col
        span={24}
        style={{
          marginTop: "var(--main-margin)",
        }}
      >
        <Row gutter={[0, 15]} align="middle" justify="space-between">
          <Col {...{ xs: 24, sm: 24, md: 18, lg: 18, xl: 18 }}>
            <Input
              type="text"
              size="large"
              className="inputBx"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Col>
          <Col {...{ xs: 8, sm: 8, md: 5, lg: 5, xl: 5 }}>
            <Select
              className="antdSelect"
              size="large"
              bordered={false}
              defaultValue={currEng}
              value={AllSelector ? "All" : currEng}
              disabled={pathname === "templateedit"}
              onChange={(e) => {
                if (e == "All") {
                  setAllSelector(true);
                } else {
                  dispatch(currEngSetter(e));
                  setAllSelector(false);
                }
              }}
            >
              <Option value="All">All</Option>
              {totalEngagementsArray.map((data, index) => {
                return (
                  <Option key={index} value={data.value}>
                    {data.icon} {data.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>
      </Col>
      <Col
        span={24}
        style={{
          marginTop: "var(--sub-margin)",
        }}
      >
        <Row gutter={[0, 10]} className="PreviousDataMain">
          {isLoading ? (
            <>
              <Skeleton.Button
                block={true}
                active={true}
                style={{
                  marginTop: "var(--sub-margin)",
                  marginBottom: "var(--sub-margin)",
                  height: "100px",
                  borderRadius: "var(--input-Border-Radius3)",
                }}
              />
            </>
          ) : (
            <>
              {displayData?.map((data, index) => {
                const { title, engagementData } = data;
                const engagementType = engagementData?.engagementType;
                const nameLength = data?.title?.substring(0, 32);
                return (
                  <Col
                    key={index}
                    span={24}
                    style={{
                      marginTop: "var(--sub-margin)",
                    }}
                  >
                    <Row
                      gutter={[0, 10]}
                      justify="space-between"
                      align="middle"
                    >
                      <Col>
                        <p>
                          <spa
                            style={{
                              marginRight: "5px",
                            }}
                            className="opacity05"
                          >
                            <span
                              style={{
                                marginLeft: "5px",
                              }}
                            >
                              {engagementIconFinder(engagementType)}
                            </span>
                          </spa>
                          {title?.substring(0, 30)}
                          {nameLength?.length > 30 ? "..." : ""}
                        </p>
                      </Col>
                      <Col>
                        <Row align="middle">
                          <Button
                            type={isActiveHandler(data)}
                            shape="round"
                            onClick={() => {
                              engClickHandler(data);
                              setCollapseActive(3);
                            }}
                            style={{
                              marginRight: "var(--sub-margin)",
                            }}
                          >
                            {isActiveHandler(data) === "primary"
                              ? "Selected"
                              : "Select"}
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                );
              })}
              {/* <Col
                span={24}
                style={{
                  marginTop: "var(--sub-margin)",
                  marginBottom: "var(--sub-margin)",
                }}
              >
                <Row align="middle" justify="end">
                  <Pagination
                    className="dashboardPagnaition"
                    size="small"
                    defaultPageSize={10}
                    total={data?.totalLength}
                    onChange={(page, pageSize) => {
                      setCurrentPagination(page);
                    }}
                  />
                </Row>
              </Col> */}
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
}

export default SuggestedEng;
