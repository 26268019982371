import {
  BarChartOutlined,
  BulbOutlined,
  BarsOutlined,
} from "@ant-design/icons";
const totalEngagementsArray = [
  {
    name: "Slider Poll",
    value: "sliderPoll",
    icon: <BarChartOutlined />,
  },
  {
    name: "Quiz",
    value: "quiz",
    icon: <BulbOutlined />,
  },
  {
    name: "Fact Check",
    value: "factcheck",
    icon: <BarsOutlined />,
  },
];

export default totalEngagementsArray;
