import { createSlice } from "@reduxjs/toolkit";
import QuizInitialState from "../../../../Initial States/Engagements/QuizInitialState";

const EngQuizSlice = createSlice({
  name: "EngQuiz",
  initialState: {
    engagement: QuizInitialState,
  },
  reducers: {
    setEngQuiz: (state, action) => {
      state.engagement = action.payload;
    },
  },
});

export const { setEngQuiz } = EngQuizSlice.actions;
export default EngQuizSlice.reducer;
