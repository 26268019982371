import { useEffect, useState } from "react";
import "./App.less";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Jwt from "jsonwebtoken";
import { useDispatch, useSelector } from "react-redux";
import ProtectedRoute from "./ProtectedRoute";
import { auth, roleSetter } from "./redux/Slices/Auth/authSlice";
import {
  useFlipcardDraftMakerMutation,
  useIsAthorizedQuery,
  useRenewTokenMutation,
} from "./Services/service";
import PublisherFlipCardWorkflow from "./Components/CardCreation/Publisher/PublisherFlipCardWorkflow";
import Navbar from "./Components/Navbar";
import Login from "./Components/User/Login";
import Signup from "./Components/User/Signup";
import ConfirmEmail from "./Components/User/ConfirmEmail";
import Dashboard from "./Components/Dashboard/Dashboard";
import { message } from "antd";
import ForgotPassword from "./Components/User/ForgotPassword";
import AnalyticsWorkflow from "./Components/Analytics/AnalyticsWorkflow";
import OnBoardingWorkflow from "./Components/OnBoarding/OnBoardingWorkflow";
import WordpressAuth from "./Components/ExternalRedirects/WordpressAuth";
import AdminFlipCardWorkflow from "./Components/CardCreation/Admin/AdminFlipCardWorkflow";
import FlipCardOverviewWorkflow from "./Components/FlipcardOverview/FlipCardOverviewWorkflow";
import ChromePlugin from "./Components/ExternalRedirects/ChromePlugin";
import { FlipCardengagement } from "./redux/Slices/Flip Cards/FlipCardSlice";
import { AdminEngagementObject } from "./redux/Slices/Flip Cards/AdminFlipCardSlice";
import UserProfile from "./Components/User/Account/UserProfile";
const token = localStorage.getItem("accessToken");
const refreshToken = localStorage.getItem("refreshToken");

function App() {
  const FlipCardData = useSelector((state) => state.flipCard);
  const isFlipcardSubmitted = useSelector(
    (state) => state.FlipCardEtc.isFlipcardSubmitted
  );
  const isEngagementSkipped = useSelector(
    (state) => state.FlipCardEtc.isEngagementSkipped
  );
  const isFlipcardPageMounted = useSelector(
    (state) => state.FlipCardEtc.isFlipcardPageMounted
  );

  // ----------------- Draft Functionality -----------------
  const [_createDraft] = useFlipcardDraftMakerMutation();
  const createDraftFun = async () => {
    const response = await _createDraft({
      draftString: JSON.stringify(FlipCardData),
    });
    if (response?.data) {
      message.success("Flipcard Saved as Draft");
      dispatch(FlipCardengagement({}));
      dispatch(AdminEngagementObject({}));
    }
  };

  useEffect(() => {
    if (isFlipcardPageMounted == "unmounted") {
      if (!isFlipcardSubmitted && FlipCardData?.title !== "") {
        createDraftFun();
      }
    }
  }, [isFlipcardPageMounted]);

  // ----------------- Auth Functionality -----------------
  const [user, setUser] = useState("");
  const [_renew] = useRenewTokenMutation();

  const { data, isSuccess, error, isLoading } = useIsAthorizedQuery();
  const isAuth = useSelector((state) => state.auth.isAuth);
  const isOnboard = useSelector((state) => state.auth.isOnboard);
  const currRole = useSelector((state) => state.auth.role);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      setUser(Jwt.decode(token));
    }
  }, [token]);

  useEffect(() => {
    if (user) {
      dispatch(roleSetter(user?.role));
    }
  }, [user]);

  const renewToken = async () => {
    const refreshTokenObj = {
      refreshToken: refreshToken,
    };
    const response = await _renew(refreshTokenObj);
    if (response?.error) {
      message.error("Session Expired Please Login Again");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      window.location.href = "/login";
    }
    if (response?.data) {
      localStorage.setItem("accessToken", response?.data?.accessToken);
      localStorage.setItem("refreshToken", response?.data?.refreshToken);
      window.location.reload();
    }
  };

  useEffect(() => {
    if (!token) {
      dispatch(auth(false));
    }
    if (!isLoading) {
      if (data) {
        dispatch(auth(true));
      }
      if (error?.status == 401 && token) {
        renewToken();
      }
    }
  }, [error, isSuccess, data, isLoading]);

  const isAdminAccess = () => {
    if (currRole === "admin") {
      return true;
    }
    return false;
  };

  const isSuperAdminAccess = () => {
    if (currRole === "superadmin") {
      return true;
    }
    return false;
  };

  const isPublisherAccess = () => {
    if (currRole === "publisher") {
      return true;
    }
    return false;
  };

  return (
    <Router>
      {isAuth && !isOnboard ? <Navbar /> : null}
      <Switch>
        <Route path="/login" component={Login} />

        <Route path="/forgotpassword/:email" component={ForgotPassword} />

        <Route path="/signup" component={Signup} />

        <Route path="/confirmemail/:email" component={ConfirmEmail} />

        <ProtectedRoute exact path="/" component={Dashboard} isAuth={isAuth} />

        <ProtectedRoute
          path="/onboarding"
          component={OnBoardingWorkflow}
          isAuth={isAuth}
        />

        <ProtectedRoute
          path="/profile"
          component={UserProfile}
          isAuth={isAuth}
        />
        {/* -------------------- Publisher -------------------- */}

        {isPublisherAccess() ? (
          <ProtectedRoute
            path="/create"
            component={PublisherFlipCardWorkflow}
            isAuth={isAuth}
          />
        ) : null}

        {isPublisherAccess() ? (
          <ProtectedRoute
            path="/flipcardedit/:_id"
            component={PublisherFlipCardWorkflow}
            isAuth={isAuth}
          />
        ) : null}

        {isPublisherAccess() ? (
          <ProtectedRoute
            path="/draft/:_id"
            component={PublisherFlipCardWorkflow}
            isAuth={isAuth}
          />
        ) : null}

        {isPublisherAccess() ? (
          <ProtectedRoute
            path="/analytics"
            component={AnalyticsWorkflow}
            isAuth={isAuth}
          />
        ) : null}

        {isPublisherAccess() ? (
          <ProtectedRoute
            path="/flipcardoverview/:_id"
            component={FlipCardOverviewWorkflow}
            isAuth={isAuth}
          />
        ) : null}

        {/* -------------------- Admin -------------------- */}

        {isAdminAccess() ? (
          <ProtectedRoute
            path="/createtemplate"
            component={AdminFlipCardWorkflow}
            isAuth={isAuth}
          />
        ) : null}

        {/* {isAdminAccess() || isSuperAdminAccess() ? (
          ) : null} */}
        <ProtectedRoute
          path="/templateedit/:_id/:type"
          component={AdminFlipCardWorkflow}
          isAuth={isAuth}
        />

        {/* -------------- External Auth -------------- */}
        <Route path="/wplogin" component={WordpressAuth} />
        <Route path="/chromelogin" component={ChromePlugin} />
      </Switch>
    </Router>
  );
}

export default App;
