import { Col, Row } from "antd";
import "./LoginSignup.css";
import React from "react";

function Sidebar({ content }) {
  return (
    <Col
      {...{ xs: 0, sm: 0, md: 0, lg: 0, xl: 8 }}
      className="gradientBackGroundDark"
    >
      <img
        style={{
          width: "120px",
          margin: "30px 20px 0px 30px",
        }}
        src="/images/logoW.png"
        alt=""
      />

      <h1
        style={{
          margin: "45px 30px 0px 30px",
          fontSize: "1.5rem",
          fontWeight: "500",
          color: "var(--main-Background-Color)",
        }}
      >
        {content}
      </h1>

      <Row
        justify="center"
        align="middle"
        style={{ position: "absolute", bottom: "30px" }}
      >
        <img
          src="/images/LSImg.png"
          alt=""
          style={{
            width: "120%",
            padding: "0px 50px 0px 50px",
          }}
        />
      </Row>
    </Col>
  );
}

export default Sidebar;
