import { Col, Row } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
const TopBar = ({ data, isLoading }) => {
  const [prcessedData, setPrcessedData] = useState({
    actionRate: 0,
    engagementRate: 0,
    actionTakes: 0,
    totalViews: 0,
  });
  useEffect(() => {
    let actionRate = data?.actionRate;
    let engagementRate = data?.engagementRate;
    let actionTakes = data?.actionTakes;
    let totalViews = data?.totalViews;
    if (actionRate >= 100) {
      actionRate = 100;
    }
    if (engagementRate >= 100) {
      engagementRate = 100;
    }

    if (actionRate == 0 || actionRate == null) {
      actionRate = "-";
    } else {
      actionRate = actionRate + "%";
    }

    if (engagementRate == 0 || engagementRate == null) {
      engagementRate = "-";
    } else {
      engagementRate = engagementRate + "%";
    }

    if (actionTakes == 0 || actionTakes == null) {
      actionTakes = "-";
    } else {
      actionTakes = "+ " + actionTakes;
    }

    if (totalViews == 0 || totalViews == null) {
      totalViews = "-";
    }

    setPrcessedData({
      actionRate,
      engagementRate,
      actionTakes,
      totalViews,
    });
  }, [data, isLoading]);
  return (
    <Row gutter={[0, 10]} className="dashboardTopBar gradientBackGroundDark">
      <Col {...{ xs: 24, sm: 24, md: 9, lg: 9, xl: 11 }}>
        <h1>Welcome</h1>
        <p>In the last 7 days you have...</p>
      </Col>
      <Col {...{ xs: 24, sm: 24, md: 15, lg: 15, xl: 13 }}>
        <Row gutter={[10, 10]} style={{ textAlign: "center" }}>
          <Col {...{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
            <Row className="dashboardTopBarCenter">
              {isLoading ? (
                <h1>
                  <LoadingOutlined />
                </h1>
              ) : (
                <h1>{prcessedData?.actionTakes}</h1>
              )}

              <p>New action-takers</p>
            </Row>
          </Col>
          <Col {...{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
            <Row className="dashboardTopBarCenter">
              {isLoading ? (
                <h1>
                  <LoadingOutlined />
                </h1>
              ) : (
                <h1>{prcessedData.actionRate}</h1>
              )}
              <p>Action rate</p>
            </Row>
          </Col>
          <Col {...{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
            <Row className="dashboardTopBarCenter">
              {isLoading ? (
                <h1>
                  <LoadingOutlined />
                </h1>
              ) : (
                <h1>{prcessedData.engagementRate}</h1>
              )}
              <p>Engagement rate</p>
            </Row>
          </Col>
          <Col {...{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
            <Row className="dashboardTopBarCenter">
              {isLoading ? (
                <h1>
                  <LoadingOutlined />
                </h1>
              ) : (
                <h1>{prcessedData.totalViews}</h1>
              )}
              <p>Total views</p>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TopBar;
