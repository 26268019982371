import {
  CaretDownOutlined,
  LogoutOutlined,
  MessageOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from "@ant-design/icons/lib/icons";
import { Avatar, Badge, Button, Col, Dropdown, Menu, Row, Space } from "antd";
import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Jwt from "jsonwebtoken";
import SupportModal from "./ExtraComponents/Components/SupportModal";
import { useLocation } from "react-router-dom";
import { auth } from "../redux/Slices/Auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setUserData } from "../redux/Slices/User/userSlice";
import { useUserInfoQuery } from "../Services/service";
const token = localStorage.getItem("accessToken");

function Navbar() {
  const [user, setUser] = useState("");
  const history = useHistory();
  const pathname = window.location.href.split("/")[3];
  const dispatch = useDispatch();
  const currRole = useSelector((state) => state.auth.role);
  const [visible, setVisible] = useState(false);
  const [modalType, setModalType] = useState("Support");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const topBarArr = ["create", "createtemplate", "flipcardedit"];
  const { data, isSuccess, isLoading } = useUserInfoQuery();
  useEffect(() => {
    if (data) {
      dispatch(setUserData(data));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (token) {
      setUser(Jwt.decode(token));
    }
  }, [token]);
  const showModal = () => {
    setVisible(true);
  };
  const usePathname = () => {
    const location = useLocation();
    return location.pathname;
  };

  const handleSignout = async () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    // history.push("/login");
    window.location.href = "/login";
    // dispatch(auth(false));
  };

  const menu = (
    <Menu>
      <Menu.Item
        style={{
          borderRadius: "var(--sub-margin)",
        }}
        onClick={() => {
          history.push("/profile");
        }}
      >
        <Row>
          <img
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
            style={{
              cursor: "pointer",
              width: 35,
              height: 35,
              objectFit: "cover",
              borderRadius: 50,
              marginRight: "var(--sub-margin)",
            }}
            src={
              data?.photo
                ? data?.photo
                : "https://avataaars.io/?avatarStyle=Circle&topType=ShortHairShortWaved&accessoriesType=Blank&hairColor=Brown&facialHairType=Blank&clotheType=BlazerSweater&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light"
            }
          />
          <div>
            <h4>
              {data?.firstname} {data?.lastname}
            </h4>
            <p
              style={{
                fontSize: "12px",
                opacity: "0.5",
              }}
            >
              {user?.username}
            </p>
          </div>
        </Row>
      </Menu.Item>
      <Menu.Divider></Menu.Divider>
      <Menu.Item
        icon={<UserOutlined />}
        onClick={() => {
          history.push("/profile");
        }}
      >
        Profile
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setModalType("Support");
          showModal();
        }}
        icon={<QuestionCircleOutlined />}
      >
        Help & Support
      </Menu.Item>
      <Menu.Divider></Menu.Divider>
      <Menu.Item
        icon={<LogoutOutlined />}
        onClick={handleSignout}
        style={{
          borderRadius: "var(--sub-margin)",
        }}
      >
        Log Out
      </Menu.Item>
      {/* <Menu.Divider></Menu.Divider> */}
      {/* <Menu.Item
        style={{
          borderRadius: "var(--sub-margin)",
        }}
      >
        <Row>
          <h4
            style={{
              color: "var(--secondary-Text-Color)",
            }}
          >
            Switch account
          </h4>
        </Row>
        <Row
          style={{
            marginTop: "5px",
          }}
        >
          <img
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
            style={{
              cursor: "pointer",
              width: 35,
              height: 35,
              objectFit: "cover",
              borderRadius: 50,
              marginRight: "var(--sub-margin)",
            }}
            src={data?.photo}
          />
          <div>
            <h4
              style={{
                margin: "0px",
              }}
            >
              {data?.firstname} {data?.lastname}
            </h4>
            <p
              style={{
                fontSize: "12px",
                opacity: "0.5",
              }}
            >
              {user?.username}
            </p>
          </div>
        </Row>
      </Menu.Item> */}
    </Menu>
  );

  return (
    <>
      <Row
        justify="center"
        align="middle"
        style={{
          borderBottom: "1px solid var(--main-Border-Color)",
          position: "sticky",
          zIndex: 1000,
          top: 0,
          backgroundColor: " var(--main-Background-Color)",
        }}
      >
        <Row
          style={{
            width: "90%",
            marginTop: "var(--main-margin)",
            maxWidth: "1400px",
          }}
        >
          <Row style={{ width: "100%" }}>
            <Col {...{ xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }}>
              <NavLink to="/">
                <img src="/images/logo.png" alt="" style={{ width: "150px" }} />
              </NavLink>
            </Col>
            <Col {...{ xs: 14, sm: 14, md: 14, lg: 14, xl: 14 }}>
              <Row justify="end" align="middle">
                <Button
                  size="large"
                  className="navBarTopToggbtn"
                  style={{
                    borderRadius: "100px",
                    marginRight: "var(--sub-margin)",
                    backgroundColor: "#f5f5f5",
                    padding: "0px 11px",
                  }}
                  onClick={() => {
                    setModalType("Feedback");
                    showModal();
                  }}
                >
                  <MessageOutlined />
                </Button>
                <Button
                  size="large"
                  className="navBarTopToggbtn"
                  style={{
                    borderRadius: "100px",
                    backgroundColor: "#f5f5f5",
                    marginRight: "var(--main-margin)",
                    padding: "0px 11px",
                  }}
                  onClick={() => {
                    setModalType("Support");
                    showModal();
                  }}
                >
                  <QuestionCircleOutlined />
                </Button>
                <Col>
                  <Row align="middle" justify="space-between">
                    <div
                      style={{
                        textAlign: "end",
                        marginRight: "var(--sub-margin)",
                      }}
                    >
                      <p
                        style={{
                          opacity: "0.5",
                        }}
                      >
                        Welcome
                      </p>
                      <Dropdown overlay={menu} trigger={["click"]}>
                        <h3
                          onClick={(e) => e.preventDefault()}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {data?.firstname} {data?.lastname}
                          <CaretDownOutlined
                            style={{
                              opacity: "0.5",
                              marginLeft: "5px",
                            }}
                          />
                        </h3>
                      </Dropdown>
                    </div>

                    <Dropdown overlay={menu} trigger={["click"]}>
                      <img
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                        style={{
                          cursor: "pointer",
                          width: 50,
                          height: 50,
                          objectFit: "cover",
                          borderRadius: 50,
                        }}
                        src={
                          data?.photo
                            ? data?.photo
                            : "https://avataaars.io/?avatarStyle=Circle&topType=ShortHairShortWaved&accessoriesType=Blank&hairColor=Brown&facialHairType=Blank&clotheType=BlazerSweater&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light"
                        }
                      />
                    </Dropdown>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[0, 10]} style={{ width: "100%", marginTop: "20px" }}>
            <Col {...{ xs: 24, sm: 15, md: 15, lg: 15, xl: 15 }}>
              <div className="navBottom" id="navBottom">
                <NavLink exact to="/">
                  <Button type="text" style={{ paddingLeft: "0px" }}>
                    Dashboard
                  </Button>
                </NavLink>
                {currRole == "publisher" ? (
                  <NavLink exact to="/analytics">
                    <Button type="text">Analytics</Button>
                  </NavLink>
                ) : null}
              </div>
            </Col>
            <Col
              {...{ xs: 24, sm: 9, md: 9, lg: 9, xl: 9 }}
              style={{
                marginBottom: "10px",
                display: topBarArr.includes(pathname) ? "none" : "block",
              }}
            >
              <Row justify="end" align="middle">
                {currRole == "publisher" ? (
                  <NavLink to="/create">
                    <Button
                      type="primary"
                      style={{
                        borderRadius: "var(--input-Border-Radius3)",
                      }}
                    >
                      <PlusOutlined /> New Card
                    </Button>
                  </NavLink>
                ) : null}
                {currRole == "admin" ? (
                  <NavLink to="/createtemplate">
                    <Button
                      type="primary"
                      style={{
                        borderRadius: "var(--input-Border-Radius3)",
                      }}
                    >
                      <PlusOutlined /> New Template
                    </Button>
                  </NavLink>
                ) : null}
              </Row>
            </Col>
          </Row>
        </Row>
        <SupportModal
          visible={visible}
          confirmLoading={confirmLoading}
          setConfirmLoading={setConfirmLoading}
          setVisible={setVisible}
          modalType={modalType}
        />
      </Row>
    </>
  );
}

export default Navbar;
