import { InfoCircleFilled } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import Iframe from "../../ResusableComponents/EtcComponents/Iframe";
import IframeMainSection from "../../ResusableComponents/EtcComponents/IframeMainSection";

function IframeSection({ iframeData }) {
  const currentSection = useSelector(
    (state) => state.FlipCardEtc.currentSection
  );
  const FlipCardSDGs = useSelector((state) => state.FlipCardEtc.flipcardSDGS);
  return (
    <>
      <IframeMainSection iframeData={iframeData} />
      {currentSection == 3 || currentSection == 4 ? null : (
        <Row>
          <Col
            span={24}
            style={{
              marginTop: "var(--main-margin)",
              paddingLeft: "var(--sub-margin)",
              paddingRight: "var(--sub-margin)",
            }}
          >
            <Row>
              {currentSection == 2 ? null : (
                <Col span={24}>
                  <h2>
                    <InfoCircleFilled /> Information
                  </h2>
                  <p
                    style={{
                      marginTop: "var(--sub-margin)",
                    }}
                  >
                    Bridged allows you to add three types of engagements to your
                    content - a quiz, a poll and a fact check. You can choose
                    them from existing templates or create new ones of your own.
                    The simple and intuitive design of the tool guarantees a
                    high user engagement.
                  </p>
                </Col>
              )}
              {/* {currentSection == 2 ? (
                <Col
                  span={24}
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <h2
                    style={{
                      marginBottom: "var(--sub-margin)",
                    }}
                  >
                    Supported SDGs
                  </h2>
                  <p
                    style={{
                      marginTop: "var(--sub-margin)",
                      marginBottom: "var(--sub-margin)",
                    }}
                  >
                    Big challenges need bold action. The 17 Sustainable
                    Development Goals are a plan agreed to by world leaders to
                    build a better world by 2030.
                  </p>
                  {FlipCardSDGs.map((data, index) => {
                    return (
                      <>
                        <img
                          key={index}
                          src={data.imageURL}
                          alt=""
                          style={{
                            width: "100%",
                            maxWidth: "100px",
                          }}
                        />
                      </>
                    );
                  })}
                </Col>
              ) : null} */}
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
}

export default IframeSection;
