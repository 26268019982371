import { Col, Form, Input, Row, Button, Popover, Tag } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { dashboardSearchSetter } from "../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
const filterContentAction = [
  {
    name: "Message Box",
    value: "messageBox",
  },
  {
    name: "Sign A Petition",
    value: "signAPetition",
  },
  {
    name: "News Letter Signup",
    value: "newsLetterSignup",
  },
  {
    name: "Donation",
    value: "donation",
  },
  {
    name: "Link",
    value: "link",
  },
  {
    name: "Dynamic Form",
    value: "dynamicForm",
  },
];

const filterContentEngagement = [
  {
    name: "Quiz",
    value: "quiz",
  },
  {
    name: "Slider Poll",
    value: "sliderPoll",
  },
  {
    name: "Fact Check",
    value: "factcheck",
  },
];

const filterContentStatus = [
  {
    name: "Active",
    value: true,
  },
  {
    name: "Paused",
    value: false,
  },
];

const sortContent = [
  {
    name: "Newest first",
  },
  {
    name: "Oldest first",
  },
];

const MyActionsTopbar = ({
  filterData,
  setFilterData,
  sortData,
  setSortData,
}) => {
  const searchKeyword = useSelector(
    (state) => state.FlipCardEtc.dashboardSearchQuery
  );
  const dispatch = useDispatch();
  const currRole = useSelector((state) => state.auth.role);
  const filterActionChange = (e) => {
    if (e.target.checked) {
      setFilterData({
        ...filterData,
        filterDataAction: [...filterData.filterDataAction, e.target.value],
      });
    } else {
      setFilterData({
        ...filterData,
        filterDataAction: filterData.filterDataAction.filter(
          (item) => item !== e.target.value
        ),
      });
    }
  };
  const filterEngagementChange = (e) => {
    if (e.target.checked) {
      setFilterData({
        ...filterData,
        filterDataEngagement: [
          ...filterData.filterDataEngagement,
          e.target.value,
        ],
      });
    } else {
      setFilterData({
        ...filterData,
        filterDataEngagement: filterData.filterDataEngagement.filter(
          (item) => item !== e.target.value
        ),
      });
    }
  };
  const filterStatusChange = (e) => {
    if (e.target.checked) {
      setFilterData({
        ...filterData,
        filterDataStatus: [...filterData.filterDataStatus, e.target.value],
      });
    } else {
      setFilterData({
        ...filterData,
        filterDataStatus: filterData.filterDataStatus.filter(
          (item) => item !== e.target.value
        ),
      });
    }
  };
  const sortDataChange = (e) => {
    if (e.target.checked) {
      setSortData({
        sortData: [e.target.value],
      });
    } else {
      setSortData({
        sortData: [],
      });
    }
  };
  const filterContent = (
    <>
      <Row
        style={{
          width: "350px",
        }}
      >
        <Col span={12}>
          <Row gutter={[0, 10]} style={{ widows: "100%" }}>
            <Col span={24}>
              <h3 className="opacity05">ACTION</h3>
            </Col>
            {filterContentAction.map((data, index) => {
              return (
                <Col key={index} span={24}>
                  <Checkbox
                    checked={filterData.filterDataAction.includes(data.value)}
                    onChange={filterActionChange}
                    value={data.value}
                  >
                    {data.name}
                  </Checkbox>
                </Col>
              );
            })}
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={[0, 10]} style={{ widows: "100%" }}>
            <Col span={24}>
              <h3 className="opacity05">ENGAGEMENT</h3>
            </Col>
            {filterContentEngagement.map((data, index) => {
              return (
                <Col key={index} span={24}>
                  <Checkbox
                    checked={filterData.filterDataEngagement.includes(
                      data.value
                    )}
                    onChange={filterEngagementChange}
                    value={data.value}
                  >
                    {data.name}
                  </Checkbox>
                </Col>
              );
            })}
          </Row>
          <Row gutter={[0, 10]} style={{ widows: "100%", marginTop: "20px" }}>
            <Col span={24}>
              <h3 className="opacity05">STATUS</h3>
            </Col>
            {filterContentStatus.map((data, index) => {
              return (
                <Col key={index} span={24}>
                  <Checkbox
                    checked={filterData.filterDataStatus.includes(data.value)}
                    onChange={filterStatusChange}
                    value={data.value}
                  >
                    {data.name}
                  </Checkbox>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </>
  );

  const SortContent = (
    <Row
      gutter={[0, 10]}
      style={{
        width: "100%",
        maxWidth: "200px",
      }}
    >
      {sortContent.map((data, index) => {
        return (
          <Col key={index} span={24}>
            <Checkbox
              checked={sortData.sortData.includes(data.name)}
              onChange={sortDataChange}
              value={data.name}
            >
              {data.name}
            </Checkbox>
          </Col>
        );
      })}
    </Row>
  );

  const title = () => {
    if (currRole === "publisher") {
      return "Your Cards";
    }
    if (currRole === "admin") {
      return "Templates";
    }
    if (currRole === "superadmin") {
      return "Unapproved cards";
    }
  };

  return (
    <>
      <Row gutter={[10, 10]} style={{ marginTop: "var(--main-margin)" }}>
        <Col {...{ xs: 24, sm: 24, md: 24, lg: 5, xl: 7 }}>
          <h1>{title()}</h1>
        </Col>
        <Col {...{ xs: 24, sm: 24, md: 24, lg: 13, xl: 10 }}>
          <Row
            justify="end"
            align="middle"
            style={{ width: "100%", height: "100%" }}
          >
            <Popover
              placement="bottomRight"
              content={filterContent}
              trigger="click"
            >
              <Button
                type="text"
                size="large"
                style={{ fontWeight: "600", marginRight: "20px" }}
              >
                Filter by:
                <span
                  style={{
                    fontWeight: "400",
                    fontSize: "13px",
                    padding: "2px 5px 2px 5px",
                  }}
                >
                  {filterData.filterDataAction.length +
                    filterData.filterDataEngagement.length +
                    filterData.filterDataStatus.length ==
                  0
                    ? "All"
                    : "Custom"}
                </span>
              </Button>
            </Popover>

            <Popover
              placement="bottomRight"
              content={SortContent}
              trigger="click"
            >
              <Button
                type="text"
                size="large"
                style={{
                  fontWeight: "600",
                  marginRight: "20px",
                  width: "180px",
                }}
              >
                Sort by:
                <span
                  style={{
                    fontWeight: "400",
                    fontSize: "13px",
                    padding: "2px 5px 2px 5px",
                  }}
                >
                  {sortData.sortData.length == 0 ? "None" : sortData.sortData}
                </span>
              </Button>
            </Popover>
          </Row>
        </Col>
        <Col {...{ xs: 24, sm: 24, md: 24, lg: 6, xl: 7 }}>
          <Row
            style={{
              width: "100%",
              height: "100%",
            }}
            align="middle"
            justify="center"
          >
            <Form.Item
              style={{
                width: "100%",
                margin: "0px",
              }}
            >
              <Input
                type="text"
                size="large"
                value={searchKeyword}
                onChange={(event) => {
                  dispatch(dashboardSearchSetter(event.target.value));
                }}
                placeholder="Search"
                style={{
                  height: "40px",
                }}
                className="inputBx"
              />
            </Form.Item>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default MyActionsTopbar;
