export default function actionNameConverter(name) {
  if (name === "messageBox") {
    return "Message Box";
  }
  if (name === "signAPetition") {
    return "Sign A Petition";
  }
  if (name === "newsLetterSignup") {
    return "News Letter Signup";
  }
  if (name === "donation") {
    return "Donation";
  }
  if (name === "link") {
    return "Link";
  }
  if (name === "dynamicForm") {
    return "Dynamic Form";
  }
}
