import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Row, Form, Input, Select, Switch, message } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setActDynamicForm } from "../../../../redux/Slices/Flip Cards/Actions/ActDynamicFormSlice";
import { AdminActionObject } from "../../../../redux/Slices/Flip Cards/AdminFlipCardSlice";
import { dynamicFormNumberOfIndexesSetter } from "../../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
const { Option } = Select;

function DynamicFormFields({ userData, index }) {
  const noOfFields = useSelector(
    (state) => state.FlipCardEtc.dynamicFormNumberOfIndexes
  );
  const dispatch = useDispatch();
  
  const onChangeHandler = (e) => {
    let { name, value } = e.target;
    if (name == "maxLine" || name == "maxLength") {
      value = parseInt(value);
    }

    if (userData.actionData.fields[index].maxLine > 10) {
      message.warn("Maximum line length should not be more than 10");
    }

    if (userData.actionData.fields[index].maxLength > 700) {
      message.warn("Maximum length should not be more than 700");
    }

    let fieldsData = [...userData.actionData.fields];
    fieldsData[index] = { ...fieldsData[index], [name]: value };
    let sentData = {
      ...userData,
      actionData: {
        ...userData.actionData,
        fields: fieldsData,
      },
    };

    dispatch(setActDynamicForm(sentData));
    dispatch(AdminActionObject(sentData));
  };

  const { title, hint, type, maxLine, maxLength, optional } =
    userData.actionData.fields[index];

  const removeFieldHandler = () => {
    let fieldsData = [...userData.actionData.fields];
    fieldsData.splice(index, 1);
    let sentData = {
      ...userData,
      actionData: {
        ...userData.actionData,
        fields: fieldsData,
      },
    };

    dispatch(setActDynamicForm(sentData));
    dispatch(AdminActionObject(sentData));
    dispatch(dynamicFormNumberOfIndexesSetter(noOfFields - 1));
  };
  return (
    <>
      <Col span={24}>
        <Row align="middle" justify="space-between">
          <h2>Field no - {index + 1}</h2>
          {noOfFields == 0 ? null : (
            <Button shape="circle" onClick={removeFieldHandler}>
              <CloseOutlined />
            </Button>
          )}
        </Row>
      </Col>
      <Col span={12}>
        <Form.Item label="Title">
          <Input
            type="text"
            placeholder="Title"
            className="inputBx"
            name="title"
            value={title}
            onChange={onChangeHandler}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Hint">
          <Input
            type="text"
            placeholder="Hint"
            className="inputBx"
            name="hint"
            value={hint}
            onChange={onChangeHandler}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Input type">
          <Select
            className="antdSelect"
            defaultValue="text"
            size="large"
            bordered={false}
            value={type}
            onChange={(e) => {
              let sentData = {
                target: {
                  value: e,
                  name: "type",
                },
              };
              onChangeHandler(sentData);
            }}
          >
            <Option value="number">Number</Option>
            <Option value="text">Text</Option>
            <Option value="email">Email</Option>
            <Option value="phone">Phone Number</Option>
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Max line">
          <Input
            type="number"
            placeholder="Maximum lines (0 - 10)"
            className="inputBx"
            name="maxLine"
            max={10}
            value={maxLine}
            onChange={onChangeHandler}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Max Length">
          <Input
            type="number"
            placeholder="Maximum length (0 - 700)"
            className="inputBx"
            name="maxLength"
            max={700}
            value={maxLength}
            onChange={onChangeHandler}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Mark as optional field">
          <Switch
            name="showShareSection"
            checked={optional}
            onChange={(e) => {
              let sentData = {
                target: {
                  value: e,
                  name: "optional",
                },
              };
              onChangeHandler(sentData);
            }}
          />
        </Form.Item>
      </Col>
    </>
  );
}

export default DynamicFormFields;
