import { createSlice } from "@reduxjs/toolkit";
import SliderPollInitialState from "../../../../Initial States/Engagements/SliderPollInitialState";

const EngSliderPollSlice = createSlice({
  name: "EngSliderPoll",
  initialState: {
    engagement: SliderPollInitialState,
  },
  reducers: {
    setEngSliderPoll: (state, action) => {
      state.engagement = action.payload;
    },
  },
});

export const { setEngSliderPoll } = EngSliderPollSlice.actions;
export default EngSliderPollSlice.reducer;
