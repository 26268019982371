import { Col, Form, Input, Modal, Row } from "antd";
import React, { useState } from "react";
import AdminCustomBridgedProjects from "../../../Dashboard/AdminCards/AdminCustomBridgedProjects";
function SearchForDonationModal({
  isModalSearchVisible,
  handleSearchModalCancel,
}) {
  const [searchKeyword, setSearchKeyword] = useState("");

  return (
    <Modal
      visible={isModalSearchVisible}
      onCancel={handleSearchModalCancel}
      width={1400}
      footer={null}
      className="flipcardNewActionModal"
    >
      <Row
        style={{
          marginTop: "var(--main-margin)",
        }}
      >
        <Col {...{ xs: 24, sm: 24, md: 12, lg: 8, xl: 8 }}>
          <Form layout="vertical">
            <Form.Item label="Search for a custom donation">
              <Input
                type="text"
                placeholder="Search..."
                className="inputBx"
                value={searchKeyword}
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                }}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col span={24}>
          <AdminCustomBridgedProjects
            searchKeyword={searchKeyword}
            clickToAddData={true}
            handleSearchModalCancel={handleSearchModalCancel}
          />
        </Col>
      </Row>
    </Modal>
  );
}

export default SearchForDonationModal;
