import {
  BarChartOutlined,
  BulbOutlined,
  BarsOutlined,
} from "@ant-design/icons";
export default function engagementIconFinder(name) {
  if (name === "sliderPoll") {
    return <BarChartOutlined />;
  } else if (name === "quiz") {
    return <BulbOutlined />;
  } else if (name === "factcheck") {
    return <BarsOutlined />;
  }
}
