import { Col, Steps } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";
const { Step } = Steps;
function Sidebar({ currentSection, sidebarTopics }) {
  return (
    <Col
      {...{ xs: 0, sm: 0, md: 0, lg: 0, xl: 6 }}
      className="gradientBackGroundDark"
    >
      <NavLink to="/">
        <img
          style={{
            width: "120px",
            margin: "30px 20px 0px 30px",
          }}
          src="/images/logoW.png"
          alt=""
        />
      </NavLink>
      <p
        style={{
          fontSize: "1.3rem",
          color: "var(--main-Background-Color)",
          padding: "0px 20px 0px 20px",
          textAlign: "start",
          marginTop: "70px",
          marginLeft: "7px",
          fontWeight: "500",
        }}
      >
        Setup your Bridged account
      </p>
      <div
        style={{
          padding: "0px 23px 0px 23px",
          marginTop: "var(--main-margin)",
        }}
      >
        <Steps
          className="onBoardingSidebarSteps"
          direction="vertical"
          size="small"
          current={currentSection}
        >
          {sidebarTopics.map((topic, index) => (
            <Step key={index} title={topic} />
          ))}
        </Steps>
      </div>
    </Col>
  );
}

export default Sidebar;
