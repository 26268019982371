import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row, Select, Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { currentSectionSetter } from "../../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
import { FlipCardtitle } from "../../../../redux/Slices/Flip Cards/FlipCardSlice";
function Start() {
  const dispatch = useDispatch();
  const [shouldSectionChange, setShouldSectionChange] = useState(false);
  const flipcardTitle = useSelector((state) => state.flipCard.title);
  const isEditFlipcardData = useSelector(
    (state) => state.FlipCardEtc.isEditFlipcardData
  );
  const isDraftData = useSelector((state) => state.FlipCardEtc.isDraftData);
  const [userData, setUserData] = useState({
    name: "",
  });

  // Pushing Code to Redux Store
  useEffect(() => {
    dispatch(FlipCardtitle(userData.name));
  }, [userData]);

  // If All the data is Saved in Redux Store Then we will call it
  useEffect(() => {
    setUserData({
      name: flipcardTitle,
    });
  }, [isEditFlipcardData, isDraftData]);

  // Checking if the required Fields are filled or not
  useEffect(() => {
    if (userData.name) {
      setShouldSectionChange(true);
    } else {
      setShouldSectionChange(false);
    }
  }, [userData]);

  return (
    <>
      <Row
        justify="center"
        align="middle"
        style={{
          width: "100%",
        }}
      >
        <Col
          {...{ xs: 24, sm: 24, md: 18, lg: 15, xl: 12 }}
          className="flipcardOutCompGrey"
        >
          <Row className="flipcardInCompWhite">
            <Col span={24}>
              <Form.Item>
                <h2>Name your card *</h2>
                <Input
                  style={{
                    marginTop: "var(--sub-margin)",
                  }}
                  name="name"
                  value={userData.name}
                  onChange={(e) => {
                    setUserData({
                      ...userData,
                      [e.target.name]: e.target.value,
                    });
                  }}
                  type="text"
                  placeholder="Card Name"
                  className="inputBx"
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        justify="center"
        align="middle"
        style={{
          width: "100%",
          marginTop: "var(--main-margin)",
          marginBottom: "var(--main-margin)",
        }}
      >
        <Col {...{ xs: 24, sm: 24, md: 18, lg: 15, xl: 12 }}>
          <Row justify="end" align="middle">
            <Button
              size="large"
              type="primary"
              className="button"
              disabled={!shouldSectionChange}
              onClick={() => {
                dispatch(currentSectionSetter(1));
              }}
            >
              Next <ArrowRightOutlined />
            </Button>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Start;
