import { createSlice } from "@reduxjs/toolkit";
import MessageBoxInitialState from "../../../../Initial States/Actions/MessageBoxInitialState";

const ActMessageBoxSlice = createSlice({
  name: "ActMessageBox",
  initialState: {
    action: MessageBoxInitialState,
  },
  reducers: {
    setActMessageBox: (state, action) => {
      state.action = action.payload;
    },
  },
});

export const { setActMessageBox } = ActMessageBoxSlice.actions;
export default ActMessageBoxSlice.reducer;
