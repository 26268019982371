import React, { useEffect, useRef, useState } from "react";
import { Col, Form, message, Row, Skeleton } from "antd";
import "../flipcard.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../ResusableComponents/EtcComponents/NavBar";
import Topbar from "../ResusableComponents/EtcComponents/Topbar";
import Start from "../ResusableComponents/EtcComponents/Start";
import Customise from "../ResusableComponents/MainSections/2Customise/Customise";
import IframeSection from "./ReusableComponents/IframeSection";
import AddAction from "../ResusableComponents/MainSections/3Add impact/AddAction";
import Preview from "./Pages/4Preview/Preview";
import Finish from "./Pages/5Finish/Finish";
import {
  currEngSetter,
  currentSectionSetter,
  isDraftDataSetter,
  isEditFlipcardDataSetter,
  isEngagementSkippedSetter,
  isFlipcardPageMountedSetter,
  isFlipcardSubmittedSetter,
  optionsAdderSetter,
} from "../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
import {
  FlipCardaction,
  FlipcardActionPage,
  FlipCardengagement,
  FlipCardId,
  FlipcardPublisher,
  FlipcardThankYouPage,
  FlipcardTheme,
  FlipCardtitle,
} from "../../../redux/Slices/Flip Cards/FlipCardSlice";
import { setEngFactCheck } from "../../../redux/Slices/Flip Cards/Engagements/EngFactCheckSlice";
import FactCheckInitialState from "../../../Initial States/Engagements/FactCheckInitialState";
import SliderPollInitialState from "../../../Initial States/Engagements/SliderPollInitialState";
import { setEngSliderPoll } from "../../../redux/Slices/Flip Cards/Engagements/EngSliderPollSlice";
import { setEngQuiz } from "../../../redux/Slices/Flip Cards/Engagements/EngQuizSlice";
import QuizInitialState from "../../../Initial States/Engagements/QuizInitialState";
import {
  AdminActionObject,
  AdminEngagementObject,
} from "../../../redux/Slices/Flip Cards/AdminFlipCardSlice";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  useFlipCardFindMutation,
  useFlipcardGetOneDraftMutation,
} from "../../../Services/service";
import FlipcardInitialState from "../../../Initial States/Flip Cards/FlipcardInitialState";
import ThankYouPage from "../ResusableComponents/ThankYouPage";

const navbarArr = [
  {
    title: "Start",
  },
  {
    title: "Customise",
  },
  {
    title: "Add Action",
  },
  {
    title: "Thank you page",
  },
  {
    title: "Preview",
  },
  {
    title: "Finish",
  },
];

const showActionScreen = {
  event: "showActionScreen",
};

const flipToFront = {
  event: "flipToFront",
};

const flipToBack = {
  event: "flipToBack",
};

function PublisherFlipCardWorkflow() {
  const pathname = window.location.href.split("/")[3];
  const { _id } = useParams();
  const history = useHistory();
  const currEng = useSelector((state) => state.FlipCardEtc.currEng);
  const currAction = useSelector((state) => state.FlipCardEtc.currAction);
  const dispatch = useDispatch();
  const FlipCardData = useSelector((state) => state.flipCard);
  const currentSection = useSelector(
    (state) => state.FlipCardEtc.currentSection
  );
  const iframeLoadedSetterData = useSelector(
    (state) => state.FlipCardEtc.iframeLoaded
  );
  const [iframeData, setIframeData] = useState(FlipCardData);
  const [flipcardSubmitId, setFlipcardSubmitId] = useState("Flipcard Id");

  const section = () => {
    switch (currentSection) {
      case 1:
        return <Customise secPrev={0} secNext={2} />;
      case 2:
        return <AddAction iscreateNewAction={false} secPrev={1} secNext={3} />;
      case 3:
        return <ThankYouPage />;
      case 4:
        return <Preview setFlipcardSubmitId={setFlipcardSubmitId} />;
      case 5:
        return <Finish flipcardSubmitId={flipcardSubmitId} />;
      default:
        return <Customise secPrev={0} secNext={2} />;
    }
  };

  // Sets the iframe data to flipcard data whenever the flipcard data changes
  useEffect(() => {
    setIframeData(FlipCardData);
  }, [FlipCardData, iframeData, currEng, currAction, iframeLoadedSetterData]);

  // Setting Iframe Data as per section
  useEffect(() => {
    if (currentSection == 1 || currentSection == 0) {
      setIframeData(flipToFront);
      setTimeout(() => {
        setIframeData(FlipCardData);
      }, 10);
    } else if (currentSection == 2) {
      setIframeData(flipToBack);
      setTimeout(() => {
        setIframeData(showActionScreen);
      }, 10);
      setTimeout(() => {
        setIframeData(FlipCardData);
      }, 20);
    } else if (currentSection == 4) {
      setTimeout(() => {
        setIframeData(flipToFront);
      }, 10);
      setTimeout(() => {
        setIframeData(showActionScreen);
      }, 20);
    }
  }, [currentSection, iframeLoadedSetterData, FlipCardData]);

  // Setting Id for preview section
  useEffect(() => {
    if (currentSection == 4) {
      dispatch(FlipCardId("preview"));
    } else {
      dispatch(FlipCardId("create"));
    }
  }, [currentSection]);

  // Check if the component is mounted or not using useEffect and useRef
  useEffect(() => {
    dispatch(isFlipcardPageMountedSetter("mounted"));
    return () => {
      dispatch(isFlipcardPageMountedSetter("unmounted"));
    };
  }, []);

  // --------------------------------- Edit Flip Card ---------------------------------
  const [
    _flipCardFind,
    { isLoading: isLoadingEdit, data: isEditFlipcardData },
  ] = useFlipCardFindMutation();
  const [_getOneDraft, { isLoading: isLoadingDraft, data: isDraftData }] =
    useFlipcardGetOneDraftMutation();

  useEffect(() => {
    if (isEditFlipcardData) {
      setTimeout(() => {
        dispatch(isEditFlipcardDataSetter(true));
      }, 100);
    }
    if (isDraftData) {
      setTimeout(() => {
        dispatch(isDraftDataSetter(true));
      }, 100);
    }
  }, [isEditFlipcardData, isDraftData]);

  useEffect(() => {
    dispatch(isDraftDataSetter(false));
    dispatch(isEditFlipcardDataSetter(false));
    dispatch(isFlipcardSubmittedSetter(false));
    dispatch(currentSectionSetter(0));
    dispatch(FlipCardtitle(""));
    dispatch(isEngagementSkippedSetter(false));
    if (_id) {
      editFlipcard();
    } else {
      dispatch(FlipcardTheme(FlipcardInitialState.theme));
      // --------------------- Engagements ---------------------
      dispatch(setEngFactCheck(FactCheckInitialState));
      dispatch(setEngSliderPoll(SliderPollInitialState));
      dispatch(setEngQuiz(QuizInitialState));

      // --------------------- Flip Cards ---------------------
      dispatch(AdminEngagementObject({}));
      dispatch(AdminActionObject({}));
      dispatch(FlipCardtitle(""));
      dispatch(FlipCardengagement({}));
      dispatch(
        FlipcardThankYouPage({
          title: "Default Thank You Page Title",
          description: "Default Thank You Page Description",
          showShareSection: true,
        })
      );
      dispatch(FlipcardActionPage(FlipcardInitialState.actionPage));
      if (history?.location?.state?.from == "chromePlugin") {
      } else {
        dispatch(FlipCardaction([]));
      }
    }
  }, []);

  const editFlipcard = async () => {
    let flipcardEditData = {};
    let response = "";

    if (pathname == "flipcardedit") {
      response = await _flipCardFind(_id);
      flipcardEditData = response?.data;
    }

    if (pathname == "draft") {
      response = await _getOneDraft(_id);
      flipcardEditData = JSON.parse(response?.data?.draftString);
    }

    if (response?.error) {
      return message.error("Data not found");
    }
    if (response?.data) {
      const { actionPage, actions, engagement, title, theme, publisher } =
        flipcardEditData;
      const engagementData = engagement?.engagementData;
      const engagementType = engagementData?.engagementType;

      dispatch(FlipCardaction([...actions]));
      dispatch(FlipcardActionPage(actionPage));
      dispatch(FlipcardTheme(theme));
      dispatch(FlipcardPublisher(publisher));
      dispatch(FlipCardtitle(title));

      if (engagement) {
        dispatch(currEngSetter(engagementType));
        if (engagementData?.options.length < 3) {
          dispatch(optionsAdderSetter(0));
        } else if (engagementData?.options.length == 3) {
          dispatch(optionsAdderSetter(1));
        } else if (engagementData?.options.length == 4) {
          dispatch(optionsAdderSetter(2));
        }
        if (engagementType === "sliderPoll") {
          dispatch(setEngSliderPoll(engagement));
          dispatch(FlipCardengagement(engagement));
          dispatch(AdminEngagementObject(engagement));
        }
        if (engagementType === "quiz") {
          dispatch(setEngQuiz(engagement));
          dispatch(FlipCardengagement(engagement));
          dispatch(AdminEngagementObject(engagement));
        }
        if (engagementType === "factcheck") {
          dispatch(setEngFactCheck(engagement));
          dispatch(FlipCardengagement(engagement));
          dispatch(AdminEngagementObject(engagement));
        }
      }
    }
  };

  return (
    <section className="flipcardSection">
      <div className="flipcardMainDiv">
        <Form layout="vertical">
          <Topbar />
          {isLoadingEdit || isLoadingDraft ? (
            <Skeleton.Button
              active={true}
              block={true}
              style={{
                height: "600px",
                borderRadius: "var(--input-Border-Radius3)",
                marginTop: "var(--main-margin)",
              }}
            />
          ) : (
            <>
              <Navbar currentSection={currentSection} navbarArr={navbarArr} />
              <Row
                style={{ marginTop: "var(--main-margin)" }}
                justify="space-between"
              >
                {currentSection === 0 ? (
                  <Start />
                ) : (
                  <>
                    {currentSection === 5 ? (
                      section()
                    ) : (
                      <Col {...{ xs: 24, sm: 24, md: 24, lg: 13, xl: 13 }}>
                        {section()}
                      </Col>
                    )}

                    {currentSection === 5 ? null : (
                      <Col {...{ xs: 24, sm: 24, md: 24, lg: 10, xl: 10 }}>
                        <IframeSection iframeData={iframeData} />
                      </Col>
                    )}
                  </>
                )}
              </Row>
            </>
          )}
        </Form>
      </div>
    </section>
  );
}

export default PublisherFlipCardWorkflow;
