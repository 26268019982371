import { createSlice } from "@reduxjs/toolkit";
import FlipcardInitialState from "../../../Initial States/Flip Cards/FlipcardInitialState";

const FlipCardSlice = createSlice({
  name: "FlipCard",
  initialState: FlipcardInitialState,
  reducers: {
    FlipCardtitle: (state, action) => {
      state.title = action.payload;
    },
    FlipCardengagement: (state, action) => {
      state.engagement = action.payload;
    },
    FlipCardaction: (state, action) => {
      state.actions = action.payload;
    },
    FlipCardId: (state, action) => {
      state._id = action.payload;
    },
    FlipcardActionPage: (state, action) => {
      state.actionPage = action.payload;
    },
    FlipcardTheme: (state, action) => {
      state.theme = action.payload;
    },
    FlipcardPublisher: (state, action) => {
      state.publisher = action.payload;
    },
    FlipcardThankYouPage: (state, action) => {
      state.thankYouPage = action.payload;
    },
  },
});

export const {
  FlipCardtitle,
  FlipCardengagement,
  FlipCardaction,
  FlipCardId,
  FlipcardActionPage,
  FlipcardTheme,
  FlipcardPublisher,
  FlipcardThankYouPage,
} = FlipCardSlice.actions;
export default FlipCardSlice.reducer;
