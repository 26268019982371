import { Col, Row, Button, Input, message } from "antd";
import "./LoginSignup.css";
import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import { useHistory, useParams } from "react-router-dom";
import {
  useResendVerifyEmailMutation,
  useVerifyEmailMutation,
} from "../../Services/service";
import { useDispatch } from "react-redux";
import { auth, roleSetter } from "../../redux/Slices/Auth/authSlice";
function ConfirmEmail() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [resendCodeLoading, setResendCodeLoading] = useState(false);
  const [resendCodeBtnText, setResendCodeBtnText] = useState("Resend code");
  const { email } = useParams();

  const [userData, setUserData] = useState({
    email: "",
    verificationCode: "",
  });

  useEffect(() => {
    setUserData({ ...userData, email: email });
  }, []);

  const HandleOnChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const [_VerifyEmail] = useVerifyEmailMutation();
  const HandleSubmit = async (e) => {
    setSubmitLoading(true);
    if (!userData.email || !userData.verificationCode) {
      message.error("Please Fill All The Details");
      setSubmitLoading(false);
    } else {
      const response = await _VerifyEmail(userData);

      if (response?.error?.status === 401) {
        message.error("Please enter valid verification code");
        setSubmitLoading(false);
      } else if (response?.error?.status === 400) {
        message.error("Email already verified");
        setSubmitLoading(false);
      } else if (response?.data?.accessToken) {
        localStorage.setItem("accessToken", response?.data?.accessToken);
        localStorage.setItem("refreshToken", response?.data?.refreshToken);
        dispatch(roleSetter(response?.data?.role));
        message.success("Email Verified Successfully");
        setTimeout(() => {
          dispatch(auth(true));
          history.push("/onboarding", { from: "confirmMail" });
        }, 500);
        setSubmitLoading(false);
      }
    }
  };
  const [_resendCode] = useResendVerifyEmailMutation();
  const resendCode = async () => {
    setResendCodeLoading(true);
    setResendCodeBtnText("Sending...");
    const response = await _resendCode({ email: userData.email });

    if (response?.data?.email) {
      const sec = 120;
      let currTime = 1;
      const timer = setInterval(() => {
        setResendCodeBtnText(`${sec - currTime} seconds`);
        currTime++;
      }, 1000);
      setTimeout(() => {
        setResendCodeLoading(false);
        setResendCodeBtnText("Resend code");
        clearInterval(timer);
      }, 1000 * sec);
    } else if (response?.error?.status === 400) {
      setResendCodeLoading(false);
      setResendCodeBtnText("Resend code");
      message.error("Email already verified");
    } else if (response?.error?.status === 401) {
      setResendCodeLoading(false);
      setResendCodeBtnText("Resend code");
      message.error("Please enter valid verification code");
    } else {
      setResendCodeLoading(false);
      setResendCodeBtnText("Resend code");
      message.error("Something went wrong");
    }
  };

  return (
    <div className="LSParent">
      <Row className="LSCard">
        <Sidebar
          content={
            "Bridging the gap between empathy in your content and social action"
          }
        />
        <Col
          {...{ xs: 24, sm: 24, md: 24, lg: 24, xl: 16 }}
          className="LSMainDiv"
        >
          <Row
            justify="center"
            align="middle"
            style={{ width: "100%", height: "100%" }}
          >
            <Row>
              <Col span={24} style={{ marginTop: "10px" }}>
                <h1>Confirm Email Address</h1>
              </Col>

              <Col
                {...{ xs: 24, sm: 24, md: 20, lg: 20 }}
                style={{ marginTop: "10px" }}
              >
                <h2>
                  Please enter five digit code that is sent to{" "}
                  <h2 style={{ color: "var(--secondary-Text-Color)" }}>
                    {email}
                  </h2>
                </h2>
              </Col>

              <Row
                gutter={[20, 20]}
                style={{ marginTop: "20px", width: "100%" }}
              >
                {/* <Col {...{ xs: 24, sm: 24, md: 12, lg: 12 }}>
                  <Row gutter={[0, 20]}>
                    <Col span={24}>
                      <h4>Email</h4>
                      <input
                        style={{ marginTop: "5px" }}
                        type="text"
                        name="email"
                        disabled={true}
                        value={userData.email}
                        onChange={HandleOnChange}
                      />
                    </Col>
                  </Row>
                </Col> */}
                <Col {...{ xs: 24, sm: 24, md: 12, lg: 12 }}>
                  <Row gutter={[0, 20]}>
                    <Col span={24}>
                      <h4>Verification Code</h4>
                      <Input
                        style={{ marginTop: "5px" }}
                        type="number"
                        name="verificationCode"
                        value={userData.verificationCode}
                        onChange={HandleOnChange}
                        className="inputBx"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Col
                {...{ xs: 24, sm: 24, md: 10, lg: 6 }}
                style={{ marginTop: "20px" }}
              >
                <Button
                  className="button"
                  type="primary"
                  loading={submitLoading}
                  size="large"
                  onClick={HandleSubmit}
                  style={{
                    paddingRight: "20px",
                    paddingLeft: "20px",
                    fontWeight: "600",
                  }}
                >
                  Confirm email
                </Button>
              </Col>
              <Col
                {...{ xs: 24, sm: 24, md: 12, lg: 12 }}
                style={{ marginTop: "20px" }}
              >
                <Button
                  className="button"
                  type="primary"
                  loading={resendCodeLoading}
                  size="large"
                  onClick={() => {
                    resendCode();
                  }}
                  style={{
                    paddingRight: "20px",
                    paddingLeft: "20px",
                    fontWeight: "600",
                  }}
                >
                  {resendCodeBtnText}
                </Button>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default ConfirmEmail;
