export default {
  title: "Fact Check Title",
  description: "",
  engagementData: {
    engagementType: "factcheck",
    note: "",
    showAnswer: true,
    options: [],
  },
};
