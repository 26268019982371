import { createSlice } from "@reduxjs/toolkit";

const AnalyticsSlice = createSlice({
  name: "Analytics",
  initialState: {
    data: {},
  },
  reducers: {
    setAnalytics: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setAnalytics } = AnalyticsSlice.actions;
export default AnalyticsSlice.reducer;
