import "./Analytics.css";
import FirstSection from "./FirstSection";
import SecondSection from "./SecondSection";
import ThirdSection from "./ThirdSection";
import AnalyticsTopBar from "./AnalyticsTopBar";
import { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
import { useFlipCardAnalyticsMutation } from "../../Services/service";
import { message, Skeleton, Row, Col } from "antd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setAnalytics } from "../../redux/Slices/Analytics/AnalyticsSlice";
import OverAllPerformance from "./Charts/OverAllPerformance";
import AquireUsers from "./Charts/AquireUsers";
import UsersLocation from "./Charts/UsersLocation";
import ContentConversion from "./Charts/ContentConversion";
import TopDevices from "./Charts/TopDevices";
import WhereAreUsers from "./Charts/WhereAreUsers";
import WorldMap from "./Charts/WorldMap";
import TopPerformingActions from "./Charts/TopPerformingActions";
const AnalyticsWorkflow = () => {
  const flipCardData = useSelector((state) => state.Analytics.data);
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState(7);
  const [filterSelectedIds, setFilterSelectedIds] = useState({
    flipcardIds: [],
    allFlipcards: true,
  });

  const [filterDate, setfilterDate] = useState({
    startDate: moment().utc().endOf("day"),
    endDate: moment().utc().add(-6, "days").startOf("day"),
  });

  useEffect(() => {
    flipCardOverviewInfo();
  }, [filterDate, filterSelectedIds]);

  const [_flipcardAnalytics, { isLoading }] = useFlipCardAnalyticsMutation();
  const flipCardOverviewInfo = async () => {
    let flipcardIds = { ...filterSelectedIds };
    flipcardIds.flipcardIds = flipcardIds.flipcardIds.map((data) => {
      const id = data.split(" ")[0];
      return id;
    });

    const flipcardOj = {
      ...flipcardIds,
      startDate: filterDate.startDate.toISOString(),
      endDate: filterDate.endDate.toISOString(),
    };
    const response = await _flipcardAnalytics(flipcardOj);
    if (response?.data) {
      dispatch(setAnalytics(response?.data));
    }
  };
  const responsiveGrid = { xs: 24, sm: 24, md: 24, lg: 24, xl: 12 };
  return (
    <>
      <AnalyticsTopBar
        setFilterData={setFilterData}
        filterData={filterData}
        filterDate={filterDate}
        setfilterDate={setfilterDate}
        setFilterSelectedIds={setFilterSelectedIds}
        filterSelectedIds={filterSelectedIds}
      />
      <section className="analyticsSection">
        <div className="analyticsMainDiv">
          <Row
            gutter={[20, 20]}
            style={{
              marginBottom: "60px",
            }}
          >
            {isLoading ? (
              [1, 2, 3, 4, 5, 6].map(() => {
                return (
                  <Col {...responsiveGrid}>
                    <Skeleton.Button
                      active={true}
                      block={true}
                      style={{
                        height: "30px",
                        borderRadius: "var(--input-Border-Radius3)",
                      }}
                    />
                    <Skeleton.Button
                      active={true}
                      block={true}
                      style={{
                        height: "455px",
                        borderRadius: "var(--input-Border-Radius3)",
                        marginTop: "15px",
                      }}
                    />
                  </Col>
                );
              })
            ) : (
              <>
                <Col {...responsiveGrid} className="analyticsCardMainDiv">
                  <h2>Overall Performance</h2>
                  <Row className="analyticsCard">
                    <OverAllPerformance />
                  </Row>
                </Col>
                <Col {...responsiveGrid} className="analyticsCardMainDiv">
                  <h2>How did I acquire my users?</h2>
                  <Row className="analyticsCard">
                    <AquireUsers />
                  </Row>
                </Col>
                <Col {...responsiveGrid} className="analyticsCardMainDiv">
                  <h2>Which pages did my users come from?</h2>
                  <Row className="analyticsCard">
                    <UsersLocation />
                  </Row>
                </Col>
                {/* <Col {...responsiveGrid} className="analyticsCardMainDiv">
                  <h2>How is my content converting over time? </h2>
                  <Row className="analyticsCard">
                    <ContentConversion />
                  </Row>
                </Col> */}
                <Col {...responsiveGrid} className="analyticsCardMainDiv">
                  <h2>What are the top devices being used?</h2>
                  <Row className="analyticsCard">
                    <TopDevices />
                  </Row>
                </Col>
                <Col {...responsiveGrid} className="analyticsCardMainDiv">
                  <h2>What are my top-performing actions?</h2>
                  <Row className="analyticsCard">
                    <TopPerformingActions />
                  </Row>
                </Col>
                <Col {...responsiveGrid} className="analyticsCardMainDiv">
                  <h2>Where are my users?</h2>
                  <Row className="analyticsCard">
                    <WhereAreUsers />
                  </Row>
                </Col>
                <Col {...responsiveGrid} className="analyticsCardMainDiv">
                  {/* <h2>Where are your users?</h2> */}
                  <Row className="analyticsCard">
                    <WorldMap />
                  </Row>
                </Col>
              </>
            )}
          </Row>
          {/* <FirstSection flipCardData={flipCardData} isLoading={isLoading} />
          <SecondSection flipCardData={flipCardData} isLoading={isLoading} />
          <ThirdSection flipCardData={flipCardData} isLoading={isLoading} /> */}
        </div>
      </section>
    </>
  );
};
export default AnalyticsWorkflow;
