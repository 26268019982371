import FlipCards from "./PublisherCards/FlipCards";
import AdminActionFlipcards from "./AdminCards/AdminActionFlipcards";
import "./Dashboard.css";
import MyActionsTopbar from "./MyActionsTopbar";
import TopBar from "./TopBar";
import { useDispatch, useSelector } from "react-redux";
import { onBoard } from "../../redux/Slices/Auth/authSlice";
import { useEffect } from "react";
import { useDashboardQuery } from "../../Services/service";
import { useState } from "react";
import AdminEngagementFlipcards from "./AdminCards/AdminEngagementFlipcards";
import PublisherActionFlipcards from "./PublisherCards/PublisherActionFlipcards";
import SuperAdminActionCards from "./SuperAdminCards/SuperAdminActionCards";
import PublisherDrafts from "./PublisherCards/PublisherDrafts";
import AdminCustomBridgedProjects from "./AdminCards/AdminCustomBridgedProjects";
import SuperAdminManuallyCreatedCards from "./SuperAdminCards/SuperAdminManuallyCreatedCards";
import SuperAdminSchedularService from "./SuperAdminCards/SuperAdminSchedularService";
const Dashboard = () => {
  const searchKeyword = useSelector(
    (state) => state.FlipCardEtc.dashboardSearchQuery
  );
  const [filterData, setFilterData] = useState({
    filterDataAction: [],
    filterDataEngagement: [],
    filterDataStatus: [],
  });
  const currRole = useSelector((state) => state.auth.role);
  const [sortData, setSortData] = useState({
    sortData: [],
  });
  const dispatch = useDispatch();
  const { data, isSuccess, isLoading, isError } = useDashboardQuery();
  useEffect(() => {
    dispatch(onBoard(false));
  }, []);
  return (
    <section className="dashboardSection">
      <div className="dashboardMainDiv">
        {currRole === "publisher" ? (
          <TopBar data={data?.weeklyReport} isLoading={isLoading} />
        ) : null}

        <MyActionsTopbar
          filterData={filterData}
          setFilterData={setFilterData}
          sortData={sortData}
          setSortData={setSortData}
          searchKeyword={searchKeyword}
        />

        {/* ---------------------- Publisher Components ---------------------- */}
        {!isError && currRole === "publisher" ? (
          <>
            <FlipCards
              data={data?.flipcards}
              isSuccess={isSuccess}
              isLoading={isLoading}
              filterData={filterData}
              sortData={sortData}
              searchKeyword={searchKeyword}
            />
            <PublisherActionFlipcards
              filterData={filterData}
              sortData={sortData}
              searchKeyword={searchKeyword}
            />
            <PublisherDrafts />
          </>
        ) : null}

        {/* ---------------------- Admin Components ---------------------- */}
        {currRole === "admin" ? (
          <>
            <AdminActionFlipcards
              filterData={filterData}
              sortData={sortData}
              searchKeyword={searchKeyword}
            />
            <AdminEngagementFlipcards
              filterData={filterData}
              sortData={sortData}
              searchKeyword={searchKeyword}
            />
            <AdminCustomBridgedProjects searchKeyword={searchKeyword} />
          </>
        ) : null}

        {/* ---------------------- Super Admin Components ---------------------- */}
        {currRole === "superadmin" ? (
          <>
            <SuperAdminActionCards
              filterData={filterData}
              sortData={sortData}
              searchKeyword={searchKeyword}
            />
            <SuperAdminManuallyCreatedCards
              filterData={filterData}
              sortData={sortData}
              searchKeyword={searchKeyword}
            />
            <SuperAdminSchedularService
              filterData={filterData}
              sortData={sortData}
              searchKeyword={searchKeyword}
            />
          </>
        ) : null}
      </div>
    </section>
  );
};

export default Dashboard;
