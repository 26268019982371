import {
  Button,
  Card,
  Col,
  Dropdown,
  Menu,
  Row,
  Pagination,
  Skeleton,
  Popconfirm,
  message,
} from "antd";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  useFlipcardUnApprovedActionInventoriesChangeStatusMutation,
  useFlipcardUnApprovedActionInventoriesQuery,
} from "../../../Services/service";
import { EllipsisOutlined } from "@ant-design/icons";
import actionNameConverter from "../../ExtraComponents/actionNameConverter";
import actionIconFinder from "../../ExtraComponents/actionIconFinder";
import DashboardSortingName from "../DashboardReusableFunctions/DashboardSortingName";
import DashboardSearchTitle from "../DashboardReusableFunctions/DashboardSearchTitle";

const SuperAdminActionCards = ({ filterData, sortData, searchKeyword }) => {
  const history = useHistory();
  const [_approveCard] =
    useFlipcardUnApprovedActionInventoriesChangeStatusMutation();
  const [paginationPage, setPaginationPage] = useState(0);
  const [paginationPageSize, setPaginationPageSize] = useState(8);
  const [actions, setActions] = useState([{}, {}, {}, {}, {}, {}, {}, {}]);
  const { isLoading, isSuccess, data, isError } =
    useFlipcardUnApprovedActionInventoriesQuery();
  useEffect(() => {
    if (isSuccess) {
      setActions(data);
    }
  }, [isSuccess, data]);

  let displayActions = actions.slice(
    paginationPage * paginationPageSize,
    (paginationPage + 1) * paginationPageSize
  );

  const paginationChange = (page, pageSize) => {
    setPaginationPage(page - 1);
    setPaginationPageSize(pageSize);
  };

  const filterDatahandler = (e) => {
    let filters = [];

    const engObj = {
      type: "engagementType",
      value: [...e.filterDataEngagement],
    };
    const actObj = {
      type: "actionTypes",
      value: [...e.filterDataAction],
    };
    const statObj = {
      type: "isActive",
      value: [...e.filterDataStatus],
    };

    // --------------------------------------
    if (e.filterDataEngagement.length !== 0) {
      filters.push(engObj);
    }
    if (e.filterDataAction.length !== 0) {
      filters.push(actObj);
    }
    if (e.filterDataStatus.length !== 0) {
      filters.push(statObj);
    }

    let filteredArr = [...data];

    for (let filter of filters) {
      filteredArr = filteredArr.filter((flipcart) => {
        if (typeof flipcart[filter.type] == "object") {
          filter.value.forEach((e) => {
            if (flipcart[filter.type].includes(e)) {
              return flipcart;
            }
          });
        }
        if (filter.value.includes(flipcart[filter.type])) {
          return flipcart;
        }
      });
    }

    setActions(filteredArr);
  };

  useEffect(() => {
    if (isSuccess) {
      const sortedData = DashboardSortingName(sortData?.sortData[0], data);
      setActions(sortedData);
    }
  }, [isSuccess, sortData]);

  useEffect(() => {
    if (isSuccess) {
      filterDatahandler(filterData);
    }
  }, [isSuccess, filterData]);

  useEffect(() => {
    if (isSuccess) {
      const searchResults = DashboardSearchTitle(searchKeyword, data);
      setActions(searchResults);
    }
  }, [isSuccess, searchKeyword]);

  const approveConfirm = async (_id) => {
    try {
      const response = await _approveCard(_id);

      if (response?.error) {
        message.error("Something went wrong");
      }
      if (response?.data) {
        message.success("Flipcard Approved");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const skeleton = () => {
    return (
      <div style={{ padding: "25px" }} className="dashboardMyActionCard">
        <Skeleton.Button active={true} shape="round" />
        <Skeleton
          active={true}
          paragraph={{ rows: 2 }}
          style={{
            marginTop: "var(--main-margin)",
          }}
        />
        <Skeleton
          active={true}
          paragraph={{ rows: 0 }}
          style={{
            marginTop: "var(--sub-margin)",
          }}
        />
      </div>
    );
  };

  return (
    <>
      <Row
        style={{
          marginTop: "var(--main-margin)",
          marginBottom: "var(--main-margin)",
        }}
      >
        <Col
          span={24}
          style={{
            marginTop: "var(--sub-margin)",
          }}
        >
          <Row gutter={[30, 30]} aign="middle" justify="start">
            {displayActions.map((data, index) => {
              const {
                title,
                createdDate,
                description,
                actionType,
                _id,
                isApproved,
              } = data;
              const titleLength = title?.substring(0, 40);
              const dropdown = (
                <Dropdown
                  overlay={
                    <Menu>
                      <Popconfirm
                        title="Are you sure to Approve this Action?"
                        onConfirm={() => {
                          approveConfirm(_id);
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Menu.Item key="3">Approve</Menu.Item>
                      </Popconfirm>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <EllipsisOutlined style={{ fontSize: "1.4rem" }} />
                </Dropdown>
              );

              return (
                <Col key={index} {...{ xs: 24, sm: 12, md: 12, lg: 12, xl: 6 }}>
                  {isLoading ? (
                    skeleton()
                  ) : (
                    <Card bordered={false} className="dashboardMyActionCard">
                      <Row style={{ width: "100%", height: "100%" }}>
                        <Col span={24}>
                          <Row align="middle" justify="space-between">
                            <Button shape="round">
                              {actionIconFinder(actionType)}
                              {actionNameConverter(actionType)}
                            </Button>
                            {_id ? dropdown : ""}
                          </Row>
                        </Col>
                        <Row
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <Col
                            span={24}
                            style={{
                              marginTop: "15px",
                            }}
                          ></Col>
                          <Row
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              marginTop: "var(--main-margin)",
                            }}
                          >
                            <Col span={24}>
                              <Row justify="start" align="middle">
                                <Col
                                  span={24}
                                  className="dashboardMyActionCardTitle"
                                >
                                  <h1
                                    style={{
                                      color: "#192b9d",
                                      fontSize: "1.7rem",
                                      fontWeight: "200",
                                      height: "85px",
                                    }}
                                  >
                                    {title?.substring(0, 32)}
                                    {title ? null : "Refresh The Page"}
                                    {titleLength?.length > 32 ? "..." : ""}
                                  </h1>
                                </Col>
                                <Col
                                  span={24}
                                  style={{
                                    marginTop: "15px",
                                  }}
                                >
                                  <Row
                                    align="middle"
                                    justify="start"
                                    className="dashboardMyActionCardTitle"
                                  >
                                    <p>{description}</p>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Col
                            span={24}
                            style={{
                              marginTop: "var(--sub-margin)",
                            }}
                          >
                            <Row align="middle" justify="space-between">
                              <p>{moment(createdDate).format("MMM Do YYYY")}</p>
                              <p>{/* <UsergroupAddOutlined /> 30% */}</p>
                            </Row>
                          </Col>
                        </Row>
                      </Row>
                    </Card>
                  )}
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      {actions.length > 8 ? (
        <Col
          span={24}
          style={{
            marginTop: "var(--main-margin)",
            marginBottom: "var(--main-margin)",
          }}
        >
          <Row align="middle" justify="end">
            <Pagination
              className="dashboardPagnaition"
              size="small"
              defaultPageSize={8}
              onChange={paginationChange}
              total={actions.length}
            />
          </Row>
        </Col>
      ) : (
        ""
      )}
    </>
  );
};
export default SuperAdminActionCards;
