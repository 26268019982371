export default [
  {
    title: "Action Type",
    dataIndex: "actionType",
  },
  {
    title: "Email Address",
    dataIndex: "email",
  },
  {
    title: "Full Name",
    dataIndex: "fullname",
  },
  {
    title: "Phone Number",
    dataIndex: "phoneNumber",
  },
  {
    title: "National Id",
    dataIndex: "nationalId",
    ellipsis: true,
  },
  {
    title: "Date Added",
    dataIndex: "dateAdded",
  },
];
