import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Row, Col, Input, Form, Button, Upload, Select, message } from "antd";
import { AdminActionObject } from "../../../../redux/Slices/Flip Cards/AdminFlipCardSlice";
import { shouldActSecChangeSetter } from "../../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
import { setActDonation } from "../../../../redux/Slices/Flip Cards/Actions/ActDonationSlice";
import CustomDonationModal from "../Actions/CustomDonationModal";
import isValidHttpUrl from "../../../ExtraComponents/isValidHttpUrl";

import SearchForDonationModal from "../EtcComponents/SearchForDonationModal";
import { isImage } from "../../../ExtraComponents/isImage";
import ActionsTTI from "../../../ExtraComponents/Components/ActionsTTI";
const { Option } = Select;
function Donation() {
  const currRole = useSelector((state) => state.auth.role);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isModalSearchVisible, setIsModalSearchVisible] = useState(false);
  const handleSearchModalCancel = () => {
    setIsModalSearchVisible(false);
  };

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.ActDonation.action);

  // Pushing Code to Redux Store
  useEffect(() => {
    dispatch(setActDonation(userData));
    dispatch(AdminActionObject(userData));
  }, []);

  // Checking if the required Fields are filled or not
  useEffect(() => {
    if (
      userData.title == "" ||
      userData.description == "" ||
      userData.actionData.projectId == "" ||
      userData.imageURL == ""
    ) {
      dispatch(shouldActSecChangeSetter(false));
    } else {
      dispatch(shouldActSecChangeSetter(true));
    }
  }, [userData]);

  // Uploading Image
  const uploadImage = async (file) => {
    const fileType = file?.type.substring(file?.type.indexOf("/") + 1);
    const sizeofImg = file?.size;
    if (!(fileType === "jpeg" || fileType === "png" || fileType === "jpg")) {
      return message.error("Image type should be jpeg,png,jpg");
    }
    if (sizeofImg >= 1500000) {
      return message.error("Image size should be less than 1.5MB");
    }

    if (file?.status == "removed") {
      file = "";
    }
    let sentData = { ...userData, imageURL: file };
    dispatch(setActDonation(sentData));
    dispatch(AdminActionObject(sentData));
  };

  // onChange handler for all the fields
  const onChangeHandler = (e) => {
    let sentData = { ...userData };
    const { name, value } = e.target;
    if (name == "projectId") {
      sentData = {
        ...sentData,
        actionData: { ...sentData.actionData, [name]: value },
      };
    } else {
      sentData = { ...sentData, [name]: value };
    }
    dispatch(setActDonation(sentData));
    dispatch(AdminActionObject(sentData));
  };

  return (
    <>
      <Col span={24}>
        <Row style={{ width: "100%", marginTop: "var(--sub-margin)" }}>
          <Col span={24}>
            <Form.Item label="Title *">
              <Input
                type="text"
                placeholder="Donation Title"
                className="inputBx"
                value={userData.title}
                name="title"
                onChange={onChangeHandler}
              />
            </Form.Item>
            <Form.Item label="Description *">
              <Input
                type="text"
                placeholder="Donation Description"
                className="inputBx"
                value={userData.description}
                name="description"
                onChange={onChangeHandler}
              />
            </Form.Item>

            <Form.Item label="Select which type of project is it">
              <Select
                className="antdSelect"
                defaultValue="engagement"
                value={userData.actionData.projectOwner}
                name="projectOwner"
                onChange={(e) => {
                  let sentData = { ...userData };
                  sentData = {
                    ...userData,
                    actionData: {
                      ...userData.actionData,
                      projectOwner: e,
                    },
                  };
                  dispatch(setActDonation(sentData));
                  dispatch(AdminActionObject(sentData));
                }}
                size="large"
                bordered={false}
              >
                <Option value="bridged">Bridged</Option>
                <Option value="globalGiving">Global Giving</Option>
              </Select>
            </Form.Item>

            {currRole === "admin" &&
            userData.actionData.projectOwner === "bridged" ? (
              <>
                <Form.Item label="Create Custom Donation">
                  <Button
                    size="large"
                    className="button"
                    onClick={() => {
                      setIsModalVisible(true);
                    }}
                  >
                    Create donation
                  </Button>
                </Form.Item>
                <Form.Item label="Search for Custom Donation">
                  <Button
                    size="large"
                    className="button"
                    onClick={() => {
                      setIsModalSearchVisible(true);
                    }}
                  >
                    Search for donation
                  </Button>
                </Form.Item>
              </>
            ) : null}

            <Form.Item label="Project Id *">
              <Input
                type="number"
                placeholder="Project Id"
                className="inputBx"
                value={userData.actionData.projectId}
                name="projectId"
                onChange={onChangeHandler}
              />
            </Form.Item>

            <Form.Item label="Image * (maximum mage size should be 1.5MB and file format should be jpeg, png, jpg)">
              <Upload
                listType="picture-card"
                name="file"
                maxCount={1}
                onPreview={() => false}
                onChange={(e) => {
                  uploadImage(e?.file);
                }}
                beforeUpload={() => false}
              >
                {isImage(userData?.imageURL) ? null : <PlusOutlined />}
              </Upload>
              {isValidHttpUrl(userData?.imageURL) && (
                <img
                  src={userData?.imageURL}
                  style={{
                    width: "100%",
                    height: "100%",
                    maxWidth: "200px",
                    marginTop: "var(--sub-margin)",
                    marginBottom: "var(--sub-margin)",
                  }}
                />
              )}
            </Form.Item>
            <ActionsTTI />
          </Col>
        </Row>
      </Col>
      <CustomDonationModal
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
      />
      <SearchForDonationModal
        isModalSearchVisible={isModalSearchVisible}
        handleSearchModalCancel={handleSearchModalCancel}
      />
    </>
  );
}

export default Donation;
