import { createSlice } from "@reduxjs/toolkit";
import DonationInitialState from "../../../../Initial States/Actions/DonationInitialState";

const ActDonationSlice = createSlice({
  name: "ActDonation",
  initialState: {
    action: DonationInitialState,
  },
  reducers: {
    setActDonation: (state, action) => {
      state.action = action.payload;
    },
  },
});

export const { setActDonation } = ActDonationSlice.actions;
export default ActDonationSlice.reducer;
