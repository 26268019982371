import { Col, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
const tableColumn = [
  {
    title: "Title",
    dataIndex: "title",
  },
  {
    title: "Count",
    dataIndex: "count",
  },
];
function EngagementChart() {
  const data = useSelector(
    (state) => state.NewFlipcardOverview.flipcardOverviewData
  );
  const { engagementChart } = data;
  const [userData, setUserData] = useState({ chartData: [] });
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setUserData({
      chartData: engagementChart?.map((item) => {
        return {
          ...item,
          date: moment(item.date).format("YY-MM-DD"),
        };
      }),
    });

    if (data?.engagementType == "sliderPoll") {
      let firstHalf = 0;
      let secondHalf = 0;
      data?.engagementOptions.forEach((data, index) => {
        if (index < 5) {
          firstHalf = firstHalf + data.count;
        } else {
          secondHalf = secondHalf + data.count;
        }
      });
      const sliderPollData = [
        {
          title: data?.engagementOptions[0]?.title,
          count: firstHalf,
        },
        {
          title: data?.engagementOptions[1]?.title,
          count: secondHalf,
        },
      ];
      setTableData(sliderPollData);
    } else {
      setTableData(data?.engagementOptions);
    }
  }, [data]);
  return (
    <>
      <Col
        span={24}
        style={{
          height: "400px",
        }}
      >
        <ResponsiveContainer>
          <BarChart data={userData?.chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="value" stackId="a" fill="var(--third-Text-Color)" />
          </BarChart>
        </ResponsiveContainer>
      </Col>
      <Col span={24}>
        <h3>{data?.engagementTitle}</h3>
      </Col>
      <Col span={24}>
        <Table
          className="analyticsTable"
          columns={tableColumn}
          dataSource={tableData}
          size="small"
          pagination={false}
        />
      </Col>
    </>
  );
}

export default EngagementChart;
