import { Col, Table } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import actionIconFinder from "../../ExtraComponents/actionIconFinder";
import actionNameConverter from "../../ExtraComponents/actionNameConverter";
const tableColumn = [
  {
    title: "Action Type",
    dataIndex: "actionType",
  },
  {
    title: "Title",
    dataIndex: "title",
    ellipsis: true,
  },
  {
    title: "Takes Count",
    dataIndex: "takesCount",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.takesCount - b.takesCount,
  },
  {
    title: "Views Count",
    dataIndex: "viewsCount",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.viewsCount - b.viewsCount,
  },
];
function TopPerformingActions() {
  const flipCardData = useSelector((state) => state.Analytics.data);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const data = flipCardData?.topPerformingActions?.map((data) => {
      const { actionType } = data;
      return {
        ...data,
        actionType: actionNameConverter(actionType),
      };
    });
    setTableData(data);
  }, [flipCardData]);
  return (
    <Col span={24}>
      <Table
        className="analyticsTable"
        columns={tableColumn}
        dataSource={tableData}
        size="small"
        pagination={{
          size: "small",
          pageSize: 9,
        }}
      />
    </Col>
  );
}

export default TopPerformingActions;
