import { Col, Form, Row, Skeleton } from "antd";
import "../flipcard.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  currActionSetter,
  currEngSetter,
  currentSectionSetter,
  dynamicFormNumberOfIndexesSetter,
  optionsAdderSetter,
} from "../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
import Topbar from "../ResusableComponents/EtcComponents/Topbar";
import Navbar from "../ResusableComponents/EtcComponents/NavBar";
import AdminStart from "./Pages/AdminStart";
import Customise from "../ResusableComponents/MainSections/2Customise/Customise";
import IframeMainSection from "../ResusableComponents/EtcComponents/IframeMainSection";
import AddAction from "../ResusableComponents/MainSections/3Add impact/AddAction";
import Preview from "./Pages/Preview";
import Finish from "./Pages/Finish";
import { setEngFactCheck } from "../../../redux/Slices/Flip Cards/Engagements/EngFactCheckSlice";
import FactCheckInitialState from "../../../Initial States/Engagements/FactCheckInitialState";
import { setEngSliderPoll } from "../../../redux/Slices/Flip Cards/Engagements/EngSliderPollSlice";
import SliderPollInitialState from "../../../Initial States/Engagements/SliderPollInitialState";
import { setEngQuiz } from "../../../redux/Slices/Flip Cards/Engagements/EngQuizSlice";
import QuizInitialState from "../../../Initial States/Engagements/QuizInitialState";
import { setActDonation } from "../../../redux/Slices/Flip Cards/Actions/ActDonationSlice";
import DonationInitialState from "../../../Initial States/Actions/DonationInitialState";
import { setActMessageBox } from "../../../redux/Slices/Flip Cards/Actions/ActMessageBoxSlice";
import MessageBoxInitialState from "../../../Initial States/Actions/MessageBoxInitialState";
import { setActNewsLetterSignUp } from "../../../redux/Slices/Flip Cards/Actions/ActNewsLetterSignUpSlice";
import NewsLetterSignupInitialState from "../../../Initial States/Actions/NewsLetterSignupInitialState";
import { setActSignAPetition } from "../../../redux/Slices/Flip Cards/Actions/ActSignAPetitionSlice";
import SignAPetitionInitialState from "../../../Initial States/Actions/SignAPetitionInitialState";
import {
  AdminActionObject,
  AdminEngagementObject,
} from "../../../redux/Slices/Flip Cards/AdminFlipCardSlice";
import {
  FlipCardaction,
  FlipcardActionPage,
  FlipCardengagement,
  FlipCardtitle,
} from "../../../redux/Slices/Flip Cards/FlipCardSlice";
import {
  useFindEngagementByIdMutation,
  useFindActionByIdMutation,
} from "../../../Services/service";
import { useParams } from "react-router-dom";
import { setActLink } from "../../../redux/Slices/Flip Cards/Actions/ActLinkSlice";
import { setActDynamicForm } from "../../../redux/Slices/Flip Cards/Actions/ActDynamicFormSlice";

const navbarArr1 = [
  {
    title: "Start",
  },
  {
    title: "Customise",
  },
  {
    title: "Preview",
  },
  {
    title: "Finish",
  },
];

const navbarArr2 = [
  {
    title: "Start",
  },
  {
    title: "Add Action",
  },
  {
    title: "Preview",
  },
  {
    title: "Finish",
  },
];

const flipToFront = {
  event: "flipToFront",
};

const flipToBack = {
  event: "flipToBack",
};

function AdminFlipCardWorkflow() {
  const pathname = window.location.href.split("/")[3];
  const iframeLoadedSetterData = useSelector(
    (state) => state.FlipCardEtc.iframeLoaded
  );
  const [isLoading, setIsLoading] = useState(false);
  const currEng = useSelector((state) => state.FlipCardEtc.currEng);
  const currAction = useSelector((state) => state.FlipCardEtc.currAction);
  const FlipCardData = useSelector((state) => state.flipCard);
  const adminFlipCardData = useSelector((state) => state.AdminFlipCard);
  const [iframeData, setIframeData] = useState(FlipCardData);
  const [currentTemplateType, setCurrentTemplateType] = useState("engagement");
  const [inventory, setInventory] = useState({
    title: "",
    SDGs: [],
    topics: [],
    hashtags: [],
  });
  const dispatch = useDispatch();
  const { _id, type } = useParams();
  const currentSection = useSelector(
    (state) => state.FlipCardEtc.currentSection
  );

  const { actionObject } = adminFlipCardData;
  const section = () => {
    switch (currentSection) {
      case 1:
        if (currentTemplateType === "engagement") {
          return <Customise secPrev={0} secNext={2} />;
        } else {
          return (
            <AddAction
              iscreateNewAction={true}
              secPrev={0}
              secNext={2}
              setInventory={setInventory}
              type={type}
            />
          );
        }
      case 2:
        return (
          <Preview
            currentTemplateType={currentTemplateType}
            setInventory={setInventory}
            inventory={inventory}
          />
        );

      case 3:
        return <Finish currentTemplateType={currentTemplateType} />;
    }
  };

  // Sets the iframe data to flipcard data whenever the flipcard data changes
  useEffect(() => {
    let customActionObj = { ...actionObject, inventory };
    const iframeSentData = { ...FlipCardData, actions: [customActionObj] };
    setIframeData(iframeSentData);
  }, [FlipCardData, currEng, currAction, iframeLoadedSetterData]);

  // Setting Iframe Data as per section
  useEffect(() => {
    const iframeEtcData = { pageViews: [], takesCount: 0 };
    let customActionObj = { ...actionObject, inventory, ...iframeEtcData };
    let iframeSentData = { ...FlipCardData, actions: [customActionObj] };
    if (currentTemplateType == "action") {
      delete iframeSentData.engagement;
    }
    if (currentTemplateType == "engagement") {
      delete iframeSentData.actions;
    }

    setIframeData(iframeSentData);
  }, [currentSection, iframeLoadedSetterData, adminFlipCardData]);

  // --------------------------------- Edit Template ---------------------------------
  const [_findEngagementById] = useFindEngagementByIdMutation();

  const [_findActionById] = useFindActionByIdMutation();

  useEffect(() => {
    dispatch(currentSectionSetter(0));
    if (_id) {
      editTemplate();
      dispatch(currentSectionSetter(1));
    } else {
      // --------------------- Engagements ---------------------
      dispatch(setEngFactCheck(FactCheckInitialState));
      dispatch(setEngSliderPoll(SliderPollInitialState));
      dispatch(setEngQuiz(QuizInitialState));

      // --------------------- Actions ---------------------
      dispatch(setActDonation(DonationInitialState));
      dispatch(setActMessageBox(MessageBoxInitialState));
      dispatch(setActNewsLetterSignUp(NewsLetterSignupInitialState));
      dispatch(setActSignAPetition(SignAPetitionInitialState));

      // --------------------- Flip Cards ---------------------
      dispatch(AdminEngagementObject({}));
      dispatch(AdminActionObject({}));
      dispatch(
        FlipcardActionPage({
          title: "Are you ready to take action?",
          description: "You can be part of the solution. Take action now.",
        })
      );
      dispatch(FlipCardtitle(""));
      dispatch(FlipCardengagement({}));
      dispatch(FlipCardaction([]));
    }
  }, []);

  const editTemplate = async () => {
    let templateData = {};
    let responseEng = "";
    let responseAct = "";
    setIsLoading(true);
    if (pathname == "templateedit") {
      if (type == "action") {
        responseAct = await _findActionById(_id);
      } else {
        responseEng = await _findEngagementById(_id);
      }
    }
    if (responseAct?.data) {
      setCurrentTemplateType("action");
      templateData = responseAct?.data;
      editActionHandler(templateData);
    }
    if (responseEng?.data) {
      setCurrentTemplateType("engagement");
      templateData = responseEng?.data;
      editEngagementHandler(templateData);
    }
    setIsLoading(false);
  };

  // Edit Engagement
  const editEngagementHandler = (templateData) => {
    const { title, engagementData, description, inventory } = templateData;
    const engagementType = engagementData?.engagementType;
    const engagement = {
      title,
      description,
      engagementData,
    };
    if (inventory) {
      setInventory(inventory);
    }
    if (engagementData?.options.length < 3) {
      dispatch(optionsAdderSetter(0));
    } else if (engagementData?.options.length == 3) {
      dispatch(optionsAdderSetter(1));
    } else if (engagementData?.options.length == 4) {
      dispatch(optionsAdderSetter(2));
    }
    dispatch(currEngSetter(engagementType));
    if (engagementType === "sliderPoll") {
      dispatch(setEngSliderPoll(engagement));
      dispatch(FlipCardengagement(engagement));
      dispatch(AdminEngagementObject(engagement));
    }
    if (engagementType === "quiz") {
      dispatch(setEngQuiz(engagement));
      dispatch(FlipCardengagement(engagement));
      dispatch(AdminEngagementObject(engagement));
    }
    if (engagementType === "factcheck") {
      dispatch(setEngFactCheck(engagement));
      dispatch(FlipCardengagement(engagement));
      dispatch(AdminEngagementObject(engagement));
    }
  };

  // Edit Action
  const editActionHandler = (templateData) => {
    const { title, description, imageURL, actionData, inventory, _id } =
      templateData;
    const actionType = actionData?.actionType;
    dispatch(currActionSetter(actionType));
    let userData = {
      title,
      description,
      imageURL,
      actionData: {
        ...actionData,
      },
    };

    if (inventory) {
      setInventory(inventory);
    }
    if (actionType == "messageBox") {
      dispatch(setActMessageBox(userData));
    }
    if (actionType == "signAPetition") {
      dispatch(setActSignAPetition(userData));
    }
    if (actionType == "donation") {
      dispatch(setActDonation(userData));
    }
    if (actionType == "newsLetterSignup") {
      dispatch(setActNewsLetterSignUp(userData));
    }
    if (actionType == "link") {
      dispatch(setActLink(userData));
    }
    if (actionType == "dynamicForm") {
      dispatch(setActDynamicForm(userData));
      dispatch(
        dynamicFormNumberOfIndexesSetter(userData.actionData.fields.length - 1)
      );
    }
  };
  return (
    <section className="flipcardSection">
      <div className="flipcardMainDiv">
        <Form layout="vertical">
          <Topbar />
          {isLoading ? (
            <Skeleton.Button
              active={true}
              block={true}
              style={{
                height: "600px",
                borderRadius: "var(--input-Border-Radius3)",
                marginTop: "var(--main-margin)",
              }}
            />
          ) : (
            <>
              <Navbar
                currentSection={currentSection}
                navbarArr={
                  currentTemplateType == "engagement" ? navbarArr1 : navbarArr2
                }
              />
              <Row
                style={{ marginTop: "var(--main-margin)" }}
                justify="space-between"
              >
                {currentSection === 0 ? (
                  <AdminStart
                    setCurrentTemplateType={setCurrentTemplateType}
                    currentTemplateType={currentTemplateType}
                  />
                ) : (
                  <>
                    {currentSection === 3 ? (
                      section()
                    ) : (
                      <Col {...{ xs: 24, sm: 24, md: 24, lg: 13, xl: 13 }}>
                        {section()}
                      </Col>
                    )}
                    {currentSection === 3 ? null : (
                      <Col {...{ xs: 24, sm: 24, md: 24, lg: 10, xl: 10 }}>
                        <IframeMainSection iframeData={iframeData} />
                      </Col>
                    )}
                  </>
                )}
              </Row>
            </>
          )}
        </Form>
      </div>
    </section>
  );
}

export default AdminFlipCardWorkflow;
