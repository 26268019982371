import { Button, Col, Row } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";

function Finish({ currentTemplateType }) {
  return (
    <>
      <Row
        justify="center"
        align="middle"
        style={{
          width: "100%",
        }}
      >
        <Col
          {...{ xs: 24, sm: 24, md: 18, lg: 15, xl: 13 }}
          className="flipcardOutCompGrey"
        >
          <Row className="flipcardInCompWhite" align="middle" justify="center">
            <Col span={24}>
              <h1
                style={{
                  fontWeight: "600",
                  textAlign: "center",
                  color: "var(--secondary-Text-Color)",
                }}
              >
                Your {currentTemplateType} is being reviewed
              </h1>
            </Col>
            <Col
              {...{ xs: 24, sm: 24, md: 24, lg: 13, xl: 13 }}
              style={{
                marginTop: "var(--sub-margin)",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                }}
              >
                This action will be reviewed by our internal team in under 24
                hours. You will receive a notification once the card is ready
                for use.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        justify="center"
        align="middle"
        style={{
          width: "100%",
          marginTop: "var(--main-margin)",
        }}
      >
        <Col {...{ xs: 24, sm: 24, md: 18, lg: 15, xl: 13 }}>
          <Row justify="center" align="middle">
            <NavLink to="/">
              <Button size="large" type="text" className="button">
                BACK TO DASHBOARD
              </Button>
            </NavLink>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Finish;
