import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Switch } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { setTopics } from "../../redux/Slices/OnBoarding/OnboardingSlice";
import SDGs from "../ExtraComponents/SDGs";
import SupportModal from "../ExtraComponents/Components/SupportModal";
const industries = [
  {
    name: "Computer",
    value: "computer",
    logo: "fas fa-tv",
  },
  {
    name: "Construction",
    value: "construction",
    logo: "fas fa-tools",
  },
  {
    name: "Education",
    value: "education",
    logo: "fas fa-university",
  },
  {
    name: "Electronics",
    value: "electronics",
    logo: "fas fa-microchip",
  },
  {
    name: "Transport",
    value: "transport",
    logo: "fas fa-motorcycle",
  },
  {
    name: "Entertainment",
    value: "entertainment",
    logo: "fas fa-film",
  },
  {
    name: "Food",
    value: "food",
    logo: "fas fa-hamburger",
  },
  {
    name: "Health Care",
    value: "health care",
    logo: "fas fa-user-md",
  },
  {
    name: "Music",
    value: "music",
    logo: "fas fa-music",
  },
  {
    name: "News Media",
    value: "news media",
    logo: "fas fa-newspaper",
  },
];

function Topics({ setToggleSections, cbo, setIsSDGs, isSDGs }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.OnBoard.topics);
  const [visible, setVisible] = useState(false);
  const [modalType, setModalType] = useState("Support");
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleSDGS = (e) => {
    const targetval = parseInt(e.target.value);
    if (e.target.checked) {
      dispatch(
        setTopics({
          ...userData,
          SDGs: [...userData.SDGs, targetval],
        })
      );
    } else {
      dispatch(
        setTopics({
          ...userData,
          SDGs: userData.SDGs.filter((sdg) => sdg !== targetval),
        })
      );
    }
  };

  return (
    <>
      <Col
        {...{ xs: 24, sm: 24, md: 24, lg: 24, xl: 18 }}
        className="onBoardingMainDiv"
      >
        <Row>
          <Col span={24}>
            <Row justify="end" align="middle" style={{ width: "100%" }}>
              <h3>
                Having an issue?{" "}
                <a
                  href="#"
                  onClick={() => {
                    setModalType("Support");
                    setVisible(true);
                  }}
                >
                  Get Help
                </a>
              </h3>
            </Row>
          </Col>

          <Col
            {...{ xs: 24, sm: 24, md: 21, lg: 21, xl: 21 }}
            style={{ marginTop: "60px" }}
          >
            <h1>What topics would you create content on?</h1>
          </Col>

          <Col
            {...{ xs: 24, sm: 24, md: 21, lg: 21, xl: 21 }}
            style={{ marginTop: "10px" }}
          >
            <p>Choose the areas that best describe your content or business.</p>
          </Col>

          <Col
            {...{ xs: 24, sm: 24, md: 21, lg: 21, xl: 21 }}
            style={{ marginTop: "var(--main-margin)" }}
          >
            <Row style={{ width: "100%" }} className="onBoardingCheckBoxexPos">
              {industries.map((data, index) => {
                return (
                  <label
                    key={index}
                    className="checkBoxLabel onBoardingPubTopiccheckBoxLabel"
                  >
                    <input
                      type="checkbox"
                      className="checkBox"
                      value={data.value}
                      onChange={(e) => {
                        dispatch(
                          setTopics({
                            ...userData,
                            contentCoverage: e.target.value,
                          })
                        );
                      }}
                      checked={data.value == userData.contentCoverage}
                    />
                    <div className="checkBoxInner">
                      <div className="checkBoxIconDiv">
                        <i className={data.logo}></i>
                      </div>
                      <div className="checkBoxName">{data.name}</div>
                    </div>
                  </label>
                );
              })}
            </Row>
          </Col>

          <Col
            {...{ xs: 24, sm: 24, md: 21, lg: 21, xl: 21 }}
            style={{ marginTop: "var(--sub-margin)" }}
          >
            <Input
              type="text"
              placeholder="Other..."
              className="inputBx"
              value={userData.contentCoverage}
              onChange={(e) => {
                dispatch(
                  setTopics({
                    ...userData,
                    contentCoverage: e.target.value,
                  })
                );
              }}
            />
          </Col>

          <Col
            span={24}
            style={{
              marginTop: "var(--main-margin)",
            }}
          >
            <h1>Do you follow any SDGs?</h1>
            <Switch
              style={{
                marginTop: "var(--sub-margin)",
              }}
              checked={isSDGs}
              onChange={(e) => {
                setIsSDGs(e);
              }}
            />
          </Col>
          {/* -------------- Option -------------- */}
          {isSDGs && (
            <Row>
              <Col
                {...{ xs: 24, sm: 24, md: 21, lg: 21, xl: 21 }}
                style={{ marginTop: "var(--main-margin)" }}
              >
                <h1>What about SDGs?</h1>
              </Col>

              <Col
                {...{ xs: 24, sm: 24, md: 21, lg: 21, xl: 21 }}
                style={{ marginTop: "10px" }}
              >
                <p>
                  Click{" "}
                  <a
                    href="https://sdgs.un.org/goals"
                    target="blank"
                    className="aTag"
                  >
                    here
                  </a>{" "}
                  to know more about the UN Sustainable Development Goals.
                </p>
              </Col>

              <Col
                {...{ xs: 24, sm: 24, md: 21, lg: 21, xl: 21 }}
                style={{ marginTop: "var(--main-margin)" }}
              >
                <Row
                  className="onBoardingCheckBoxexPos"
                  style={{ width: "100%" }}
                >
                  {SDGs.map((data, index) => {
                    return (
                      <label
                        key={index}
                        className="checkBoxLabel onBoardingPubTopicSDGcheckBoxLabel"
                      >
                        <input
                          type="checkbox"
                          className="checkBox"
                          value={data.digitValue}
                          checked={userData.SDGs.includes(data.digitValue)}
                          onChange={handleSDGS}
                        />
                        <div className="checkBoxInner">
                          <img
                            style={{
                              borderRadius: "var(--input-Border-Radius3)",
                            }}
                            src={`https://assets.bridged.media/${data.imgSrc}`}
                            alt=""
                          />
                        </div>
                      </label>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          )}

          <Row
            gutter={[0, 20]}
            style={{ width: "100%", marginTop: "var(--main-margin)" }}
          >
            <Col {...{ xs: 24, sm: 12, md: 12, lg: 12 }}>
              <Button
                className="button"
                size="large"
                type="default"
                onClick={() => {
                  history.push("/");
                }}
              >
                <ArrowLeftOutlined />
                Dashboard
              </Button>
            </Col>
            <Col {...{ xs: 24, sm: 12, md: 12, lg: 12 }}>
              <Row justify="end" align="middle" style={{ width: "100%" }}>
                <Row
                  justify="end"
                  align="middle"
                  style={{ width: "100%", maxWidth: "180px" }}
                >
                  <Button
                    className="button"
                    type="primary"
                    size="large"
                    onClick={() => {
                      if (cbo) {
                        setToggleSections("Organisation Details");
                      } else {
                        setToggleSections("Goals");
                      }
                    }}
                  >
                    NEXT
                    <ArrowRightOutlined />
                  </Button>
                </Row>
              </Row>
            </Col>
          </Row>
        </Row>
      </Col>
      <SupportModal
        visible={visible}
        confirmLoading={confirmLoading}
        setConfirmLoading={setConfirmLoading}
        setVisible={setVisible}
        modalType={modalType}
      />
    </>
  );
}

export default Topics;
