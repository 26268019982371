import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, message } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setGoals } from "../../redux/Slices/OnBoarding/OnboardingSlice";
import { useOnBoardMutation } from "../../Services/service";
import SupportModal from "../ExtraComponents/Components/SupportModal";

const objective = [
  {
    name: `Increasing retention`,
    value: "increasing retention",
  },
  {
    name: "Complementing content with purpose",
    value: "complementing content with purpose",
  },
  {
    name: "Enhancing engagement",
    value: "enhancing engagement",
  },
  {
    name: "Collecting richer data about your audience",
    value: "collecting richer data about your audience",
  },
];

function Goals({ setToggleSections, cbo }) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.OnBoard.goals);

  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalType, setModalType] = useState("Support");

  return (
    <>
      <Col
        {...{ xs: 24, sm: 24, md: 24, lg: 24, xl: 18 }}
        className="onBoardingMainDiv"
      >
        <Row>
          <Col span={24}>
            <Row justify="end" align="middle" style={{ width: "100%" }}>
              <h3>
                Having an issue?{" "}
                <a
                  href="#"
                  onClick={() => {
                    setModalType("Support");
                    setVisible(true);
                  }}
                >
                  Get Help
                </a>
              </h3>
            </Row>
          </Col>

          <Col
            {...{ xs: 24, sm: 24, md: 21, lg: 21, xl: 21 }}
            style={{ marginTop: "10px" }}
          >
            <h1 style={{ color: "var(--Text-Color)", fontSize: "1.8rem" }}>
              What are your content objectives?*
            </h1>
          </Col>

          <Col
            {...{ xs: 24, sm: 24, md: 21, lg: 21, xl: 21 }}
            style={{ marginTop: "10px" }}
          >
            <p>Tell us what do you hope Bridged will help you with.</p>
          </Col>

          <Col
            {...{ xs: 24, sm: 24, md: 21, lg: 21, xl: 21 }}
            style={{ marginTop: "var(--sub-margin)", flexWrap: "wrap" }}
            className="onBoardingCheckBoxexPos"
          >
            {objective.map((data, index) => {
              return (
                <label
                  key={index}
                  className="checkBoxLabel onBoardingPubGoalcheckBoxLabel"
                >
                  <input
                    type="checkbox"
                    className="checkBox"
                    value={data.value}
                    checked={userData.mainGoal == data.value}
                    onChange={(e) => {
                      dispatch(
                        setGoals({ ...userData, mainGoal: e.target.value })
                      );
                    }}
                  />
                  <div className="checkBoxInner">
                    <div className="checkBoxName">{data.name}</div>
                  </div>
                </label>
              );
            })}
          </Col>

          <Col
            {...{ xs: 24, sm: 24, md: 21, lg: 21, xl: 21 }}
            style={{ marginTop: "var(--sub-margin)" }}
          >
            <Input
              type="text"
              className="inputBx"
              placeholder="Others..."
              value={userData.mainGoal}
              onChange={(e) => {
                dispatch(setGoals({ ...userData, mainGoal: e.target.value }));
              }}
            />
          </Col>

          <Row gutter={[0, 20]} style={{ width: "100%", marginTop: "250px" }}>
            <Col {...{ xs: 24, sm: 12, md: 12, lg: 12 }}>
              <Button
                className="buttonWhite opacity05"
                size="large"
                type="text"
                style={{ padding: "0px" }}
                onClick={() => {
                  if (cbo) {
                    setToggleSections("Organisation Details");
                  } else {
                    setToggleSections("Topics");
                  }
                }}
              >
                BACK
              </Button>
            </Col>
            <Col {...{ xs: 24, sm: 12, md: 12, lg: 12 }}>
              <Row justify="end" align="middle" style={{ width: "100%" }}>
                <Row
                  justify="end"
                  align="middle"
                  style={{ width: "100%", maxWidth: "181px" }}
                >
                  <Button
                    className="button"
                    type="primary"
                    size="large"
                    onClick={() => {
                      setToggleSections("Personal information");
                    }}
                  >
                    NEXT
                    <ArrowRightOutlined />
                  </Button>
                </Row>
              </Row>
            </Col>
          </Row>
        </Row>
      </Col>
      <SupportModal
        visible={visible}
        confirmLoading={confirmLoading}
        setConfirmLoading={setConfirmLoading}
        setVisible={setVisible}
        modalType={modalType}
      />
    </>
  );
}

export default Goals;
