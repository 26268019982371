import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Select } from "antd";
import { useDispatch } from "react-redux";
import { currentSectionSetter } from "../../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
import React from "react";
const { Option } = Select;
function AdminStart({ setCurrentTemplateType, currentTemplateType }) {
  const dispatch = useDispatch();
  const pathname = window.location.href.split("/")[3];

  return (
    <>
      <Row
        justify="center"
        align="middle"
        style={{
          width: "100%",
        }}
      >
        <Col
          {...{ xs: 24, sm: 24, md: 18, lg: 15, xl: 12 }}
          className="flipcardOutCompGrey"
        >
          <Row className="flipcardInCompWhite">
            <Col span={24}>
              <Form.Item
                style={{
                  marginBottom: "0px",
                }}
              >
                <h2>Select a Template Type</h2>
                <p
                  style={{
                    marginBottom: "var(--sub-margin)",
                  }}
                  className="opacity05"
                >
                  Create reusable templates for your team
                </p>
                <Select
                  className="antdSelect"
                  defaultValue="engagement"
                  value={currentTemplateType}
                  onChange={(e) => setCurrentTemplateType(e)}
                  size="large"
                  bordered={false}
                  disabled={pathname === "templateedit"}
                >
                  <Option value="engagement">Engagement</Option>
                  <Option value="action">Action</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        justify="center"
        align="middle"
        style={{
          width: "100%",
          marginTop: "var(--main-margin)",
        }}
      >
        <Col {...{ xs: 24, sm: 24, md: 18, lg: 15, xl: 12 }}>
          <Row justify="end" align="middle">
            <Button
              size="large"
              type="primary"
              className="button"
              onClick={() => {
                dispatch(currentSectionSetter(1));
              }}
            >
              Next <ArrowRightOutlined />
            </Button>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default AdminStart;
