import { InfoCircleFilled } from "@ant-design/icons";
import { Button, Col, message, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
const { TabPane } = Tabs;

function ShareCardComponent({ flipcardSubmitId: id }) {
  const [flipcardSrc, setflipcardSrc] = useState();
  const [flipcardCode, setflipcardCode] = useState();
  const [flipcardiframeCode, setflipcardiframeCode] = useState();
  useEffect(() => {
    setflipcardSrc(`https://action.bridged.media/?id=${id}&embed=false`);
    setflipcardCode(`<script async src="${process.env.REACT_APP_FLIPCARD_URL}widget/loader.js"></script>
<div bridgedid="poll-div" flipcardid="${id}"> </div>
`);
    setflipcardiframeCode(`
    <div style="height: 430px;">
    <iframe
      sandbox="allow-forms allow-scripts allow-same-origin allow-popups"
      allow="clipboard-write"
      style="
          width: 100%;
          height: 100%;
          border: none;
          overflow: hidden;
          touch-action: initial;
        "
      src="https://app.bridged.media/?id=${id}&embed=true"
    ></iframe>
  </div>`);
  }, []);

  return (
    <>
      <Col span={24}>
        <h1
          style={{
            fontWeight: "600",
            textAlign: "center",
            color: "var(--secondary-Text-Color)",
          }}
        >
          Your Card is live!
        </h1>
      </Col>
      <Col
        span={24}
        style={{
          marginTop: "var(--main-margin)",
        }}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Embed script" key="1">
            <Row>
              <Col span={24}>
                <p>Copy and paste this code into your website or blog.</p>
              </Col>
              <Col
                span={24}
                style={{
                  marginTop: "var(--sub-margin)",
                  padding: "var(--sub-margin)",
                  backgroundColor: "var(--secondary-Text-Color-bg)",
                  borderRadius: "var(--input-Border-Radius3)",
                }}
              >
                <p
                  style={{
                    color: "var(--secondary-Text-Color)",
                  }}
                >
                  <InfoCircleFilled /> Unsure how to embed this card on your
                  website?
                  <a
                    href="#"
                    style={{
                      fontWeight: "bold",
                      color: "var(--secondary-Text-Color)",
                    }}
                  >
                    {" "}
                    Read about embedding
                  </a>
                </p>
              </Col>
              <Col
                span={24}
                style={{
                  backgroundColor: "#283341",
                  margin: "10px 0px 10px 0px",
                  padding: "20px",
                  borderRadius: "var(--input-Border-Radius3)",
                  color: "#be6d7e",
                }}
              >
                {`<script async src=
                    `}
                <span style={{ color: "#6bb1a8" }}>
                  "{process.env.REACT_APP_FLIPCARD_URL}widget/loader.js"
                </span>
                {`
                  ></script>
                  <div bridgedid="poll-div" flipcardid=
                `}
                <span style={{ color: "#6bb1a8" }}>"{id}"</span>
                {`
                  > </div>`}
              </Col>

              <Col
                span={24}
                style={{
                  marginTop: "var(--sub-margin)",
                }}
              >
                <Row justify="end" align="middle">
                  <Button
                    onClick={() => {
                      navigator.clipboard.writeText(flipcardCode);
                      message.success("Code Copied to clipboard");
                    }}
                    size="large"
                    type="primary"
                    className="button"
                  >
                    Copy
                  </Button>
                </Row>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Embed iframe" key="2">
            <Row>
              <Col span={24}>
                <p>
                  Copy and paste this iframe code into your website or blog.
                </p>
              </Col>
              <Col
                span={24}
                style={{
                  marginTop: "var(--sub-margin)",
                  padding: "var(--sub-margin)",
                  backgroundColor: "var(--secondary-Text-Color-bg)",
                  borderRadius: "var(--input-Border-Radius3)",
                }}
              >
                <p
                  style={{
                    color: "var(--secondary-Text-Color)",
                  }}
                >
                  <InfoCircleFilled /> Unsure how to embed this card on your
                  website?
                  <a
                    href="#"
                    style={{
                      fontWeight: "bold",
                      color: "var(--secondary-Text-Color)",
                    }}
                  >
                    {" "}
                    Read about embedding
                  </a>
                </p>
              </Col>
              <Col
                span={24}
                style={{
                  backgroundColor: "#283341",
                  margin: "10px 0px 10px 0px",
                  padding: "20px",
                  borderRadius: "var(--input-Border-Radius3)",
                  color: "#be6d7e",
                }}
              >
                {`<div`}{" "}
                <span style={{ color: "#6bb1a8" }}>style="height: 430px;"</span>
                {`><iframe
                    sandbox="allow-forms allow-scripts allow-same-origin allow-popups"
                    allow="clipboard-write"
                    style="width: 100%; height: 100%; border: none; overflow: hidden; touch-action: initial; "
                    `}
                <span style={{ color: "#6bb1a8" }}>
                  src="https://app.bridged.media/?id={id}&embed=true"
                </span>
                {`></iframe></div>`}
              </Col>

              <Col
                span={24}
                style={{
                  marginTop: "var(--sub-margin)",
                }}
              >
                <Row justify="end" align="middle">
                  <Button
                    onClick={() => {
                      navigator.clipboard.writeText(flipcardiframeCode);
                      message.success("Code Copied to clipboard");
                    }}
                    size="large"
                    type="primary"
                    className="button"
                  >
                    Copy
                  </Button>
                </Row>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Share link" key="3">
            <Row>
              <Col span={24}>
                <p>
                  Copy the link below to share in all your favourite platforms.
                </p>
              </Col>
              <Col
                span={24}
                style={{
                  marginTop: "var(--sub-margin)",
                  padding: "var(--sub-margin)",
                  backgroundColor: "var(--secondary-Text-Color-bg)",
                  borderRadius: "var(--input-Border-Radius3)",
                }}
              >
                <p
                  style={{
                    color: "var(--secondary-Text-Color)",
                  }}
                >
                  <InfoCircleFilled /> Anyone with this link can view and engage
                  with the card.
                </p>
              </Col>
              <Col
                span={24}
                style={{
                  margin: "10px 0px 10px 0px",
                  padding: "20px",
                  borderRadius: "var(--input-Border-Radius3)",
                  border: "1px solid var(--main-Border-Color)",
                }}
              >
                <a
                  href={`https://action.bridged.media/?id=${id}&embed=false`}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: "var(--secondary-Text-Color)",
                  }}
                >
                  {`https://action.bridged.media/?id=${id}&embed=false`}
                </a>
              </Col>
              <Col
                span={24}
                style={{
                  marginTop: "var(--sub-margin)",
                }}
              >
                <Row justify="end" align="middle">
                  <Button
                    onClick={() => {
                      navigator.clipboard.writeText(flipcardSrc);
                      message.success("URL Copied to clipboard");
                    }}
                    size="large"
                    type="primary"
                    className="button"
                  >
                    Copy
                  </Button>
                </Row>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Col>
    </>
  );
}

export default ShareCardComponent;
