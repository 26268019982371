import React, { useState } from "react";
import { Button, Col, Input, Row, message, Upload, Select } from "antd";
import SupportModal from "../ExtraComponents/Components/SupportModal";
import { ArrowRightOutlined, PlusOutlined } from "@ant-design/icons";
import { isImage } from "../ExtraComponents/isImage";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setGoals,
  setPersonalInformation,
} from "../../redux/Slices/OnBoarding/OnboardingSlice";
import {
  useOnBoardMutation,
  useUploadImageMutation,
} from "../../Services/service";
import { useHistory } from "react-router-dom";
import isValidHttpUrl from "../ExtraComponents/isValidHttpUrl";
const { Option } = Select;

function PersonalInformation({ setToggleSections, cbo }) {
  const userData = useSelector((state) => state.OnBoard.goals);
  const dispatch = useDispatch();
  const personalInformation = useSelector(
    (state) => state.OnBoard.personalInformation
  );
  const onBoardingData = useSelector((state) => state.OnBoard);
  const history = useHistory();

  const [modalType, setModalType] = useState("Support");
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const uploadImage = async (file) => {
    const fileType = file?.type.substring(file?.type.indexOf("/") + 1);
    const sizeofImg = file?.size;
    if (!(fileType === "jpeg" || fileType === "png" || fileType === "jpg")) {
      return message.error("Image type should be jpeg,png,jpg");
    }
    if (sizeofImg >= 1500000) {
      return message.error("Image size should be less than 1.5MB");
    }

    if (file?.status == "removed") {
      file = "";
    }
    dispatch(setPersonalInformation({ ...personalInformation, logoURL: file }));
  };
  const [_uploadImage, { isLoading: imageLoading }] = useUploadImageMutation();
  const [_onBoard, { isLoading }] = useOnBoardMutation();
  const handleFormSubmit = async () => {
    const photo = onBoardingData.personalInformation.logoURL;
    let sentData = { ...onBoardingData };

    if (isImage(photo)) {
      let formData = new FormData();
      formData.append("file", photo);
      let imageSrc = await _uploadImage(formData);
      if (isValidHttpUrl(imageSrc?.data?.url)) {
        sentData = {
          ...sentData,
          personalInformation: {
            ...sentData.personalInformation,
            logoURL: imageSrc?.data?.url,
          },
        };
      } else {
        message.error("Something went wrong while uploading logo");
      }
    }

    const response = await _onBoard(sentData);

    if (response?.data) {
      message.success("Onboarding successful");
    } else {
      message.warn("Something went wrong");
    }
    history.push("/");
  };

  const hearAbout = [
    {
      name: "Publishing Partner",
      value: "publishing partner",
    },
    {
      name: "Impact Partner",
      value: "impact partner",
    },
    {
      name: "Blog",
      value: "blog",
    },
    {
      name: "LinkedIn",
      value: "linkedIn",
    },
    {
      name: "Facebook",
      value: "facebook",
    },
    {
      name: "Twitter",
      value: "twitter",
    },
    {
      name: "Search engine",
      value: "search engine",
    },
    {
      name: "Colleague / Friend",
      value: "colleague / friend",
    },
  ];

  return (
    <>
      <Col
        {...{ xs: 24, sm: 24, md: 24, lg: 24, xl: 18 }}
        className="onBoardingMainDiv"
      >
        <Row>
          <Col span={24}>
            <Row justify="end" align="middle" style={{ width: "100%" }}>
              <h3>
                Having an issue?{" "}
                <a
                  href="#"
                  onClick={() => {
                    setModalType("Support");
                    setVisible(true);
                  }}
                >
                  Get Help
                </a>
              </h3>
            </Row>
          </Col>

          <Col
            {...{ xs: 24, sm: 24, md: 21, lg: 21, xl: 21 }}
            style={{ marginTop: "10px" }}
          >
            <h1 style={{ color: "var(--Text-Color)", fontSize: "1.8rem" }}>
              Account information
            </h1>
          </Col>

          <Col
            {...{ xs: 24, sm: 24, md: 21, lg: 21, xl: 21 }}
            style={{ marginTop: "10px" }}
          >
            <p>Full Name</p>
            <Input
              style={{
                marginTop: "5px",
              }}
              value={personalInformation.fullname}
              onChange={(e) => {
                dispatch(
                  setPersonalInformation({
                    ...personalInformation,
                    fullname: e.target.value,
                  })
                );
              }}
              type="text"
              className="inputBx"
              placeholder="Full name"
            />

            <p
              style={{
                marginBottom: "5px",
                marginTop: "var(--sub-margin)",
              }}
            >
              Display image (maximum mage size should be 1.5MB and file format
              should be jpeg, png, jpg)
            </p>
            <Upload
              listType="picture-card"
              name="file"
              maxCount={1}
              onPreview={() => false}
              onChange={(e) => {
                uploadImage(e?.file);
              }}
              beforeUpload={() => false}
            >
              {personalInformation.logoURL ? null : <PlusOutlined />}
            </Upload>
          </Col>

          <Col
            {...{ xs: 24, sm: 24, md: 21, lg: 21, xl: 21 }}
            style={{ marginTop: "var(--main-margin)" }}
          >
            <h1 style={{ color: "var(--Text-Color)", fontSize: "1.8rem" }}>
              How did you hear about Bridged?
            </h1>
            <p>We would love to know how did you hear about us.</p>
          </Col>
          <Col span={24} style={{ marginTop: "var(--main-margin)" }}>
            <Select
              className="antdSelect"
              size="large"
              bordered={false}
              value={userData.heardAboutBridged[0]}
              onChange={(e) => {
                dispatch(
                  setGoals({
                    ...userData,
                    heardAboutBridged: [e],
                  })
                );
              }}
            >
              {hearAbout.map((data, index) => {
                return (
                  <Option value={data.value} key={index}>
                    {data.name}
                  </Option>
                );
              })}
            </Select>
          </Col>

          <Row gutter={[0, 20]} style={{ width: "100%", marginTop: "100px" }}>
            <Col {...{ xs: 24, sm: 12, md: 12, lg: 12 }}>
              <Button
                className="buttonWhite opacity05"
                size="large"
                type="text"
                style={{ padding: "0px" }}
                onClick={() => {
                  if (cbo) {
                    setToggleSections("Business Goals");
                  } else {
                    setToggleSections("Goals");
                  }
                }}
              >
                BACK
              </Button>
            </Col>
            <Col {...{ xs: 24, sm: 12, md: 12, lg: 12 }}>
              <Row justify="end" align="middle" style={{ width: "100%" }}>
                <Row
                  justify="end"
                  align="middle"
                  style={{ width: "100%", maxWidth: "181px" }}
                >
                  <Button
                    className="button"
                    type="primary"
                    loading={isLoading || imageLoading}
                    onClick={handleFormSubmit}
                    size="large"
                  >
                    FINISH
                    <ArrowRightOutlined />
                  </Button>
                </Row>
              </Row>
            </Col>
          </Row>
        </Row>
      </Col>
      <SupportModal
        visible={visible}
        confirmLoading={confirmLoading}
        setConfirmLoading={setConfirmLoading}
        setVisible={setVisible}
        modalType={modalType}
      />
    </>
  );
}

export default PersonalInformation;
