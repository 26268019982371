import { CloseOutlined, SendOutlined } from "@ant-design/icons";
import { Col, Modal, Row, Input, message, Button } from "antd";
import React, { useState } from "react";
import {
  useFeedbackMutation,
  useSupportMutation,
} from "../../../Services/service";
function SupportModal({
  visible,
  confirmLoading,
  setConfirmLoading,
  setVisible,
  modalType,
}) {
  const [_feedback] = useFeedbackMutation();
  const [_support] = useSupportMutation();
  const [data, setData] = useState({
    name: "",
    subject: "",
    body: "",
  });

  const handleOk = async () => {
    setConfirmLoading(true);

    if (!data.name || !data.subject || !data.body) {
      message.error("Please fill all the fields");
      setConfirmLoading(false);
    } else {
      if (modalType === "Support") {
        const response = await _support(data);
        if (response?.data) {
          message.success("Our Support Team will get back to you soon");
        } else {
          message.error("Something went wrong");
        }
      } else if (modalType === "Feedback") {
        const response = await _feedback(data);
        if (response?.data) {
          message.success("Thank you for your feedback");
        } else {
          message.error("Something went wrong");
        }
      }
      setConfirmLoading(false);
      setVisible(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleInput = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  return (
    <Modal
      visible={visible}
      confirmLoading={confirmLoading}
      centered={true}
      footer={null}
      onCancel={handleCancel}
    >
      <Row
        style={{
          marginBottom: "var(--main-margin)",
        }}
      >
        <Col span={24}>
          <h3>Send an Email to us</h3>
        </Col>
      </Row>
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <Input
            type="text"
            value={data.name}
            onChange={handleInput}
            name="name"
            placeholder="Name"
            className="inputBx"
          />
        </Col>
        <Col span={24}>
          <Input
            type="text"
            value={data.subject}
            onChange={handleInput}
            name="subject"
            placeholder="Subject"
            className="inputBx"
          />
        </Col>
        <Col span={24}>
          <Input.TextArea
            rows={6}
            value={data.message}
            onChange={handleInput}
            name="body"
            placeholder="Query"
            className="inputBx"
            style={{ paddingTop: "10px" }}
          />
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "var(--main-margin)",
        }}
        justify="center"
        align="end"
      >
        <Button
          style={{
            marginRight: "var(--sub-margin)",
          }}
          size="large"
          className="button"
          type="primary"
          onClick={handleCancel}
        >
          Cancel <CloseOutlined />
        </Button>
        <Button
          size="large"
          className="button"
          type="primary"
          onClick={handleOk}
        >
          Send <SendOutlined />
        </Button>
      </Row>
    </Modal>
  );
}

export default SupportModal;
