import { Col, Row, message, Form, Input, Upload, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { setActMessageBox } from "../../../../redux/Slices/Flip Cards/Actions/ActMessageBoxSlice";
import { AdminActionObject } from "../../../../redux/Slices/Flip Cards/AdminFlipCardSlice";
import { shouldActSecChangeSetter } from "../../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
import isValidHttpUrl from "../../../ExtraComponents/isValidHttpUrl";
import { isImage } from "../../../ExtraComponents/isImage";
import ActionsTTI from "../../../ExtraComponents/Components/ActionsTTI";
function MessageBox() {
  const dispatch = useDispatch();
  const [isFetchBodyLoading, setIsFetchBodyLoading] = useState();
  const [AIgeneratedKeywords, setAIgeneratedKeywords] = useState("");
  const userData = useSelector((state) => state.ActMessageBox.action);

  useEffect(() => {
    dispatch(setActMessageBox(userData));
    dispatch(AdminActionObject(userData));
  }, []);

  // Checking if the required Fields are filled or not
  useEffect(() => {
    if (
      userData.title == "" ||
      userData.description == "" ||
      userData.imageURL == ""
    ) {
      dispatch(shouldActSecChangeSetter(false));
    } else {
      dispatch(shouldActSecChangeSetter(true));
    }
  }, [userData]);
  // Uploading Image
  const uploadImage = async (file) => {
    const fileType = file?.type.substring(file?.type.indexOf("/") + 1);
    const sizeofImg = file?.size;
    if (!(fileType === "jpeg" || fileType === "png" || fileType === "jpg")) {
      return message.error("Image type should be jpeg,png,jpg");
    }
    if (sizeofImg >= 1500000) {
      return message.error("Image size should be less than 1.5MB");
    }

    if (file?.status == "removed") {
      file = "";
    }
    let sentData = { ...userData, imageURL: file };
    dispatch(setActMessageBox(sentData));
    dispatch(AdminActionObject(sentData));
  };

  const fetchEmailBodyHandler = async () => {
    setIsFetchBodyLoading(true);
    const response = await fetch(
      "https://emailgenerator.bridged.media/generateMPBody",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subject: AIgeneratedKeywords,
        }),
      }
    );
    setIsFetchBodyLoading(false);
    const data = await response.json();

    if (data?.body) {
      message.success("Data Added to Editor");
      let sentData = { ...userData };
      sentData = {
        ...sentData,
        actionData: {
          ...sentData.actionData,
          body: data?.body,
        },
      };
      dispatch(setActMessageBox(sentData));
      dispatch(AdminActionObject(sentData));
    } else {
      message.error("No relevent content found");
    }
  };

  // onChange Handler for all the fields
  const onChangeHandler = (e) => {
    let sentData = { ...userData };
    const { name, value } = e.target;
    if (name == "body") {
      sentData = {
        ...sentData,
        actionData: {
          ...sentData.actionData,
          [name]: value,
        },
      };
    } else {
      sentData = { ...sentData, [name]: value };
    }
    dispatch(setActMessageBox(sentData));
    dispatch(AdminActionObject(sentData));
  };

  return (
    <Col span={24}>
      <Row style={{ width: "100%", marginTop: "var(--sub-margin)" }}>
        <Col span={24}>
          <Form.Item label="Title *">
            <Input
              type="text"
              placeholder="Comment Title"
              className="inputBx"
              value={userData.title}
              name="title"
              onChange={onChangeHandler}
            />
          </Form.Item>
          <Form.Item label="Description *">
            <Input
              type="text"
              placeholder="Description Title"
              className="inputBx"
              value={userData.description}
              name="description"
              onChange={onChangeHandler}
            />
          </Form.Item>
          <Form.Item label="Subject for Email Template">
            <Input
              type="text"
              placeholder="Eg: Effect of Climate Change in London"
              className="inputBx"
              onChange={(e) => {
                setAIgeneratedKeywords(e.target.value);
              }}
            />
            <Button
              onClick={fetchEmailBodyHandler}
              size="large"
              loading={isFetchBodyLoading}
              className="button"
              style={{
                marginTop: "var(--sub-margin)",
              }}
            >
              Fetch Body
            </Button>
          </Form.Item>
          <Form.Item label="Body">
            <Input.TextArea
              className="inputBx"
              placeholder="Body of the message box"
              style={{ padding: "10px" }}
              rows={9}
              value={userData.actionData.body}
              name="body"
              onChange={onChangeHandler}
            />
          </Form.Item>
          <Form.Item label="Image * (maximum mage size should be 1.5MB and file format should be jpeg, png, jpg)">
            <Upload
              listType="picture-card"
              name="file"
              maxCount={1}
              onPreview={() => false}
              onChange={(e) => {
                uploadImage(e?.file);
              }}
              beforeUpload={() => false}
            >
              {isImage(userData?.imageURL) ? null : <PlusOutlined />}
            </Upload>
            {isValidHttpUrl(userData?.imageURL) && (
              <img
                src={userData?.imageURL}
                style={{
                  width: "100%",
                  height: "100%",
                  maxWidth: "200px",
                  marginTop: "var(--sub-margin)",
                  marginBottom: "var(--sub-margin)",
                }}
              />
            )}
          </Form.Item>
          <ActionsTTI />
        </Col>
      </Row>
    </Col>
  );
}

export default MessageBox;
