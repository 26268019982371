export default {
  title: "Donation Title",
  description: "Donation Description",
  imageURL: "",
  actionData: {
    actionType: "donation",
    projectOwner: "globalGiving",
    projectId: "000000",
  },
};
