export default {
  title: "Dynamic form title",
  description: "Dynamic form description",
  imageURL: "",
  actionData: {
    actionType: "dynamicForm",
    fields: [
      {
        title: "Form title",
        hint: "Form hint",
        type: "text",
        maxLine: 1,
        maxLength: 32,
        optional: true,
      },
    ],
  },
};
