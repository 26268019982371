export default [
  {
    name: "NO POVERTY",
    nameVal: "No Poverty",
    digitValue: 1,
    imgSrc: "/images/sdgs/01.png",
  },
  {
    name: "ZERO HUNGER",
    nameVal: "Zero Hunger",
    digitValue: 2,
    imgSrc: "/images/sdgs/02.png",
  },
  {
    name: "GOOD HEALTH & WELL BEING",
    nameVal: "Good Health and Well-being",
    digitValue: 3,
    imgSrc: "/images/sdgs/03.png",
  },
  {
    name: "QUALITY EDUCATION",
    nameVal: "Quality Education",
    digitValue: 4,
    imgSrc: "/images/sdgs/04.png",
  },
  {
    name: "GENDER EQUALITY",
    nameVal: "gender equality",
    digitValue: 5,
    imgSrc: "/images/sdgs/05.png",
  },
  {
    name: "CLEAN WATER & SANITATION",
    nameVal: "clean water and sanitation",
    digitValue: 6,
    imgSrc: "/images/sdgs/06.png",
  },
  {
    name: "AFFORDABLE AND CLEAN ENERGY",
    nameVal: "affordable and clean energy",
    digitValue: 7,
    imgSrc: "/images/sdgs/07.png",
  },
  {
    name: "DECENT WORK & ECONOMIC GROWTH",
    nameVal: "decent work and economic growth",
    digitValue: 8,
    imgSrc: "/images/sdgs/08.png",
  },
  {
    name: "INDUSTRY, INNOVATION & INFRASTRUCTURE",
    nameVal: "industry, innovation and infrastructure",
    digitValue: 9,
    imgSrc: "/images/sdgs/09.png",
  },
  {
    name: "REDUCED INEQUALITIES",
    nameVal: "reduced inequalities",
    digitValue: 10,
    imgSrc: "/images/sdgs/10.png",
  },
  {
    name: "SUSTAINABLE CITIES & COMMUNITIES",
    nameVal: "sustainable cities and communities",
    digitValue: 11,
    imgSrc: "/images/sdgs/11.png",
  },
  {
    name: "RESPONSIBLE CONSUMPTION & PRODUCTION",
    nameVal: "responsible consumption and production",
    digitValue: 12,
    imgSrc: "/images/sdgs/12.png",
  },
  {
    name: "CLIMATE ACTION",
    nameVal: "climate action",
    digitValue: 13,
    imgSrc: "/images/sdgs/13.png",
  },
  {
    name: "LIFE BELOW WATER",
    nameVal: "life below water",
    digitValue: 14,
    imgSrc: "/images/sdgs/14.png",
  },
  {
    name: "LIFE ON LAND",
    nameVal: "life on land",
    digitValue: 15,
    imgSrc: "/images/sdgs/15.png",
  },
  {
    name: "PEACE, JUSTICE & STRONG INSTITUTIONS",
    nameVal: "peace, justice and strong institutions",
    digitValue: 16,
    imgSrc: "/images/sdgs/16.png",
  },
  {
    name: "PARTNERSHIP FOR THE GOALS",
    nameVal: "partnership for the goals",
    digitValue: 17,
    imgSrc: "/images/sdgs/17.png",
  },
];
