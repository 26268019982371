import { Col, Row } from "antd";
import React from "react";
import Iframe from "./Iframe";

function IframeMainSection({ iframeData }) {
  return (
    <Row className="flipcardOutCompGrey iframeSection">
      <Col span={24} className="flipcardInCompWhite">
        <Row>
          <Col span={24}>
            <h2>Preview</h2>
          </Col>
          <Col
            span={24}
            style={{
              marginTop: "var(--sub-margin)",
            }}
          >
            <Row justify="center" align="middle">
              <Iframe iframeData={iframeData} />
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default IframeMainSection;
