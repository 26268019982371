import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuth: true,
    isOnboard: false,
    role: "",
  },
  reducers: {
    auth: (state, action) => {
      state.isAuth = action.payload;
    },
    onBoard: (state, action) => {
      state.isOnboard = action.payload;
    },
    roleSetter: (state, action) => {
      state.role = action.payload;
    },
  },
});

export const { auth, onBoard, roleSetter } = authSlice.actions;
export default authSlice.reducer;
