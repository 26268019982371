import { Button, Card, Col, Row, Pagination, Skeleton, Tooltip } from "antd";
import moment from "moment";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFlipcardUserActionInventoriesQuery } from "../../../Services/service";
import { PlusOutlined } from "@ant-design/icons";
import actionNameConverter from "../../ExtraComponents/actionNameConverter";
import NewActionModal from "../../CardCreation/ResusableComponents/MainSections/3Add impact/NewActionModal";
import actionIconFinder from "../../ExtraComponents/actionIconFinder";
import DashboardSortingTitle from "../DashboardReusableFunctions/DashboardSortingTitle";
import DashboardSearchTitle from "../DashboardReusableFunctions/DashboardSearchTitle";

const PublisherActionFlipcards = ({ filterData, sortData, searchKeyword }) => {
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [paginationPage, setPaginationPage] = useState(0);
  const currRole = useSelector((state) => state.auth.role);
  const [paginationPageSize, setPaginationPageSize] = useState(7);
  const [actions, setActions] = useState([{}, {}, {}, {}, {}, {}, {}]);
  const { isLoading, isSuccess, data } =
    useFlipcardUserActionInventoriesQuery();
  useEffect(() => {
    if (isSuccess) {
      if (data) {
        setActions(data);
      }
    }
  }, [isSuccess, data]);
  let displayActions = actions.slice(
    paginationPage * paginationPageSize,
    (paginationPage + 1) * paginationPageSize
  );

  const paginationChange = (page, pageSize) => {
    setPaginationPage(page - 1);
    setPaginationPageSize(pageSize);
  };

  const filterDatahandler = (e) => {
    let filters = [];

    const engObj = {
      type: "engagementType",
      value: [...e.filterDataEngagement],
    };
    const actObj = {
      type: "actionTypes",
      value: [...e.filterDataAction],
    };
    const statObj = {
      type: "isActive",
      value: [...e.filterDataStatus],
    };

    // --------------------------------------
    if (e.filterDataEngagement.length !== 0) {
      filters.push(engObj);
    }
    if (e.filterDataAction.length !== 0) {
      filters.push(actObj);
    }
    if (e.filterDataStatus.length !== 0) {
      filters.push(statObj);
    }

    let filteredArr = [...data];

    for (let filter of filters) {
      filteredArr = filteredArr.filter((flipcart) => {
        if (typeof flipcart[filter.type] == "object") {
          filter.value.forEach((e) => {
            if (flipcart[filter.type].includes(e)) {
              return flipcart;
            }
          });
        }
        if (filter.value.includes(flipcart[filter.type])) {
          return flipcart;
        }
      });
    }

    setActions(filteredArr);
  };
  
  useEffect(() => {
    if (isSuccess) {
      const sortedData = DashboardSortingTitle(sortData?.sortData[0], data);
      setActions(sortedData);
    }
  }, [isSuccess, sortData]);

  useEffect(() => {
    if (isSuccess) {
      filterDatahandler(filterData);
    }
  }, [isSuccess, filterData]);

  useEffect(() => {
    if (isSuccess) {
      const searchResults = DashboardSearchTitle(searchKeyword, data);
      setActions(searchResults);
    }
  }, [isSuccess, searchKeyword]);

  const skeleton = () => {
    return (
      <div style={{ padding: "25px" }} className="dashboardMyActionCard">
        <Skeleton.Button active={true} shape="round" />
        <Skeleton
          active={true}
          paragraph={{ rows: 2 }}
          style={{
            marginTop: "var(--main-margin)",
          }}
        />
        <Skeleton
          active={true}
          paragraph={{ rows: 0 }}
          style={{
            marginTop: "var(--sub-margin)",
          }}
        />
      </div>
    );
  };

  return (
    <>
      <Row
        style={{
          marginTop: "var(--main-margin)",
          marginBottom: "var(--main-margin)",
        }}
      >
        <Col span={24}>
          <h2>Requested Actions</h2>
        </Col>
        <Col
          span={24}
          style={{
            marginTop: "var(--sub-margin)",
          }}
        >
          <Row gutter={[30, 30]} aign="middle" justify="start">
            <Col {...{ xs: 24, sm: 12, md: 12, lg: 12, xl: 6 }}>
              <Button
                type="dashed"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "var(--input-Border-Radius3)",
                }}
                className="dashboardMyActionCard opacity05"
                onClick={() => {
                  setIsModalVisible(true);
                }}
              >
                <PlusOutlined
                  style={{
                    fontSize: "2rem",
                  }}
                />
                <h1
                  style={{
                    fontSize: "1.3rem",
                    marginTop: "var(--sub-margin)",
                  }}
                >
                  Request new action
                </h1>
              </Button>
            </Col>
            {displayActions.map((data, index) => {
              const {
                title,
                createdDate,
                isApproved,
                description,
                actionData,
                _id,
              } = data;
              const actionType = actionData?.actionType;
              const titleLength = title?.substring(0, 40);

              return (
                <Col key={index} {...{ xs: 24, sm: 12, md: 12, lg: 12, xl: 6 }}>
                  {isLoading ? (
                    skeleton()
                  ) : (
                    <Card bordered={false} className="dashboardMyActionCard">
                      <Row style={{ width: "100%", height: "100%" }} onClick={() => {
                          history.push(`/templateedit/${_id}/action`);
                        }}>
                        <Col span={24}>
                          <Row align="middle" justify="space-between">
                            <Button shape="round">
                              {actionIconFinder(actionType)}
                              {actionNameConverter(actionType)}
                            </Button>
                          </Row>
                        </Col>
                        <Row
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <Col
                            span={24}
                            style={{
                              marginTop: "15px",
                            }}
                          >
                            <a
                              style={{
                                color: isApproved ? "green" : "red",
                              }}
                            >
                              {isApproved ? "Approved" : "Pending"}
                            </a>
                          </Col>
                          <Row
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              marginTop: "var(--main-margin)",
                            }}
                          >
                            <Col span={24}>
                              <Row justify="start" align="middle">
                                <Col
                                  span={24}
                                  className="dashboardMyActionCardTitle"
                                >
                                  <Tooltip title={title}>
                                    <h1
                                      style={{
                                        color: "#192b9d",
                                        fontSize: "1.7rem",
                                        fontWeight: "200",
                                        height: "85px",
                                      }}
                                    >
                                      {title?.substring(0, 32)}
                                      {title ? null : "Refresh The Page"}
                                      {titleLength?.length > 32 ? "..." : ""}
                                    </h1>
                                  </Tooltip>
                                </Col>
                                <Col
                                  span={24}
                                  style={{
                                    marginTop: "15px",
                                  }}
                                >
                                  <Row
                                    align="middle"
                                    justify="start"
                                    className="dashboardMyActionCardTitle"
                                  >
                                    <p>{description}</p>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Col
                            span={24}
                            style={{
                              marginTop: "var(--sub-margin)",
                            }}
                          >
                            <Row align="middle" justify="space-between">
                              <p>{moment(createdDate).format("MMM Do YYYY")}</p>
                            </Row>
                          </Col>
                        </Row>
                      </Row>
                    </Card>
                  )}
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      {actions.length > 7 ? (
        <Col
          span={24}
          style={{
            marginTop: "var(--main-margin)",
            marginBottom: "var(--main-margin)",
          }}
        >
          <Row align="middle" justify="end">
            <Pagination
              className="dashboardPagnaition"
              size="small"
              defaultPageSize={7}
              onChange={paginationChange}
              total={actions.length}
            />
          </Row>
        </Col>
      ) : (
        ""
      )}
      <NewActionModal
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
      />
    </>
  );
};
export default PublisherActionFlipcards;
