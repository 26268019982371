import { Modal, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Content from "./Content";
import Recipient from "./Recipient";
const { TabPane } = Tabs;
function SendEmailModal({ sendEmailVisible, setSendEmailVisible }) {
  const [userData, setUserData] = useState({
    audienceIds: [],
    subject: "",
    emailBody: "",
  });

  const selectedTableData = useSelector(
    (state) => state.NewFlipcardOverview.selectedTableData
  );

  const originalTableData = useSelector(
    (state) => state.NewFlipcardOverview.originalTableData
  );

  useEffect(() => {
    let userIds =
      selectedTableData.length > 0 ? selectedTableData : originalTableData;
    userIds = userIds?.map((item) => item?.actionTakerId);
    setUserData({ ...userData, audienceIds: userIds });
  }, [selectedTableData, originalTableData]);
  return (
    <Modal
      visible={sendEmailVisible}
      onCancel={() => setSendEmailVisible(!sendEmailVisible)}
      width={1000}
      footer={null}
      className="flipcardOverviewModal"
    >
      <Tabs defaultActiveKey="1" style={{ marginTop: "10px" }}>
        <TabPane tab="Recipient" key="1">
          <h1>Send follow-up email</h1>
          <Recipient />
        </TabPane>

        <TabPane tab="Content" key="2">
          <h1>Send follow-up email</h1>
          <Content
            userData={userData}
            setUserData={setUserData}
            setSendEmailVisible={setSendEmailVisible}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
}

export default SendEmailModal;
