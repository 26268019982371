import { Col, Form, Input, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdminEngagementObject } from "../../../../redux/Slices/Flip Cards/AdminFlipCardSlice";
import { setEngSliderPoll } from "../../../../redux/Slices/Flip Cards/Engagements/EngSliderPollSlice";
import { shouldEngSecChangeSetter } from "../../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
import { FlipCardengagement } from "../../../../redux/Slices/Flip Cards/FlipCardSlice";
function SliderPoll() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.EngSliderPoll.engagement);

  // Checking if the required Fields are filled or not
  useEffect(() => {
    if (
      userData.title === "" ||
      userData?.engagementData?.options[0]?.title === "" ||
      userData?.engagementData?.options[8]?.title === ""
    ) {
      dispatch(shouldEngSecChangeSetter(false));
    } else {
      dispatch(shouldEngSecChangeSetter(true));
    }
  }, [userData]);

  useEffect(() => {
    dispatch(setEngSliderPoll(userData));
    dispatch(FlipCardengagement(userData));
    dispatch(AdminEngagementObject(userData));
  }, []);

  // onchange Handler listener for all the fields
  const onChangeHandler = (e) => {
    let sentData = { ...userData };
    const { name, value } = e.target;
    if (name.includes("options")) {
      if (name.includes(0)) {
        sentData = {
          ...sentData,
          engagementData: {
            ...sentData.engagementData,
            options: [
              { ...sentData.engagementData.options[0], title: value },
              ...sentData.engagementData.options.slice(1),
            ],
          },
        };
      } else if (name.includes(8)) {
        sentData = {
          ...sentData,
          engagementData: {
            ...sentData.engagementData,
            options: [
              ...sentData.engagementData.options.slice(0, 8),
              { ...sentData.engagementData.options[8], title: value },
            ],
          },
        };
      }
    } else {
      sentData[name] = value;
    }
    dispatch(setEngSliderPoll(sentData));
    dispatch(FlipCardengagement(sentData));
    dispatch(AdminEngagementObject(sentData));
  };

  return (
    <Col span={24}>
      <Row style={{ width: "100%" }}>
        <Col span={24}>
          <Form.Item label="Title *">
            <Input
              type="text"
              placeholder="Poll Title"
              maxLength={120}
              showCount
              className="inputBx"
              name="title"
              value={userData.title}
              onChange={onChangeHandler}
            />
          </Form.Item>
        </Col>
        <Row style={{ width: "100%" }} justify="space-between">
          <Col span={11}>
            <Form.Item label="Option 1 *">
              <Input
                type="text"
                placeholder="Left-side of the poll"
                maxLength={40}
                showCount
                className="inputBx"
                name="options.0"
                value={userData?.engagementData?.options[0]?.title}
                onChange={onChangeHandler}
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item label="Option 2 *">
              <Input
                type="text"
                placeholder="Right-side of the poll"
                maxLength={40}
                showCount
                className="inputBx"
                name="options.8"
                value={userData?.engagementData?.options[8]?.title}
                onChange={onChangeHandler}
              />
            </Form.Item>
          </Col>
        </Row>
      </Row>
    </Col>
  );
}

export default SliderPoll;
