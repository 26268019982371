import {
  Button,
  Card,
  Col,
  Row,
  Pagination,
  Skeleton,
  Dropdown,
  Menu,
  Popconfirm,
  message,
} from "antd";
import moment from "moment";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  useFlipcardDraftDeleteMutation,
  useFlipcardDraftsQuery,
} from "../../../Services/service";
import { EllipsisOutlined } from "@ant-design/icons";
import engagementNameConverter from "../../ExtraComponents/engagementNameConverter";
import engagementIconFinder from "../../ExtraComponents/engagementIconFinder";
import engagementColorFinder from "../../ExtraComponents/engagementColorFinder";

const PublisherDrafts = () => {
  const history = useHistory();
  const [paginationPage, setPaginationPage] = useState(0);
  const [paginationPageSize, setPaginationPageSize] = useState(8);
  const [actions, setActions] = useState([{}, {}, {}, {}, {}, {}, {}]);
  const { isLoading, isSuccess, data } = useFlipcardDraftsQuery();
  useEffect(() => {
    if (isSuccess) {
      if (data) {
        setActions(data);
      }
    }
  }, [isSuccess, data]);
  let displayActions = actions.slice(
    paginationPage * paginationPageSize,
    (paginationPage + 1) * paginationPageSize
  );

  const paginationChange = (page, pageSize) => {
    setPaginationPage(page - 1);
    setPaginationPageSize(pageSize);
  };

  const [_deleteDraft] = useFlipcardDraftDeleteMutation();
  const deleteConfirm = async (_id) => {
    try {
      const response = await _deleteDraft(_id);
      if (response?.error) {
        message.error("Something went wrong");
      }
      if (response?.data) {
        message.success("Draft deleted successfully");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const skeleton = () => {
    return (
      <div style={{ padding: "25px" }} className="dashboardMyActionCard">
        <Skeleton.Button active={true} shape="round" />
        <Skeleton
          active={true}
          paragraph={{ rows: 2 }}
          style={{
            marginTop: "var(--main-margin)",
          }}
        />
        <Skeleton
          active={true}
          paragraph={{ rows: 0 }}
          style={{
            marginTop: "var(--sub-margin)",
          }}
        />
      </div>
    );
  };

  return (
    <>
      <Row
        style={{
          marginTop: "var(--main-margin)",
          marginBottom: "var(--main-margin)",
        }}
      >
        <Col span={24}>
          <h2>Drafts</h2>
        </Col>
        <Col
          span={24}
          style={{
            marginTop: "var(--sub-margin)",
          }}
        >
          <Row gutter={[30, 30]} aign="middle" justify="start">
            {displayActions.map((data, index) => {
              let draftData = {};
              if (data?.draftString) {
                draftData = JSON.parse(data.draftString);
              }
              const title = draftData?.title;
              const titleLength = title?.substring(0, 40);

              const engagementType =
                draftData?.engagement?.engagementData?.engagementType;
              const dropdown = (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="1"
                        onClick={() => {
                          history.push(`/draft/${data?._id}`);
                        }}
                      >
                        Use Draft
                      </Menu.Item>
                      <Popconfirm
                        title="Are you sure to delete this Draft?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => {
                          deleteConfirm(data?._id);
                        }}
                      >
                        <Menu.Item key="3">Delete</Menu.Item>
                      </Popconfirm>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <EllipsisOutlined style={{ fontSize: "1.4rem" }} />
                </Dropdown>
              );

              return (
                <Col key={index} {...{ xs: 24, sm: 12, md: 12, lg: 12, xl: 6 }}>
                  {isLoading ? (
                    skeleton()
                  ) : (
                    <Card bordered={false} className="dashboardMyActionCard">
                      <Row style={{ width: "100%", height: "100%" }}>
                        <Col span={24}>
                          <Row align="middle" justify="space-between">
                            <Button
                              shape="round"
                              style={{
                                backgroundColor:
                                  engagementColorFinder(engagementType),
                                color: !engagementType ? "#000" : "#fff",
                              }}
                            >
                              {engagementIconFinder(engagementType)}
                              {engagementNameConverter(engagementType)}
                              {!engagementType ? "No Engagement" : null}
                            </Button>
                            {dropdown}
                          </Row>
                        </Col>
                        <Row
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            history.push(`/draft/${data?._id}`);
                          }}
                        >
                          <Col
                            span={24}
                            style={{
                              marginTop: "15px",
                            }}
                          >
                            <a
                              style={{
                                color: "#ffff",
                              }}
                            >
                              Demo Text
                            </a>
                          </Col>
                          <Row
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              marginTop: "var(--main-margin)",
                            }}
                          >
                            <Col span={24}>
                              <Row justify="start" align="middle">
                                <Col
                                  span={24}
                                  className="dashboardMyActionCardTitle"
                                >
                                  <h1
                                    style={{
                                      color: "#192b9d",
                                      fontSize: "1.7rem",
                                      fontWeight: "200",
                                      height: "85px",
                                      overflow: "hidden",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {title?.substring(0, 32)}
                                    {title ? null : "Title not available"}
                                    {titleLength?.length > 32 ? "..." : ""}
                                  </h1>
                                </Col>
                                <Col
                                  span={24}
                                  style={{
                                    marginTop: "15px",
                                  }}
                                >
                                  <Row
                                    align="middle"
                                    justify="start"
                                    className="dashboardMyActionCardTitle"
                                  >
                                    <p
                                      style={{
                                        color: "#fff",
                                      }}
                                    >
                                      {"description"}
                                    </p>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Col
                            span={24}
                            style={{
                              marginTop: "var(--sub-margin)",
                            }}
                          >
                            <Row align="middle" justify="space-between">
                              <p>
                                {moment(data?.createdDate).format(
                                  "MMM Do YYYY"
                                )}
                              </p>
                            </Row>
                          </Col>
                        </Row>
                      </Row>
                    </Card>
                  )}
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      {actions.length > 8 ? (
        <Col
          span={24}
          style={{
            marginTop: "var(--main-margin)",
            marginBottom: "var(--main-margin)",
          }}
        >
          <Row align="middle" justify="end">
            <Pagination
              className="dashboardPagnaition"
              size="small"
              defaultPageSize={8}
              onChange={paginationChange}
              total={actions.length}
            />
          </Row>
        </Col>
      ) : (
        ""
      )}
    </>
  );
};
export default PublisherDrafts;
