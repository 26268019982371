import React, { useState } from "react";
import {
  Avatar,
  Col,
  Form,
  Input,
  Row,
  Button,
  Divider,
  Upload,
  message,
  Skeleton,
} from "antd";

import "./userAccount.css";
import {
  DeleteOutlined,
  SaveOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useEffect } from "react";
import {
  useChangePasswordMutation,
  useUpdateUserInfoMutation,
  useUpdateUserPhotoMutation,
  useUploadImageMutation,
  useUserInfoQuery,
} from "../../../Services/service";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../redux/Slices/User/userSlice";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
function UserProfile() {
  const userData = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();
  const history = useHistory();
  const [password, setPassword] = useState({
    currentPassword: "",
    newPassword: "",
  });

  const { data, isSuccess, isLoading } = useUserInfoQuery();
  const [_uploadImage, { isLoading: imageUploadingLoading }] =
    useUploadImageMutation();
  const [_updateProfile, { isLoading: updateUserLoading }] =
    useUpdateUserInfoMutation();
  const [_updateProfilePicture, { isLoading: updateProfilePictureLoading }] =
    useUpdateUserPhotoMutation();

  const [_changePassword, { isLoading: changePassLoading }] =
    useChangePasswordMutation();

  useEffect(() => {
    if (data) {
      dispatch(setUserData(data));
    }
  }, [isSuccess]);

  const onChange = (e) => {
    const { name, value } = e.target;
    dispatch(setUserData({ ...userData, [name]: value }));
  };

  const onPhotoChange = async (file) => {
    const fileType = file?.type.substring(file?.type.indexOf("/") + 1);
    const sizeofImg = file?.size;
    if (!(fileType === "jpeg" || fileType === "png" || fileType === "jpg")) {
      return message.error("Image type should be jpeg, png, jpg");
    }
    if (sizeofImg >= 1500000) {
      return message.error("Image size should be less than 1.5MB");
    }

    if (file?.status == "removed") {
      file = "";
    }

    let formData = new FormData();
    formData.append("file", file);
    let imageSrc = await _uploadImage(formData);
    if (imageSrc.data.url) {
      await _updateProfilePicture({
        imageURL: imageSrc.data.url,
      });
      dispatch(setUserData({ ...userData, photo: imageSrc.data.url }));

      message.success("Profile Picture changed");
    }
  };

  const onSubmitHandler = async () => {
    const sentData = { ...userData, photo: undefined };
    await _updateProfile(sentData);
    message.success("Account information changed");
  };

  const changePasswordHandler = async () => {
    if (password.newPassword.length < 8) {
      return message.error("Password must be atleast 8 characters");
    }
    const response = await _changePassword(password);
    if (response?.data) {
      message.success("Password successfully changed");
    } else {
      message.error("Current password is incorrect");
    }
    setPassword({
      currentPassword: "",
      newPassword: "",
    });
  };

  const skeleton = () => {
    return (
      <>
        <Row>
          <Col {...{ xs: 24, sm: 24, md: 20, lg: 12 }}>
            <Skeleton.Button
              active={true}
              block
              style={{
                height: "500px",
              }}
            />
          </Col>
        </Row>
      </>
    );
  };

  return (
    <section className="userAccountSection">
      <div className="userAccountMainDiv">
        {isLoading || updateUserLoading ? (
          skeleton()
        ) : (
          <Form layout="vertical">
            <Row gutter={[10, 40]}>
              <Col span={24}>
                <h1>Edit Profile</h1>
                <p>
                  This information will be shared publicly so be careful what
                  you share
                </p>
              </Col>
              <Col {...{ xs: 24, sm: 12, md: 10, lg: 6 }}>
                <Form.Item label="First name">
                  <Input
                    type="text"
                    className="inputBx"
                    name="firstname"
                    onChange={onChange}
                    value={userData.firstname}
                  />
                </Form.Item>
              </Col>
              <Col {...{ xs: 24, sm: 12, md: 10, lg: 6 }}>
                <Form.Item label="Last name">
                  <Input
                    type="text"
                    className="inputBx"
                    name="lastname"
                    onChange={onChange}
                    value={userData.lastname}
                  />
                </Form.Item>
              </Col>
              <Col {...{ xs: 24, sm: 24, md: 24, lg: 24 }}>
                <p
                  style={{
                    marginBottom: "5px",
                  }}
                >
                  Photo
                </p>
                <Row justify="start" align="middle" className="userAccountGap">
                  <img
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                    style={{
                      cursor: "pointer",
                      width: 50,
                      height: 50,
                      objectFit: "cover",
                      borderRadius: 50,
                    }}
                    src={
                      userData?.photo
                        ? userData?.photo
                        : "https://avataaars.io/?avatarStyle=Circle&topType=ShortHairShortWaved&accessoriesType=Blank&hairColor=Brown&facialHairType=Blank&clotheType=BlazerSweater&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light"
                    }
                  />
                  <Upload
                    name="file"
                    type="select"
                    maxCount={1}
                    onPreview={() => false}
                    onChange={(e) => {
                      onPhotoChange(e?.file);
                    }}
                    beforeUpload={() => false}
                  >
                    <Button
                      type="text"
                      size="large"
                      className="button"
                      loading={imageUploadingLoading}
                      style={{
                        backgroundColor: "var(--secondary-Text-Color)",
                        color: "white",
                      }}
                    >
                      <UploadOutlined />
                      Change
                    </Button>
                  </Upload>
                  {/* <Button type="text" size="large" className="button">
                    <DeleteOutlined />
                    Remove
                  </Button> */}
                </Row>
              </Col>
              <Col {...{ xs: 24, sm: 24, md: 20, lg: 12 }}>
                <Form.Item label="About">
                  <Input
                    type="text"
                    className="inputBx"
                    onChange={onChange}
                    value={userData.about}
                    name="about"
                    style={{
                      height: "100px",
                      marginBottom: "10px",
                    }}
                  />
                  <p>
                    Brief description about your profile or your company, URLs
                    are hyperlinked
                  </p>
                </Form.Item>
                <Form.Item label="Website">
                  <Button
                    className="button inputBx"
                    size="large"
                    type="text"
                    style={{
                      width: "15%",
                      borderRadius: "7px 0px 0px 7px",
                      backgroundColor: "var(--newAction-padding-color)",
                      borderRight: "none",
                    }}
                  >
                    HTTPS://
                  </Button>
                  <Input
                    type="text"
                    className="inputBx"
                    onChange={onChange}
                    value={userData.website}
                    name="website"
                    style={{
                      width: "85%",
                      borderRadius: "0px 7px 7px 0px",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Divider />
                <h1>Personal Information</h1>
                <p>
                  This information will be shared publicly so be careful what
                  you share ( On boarding data should be included here )
                </p>
              </Col>
              <Col {...{ xs: 24, sm: 24, md: 20, lg: 12 }}>
                <Row gutter={[10, 0]}>
                  {/* <Col {...{ xs: 24, sm: 24, md: 12, lg: 12 }}>
                    <Form.Item label="Email address">
                      <Input
                        type="email"
                        className="inputBx"
                        name="email"
                        onChange={onChange}
                        value={userData?.email}
                      />
                    </Form.Item>
                  </Col> */}
                  <Col {...{ xs: 24, sm: 24, md: 12, lg: 12 }}>
                    <Form.Item label="Phone number">
                      <Input
                        type="number"
                        className="inputBx"
                        name="phoneNumber"
                        onChange={onChange}
                        value={userData.phoneNumber}
                      />
                    </Form.Item>
                  </Col>
                  <Col {...{ xs: 24, sm: 24, md: 12, lg: 12 }}>
                    <Form.Item label="Country">
                      <Input
                        type="text"
                        className="inputBx"
                        name="country"
                        onChange={onChange}
                        value={userData.country}
                      />
                    </Form.Item>
                  </Col>
                  <Col {...{ xs: 24, sm: 24, md: 12, lg: 12 }}>
                    <Form.Item label="Language">
                      <Input
                        type="text"
                        className="inputBx"
                        name="language"
                        onChange={onChange}
                        value={userData.language}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <p
                      style={{
                        opacity: "0.5",
                      }}
                    >
                      This account was created on January 5 2017, 8:35:40 PM
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Divider />
                <h1>Change Password</h1>
              </Col>
              <Col {...{ xs: 24, sm: 24, md: 20, lg: 12 }}>
                <Row gutter={[10, 0]}>
                  <Col {...{ xs: 24, sm: 24, md: 12, lg: 12 }}>
                    <Form.Item label="Current password">
                      <Input
                        type="password"
                        className="inputBx"
                        onChange={(e) => {
                          setPassword({
                            ...password,
                            currentPassword: e.target.value,
                          });
                        }}
                        value={password.currentPassword}
                      />
                    </Form.Item>
                  </Col>
                  <Col {...{ xs: 24, sm: 24, md: 12, lg: 12 }}>
                    <Form.Item label="New Password">
                      <Input
                        type="password"
                        className="inputBx"
                        onChange={(e) => {
                          setPassword({
                            ...password,
                            newPassword: e.target.value,
                          });
                        }}
                        value={password.newPassword}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Button
                      type="text"
                      size="large"
                      loading={changePassLoading}
                      className="button"
                      onClick={changePasswordHandler}
                      style={{
                        backgroundColor: "var(--secondary-Text-Color)",
                        color: "white",
                      }}
                    >
                      <SaveOutlined />
                      Change password
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col
                span={24}
                style={{
                  marginBottom: "var(--main-margin)",
                }}
              >
                <Divider />
                <Row align="middle" justify="end">
                  <Button
                    type="text"
                    size="large"
                    className="button"
                    onClick={() => {
                      message.warning("Changes discarded");
                      history.push("/");
                    }}
                  >
                    <DeleteOutlined />
                    Discard changes
                  </Button>
                  <Button
                    type="text"
                    size="large"
                    className="button"
                    onClick={onSubmitHandler}
                    style={{
                      marginLeft: "var(--sub-margin)",
                      backgroundColor: "var(--secondary-Text-Color)",
                      color: "white",
                    }}
                  >
                    <SaveOutlined />
                    Save
                  </Button>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    </section>
  );
}

export default UserProfile;
