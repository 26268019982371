import { Button, Col, Row, DatePicker, Popover, Select, Skeleton } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { DownOutlined } from "@ant-design/icons";
import moment from "moment";
import { jsPDF } from "jspdf";
import { svgAsPngUri } from "save-svg-as-png";
// import { saveSvgAsPng } from "../../../node_modules/save-svg-as-png/lib/saveSvgAsPng";
import { useEffect, useState } from "react";
import { useDashboardQuery } from "../../Services/service";
import { useSelector } from "react-redux";

const { Option } = Select;
const { RangePicker } = DatePicker;
const AnalyticsTopBar = ({
  setFilterData,
  filterData,
  filterDate,
  setfilterDate,
  setFilterSelectedIds,
  filterSelectedIds,
}) => {
  const {
    data,
    isLoading: isDashboardLoading,
    isSuccess,
  } = useDashboardQuery();
  const flipCardData = useSelector((state) => state.Analytics.data);
  let actionRate =
    (flipCardData?.totalActionTake / flipCardData?.totalViews) * 100;
  if (actionRate >= 100) {
    actionRate = 100;
  }
  let engRate =
    (flipCardData?.totalEngagementTake / flipCardData?.totalViews) * 100;
  if (engRate >= 100) {
    engRate = 100;
  }
  let conversionRate =
    actionRate.toFixed(1) == "NaN" ? "0" : actionRate.toFixed(1);
  let totlaViews = flipCardData?.totalViews ? flipCardData?.totalViews : 0;
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    dateSetter();
  }, [filterData]);

  const dateFilterHandler = (e) => {
    setFilterData(e.target.value);
  };

  const dateSetter = (e) => {
    if (filterData === 7) {
      setfilterDate({
        startDate: moment().utc().endOf("day"),
        endDate: moment().utc().add(-6, "days").startOf("day"),
      });
    } else if (filterData === 28) {
      setfilterDate({
        startDate: moment().utc().endOf("day"),
        endDate: moment().utc().add(-27, "days").startOf("day"),
      });
    } else if (filterData === 90) {
      setfilterDate({
        startDate: moment().utc().endOf("day"),
        endDate: moment().utc().add(-89, "days").startOf("day"),
      });
    } else if (filterData === "custom" && e) {
      setfilterDate({
        startDate: e[1],
        endDate: e[0],
      });
    }
  };

  const handleChartDownload = async () => {
    const chart = document.getElementsByClassName("recharts-surface");
    const highChart = document.getElementsByClassName("highcharts-root")[0];
    const pdf = new jsPDF("l", "pt", "a4");
    setIsLoading(true);
    const chart1 = chart[0];
    const chart2 = chart[4];
    const chart3 = chart[5];
    const chart4 = highChart;

    const dataURI1 = await svgAsPngUri(chart1);
    const dataURI2 = await svgAsPngUri(chart2);
    const dataURI3 = await svgAsPngUri(chart3);
    const dataURI4 = await svgAsPngUri(chart4);

    pdf.text("Overall Performance", 180, 70);
    pdf.text("Views", 80, 130);
    pdf.text(totlaViews.toString(), 98, 155);
    pdf.text("Engagement", 200, 130);
    pdf.text(engRate.toString() + "%", 220, 155);
    pdf.text("Conversion", 340, 130);
    pdf.text(conversionRate.toString() + "%", 360, 155);
    pdf.addImage(dataURI1, "PNG", 50, 200, 372, 206);

    pdf.text("How do you aquire users?", 550, 70);
    pdf.addImage(dataURI2, "PNG", 450, 120, 450, 304);

    pdf.addPage();
    pdf.text("How is your content converting over time?", 100, 70);
    pdf.addImage(dataURI3, "PNG", 40, 150, 447, 301);

    pdf.text("What are your top devices?", 550, 70);
    pdf.addImage(dataURI4, "PNG", 400, 150, 397, 300);

    pdf.save("Flipcard Analytics.pdf");
    setIsLoading(false);
  };

  useEffect(() => {
    if (isSuccess) {
      if (data?.flipcards?.length >= 10) {
        const ids = [];

        for (let index = 0; index < 10; index++) {
          const item = data?.flipcards[index];
          const name = item?.name?.substring(0, 30);
          const key = item?._id;
          ids.push(key + " " + name);
        }

        setFilterSelectedIds({
          flipcardIds: ids,
          allFlipcards: false,
        });
      }
    }
  }, [isSuccess]);
  const datePopoverContent = (
    <Row
      gutter={[0, 10]}
      style={{
        width: "100%",
        maxWidth: "200px",
      }}
    >
      <Col span={24}>
        <Checkbox
          checked={filterData === 7}
          onChange={dateFilterHandler}
          value={7}
        >
          Past 7 days
        </Checkbox>
      </Col>
      <Col span={24}>
        <Checkbox
          checked={filterData === 28}
          onChange={dateFilterHandler}
          value={28}
        >
          past 28 days
        </Checkbox>
      </Col>
      <Col span={24}>
        <Checkbox
          checked={filterData === 90}
          onChange={dateFilterHandler}
          value={90}
        >
          Past 90 days
        </Checkbox>
      </Col>
      <Col span={24}>
        <Checkbox
          checked={filterData === "custom"}
          onChange={dateFilterHandler}
          value={"custom"}
        >
          Custom...
        </Checkbox>
      </Col>
      {filterData === "custom" ? (
        <Col span={24}>
          <RangePicker
            style={{ borderRadius: "var(--input-Border-Radius3)" }}
            onChange={(e) => {
              dateSetter(e);
            }}
          />
        </Col>
      ) : (
        ""
      )}
    </Row>
  );

  return (
    <Row justify="center" style={{ marginTop: "20px" }}>
      <Row gutter={[10, 10]} style={{ width: "90%", maxWidth: "1400px" }}>
        <Col {...{ xs: 24, sm: 24, md: 10, lg: 12, xl: 12 }}>
          <h1 style={{ color: "var(--third-Text-Color)" }}>Analytics Board</h1>
        </Col>
        <Col {...{ xs: 24, sm: 24, md: 14, lg: 12, xl: 12 }}>
          <Row gutter={[10, 10]} justify="end" align="middle">
            <Col>
              <Popover
                placement="bottomLeft"
                content={datePopoverContent}
                trigger="click"
              >
                <Button size="large" className="button">
                  {filterData == "custom" ? (
                    <>
                      {moment(filterDate.startDate).format("YYYY-MM-DD")} to{" "}
                      {moment(filterDate.endDate).format("YYYY-MM-DD")}
                    </>
                  ) : (
                    <>Past {filterData} days</>
                  )}

                  <DownOutlined />
                </Button>
              </Popover>
            </Col>
            <Col>
              <Button
                loading={isLoading}
                type="primary"
                size="large"
                className="button"
                style={{ marginLeft: "10px" }}
                onClick={handleChartDownload}
              >
                <DownloadOutlined />
                Download
              </Button>
            </Col>
          </Row>
        </Col>
        <Col {...{ xs: 24, sm: 24, md: 24, lg: 12, xl: 12 }}>
          {isDashboardLoading ? (
            <Skeleton.Button
              active={true}
              block={true}
              style={{
                height: "40px",
                borderRadius: "var(--input-Border-Radius3)",
              }}
            />
          ) : (
            <Select
              mode="multiple"
              placeholder="Select Specific cards for analytics"
              bordered={false}
              loading={isDashboardLoading}
              value={filterSelectedIds.flipcardIds}
              allowClear={true}
              onChange={(data) => {
                if (data.length === 0) {
                  setFilterSelectedIds({
                    flipcardIds: data,
                    allFlipcards: true,
                  });
                }
                if (data.length > 0) {
                  setFilterSelectedIds({
                    flipcardIds: data,
                    allFlipcards: false,
                  });
                }
              }}
              size="large"
              className="antdSelect"
            >
              {data?.flipcards?.map((item, index) => {
                const name = item?.name?.substring(0, 30);
                const nameLength = item?.name?.length;
                const key = item?._id;
                return (
                  <Option key={key + " " + name}>
                    {name}
                    {nameLength > 30 ? "..." : null}
                  </Option>
                );
              })}
            </Select>
          )}
        </Col>
      </Row>
    </Row>
  );
};

export default AnalyticsTopBar;
