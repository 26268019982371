export default [
  { label: "Action Type", key: "actionType" },
  { label: "Email Address", key: "email" },
  { label: "Full Name", key: "fullname" },
  { label: "Notify", key: "notify" },
  { label: "Petition Id", key: "petitionId" },
  { label: "Source", key: "source" },
  { label: "Postal Code", key: "postalCode" },
  { label: "Date Added", key: "dateAdded" },
];
