import { SendOutlined } from "@ant-design/icons";
import { Button, Col, Input, message, Row } from "antd";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import { useFlipCardSendEmailToAudienceMutation } from "../../../Services/service";
import "../../../../node_modules/react-quill/dist/quill.snow.css";
let modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
};

let formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];
function Content({ userData, setUserData, setSendEmailVisible }) {
  const [isFetchBodyLoading, setIsFetchBodyLoading] = useState();
  const _id = useSelector((state) => state.NewFlipcardOverview._id);
  const [_sendEmail, { isLoading }] = useFlipCardSendEmailToAudienceMutation();
  const handleEmailSender = async () => {
    try {
      if (
        userData?.audienceIds.length === 0 ||
        userData?.subject === "" ||
        userData?.emailBody === ""
      ) {
        message.error("Please fill all the fields");
      } else {
        const response = await _sendEmail({ _id, userData });
        if (response?.data) {
          message.success("Email sent successfully");
          setSendEmailVisible(false);
        } else if (response?.error?.status === 413) {
          message.error("File size is too large");
        } else {
          message.error("Email not sent");
        }
      }
    } catch (err) {
      message.error("Something went wrong");
    }
  };

  const fetchEmailBodyHandler = async () => {
    setIsFetchBodyLoading(true);
    const response = await fetch(
      "https://emailgenerator.bridged.media/generateBody",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subject: userData.subject,
        }),
      }
    );
    setIsFetchBodyLoading(false);
    const data = await response.json();
    if (data?.body) {
      message.success("Data Added to Editor");
      setUserData({ ...userData, emailBody: data?.body });
    } else {
      message.error("No relevent content found");
    }
  };
  return (
    <Row style={{ marginTop: "var(--sub-margin)" }}>
      <Col span={24}>
        <Row gutter={[10, 0]} justify="space-between" align="bottom">
          <Col span={20}>
            <p>Email Subject</p>
            <Input
              value={userData.subject}
              onChange={(e) =>
                setUserData({ ...userData, subject: e.target.value })
              }
              type="text"
              placeholder="Subject"
              className="inputBx"
              style={{ marginTop: "7.5px" }}
            />
          </Col>
          <Col span={4}>
            <Button
              type="primary"
              size="large"
              className="button"
              loading={isFetchBodyLoading}
              onClick={fetchEmailBodyHandler}
            >
              Fetch Body
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24} style={{ marginTop: "var(--main-margin)" }}>
        <p>
          Email content{" "}
          <span style={{ color: "var(--third-Text-Color)" }}>
            {
              "If you want to use the fullname, Use this in email body : {fullname}"
            }
          </span>{" "}
        </p>
        <ReactQuill
          value={userData.emailBody}
          onChange={(e) => setUserData({ ...userData, emailBody: e })}
          style={{ marginTop: "var(--sub-margin)" }}
          modules={modules}
          formats={formats}
        />
      </Col>
      <Col span={24} style={{ marginTop: "var(--main-margin)" }}>
        <Row justify="end" align="middle">
          <Button
            loading={isLoading}
            size="large"
            className="button"
            type="primary"
            onClick={handleEmailSender}
          >
            Send <SendOutlined />
          </Button>
        </Row>
      </Col>
    </Row>
  );
}

export default Content;
