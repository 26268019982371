import { Col, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import "./flipCardOverview.css";
import LeftSection from "./LeftSection";
import RightSection from "./RightSection";
import { useParams } from "react-router-dom";
import { useFlipCardOverviewMutation } from "../../Services/service";
import TableSection from "./TableSection";
import TopBar from "./TopBar";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentExportLabel,
  setCurrentTableColumn,
  setCurrTableActionType,
  setFlipcardOverviewData,
  setFlipcardOverviewDataStatus,
  setFlipcardOverviewId,
  setOriginalTableData,
  setSelectedTableData,
} from "../../redux/Slices/Overview/NewFlipcardOverviewSlice";
import messageBoxColumn from "./Data Columns/messageBoxColumn";
import actionNameConverter from "../ExtraComponents/actionNameConverter";
import messageBoxExportLabels from "./Data Columns/messageBoxExportLabels";
import signAPetitionColumn from "./Data Columns/signAPetitionColumn";
import signAPetitionExportLabels from "./Data Columns/signAPetitionExportLabels";
import allColumn from "./Data Columns/allColumn";
import allLabels from "./Data Columns/allLabels";
import newsLetterSignupColumn from "./Data Columns/newsLetterSignupColumn";
import newsLetterSignupLabels from "./Data Columns/newsLetterSignupLabels";
import donationColumn from "./Data Columns/donationColumn";
import donationExportLabels from "./Data Columns/donationExportLabels";
import linkColumn from "./Data Columns/linkColumn";
import linkLabel from "./Data Columns/linkLabel";
import applicationSignupColumn from "./Data Columns/applicationSignupColumn";
import applicationSignupLabel from "./Data Columns/applicationSignupLabel";
function FlipCardOverviewWorkflow() {
  //   fullname
  // email
  // phoneNumber
  // nationalId

  const currtableActionType = useSelector(
    (state) => state.NewFlipcardOverview.currtableActionType
  );
  const cardOverviewTableData = useSelector(
    (state) => state.NewFlipcardOverview.flipcardOverviewData.audience
  );
  const dispatch = useDispatch();
  const [filterDate, setfilterDate] = useState({
    startDate: moment().utc().endOf("day"),
    endDate: moment().utc().add(-6, "days").startOf("day"),
  });
  const { _id } = useParams();
  const [_flipCardoverview, { isLoading, isError }] =
    useFlipCardOverviewMutation();

  // ------------- Take the date and return the data -------------
  const flipCardOverviewInfo = async () => {
    let flipCardOverviewObj = {
      flipcardId: _id,
      startDate: filterDate.startDate.toISOString(),
      endDate: filterDate.endDate.toISOString(),
    };
    const response = await _flipCardoverview(flipCardOverviewObj);
    if (response?.data) {
      dispatch(setFlipcardOverviewData(response?.data));
    } else {
      message.error("Something went wrong");
    }
  };

  // ------------ Call The Function when filterDate Changes -------------
  useEffect(() => {
    flipCardOverviewInfo();
  }, [filterDate]);

  // ------------ Send Data to redux when this fields are changed ------------
  useEffect(() => {
    dispatch(setFlipcardOverviewId(_id));
    dispatch(setFlipcardOverviewDataStatus({ isLoading, isError }));
  }, [_id, isLoading, isError]);

  // ------------ Set Table Data as per actions ------------
  useEffect(() => {
    if (cardOverviewTableData) {
      if (currtableActionType === "all") {
        dispatch(setCurrentTableColumn(allColumn));
        dispatch(setCurrentExportLabel(allLabels));
        let setData = [];
        cardOverviewTableData.filter((item) => {
          setData.push({
            key: item.id,
            notify: item.notify ? "Yes" : "No",
            dateAdded: moment(item?.dateAdded).format("YYYY-MM-DD"),
            email: item?.actionObject?.email,
            fullname: item?.actionObject?.fullname,
            postalCode: item?.actionObject?.postalCode,
            actionType: actionNameConverter(item?.actionType),
            source: item?.source,
            actionTakerId: item?.actionTakerId,
          });
        });
        dispatch(setOriginalTableData(setData));
      }
      if (currtableActionType === "messageBox") {
        dispatch(setCurrentTableColumn(messageBoxColumn));
        dispatch(setCurrentExportLabel(messageBoxExportLabels));
        let setData = [];
        cardOverviewTableData.filter((item) => {
          if (item.actionType == "messageBox") {
            setData.push({
              key: item.id,
              notify: item.notify ? "Yes" : "No",
              dateAdded: moment(item?.dateAdded).format("YYYY-MM-DD"),
              email: item?.actionObject?.email,
              fullname: item?.actionObject?.fullname,
              postalCode: item?.actionObject?.postalCode,
              message: item?.actionObject?.body,
              actionType: actionNameConverter(item?.actionType),
              source: item?.source,
              actionTakerId: item?.actionTakerId,
            });
          }
        });
        dispatch(setOriginalTableData(setData));
      }
      if (currtableActionType === "signAPetition") {
        dispatch(setCurrentTableColumn(signAPetitionColumn));
        dispatch(setCurrentExportLabel(signAPetitionExportLabels));
        let setData = [];
        cardOverviewTableData.filter((item) => {
          if (item.actionType == "signAPetition") {
            setData.push({
              key: item.id,
              notify: item.notify ? "Yes" : "No",
              dateAdded: moment(item?.dateAdded).format("YYYY-MM-DD"),
              email: item?.actionObject?.email,
              fullname: item?.actionObject?.fullname,
              postalCode: item?.actionObject?.postalCode,
              message: item?.actionObject?.body,
              actionType: actionNameConverter(item?.actionType),
              source: item?.source,
              petitionId: item?.actionObject?.petitionId,
              actionTakerId: item?.actionTakerId,
            });
          }
        });
        dispatch(setOriginalTableData(setData));
      }
      if (currtableActionType === "newsLetterSignup") {
        dispatch(setCurrentTableColumn(newsLetterSignupColumn));
        dispatch(setCurrentExportLabel(newsLetterSignupLabels));
        let setData = [];
        cardOverviewTableData.filter((item) => {
          if (item.actionType == "newsLetterSignup") {
            setData.push({
              key: item.id,
              notify: item.notify ? "Yes" : "No",
              dateAdded: moment(item?.dateAdded).format("YYYY-MM-DD"),
              email: item?.actionObject?.email,
              fullname: item?.actionObject?.fullname,
              postalCode: item?.actionObject?.postalCode,
              message: item?.actionObject?.body,
              actionType: actionNameConverter(item?.actionType),
              source: item?.source,
              petitionId: item?.actionObject?.petitionId,
              actionTakerId: item?.actionTakerId,
            });
          }
        });
        dispatch(setOriginalTableData(setData));
      }
      if (currtableActionType === "donation") {
        dispatch(setCurrentTableColumn(donationColumn));
        dispatch(setCurrentExportLabel(donationExportLabels));
        let setData = [];
        cardOverviewTableData.filter((item) => {
          if (item.actionType == "donation") {
            setData.push({
              key: item.id,
              notify: item.notify ? "Yes" : "No",
              dateAdded: moment(item?.dateAdded).format("YYYY-MM-DD"),
              email: item?.actionObject?.email,
              fullname: item?.actionObject?.fullname,
              postalCode: item?.actionObject?.postalCode,
              actionType: actionNameConverter(item?.actionType),
              source: item?.source,
              projectId: item?.actionObject?.projectId,
              actionTakerId: item?.actionTakerId,
            });
          }
        });
        dispatch(setOriginalTableData(setData));
      }
      if (currtableActionType === "link") {
        dispatch(setCurrentTableColumn(linkColumn));
        dispatch(setCurrentExportLabel(linkLabel));
        let setData = [];
        cardOverviewTableData.filter((item) => {
          if (item.actionType == "link") {
            setData.push({
              key: item.id,
              notify: item.notify ? "Yes" : "No",
              dateAdded: moment(item?.dateAdded).format("YYYY-MM-DD"),
              email: item?.actionObject?.email,
              fullname: item?.actionObject?.fullname,
              postalCode: item?.actionObject?.postalCode,
              actionType: actionNameConverter(item?.actionType),
              source: item?.source,
              actionTakerId: item?.actionTakerId,
            });
          }
        });
        dispatch(setOriginalTableData(setData));
      }
      if (currtableActionType === "applicationSignup") {
        dispatch(setCurrentTableColumn(applicationSignupColumn));
        dispatch(setCurrentExportLabel(applicationSignupLabel));
        let setData = [];
        cardOverviewTableData.filter((item) => {
          if (item.actionType == "applicationSignup") {
            setData.push({
              key: item.id,
              email: item?.actionObject?.email,
              fullname: item?.actionObject?.fullname,
              phoneNumber: item?.actionObject?.phoneNumber,
              nationalId: item?.actionObject?.nationalId,
              actionType: actionNameConverter(item?.actionType),
              dateAdded: moment(item?.dateAdded).format("YYYY-MM-DD"),
              source: item?.source,
              actionTakerId: item?.actionTakerId,
            });
          }
        });
        dispatch(setOriginalTableData(setData));
      }
    }
  }, [cardOverviewTableData, currtableActionType]);

  // ------------- Make Default value in redux -------------
  useEffect(() => {
    dispatch(setCurrTableActionType("all"));
    dispatch(setOriginalTableData([]));
    dispatch(setSelectedTableData([]));
    dispatch(setCurrentTableColumn([]));
    dispatch(setCurrentExportLabel([]));
  }, []);

  return (
    <section className="cardOverviewSection">
      <div className="cardOverviewMainDiv">
        <TopBar flipCardOverviewInfo={flipCardOverviewInfo} />
        <Row
          gutter={[10, 30]}
          style={{
            marginTop: "20px",
          }}
          justify="space-between"
        >
          <LeftSection />
          <RightSection setfilterDate={setfilterDate} />
          <TableSection />
        </Row>
      </div>
    </section>
  );
}

export default FlipCardOverviewWorkflow;
