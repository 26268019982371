import { Button } from "antd";
import React, { useState } from "react";
import TextToImageModal from "./TextToImageModal";

function ActionsTTI() {
  const [isTTIModal, setIsTTIModal] = useState(false);
  return (
    <>
      <Button
        size="large"
        className="button"
        onClick={() => {
          setIsTTIModal(true);
        }}
      >
        Generate image
      </Button>
      <TextToImageModal visible={isTTIModal} onCancel={setIsTTIModal} />
    </>
  );
}

export default ActionsTTI;
