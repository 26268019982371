import { createSlice } from "@reduxjs/toolkit";
import AdminFlipcardInitialState from "../../../Initial States/Flip Cards/AdminFlipcardInitialState";

const AdminFlipCardSlice = createSlice({
  name: "AdminFlipCard",
  initialState: AdminFlipcardInitialState,
  reducers: {
    AdminActionObject: (state, action) => {
      state.actionObject = action.payload;
    },
    AdminEngagementObject: (state, action) => {
      state.engagementObject = action.payload;
    },
  },
});

export const { AdminActionObject, AdminEngagementObject } =
  AdminFlipCardSlice.actions;
export default AdminFlipCardSlice.reducer;
