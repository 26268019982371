export default (e, data) => {
  let actionsArray = [...data];
  if (e === undefined) {
  } else if (e === "Newest first") {
    actionsArray.sort((a, b) => {
      if (a.createdDate > b.createdDate) {
        return -1;
      }
      if (a.createdDate < b.createdDate) {
        return 1;
      }
      return 0;
    });
  } else if (e === "Oldest first") {
    actionsArray.sort((a, b) => {
      if (a.createdDate < b.createdDate) {
        return -1;
      }
      if (a.createdDate > b.createdDate) {
        return 1;
      }
      return 0;
    });
  }
  return actionsArray;
};
