import { createSlice } from "@reduxjs/toolkit";

const FlipCardEtcSlice = createSlice({
  name: "FlipCardEtc",
  initialState: {
    currEng: "sliderPoll",
    currAction: "messageBox",
    currentSection: 0,
    optionsAdder: 0,
    shouldEngSecChange: false,
    shouldActSecChange: false,
    flipcardSDGS: [],
    iframeLoaded: false,
    isEngagementSkipped: false,
    isFlipcardSubmitted: false,
    isFlipcardPageMounted: "unmounted",
    isEditFlipcardData: false,
    isDraftData: false,
    isCustomDonationChanged: false,
    dashboardSearchQuery: "",
    dynamicFormNumberOfIndexes: 0,
  },
  reducers: {
    currEngSetter: (state, action) => {
      state.currEng = action.payload;
    },
    currActionSetter: (state, action) => {
      state.currAction = action.payload;
    },
    currentSectionSetter: (state, action) => {
      state.currentSection = action.payload;
    },
    optionsAdderSetter: (state, action) => {
      state.optionsAdder = action.payload;
    },
    shouldEngSecChangeSetter: (state, action) => {
      state.shouldEngSecChange = action.payload;
    },
    shouldActSecChangeSetter: (state, action) => {
      state.shouldActSecChange = action.payload;
    },
    flipcardSDGSSetter: (state, action) => {
      state.flipcardSDGS = action.payload;
    },
    iframeLoadedSetter: (state, action) => {
      state.iframeLoaded = action.payload;
    },
    isEngagementSkippedSetter: (state, action) => {
      state.isEngagementSkipped = action.payload;
    },
    isFlipcardSubmittedSetter: (state, action) => {
      state.isFlipcardSubmitted = action.payload;
    },
    isFlipcardPageMountedSetter: (state, action) => {
      state.isFlipcardPageMounted = action.payload;
    },
    isEditFlipcardDataSetter: (state, action) => {
      state.isEditFlipcardData = action.payload;
    },
    isDraftDataSetter: (state, action) => {
      state.isDraftData = action.payload;
    },
    isCustomDonationChangedSetter: (state, action) => {
      state.isCustomDonationChanged = action.payload;
    },
    dashboardSearchSetter: (state, action) => {
      state.dashboardSearchQuery = action.payload;
    },
    dynamicFormNumberOfIndexesSetter: (state, action) => {
      state.dynamicFormNumberOfIndexes = action.payload;
    },
  },
});

export const {
  currEngSetter,
  currActionSetter,
  currentSectionSetter,
  optionsAdderSetter,
  shouldEngSecChangeSetter,
  shouldActSecChangeSetter,
  flipcardSDGSSetter,
  iframeLoadedSetter,
  isEngagementSkippedSetter,
  isFlipcardSubmittedSetter,
  isFlipcardPageMountedSetter,
  isEditFlipcardDataSetter,
  isDraftDataSetter,
  isCustomDonationChangedSetter,
  dashboardSearchSetter,
  dynamicFormNumberOfIndexesSetter,
} = FlipCardEtcSlice.actions;
export default FlipCardEtcSlice.reducer;
