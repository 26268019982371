import { Button, Col, message, Row, Tabs } from "antd";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import ShareCardComponent from "../../../ResusableComponents/EtcComponents/ShareCardComponent";
function Finish({ flipcardSubmitId }) {
  return (
    <>
      <Row
        justify="center"
        align="middle"
        style={{
          width: "100%",
        }}
      >
        <Col
          {...{ xs: 24, sm: 24, md: 18, lg: 15, xl: 13 }}
          className="flipcardOutCompGrey"
        >
          <Row className="flipcardInCompWhite">
            <ShareCardComponent flipcardSubmitId={flipcardSubmitId} />
          </Row>
        </Col>
      </Row>
      <Row
        justify="center"
        align="middle"
        style={{
          width: "100%",
          marginTop: "var(--main-margin)",
        }}
      >
        <Col {...{ xs: 24, sm: 24, md: 18, lg: 15, xl: 13 }}>
          <Row justify="center" align="middle">
            <NavLink to="/">
              <Button size="large" type="text" className="button">
                BACK TO DASHBOARD
              </Button>
            </NavLink>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Finish;
