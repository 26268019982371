import { Button, Col, Form, Input, message, Modal, Row, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import {
  useBridgedProjectCreateMutation,
  useBridgedProjectFindByIdMutation,
  useBridgedProjectUpdateMutation,
} from "../../../../Services/service";
import { useSelector, useDispatch } from "react-redux";
import { setActDonation } from "../../../../redux/Slices/Flip Cards/Actions/ActDonationSlice";
import { isCustomDonationChangedSetter } from "../../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
function CustomDonationModal({
  isModalVisible,
  handleCancel,
  editModalProjectId,
  customDonationMongoDbId,
}) {
  const dispatch = useDispatch();
  const ActDonationData = useSelector((state) => state.ActDonation.action);
  const isCustomDonationChanged = useSelector(
    (state) => state.FlipCardEtc.isCustomDonationChanged
  );
  const [shouldSectionChange, setShouldSectionChange] = useState(false);
  const [userData, setUserData] = useState({
    title: "",
    summary: "",
    goal: 0,
    funding: 0,
    need: "",
    longTermImpact: "",
    organization: {
      name: "",
      mission: "",
      url: "",
      logoUrl: "",
      addressLine1: "",
      postal: "",
    },
  });

  // Sets data to userData as per name attribute
  const handleChange = (e) => {
    let value = e.target.value;
    if (e.target.name === "goal" || e.target.name === "funding") {
      if (value === "") {
        value = 0;
      } else {
        value = parseInt(value);
      }
    }
    if (e.target.name.split(".")[0] === "organization") {
      setUserData({
        ...userData,
        organization: {
          ...userData.organization,
          [e.target.name.split(".")[1]]: value,
        },
      });
    } else {
      setUserData({
        ...userData,
        [e.target.name]: value,
      });
    }
  };

  // Cheks if userData is empty and if so, sets shouldSectionChange to true
  useEffect(() => {
    const {
      title,
      summary,
      goal,
      funding,
      need,
      longTermImpact,
      organization,
    } = userData;
    const { name, mission, url, logoUrl, addressLine1, postal } = organization;
    if (
      title === "" ||
      summary === "" ||
      need === "" ||
      longTermImpact === "" ||
      name === "" ||
      mission === "" ||
      url === "" ||
      logoUrl === "" ||
      addressLine1 === "" ||
      postal === ""
    ) {
      setShouldSectionChange(false);
    } else {
      setShouldSectionChange(true);
    }
  }, [userData]);

  // Handles submit of form
  const [_createProject, { isLoading }] = useBridgedProjectCreateMutation();

  const createDonation = async (e) => {
    e.preventDefault();
    const response = await _createProject(userData);
    if (response?.data) {
      const projectId = response?.data?.projectId;
      let processedData = {
        ...ActDonationData,
        actionData: {
          ...ActDonationData.actionData,
          projectId,
        },
      };
      message.success("Project Created");
      dispatch(setActDonation(processedData));
      dispatch(isCustomDonationChangedSetter(!isCustomDonationChanged));
      handleCancel();
    } else {
      message.error("Something went wrong");
    }
  };

  // ------------------ Edit Donation ------------------------
  useEffect(() => {
    if (editModalProjectId) {
      editDonation();
    }
  }, [editModalProjectId]);

  const [_findProjectById, { isLoading: editisLoading }] =
    useBridgedProjectFindByIdMutation();

  const editDonation = async () => {
    const response = await _findProjectById(editModalProjectId);
    const data = response?.data;
    const processedData = {
      ...data,
      _id: undefined,
      projectId: undefined,
      numberOfDonations: undefined,
      createdDate: undefined,
      active: undefined,
    };
    setUserData(processedData);
  };

  const [_updateProject, { isLoading: projectUpdateLoading }] =
    useBridgedProjectUpdateMutation();

  const editSubmitDonation = async (e) => {
    e.preventDefault();
    const response = await _updateProject({
      userData,
      customDonationMongoDbId,
    });

    if (response?.data) {
      let processedData = {
        ...ActDonationData,
        actionData: {
          ...ActDonationData.actionData,
          projectId: editModalProjectId,
        },
      };
      message.success("Project edited successfully");
      dispatch(setActDonation(processedData));
      dispatch(isCustomDonationChangedSetter(!isCustomDonationChanged));
      handleCancel();
    } else {
      message.error("Something went wrong");
    }
  };

  return (
    <Modal
      visible={isModalVisible}
      onCancel={handleCancel}
      width={1400}
      footer={null}
      className="flipcardNewActionModal"
    >
      {editisLoading ? (
        <>
          <Skeleton.Button
            active={true}
            block={true}
            style={{
              height: "600px",
              borderRadius: "var(--input-Border-Radius3)",
              marginTop: "var(--sub-margin)",
            }}
          />
        </>
      ) : (
        <Row>
          <Col span={24}>
            <h2>{editModalProjectId ? "Update" : "Create"} Custom Project</h2>
            <p>
              Create a new donation project by adding details of the
              organisation and project mission
            </p>
          </Col>
          <Col
            span={24}
            style={{
              marginTop: "var(--main-margin)",
            }}
          >
            <Form layout="vertical">
              <Row gutter={[15, 15]}>
                <Col {...{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}>
                  <h2
                    style={{
                      marginBottom: "var(--sub-margin)",
                    }}
                  >
                    Project information
                  </h2>
                  <Form.Item label="Title *">
                    <Input
                      type="text"
                      name="title"
                      value={userData.title}
                      onChange={handleChange}
                      placeholder="Title"
                      className="inputBx"
                    />
                  </Form.Item>
                  <Form.Item label="Summary *">
                    <Input
                      type="text"
                      name="summary"
                      value={userData.summary}
                      onChange={handleChange}
                      placeholder="Summary"
                      className="inputBx"
                    />
                  </Form.Item>
                  <Form.Item label="Goal">
                    <Input
                      type="number"
                      name="goal"
                      value={userData.goal}
                      onChange={handleChange}
                      placeholder="Goal"
                      className="inputBx"
                    />
                  </Form.Item>
                  <Form.Item label="Funding">
                    <Input
                      type="number"
                      name="funding"
                      value={userData.funding}
                      onChange={handleChange}
                      placeholder="Funding"
                      className="inputBx"
                    />
                  </Form.Item>
                  <Form.Item label="Need *">
                    <Input
                      type="text"
                      name="need"
                      value={userData.need}
                      onChange={handleChange}
                      placeholder="Need"
                      className="inputBx"
                    />
                  </Form.Item>
                  <Form.Item label="Long term impact *">
                    <Input
                      type="text"
                      name="longTermImpact"
                      value={userData.longTermImpact}
                      onChange={handleChange}
                      placeholder="Long term impact"
                      className="inputBx"
                    />
                  </Form.Item>
                </Col>
                <Col {...{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}>
                  <h2
                    style={{
                      marginBottom: "var(--sub-margin)",
                    }}
                  >
                    Organisation information
                  </h2>
                  <Form.Item label="Organisation name *">
                    <Input
                      type="text"
                      name="organization.name"
                      value={userData.organization.name}
                      onChange={handleChange}
                      placeholder="Organisation name"
                      className="inputBx"
                    />
                  </Form.Item>
                  <Form.Item label="Mission *">
                    <Input
                      type="text"
                      name="organization.mission"
                      value={userData.organization.mission}
                      onChange={handleChange}
                      placeholder="Mission"
                      className="inputBx"
                    />
                  </Form.Item>
                  <Form.Item label="Organisation url *">
                    <Input
                      type="url"
                      name="organization.url"
                      value={userData.organization.url}
                      onChange={handleChange}
                      placeholder="Organisation url"
                      className="inputBx"
                    />
                  </Form.Item>
                  <Form.Item label="Logo url *">
                    <Input
                      type="url"
                      name="organization.logoUrl"
                      value={userData.organization.logoUrl}
                      onChange={handleChange}
                      placeholder="Logo url"
                      className="inputBx"
                    />
                  </Form.Item>
                  <Form.Item label="Organisation address *">
                    <Input
                      type="url"
                      name="organization.addressLine1"
                      value={userData.organization.addressLine1}
                      onChange={handleChange}
                      placeholder="Organisation address"
                      className="inputBx"
                    />
                  </Form.Item>
                  <Form.Item label="Postal code *">
                    <Input
                      type="url"
                      name="organization.postal"
                      value={userData.organization.postal}
                      onChange={handleChange}
                      placeholder="Postal code"
                      className="inputBx"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col span={24}>
            <Row justify="end" align="middle">
              <Button
                type="primary"
                size="large"
                className="button"
                onClick={
                  editModalProjectId ? editSubmitDonation : createDonation
                }
                loading={isLoading || projectUpdateLoading}
                disabled={!shouldSectionChange}
              >
                {editModalProjectId ? "Update" : "Create"}
              </Button>
            </Row>
          </Col>
        </Row>
      )}
    </Modal>
  );
}

export default CustomDonationModal;
