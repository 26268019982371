import { Col, Row, Table } from "antd";
import React from "react";
import { useSelector } from "react-redux";

function Recipient() {
  const currentTableColumn = useSelector(
    (state) => state.NewFlipcardOverview.currentTableColumn
  );

  const originalTableData = useSelector(
    (state) => state.NewFlipcardOverview.originalTableData
  );

  const selectedTableData = useSelector(
    (state) => state.NewFlipcardOverview.selectedTableData
  );

  return (
    <Row style={{ marginTop: "var(--sub-margin)" }}>
      <Col span={24} style={{ marginTop: "var(--sub-margin)" }}>
        <p>
          When this email will be delivered to{" "}
          <span style={{ fontWeight: "700" }}>
            {selectedTableData.length > 0
              ? selectedTableData.length
              : originalTableData.length}
          </span>{" "}
          recipients
        </p>
      </Col>
      <Col span={24} style={{ marginTop: "var(--sub-margin)" }}>
        <div
          style={{
            marginTop: "var(--main-margin)",
            marginBottom: "50px",
            width: "100%",
          }}
        >
          <Table
            className="antDesigntable"
            columns={currentTableColumn}
            dataSource={
              selectedTableData.length > 0
                ? selectedTableData
                : originalTableData
            }
            pagination={false}
            scroll={{ x: 1300 }}
          />
        </div>
      </Col>
    </Row>
  );
}

export default Recipient;
