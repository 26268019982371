import { Button, Col, Form, Input, Modal, Table } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { FileTextOutlined } from "@ant-design/icons";
function ExportAudienceModal({ exportVisible, setExportVisible }) {
  const [customName, setCustomName] = useState("");

  const currentTableColumn = useSelector(
    (state) => state.NewFlipcardOverview.currentTableColumn
  );
  const currentExportLabel = useSelector(
    (state) => state.NewFlipcardOverview.currentExportLabel
  );

  const selectedTableData = useSelector(
    (state) => state.NewFlipcardOverview.selectedTableData
  );

  const originalTableData = useSelector(
    (state) => state.NewFlipcardOverview.originalTableData
  );
  return (
    <Modal
      visible={exportVisible}
      onCancel={() => setExportVisible(!exportVisible)}
      width={1000}
      footer={null}
      className="flipcardOverviewModal"
    >
      <Col
        span={24}
        style={{
          marginTop: "var(--main-margin)",
        }}
      >
        <Form.Item
          style={{
            width: "100%",
            marginTop: "var(--sub-margin)",
            marginBottom: "var(--sub-margin)",
          }}
        >
          <Input
            type="text"
            size="large"
            value={customName}
            onChange={(e) => {
              setCustomName(e.target.value);
            }}
            placeholder="Name your export"
            style={{
              height: "50px",
            }}
            className="inputBx"
          />
        </Form.Item>
      </Col>
      <div
        style={{
          marginTop: "var(--main-margin)",
          marginBottom: "50px",
          width: "100%",
        }}
      >
        <Table
          className="antDesigntable"
          columns={currentTableColumn}
          dataSource={
            selectedTableData.length > 0 ? selectedTableData : originalTableData
          }
          pagination={false}
          scroll={{ x: 1300 }}
        />
      </div>
      <Col>
        <CSVLink
          data={
            selectedTableData.length > 0 ? selectedTableData : originalTableData
          }
          headers={currentExportLabel}
          filename={
            customName === "" ? "Bridged User Data.csv" : `${customName}.csv`
          }
          target="_blank"
        >
          <Button
            type="primary"
            size="large"
            className="button"
            style={{
              marginRight: "var(--main-margin)",
            }}
          >
            <FileTextOutlined />
            Export Audience
          </Button>
        </CSVLink>
      </Col>
    </Modal>
  );
}

export default ExportAudienceModal;
