import React, { useEffect, useState } from "react";
import "./OnBoarding.css";
import { useDispatch } from "react-redux";
import { onBoard } from "../../redux/Slices/Auth/authSlice";
import { Row } from "antd";
import Sidebar from "./Sidebar";
import Topics from "./Topics";
import Goals from "./Goals";
import CompanyDetails from "./CompanyDetails";
import PersonalInformation from "./PersonalInformation";
import OnBoardingSelection from "./OnBoardingSelection";
function OnBoardingWorkflow() {
  const sidebarTopics = [
    "Industry",
    "Organisation Details",
    "Business Goals",
    "Personal information",
  ];
  const [toggleSections, setToggleSections] = useState("start");
  const [currentSection, setCurrentSection] = useState(0);
  const [isSDGs, setIsSDGs] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onBoard(true));
  }, []);

  useEffect(() => {
    if (toggleSections == sidebarTopics[0]) {
      setCurrentSection(0);
    }
    if (toggleSections == sidebarTopics[1]) {
      setCurrentSection(1);
    }
    if (toggleSections == sidebarTopics[2]) {
      setCurrentSection(2);
    }
    if (toggleSections == sidebarTopics[3]) {
      setCurrentSection(3);
    }
  }, [toggleSections]);

  const section = () => {
    switch (toggleSections) {
      case sidebarTopics[0]:
        return (
          <Topics
            setToggleSections={setToggleSections}
            cbo={true}
            setIsSDGs={setIsSDGs}
            isSDGs={isSDGs}
          />
        );
      case sidebarTopics[1]:
        return (
          <CompanyDetails setToggleSections={setToggleSections} cbo={true} />
        );
      case sidebarTopics[2]:
        return <Goals setToggleSections={setToggleSections} cbo={true} />;
      case sidebarTopics[3]:
        return (
          <PersonalInformation
            setToggleSections={setToggleSections}
            cbo={true}
          />
        );
      default:
        return <OnBoardingSelection setToggleSections={setToggleSections} />;
    }
  };

  return (
    <div className="onBoardingParent">
      <Row className="onBoardingCard">
        {toggleSections !== "start" ? (
          <Sidebar
            currentSection={currentSection}
            sidebarTopics={sidebarTopics}
          />
        ) : null}

        {section()}
      </Row>
    </div>
  );
}

export default OnBoardingWorkflow;
