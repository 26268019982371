import {
  Button,
  Card,
  Col,
  Row,
  Pagination,
  Skeleton,
  message,
  Dropdown,
  Menu,
  Popconfirm,
} from "antd";
import moment from "moment";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  useBridgedProjectDeleteMutation,
  useBridgedProjectsFindAllQuery,
} from "../../../Services/service";
import {
  CopyOutlined,
  EllipsisOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import CustomDonationModal from "../../CardCreation/ResusableComponents/Actions/CustomDonationModal";
import { isCustomDonationChangedSetter } from "../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
import { setActDonation } from "../../../redux/Slices/Flip Cards/Actions/ActDonationSlice";
import DashboardSearchTitle from "../DashboardReusableFunctions/DashboardSearchTitle";

const AdminCustomBridgedProjects = ({
  searchKeyword,
  clickToAddData,
  handleSearchModalCancel,
}) => {
  const ActDonationData = useSelector((state) => state.ActDonation.action);
  const isCustomDonationChanged = useSelector(
    (state) => state.FlipCardEtc.isCustomDonationChanged
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editModalProjectId, setEditModalProjectId] = useState(false);
  const [customDonationMongoDbId, setCustomDonationMongoDbId] = useState(false);
  const [paginationPage, setPaginationPage] = useState(0);
  const [paginationPageSize, setPaginationPageSize] = useState(7);
  const [actions, setActions] = useState([{}, {}, {}, {}, {}, {}, {}]);
  const { isLoading, isSuccess, data } = useBridgedProjectsFindAllQuery();
  const dispatch = useDispatch();
  const handleCancel = () => {
    setIsModalVisible(false);
    setEditModalProjectId(false);
    setCustomDonationMongoDbId(false);
  };
  useEffect(() => {
    if (isSuccess) {
      if (data) {
        setActions(data);
      }
    }
  }, [isSuccess, data]);

  let displayActions = actions.slice(
    paginationPage * paginationPageSize,
    (paginationPage + 1) * paginationPageSize
  );

  const paginationChange = (page, pageSize) => {
    setPaginationPage(page - 1);
    setPaginationPageSize(pageSize);
  };

  useEffect(() => {
    if (isSuccess) {
      const searchResults = DashboardSearchTitle(searchKeyword, data);
      setActions(searchResults);
    }
  }, [isSuccess, searchKeyword]);

  const [_deleteDonation] = useBridgedProjectDeleteMutation();
  const deleteConfirm = async (_id) => {
    try {
      const response = await _deleteDonation(_id);
      if (response?.error) {
        message.error("Something went wrong");
      }
      if (response?.data) {
        message.success("Donation deleted successfully");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const skeleton = () => {
    return (
      <div style={{ padding: "25px" }} className="dashboardMyActionCard">
        <Skeleton.Button active={true} shape="round" />
        <Skeleton
          active={true}
          paragraph={{ rows: 2 }}
          style={{
            marginTop: "var(--main-margin)",
          }}
        />
        <Skeleton
          active={true}
          paragraph={{ rows: 0 }}
          style={{
            marginTop: "var(--sub-margin)",
          }}
        />
      </div>
    );
  };

  return (
    <>
      <Row
        style={{
          marginTop: "var(--main-margin)",
          marginBottom: "var(--main-margin)",
        }}
      >
        <Col span={24}>
          <h2>Custom Donations</h2>
        </Col>
        <Col
          span={24}
          style={{
            marginTop: "var(--sub-margin)",
          }}
        >
          <Row gutter={[30, 30]} aign="middle" justify="start">
            <Col {...{ xs: 24, sm: 12, md: 12, lg: 12, xl: 6 }}>
              <Button
                type="dashed"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "var(--input-Border-Radius3)",
                }}
                className="dashboardMyActionCard opacity05"
                onClick={() => {
                  setEditModalProjectId(false);
                  setCustomDonationMongoDbId(false);
                  setIsModalVisible(true);
                }}
              >
                <PlusOutlined
                  style={{
                    fontSize: "2rem",
                  }}
                />
                <h1
                  style={{
                    fontSize: "1.3rem",
                    marginTop: "var(--sub-margin)",
                  }}
                >
                  Create Donation
                </h1>
              </Button>
            </Col>
            {displayActions.map((data, index) => {
              const {
                title,
                summary,
                projectId,
                createdDate,
                goal,
                funding,
                _id,
              } = data;
              const titleLength = title?.substring(0, 40);
              const summaryLength = summary?.substring(0, 45);

              const dropdown = (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="1"
                        onClick={() => {
                          setEditModalProjectId(projectId);
                          setIsModalVisible(true);
                          setCustomDonationMongoDbId(_id);
                        }}
                      >
                        Edit
                      </Menu.Item>
                      <Popconfirm
                        title="Are you sure to delete this Donation?"
                        okText="Yes"
                        onConfirm={() => {
                          deleteConfirm(_id);
                        }}
                        cancelText="No"
                      >
                        <Menu.Item key="3">Delete</Menu.Item>
                      </Popconfirm>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <EllipsisOutlined style={{ fontSize: "1.4rem" }} />
                </Dropdown>
              );

              return (
                <Col key={index} {...{ xs: 24, sm: 12, md: 12, lg: 12, xl: 6 }}>
                  {isLoading ? (
                    skeleton()
                  ) : (
                    <Card bordered={false} className="dashboardMyActionCard">
                      <Row style={{ width: "100%", height: "100%" }}>
                        <Col span={24}>
                          <Row align="middle" justify="space-between">
                            <Button shape="round">
                              Project Id:- {projectId}
                            </Button>
                            {_id ? dropdown : ""}
                          </Row>
                        </Col>
                        <Row
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (clickToAddData) {
                              let processedData = {
                                ...ActDonationData,
                                actionData: {
                                  ...ActDonationData.actionData,
                                  projectId,
                                },
                              };
                              dispatch(setActDonation(processedData));
                              dispatch(
                                isCustomDonationChangedSetter(
                                  !isCustomDonationChanged
                                )
                              );
                              message.success("Project Id Added");
                              handleSearchModalCancel();
                            } else {
                              navigator.clipboard.writeText(projectId);
                              message.success("Project Id Copied to clipboard");
                            }
                          }}
                        >
                          <Col
                            span={24}
                            style={{
                              marginTop: "15px",
                            }}
                          >
                            <Row align="middle">
                              <a
                                style={{
                                  color: "var(--secondary-Text-Color)",
                                  marginRight: "var(--sub-margin)",
                                }}
                              >
                                Goal <b>{goal}</b>
                              </a>
                              <a
                                style={{
                                  color: "var(--secondary-Text-Color)",
                                }}
                              >
                                Funding <b>{funding}</b>
                              </a>
                            </Row>
                          </Col>
                          <Row
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              marginTop: "var(--main-margin)",
                            }}
                          >
                            <Col span={24}>
                              <Row justify="start" align="middle">
                                <Col
                                  span={24}
                                  className="dashboardMyActionCardTitle"
                                >
                                  <h1
                                    style={{
                                      color: "#192b9d",
                                      fontSize: "1.7rem",
                                      fontWeight: "200",
                                      height: "85px",
                                    }}
                                  >
                                    {title?.substring(0, 32)}
                                    {title ? null : "Refresh The Page"}
                                    {titleLength?.length > 32 ? "..." : ""}
                                  </h1>
                                </Col>
                                <Col
                                  span={24}
                                  style={{
                                    marginTop: "15px",
                                  }}
                                >
                                  <Row
                                    align="middle"
                                    justify="start"
                                    className="dashboardMyActionCardHashTags"
                                  >
                                    <p>
                                      {summary?.substring(0, 40)}
                                      {summary ? null : "Refresh The Page"}
                                      {summaryLength?.length > 40 ? "..." : ""}
                                    </p>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Col
                            span={24}
                            style={{
                              marginTop: "var(--sub-margin)",
                            }}
                          >
                            <Row align="middle" justify="space-between">
                              <p>{moment(createdDate).format("MMM Do YYYY")}</p>
                            </Row>
                          </Col>
                        </Row>
                      </Row>
                    </Card>
                  )}
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      {actions.length > 7 ? (
        <Col
          span={24}
          style={{
            marginTop: "var(--main-margin)",
            marginBottom: "var(--main-margin)",
          }}
        >
          <Row align="middle" justify="end">
            <Pagination
              className="dashboardPagnaition"
              size="small"
              defaultPageSize={7}
              onChange={paginationChange}
              total={actions.length}
            />
          </Row>
        </Col>
      ) : (
        ""
      )}
      <CustomDonationModal
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        editModalProjectId={editModalProjectId}
        customDonationMongoDbId={customDonationMongoDbId}
      />
    </>
  );
};
export default AdminCustomBridgedProjects;
