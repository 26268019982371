import { Col, Row, Button, Input, message } from "antd";
import "./LoginSignup.css";
import React, { useState } from "react";
import Sidebar from "./Sidebar";
import { NavLink, useHistory } from "react-router-dom";
import Checkbox from "antd/lib/checkbox/Checkbox";
import Swal from "sweetalert2";
import {
  useForgotPasswordMutation,
  useLoginMutation,
} from "../../Services/service";
import { useDispatch } from "react-redux";
import { auth, roleSetter } from "../../redux/Slices/Auth/authSlice";
function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  const HandleInputVal = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const [_login] = useLoginMutation();
  const HandleFormSubmit = async (e) => {
    setSubmitLoading(true);

    if (!userData.email || !userData.password) {
      message.error("Please Fill All The Details");
      setSubmitLoading(false);
    } else {
      const response = await _login(userData);
      if (response?.data?.accessToken) {
        const { accessToken, refreshToken, role } = response?.data;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        dispatch(roleSetter(role));
        message.success("Login Successful");
        setTimeout(() => {
          dispatch(auth(true));
          history.push("/");
        }, 500);

        setSubmitLoading(false);
      }
      if (response?.error?.status) {
        message.error("Invalid Credentials");
        setSubmitLoading(false);
      }
    }
  };
  const [_forgetPass] = useForgotPasswordMutation();
  const forgotPassword = () => {
    Swal.fire({
      title: "Enter your email",
      input: "email",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      preConfirm: async (email) => {
        const response = await _forgetPass({
          email: email,
        });

        if (response?.error?.status === 400) {
          Swal.showValidationMessage(`Email not registered`);
        } else if (response?.data) {
          message.success("Password reset link sent to your email");
          history.push(`/forgotpassword/${response?.data?.email}`);
        } else {
          message.error("Something went wrong");
        }
      },
    });
  };
  return (
    <div className="LSParent">
      <Row className="LSCard">
        <Sidebar
          content={
            "Bridging the gap between empathy and social action, in your content"
          }
        />
        <Col
          {...{ xs: 24, sm: 24, md: 24, lg: 24, xl: 16 }}
          className="LSMainDiv"
        >
          <Row>
            <Col span={24} style={{ marginTop: "10px" }}>
              <h1>Login</h1>
            </Col>

            <Col span={24} style={{ marginTop: "10px" }}>
              <h2>Login to your account</h2>
            </Col>

            <Col
              {...{ xs: 24, sm: 24, md: 20, lg: 20 }}
              style={{ marginTop: "10px" }}
            >
              <p>
                Thank you for getting back to Bridged. Hop in and access our
                best recommendations for you.
              </p>
            </Col>

            <Row gutter={[20, 20]} style={{ marginTop: "60px", width: "100%" }}>
              <Col {...{ xs: 24, sm: 24, md: 12, lg: 12 }}>
                <Row gutter={[0, 20]}>
                  <Col span={24}>
                    <h4>Email</h4>
                    <Input
                      name="email"
                      onChange={HandleInputVal}
                      style={{ marginTop: "5px" }}
                      className="inputBx"
                    />
                  </Col>
                </Row>
              </Col>
              <Col {...{ xs: 24, sm: 24, md: 12, lg: 12 }}>
                <Row gutter={[0, 20]}>
                  <Col span={24}>
                    <h4>Password</h4>
                    <input
                      type="password"
                      name="password"
                      onChange={HandleInputVal}
                      style={{
                        outline: "none",
                        marginTop: "5px",
                        border: "1px solid var(--main-Border-Color)",
                      }}
                      className="inputBx"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[0, 15]} style={{ marginTop: "20px", width: "100%" }}>
              <Col {...{ xs: 24, sm: 12, md: 12, lg: 12 }}>
                <Checkbox
                  checked={rememberMe}
                  onChange={(e) => {
                    setRememberMe(e.target.checked);
                  }}
                >
                  <p
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: "400",
                      opacity: 1,
                    }}
                  >
                    {" "}
                    Remember me
                  </p>
                </Checkbox>
              </Col>
              <Col {...{ xs: 24, sm: 12, md: 12, lg: 12 }}>
                <a
                  onClick={() => {
                    forgotPassword();
                  }}
                >
                  Reset Password?
                </a>
              </Col>
            </Row>
            <Col span={24} style={{ marginTop: "60px" }}>
              <Button
                className="button"
                loading={submitLoading}
                type="primary"
                size="large"
                style={{
                  paddingRight: "50px",
                  paddingLeft: "50px",
                  fontWeight: "600",
                }}
                onClick={HandleFormSubmit}
              >
                Login
              </Button>
            </Col>
            <Col span={24} style={{ marginTop: "20px" }}>
              <h3>
                Don't have an acount? <NavLink to="/signup">Sign up</NavLink>
              </h3>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
