import React from "react";
import { Col, Row, Steps } from "antd";

const { Step } = Steps;

function navBar({ currentSection, navbarArr }) {
  return (
    <Row
      style={{ width: "100%", marginTop: "var(--main-margin)" }}
      className="flipcardOutCompGrey flipcardNav"
    >
      <Col span={24} className="flipcardInCompWhite">
        <Steps current={currentSection} size="small" className="FlipCardNavbar">
          {navbarArr.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
      </Col>
    </Row>
  );
}

export default navBar;
