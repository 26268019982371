import { createSlice } from "@reduxjs/toolkit";
import DynamicFormInitialState from "../../../../Initial States/Actions/DynamicFormInitialState";

const ActDynamicFormSlice = createSlice({
  name: "ActDynamicForm",
  initialState: {
    action: DynamicFormInitialState,
  },
  reducers: {
    setActDynamicForm: (state, action) => {
      state.action = action.payload;
    },
  },
});

export const { setActDynamicForm } = ActDynamicFormSlice.actions;
export default ActDynamicFormSlice.reducer;
