import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Button, Col, Collapse, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { currentSectionSetter } from "../../../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
import { isEngagementSkippedSetter } from "../../../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
import NewEng from "../NewEng";
import SuggestedEng from "./SuggestedEng";
const { Panel } = Collapse;
function Customise({ secPrev, secNext }) {
  const [shouldSkipBtnVissible, setShouldSkipBtnVissible] = useState(false);
  const isEngagement = useSelector(
    (state) => state.FlipCardEtc.isEngagementSkipped
  );
  const shouldEngSecChange = useSelector(
    (state) => state.FlipCardEtc.shouldEngSecChange
  );
  const [collapseActive, setCollapseActive] = useState(3);
  const dispatch = useDispatch();
  const pathname = window.location.href.split("/")[3];

  useEffect(() => {
    if (pathname === "createtemplate" || pathname === "templateedit") {
      setShouldSkipBtnVissible(false);
    } else {
      setShouldSkipBtnVissible(true);
    }
  }, [pathname]);

  return (
    <Row>
      <Col span={24} className="flipcardOutCompGrey">
        <Collapse
          accordion
          bordered={false}
          ghost
          expandIconPosition="right"
          defaultActiveKey={3}
          activeKey={collapseActive}
          onChange={(e) => {
            setCollapseActive(e);
          }}
        >
          <Panel
            header="Select from Suggested engagement"
            key="2"
            className="flipcardInCompWhite flipcardContentPanel"
            style={{
              borderRadius: "var(--input-Border-Radius1)",
            }}
          >
            <SuggestedEng setCollapseActive={setCollapseActive} />
          </Panel>
          <Panel
            header="New Engagement"
            key="3"
            className="flipcardInCompWhite flipcardContentPanel"
            style={{
              marginTop: "var(--sub-margin)",
              borderRadius: "var(--input-Border-Radius1)",
            }}
          >
            <Row>
              <Col span={24}>
                <p className="flipcardContentPanelSubHeading">
                  Did not find what you were looking for? You can create your
                  own engagement below
                </p>
              </Col>
              <Col
                span={24}
                style={{
                  marginTop: "var(--main-margin)",
                }}
              >
                <NewEng />
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Col>
      <Col
        span={24}
        style={{
          marginTop: "var(--main-margin)",
          marginBottom: "var(--main-margin)",
        }}
      >
        <Row justify="space-between" align="middle">
          <Button
            size="large"
            className="button"
            onClick={() => {
              dispatch(currentSectionSetter(secPrev));
            }}
          >
            <ArrowLeftOutlined /> Back
          </Button>

          <Col>
            {shouldSkipBtnVissible ? (
              <Button
                style={{
                  marginRight: "var(--sub-margin)",
                }}
                size="large"
                className="button"
                onClick={() => {
                  dispatch(isEngagementSkippedSetter(true));
                  message.success("Engagement Skipped");
                  dispatch(currentSectionSetter(secNext));
                }}
              >
                Skip <CloseOutlined />
              </Button>
            ) : null}

            <Button
              size="large"
              className="button"
              type="primary"
              disabled={!shouldEngSecChange || isEngagement}
              onClick={() => {
                dispatch(currentSectionSetter(secNext));
                dispatch(isEngagementSkippedSetter(false));
              }}
            >
              Next <ArrowRightOutlined />
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Customise;
