import { Button, Col, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { onBoard } from "../../redux/Slices/Auth/authSlice";
import { useHistory } from "react-router-dom";
import { setUserType } from "../../redux/Slices/OnBoarding/OnboardingSlice";
import { useSelector } from "react-redux";
function OnBoardingSelection({ setToggleSections }) {
  const history = useHistory();
  const section = useSelector((state) => state.OnBoard.userType);

  const selectionArr = [
    {
      name: "I am a Publisher",
      value: "publisher",
    },
    {
      name: "I am a Content marketer",
      value: "content marketer",
    },
    {
      name: "I am a Blogger",
      value: "blogger",
    },
    {
      name: "I work for a caused based organisation",
      value: "cbo",
    },
  ];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onBoard(true));
  }, []);

  return (
    <Row>
      <Col
        span={24}
        className="onBoardingMainDiv"
        style={{
          padding: "var(--main-margin)",
        }}
      >
        <Row
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <Col span={24}>
            <NavLink to="/">
              <img
                style={{
                  width: "120px",
                  margin: "0px 0px 30px 0px",
                }}
                src="/images/logo.png"
                alt=""
              />
            </NavLink>
          </Col>
          <Col
            span={24}
            style={{
              textAlign: "center",
            }}
          >
            <Row align="middle" justify="center">
              <div>
                <h1
                  style={{
                    fontSize: "2.5rem",
                    opacity: 1,
                  }}
                >
                  Let’s get started!
                </h1>
                <p
                  style={{
                    marginTop: "15px",
                    opacity: 1,
                  }}
                >
                  We want to know more about you so that we can provide the best
                  experience.
                </p>
              </div>
            </Row>
          </Col>
          <Col
            span={24}
            style={{
              textAlign: "center",
              marginTop: "15px",
            }}
          >
            <Row justify="center" align="middle">
              {selectionArr.map((data, index) => {
                return (
                  <label
                    className="checkBoxLabel onBoardingSelectioncheckBoxLabel"
                    style={{
                      margin: "20px",
                      height: "150px",
                      width: "100%",
                      maxWidth: "400px",
                    }}
                  >
                    <input
                      type="checkbox"
                      className="checkBox"
                      value={data.value}
                      checked={section === data.value}
                      onChange={(e) => {
                        dispatch(setUserType(e.target.value));
                      }}
                    />
                    <div className="checkBoxInner">
                      <div className="checkBoxName">{data.name}</div>
                    </div>
                  </label>
                );
              })}
            </Row>
          </Col>
          <Col span={24}>
            <Row
              gutter={[0, 15]}
              style={{
                textAlign: "center",
                marginTop: "60px",
              }}
            >
              <Col span={24}>
                <p
                  style={{
                    opacity: 1,
                  }}
                >
                  Check out our <b>subscription plans.</b>{" "}
                </p>
              </Col>
              <Col span={24}>
                <Row justify="end" align="middle">
                  <Button
                    size="large"
                    className="button"
                    style={{
                      paddingLeft: "30px",
                      paddingRight: "30px",
                    }}
                    disabled={!section}
                    onClick={() => {
                      setToggleSections("Industry");
                    }}
                  >
                    Start
                  </Button>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default OnBoardingSelection;
