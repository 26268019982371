import { Col, Modal, Row } from "antd";
import { useSelector } from "react-redux";
import ShareCardComponent from "../CardCreation/ResusableComponents/EtcComponents/ShareCardComponent";

function PreviewModal({ visible, setVisible }) {
  const _id = useSelector((state) => state.NewFlipcardOverview._id);
  return (
    <Modal
      visible={visible}
      width={1400}
      onCancel={() => setVisible(!visible)}
      footer={null}
      className="flipcardOverviewModal"
    >
      <Row
        gutter={[0, 30]}
        justify="space-between"
        align="middle"
        style={{
          marginTop: "var(--main-margin)",
          marginBottom: "var(--sub-margin)",
        }}
      >
        <Col
          {...{ xs: 24, sm: 24, md: 24, lg: 11, xl: 11 }}
          className="flipcardOutCompGrey"
        >
          <Row className="flipcardInCompWhite">
            <ShareCardComponent flipcardSubmitId={_id} />
          </Row>
        </Col>
        <Col
          style={{
            height: "100%",
            minHeight: "450px",
          }}
          {...{ xs: 24, sm: 24, md: 24, lg: 12, xl: 12 }}
          className="flipcardOutCompGrey"
        >
          <Row
            className="flipcardInCompWhite"
            style={{
              height: "100%",
              minHeight: "450px",
            }}
          >
            <iframe
              src={`${process.env.REACT_APP_FLIPCARD_URL}?_id=${_id}`}
              frameborder="0"
              style={{
                width: "100%",
              }}
            ></iframe>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
}

export default PreviewModal;
