import { Col, Row, Skeleton } from "antd";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import countryCodes from "../ExtraComponents/countryCodes";
import mapData from "@highcharts/map-collection/custom/world.geo.json";
import { useEffect } from "react";
import { useState } from "react";
require("highcharts/modules/map")(Highcharts);
const COLORS = ["#001493", "#4b5bbb", "#8c98e3"];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
const ThirdSection = ({ flipCardData, isLoading }) => {
  const [userMapData, setUserMapData] = useState([]);
  const [firstChartData, setFirstChartData] = useState([]);
  const [secondChartData, setSecondChartData] = useState([]);
  useEffect(() => {
    const userData = flipCardData?.countries?.map((item) => [
      item.name.toLowerCase(),
      item.overallReport.totalView,
      ,
    ]);
    setUserMapData(userData);
    const data = flipCardData?.devices?.map((item) => {
      return {
        name: item.name,
        totalView: item.overallReport.totalView,
      };
    });
    setFirstChartData(data);
    const data2 = flipCardData?.countries?.map((item) => {
      const name = countryCodes.find((country) => country.code === item.name);
      return {
        name: name?.name,
        actionTake: item?.overallReport?.actionTake,
        engagementTake: item?.overallReport?.engagementTake,
        totalView: item?.overallReport?.totalView,
      };
    });
    setSecondChartData(data2);
  }, [flipCardData]);
  const mapOptions = {
    title: {
      text: "",
    },
    colorAxis: {
      min: 0,
      stops: [
        [0.4, "#8c98e3"],
        [0.65, "#4b5bbb"],
        [1, "#001493"],
      ],
    },
    mapNavigation: {
      enabled: true,
      enableDoubleClickZoomTo: true,
      buttonOptions: {
        verticalAlign: "bottom",
      },
    },

    series: [
      {
        mapData,
        name: "World",
        data: userMapData,
      },
    ],
  };

  return (
    <Row gutter={[20, 20]} style={{ marginTop: "20px" }}>
      <Col {...{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}>
        <Row>
          <Col span={24}>
            <h2>What are your top devices?</h2>
          </Col>
        </Row>
        {isLoading ? (
          <Skeleton.Button
            active={true}
            block={true}
            style={{
              height: "380px",
              borderRadius: "var(--input-Border-Radius3)",
              marginTop: "10px",
            }}
          />
        ) : (
          <Row
            style={{
              marginTop: "10px",
              backgroundColor: "#fff",
              padding: "10px",
              borderRadius: "var(--input-Border-Radius3)",
            }}
          >
            <Col span={24}>
              <h3 style={{ marginLeft: "10px" }}>Sessions by device</h3>
            </Col>
            <Col span={24} style={{ height: "270px" }}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Legend
                    layout="vertical"
                    verticalAlign="middle"
                    align="right"
                  />
                  <Pie
                    data={firstChartData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    innerRadius={20}
                    outerRadius={100}
                    dataKey="totalView"
                  >
                    {firstChartData?.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Col>
            <Col span={24} style={{ marginTop: "10px" }}>
              <Row
                style={{ width: "100%", opacity: "0.7", textAlign: "center" }}
                align="middle"
                justify="space-around"
              >
                {firstChartData?.map((data, index) => {
                  return (
                    <Col
                      key={index}
                      style={{ width: "100%", maxWidth: "100px" }}
                    >
                      <p>{data?.name}</p>
                      <h2>{data?.totalView}</h2>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        )}
      </Col>
      <Col {...{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}>
        <Row>
          <Col span={24}>
            <h2>When do your users interact?</h2>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "10px",
            backgroundColor: "#fff",
            padding: "10px",
            borderRadius: "var(--input-Border-Radius3)",
          }}
        >
          <Col style={{ height: "360px" }}></Col>
        </Row>
      </Col>
      <Col {...{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}>
        <Row>
          <Col span={24}>
            <h2>Where are your users?</h2>
          </Col>
        </Row>
        {isLoading ? (
          <Skeleton.Button
            active={true}
            block={true}
            style={{
              height: "100%",
              minHeight: "400px",
              borderRadius: "var(--input-Border-Radius3)",
              marginTop: "10px",
            }}
          />
        ) : (
          <Row
            style={{
              marginTop: "10px",
              backgroundColor: "#fff",
              padding: "10px",
              borderRadius: "var(--input-Border-Radius3)",
            }}
          >
            <Col
              {...{ xs: 24, sm: 24, md: 24, lg: 12, xl: 12 }}
              style={{ height: "400px" }}
            >
              <div>
                <HighchartsReact
                  options={mapOptions}
                  constructorType={"mapChart"}
                  highcharts={Highcharts}
                />
              </div>
            </Col>
            <Col
              {...{ xs: 24, sm: 24, md: 24, lg: 12, xl: 12 }}
              style={{ height: "400px" }}
            >
              <ResponsiveContainer>
                <BarChart data={secondChartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="totalView"
                    stackId="a"
                    fill="var(--chart-Color-1)"
                  />
                  <Bar
                    dataKey="engagementTake"
                    stackId="a"
                    fill="var(--chart-Color-2)"
                  />
                  <Bar
                    dataKey="actionTake"
                    stackId="a"
                    fill="var(--chart-Color-3)"
                  />
                </BarChart>
              </ResponsiveContainer>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};
export default ThirdSection;
