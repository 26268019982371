import { Button, Col, Row, Select, Input } from "antd";
import React, { useState } from "react";
import SupportModal from "../ExtraComponents/Components/SupportModal";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setCompanyDetails } from "../../redux/Slices/OnBoarding/OnboardingSlice";
const { Option } = Select;

function CompanyDetails({ setToggleSections }) {
  const userData = useSelector((state) => state.OnBoard.companyDetails);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalType, setModalType] = useState("Support");

  const handleInputVal = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(setCompanyDetails({ ...userData, [name]: value }));
  };

  return (
    <>
      <Col
        {...{ xs: 24, sm: 24, md: 24, lg: 24, xl: 18 }}
        className="onBoardingMainDiv"
      >
        <Row>
          <Col span={24}>
            <Row justify="end" align="middle" style={{ width: "100%" }}>
              <h3>
                Having an issue ?{" "}
                <a
                  href="#"
                  onClick={() => {
                    setModalType("Support");
                    setVisible(true);
                  }}
                >
                  Get Help
                </a>
              </h3>
            </Row>
          </Col>

          <Col span={24} style={{ marginTop: "10px" }}>
            <h1>Organisation information</h1>
          </Col>

          <Col span={24} style={{ marginTop: "10px" }}>
            <p>
              We would love to know more about your organisation and its mission
              so we can serve you better
            </p>
          </Col>

          <Col span={24} style={{ marginTop: "10px" }}>
            <p
              style={{
                padding: "5px",
                backgroundColor: "#ffe3c7",
                color: "#884400",
                fontSize: "0.8rem",
                fontWeight: "500",
              }}
            >
              We are GDPR compliant and your data is only used for your account
              - it will not be shared with any 3rd party
            </p>
          </Col>

          <Row
            gutter={[15, 0]}
            style={{ marginTop: "var(--main-margin)", width: "100%" }}
            className="onBoardingCompanySection"
          >
            <Col {...{ xs: 24, sm: 24, md: 12, lg: 12 }}>
              <h4>Organisation Name*</h4>
              <Input
                className="inputBx"
                value={userData.name}
                name="name"
                onChange={handleInputVal}
              />
            </Col>
            <Col {...{ xs: 24, sm: 24, md: 12, lg: 12 }}>
              <h4>Your Role*</h4>
              <Input
                className="inputBx"
                value={userData.role}
                name="role"
                onChange={handleInputVal}
              />
            </Col>
            <Col {...{ xs: 24, sm: 24, md: 12, lg: 12 }}>
              <h4>Number of employees</h4>
              <Select
                className="antdSelect"
                size="large"
                bordered={false}
                onChange={(e) => {
                  dispatch(
                    setCompanyDetails({ ...userData, numberOfEmployees: e })
                  );
                }}
                value={userData.numberOfEmployees}
              >
                <Option value={0}>1 - 10</Option>
                <Option value={1}>10 - 100</Option>
                <Option value={2}>10 - 1000</Option>
                <Option value={3}>+1000</Option>
              </Select>
            </Col>
            <Col {...{ xs: 24, sm: 24, md: 12, lg: 12 }}>
              <h4>Content management system</h4>
              <Input
                className="inputBx"
                value={userData.managementSystem}
                onChange={handleInputVal}
                name="managementSystem"
              />
            </Col>
            <Col span={24}>
              <h4>Organisation description</h4>
              <Input.TextArea
                value={userData.description}
                onChange={handleInputVal}
                style={{
                  borderRadius: "var(--input-Border-Radius3)",
                  height: "127px",
                }}
                name="description"
              ></Input.TextArea>
            </Col>
          </Row>

          <Row gutter={[0, 20]} style={{ width: "100%", marginTop: "30px" }}>
            <Col {...{ xs: 24, sm: 12, md: 12, lg: 12 }}>
              <Button
                className="buttonWhite opacity05"
                size="large"
                type="text"
                style={{ padding: "0px" }}
                onClick={() => {
                  setToggleSections("Industry");
                }}
              >
                BACK
              </Button>
            </Col>
            <Col {...{ xs: 24, sm: 12, md: 12, lg: 12 }}>
              <Row justify="end" align="middle" style={{ width: "100%" }}>
                <Row
                  justify="end"
                  align="middle"
                  style={{ width: "100%", maxWidth: "180px" }}
                >
                  <Button
                    onClick={() => {
                      setToggleSections("Business Goals");
                    }}
                    className="button"
                    type="primary"
                    size="large"
                  >
                    NEXT
                    <ArrowRightOutlined />
                  </Button>
                </Row>
              </Row>
            </Col>
          </Row>
        </Row>
      </Col>
      <SupportModal
        visible={visible}
        confirmLoading={confirmLoading}
        setConfirmLoading={setConfirmLoading}
        setVisible={setVisible}
        modalType={modalType}
      />
    </>
  );
}

export default CompanyDetails;
