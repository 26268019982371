import { createSlice } from "@reduxjs/toolkit";
import SignAPetitionInitialState from "../../../../Initial States/Actions/SignAPetitionInitialState";

const ActSignAPetitionSlice = createSlice({
  name: "ActSignAPetition",
  initialState: {
    action: SignAPetitionInitialState,
  },
  reducers: {
    setActSignAPetition: (state, action) => {
      state.action = action.payload;
    },
  },
});

export const { setActSignAPetition } = ActSignAPetitionSlice.actions;
export default ActSignAPetitionSlice.reducer;
