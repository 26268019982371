import { Col, Row, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currEngSetter } from "../../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
import totalEngagementsArray from "../../../ExtraComponents/totalEngagementsArray";
import Poll from "../Engagements/SliderPoll";
import FactCheck from "../Engagements/FactCheck";
import Quiz from "../Engagements/Quiz";
const { Option } = Select;
function NewEng() {
  const dispatch = useDispatch();
  const currEng = useSelector((state) => state.FlipCardEtc.currEng);
  const pathname = window.location.href.split("/")[3];

  const section = () => {
    switch (currEng) {
      case "sliderPoll":
        return <Poll />;
      case "factcheck":
        return <FactCheck />;
      case "quiz":
        return <Quiz />;
      default:
        return <Poll />;
    }
  };

  return (
    <>
      <Col span={24}>
        <Row gutter={[0, 15]} align="middle" justify="space-between">
          <Col {...{ xs: 24, sm: 24, md: 18, lg: 18, xl: 18 }}>
            <h2>Choose how you would like to engage</h2>
            <p>Select an engagement type relevant to your action</p>
          </Col>
          <Col {...{ xs: 8, sm: 8, md: 5, lg: 5, xl: 5 }}>
            <Select
              className="antdSelect"
              size="large"
              bordered={false}
              defaultValue={currEng}
              value={currEng}
              disabled={
                pathname === "templateedit" || pathname == "flipcardedit"
              }
              onChange={(e) => {
                dispatch(currEngSetter(e));
              }}
            >
              {totalEngagementsArray.map((data, index) => {
                return (
                  <Option key={index} value={data.value}>
                    {data.icon} {data.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>
      </Col>
      <Col
        span={24}
        style={{
          marginTop: "var(--sub-margin)",
        }}
      >
        {section()}
      </Col>
    </>
  );
}

export default NewEng;
