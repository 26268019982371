import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Col, message, Modal, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  useFlipcardActionInventoriesPostMutation,
  useUploadImageMutation,
} from "../../../../../Services/service";
import isValidHttpUrl from "../../../../ExtraComponents/isValidHttpUrl";
import NewAction from "../NewAction";
function NewActionModal({ isModalVisible, handleCancel }) {
  const adminFlipCardData = useSelector((state) => state.AdminFlipCard);
  const [isLoading, setIsLoading] = useState();
  const [_actionInventories] = useFlipcardActionInventoriesPostMutation();
  const [_uploadImage] = useUploadImageMutation();
  const submitHandler = async () => {
    const { actionObject } = adminFlipCardData;
    let inventory = {};
    setIsLoading(true);
    try {
      const inventoryResponse = await fetch(
        "https://inventorydetails.bridged.media/api/project",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            summary: actionObject?.description,
          }),
        }
      );
      const inventoryResponseData = await inventoryResponse.json();
      const { SDGs, topics, hashtags } = inventoryResponseData;
      inventory = {
        title: "",
        SDGs,
        topics,
        hashtags,
      };

      let userData = {};
      if (isValidHttpUrl(actionObject?.imageURL)) {
        userData = { ...actionObject, _id: undefined, inventory };
      } else {
        let formData = new FormData();
        formData.append("file", actionObject?.imageURL);
        let imageSrc = await _uploadImage(formData);
        userData = {
          ...actionObject,
          imageURL: imageSrc?.data?.url,
          _id: undefined,
          inventory,
        };
      }

      let response = await _actionInventories(userData);
      if (response?.data) {
        handleCancel();
        message.success("Action Requested Successfully");
      } else {
        message.error("Something went wrong");
      }

      setIsLoading(false);
    } catch (error) {}
  };

  const shouldActSecChange = useSelector(
    (state) => state.FlipCardEtc.shouldActSecChange
  );

  return (
    <Modal
      visible={isModalVisible}
      onCancel={handleCancel}
      width={700}
      footer={null}
      className="flipcardNewActionModal"
    >
      <>
        <Row>
          <Col
            span={24}
            style={{
              marginTop: "var(--main-margin)",
            }}
          >
            <NewAction />
          </Col>
          <Col
            span={24}
            style={{
              marginTop: "var(--main-margin)",
            }}
          >
            <Row justify="end" align="middle">
              <Button
                size="large"
                className="button"
                type="primary"
                disabled={!shouldActSecChange}
                loading={isLoading}
                onClick={submitHandler}
              >
                Next <ArrowRightOutlined />
              </Button>
            </Row>
          </Col>
        </Row>
      </>
    </Modal>
  );
}

export default NewActionModal;
