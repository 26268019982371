import { DownloadOutlined, DownOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Popover,
  Row,
  Skeleton,
} from "antd";
import moment from "moment";
import { saveSvgAsPng } from "../../../node_modules/save-svg-as-png/lib/saveSvgAsPng";
import { useDispatch, useSelector } from "react-redux";
import EngagementChart from "./EngagementChart";
import ActionChart from "./ActionChart";
import { setFilterData } from "../../redux/Slices/Overview/NewFlipcardOverviewSlice";
const { RangePicker } = DatePicker;
function RightSection({ setfilterDate }) {
  const dispatch = useDispatch();
  const etcData = useSelector(
    (state) => state.NewFlipcardOverview.flipcardOverviewDataStatus
  );
  const filterData = useSelector(
    (state) => state.NewFlipcardOverview.filterData
  );
  const { isLoading, isError } = etcData;
  const [chartSection, setChartSection] = useState("engagement");
  const section = () => {
    switch (chartSection) {
      case "engagement":
        return <EngagementChart />;
      case "action":
        return <ActionChart />;
      default:
        return <EngagementChart />;
    }
  };

  const chartDownloadHandler = () => {
    const chart = document.getElementsByClassName("recharts-surface")[0];
    if (chartSection === "engagement") {
      saveSvgAsPng(chart, "Bridged Engagement Chart.png");
    } else {
      saveSvgAsPng(chart, "Bridged Action Chart.png");
    }
  };

  // ---------------- Date Logic ----------------

  useEffect(() => {
    dateSetter();
  }, [filterData]);

  const dateFilterHandler = (e) => {
    dispatch(setFilterData(e.target.value));
  };

  const dateSetter = (e) => {
    if (filterData === 7) {
      setfilterDate({
        startDate: moment().utc().endOf("day"),
        endDate: moment().utc().add(-6, "days").startOf("day"),
      });
    } else if (filterData === 28) {
      setfilterDate({
        startDate: moment().utc().endOf("day"),
        endDate: moment().utc().add(-27, "days").startOf("day"),
      });
    } else if (filterData === 90) {
      setfilterDate({
        startDate: moment().utc().endOf("day"),
        endDate: moment().utc().add(-89, "days").startOf("day"),
      });
    } else if (filterData === "custom" && e) {
      setfilterDate({
        startDate: e[1],
        endDate: e[0],
      });
    }
  };

  const datePopoverContent = (
    <Row
      gutter={[0, 10]}
      style={{
        width: "100%",
        maxWidth: "200px",
      }}
    >
      <Col span={24}>
        <Checkbox
          checked={filterData === 7}
          onChange={dateFilterHandler}
          value={7}
        >
          Past 7 days
        </Checkbox>
      </Col>
      <Col span={24}>
        <Checkbox
          checked={filterData === 28}
          onChange={dateFilterHandler}
          value={28}
        >
          past 28 days
        </Checkbox>
      </Col>
      <Col span={24}>
        <Checkbox
          checked={filterData === 90}
          onChange={dateFilterHandler}
          value={90}
        >
          Past 90 days
        </Checkbox>
      </Col>
      <Col span={24}>
        <Checkbox
          checked={filterData === "custom"}
          onChange={dateFilterHandler}
          value={"custom"}
        >
          Custom...
        </Checkbox>
      </Col>
      {filterData === "custom" ? (
        <Col span={24}>
          <RangePicker
            style={{ borderRadius: "var(--input-Border-Radius3)" }}
            onChange={(e) => {
              dateSetter(e);
            }}
          />
        </Col>
      ) : (
        ""
      )}
    </Row>
  );
  return (
    <Col {...{ xs: 24, sm: 24, md: 14, lg: 14, xl: 14 }}>
      <Row>
        <Col {...{ xs: 16, sm: 16, md: 12, lg: 12, xl: 12 }}>
          <Row
            justify="start"
            align="middle"
            style={{ width: "100%", height: "100%" }}
          >
            <h3
              style={{
                cursor: "pointer",
                height: "30px",
                borderBottom:
                  chartSection === "engagement"
                    ? "1px solid var(--main-Color)"
                    : "none",
                opacity: chartSection === "engagement" ? 1 : 0.5,
              }}
              onClick={() => setChartSection("engagement")}
            >
              Engagement
            </h3>
            <h3
              style={{
                cursor: "pointer",
                height: "30px",
                marginLeft: "var(--main-margin)",
                borderBottom:
                  chartSection === "action"
                    ? "1px solid var(--main-Color)"
                    : "none",
                opacity: chartSection === "action" ? 1 : 0.5,
              }}
              onClick={() => setChartSection("action")}
            >
              Action
            </h3>
          </Row>
        </Col>
        <Col {...{ xs: 8, sm: 8, md: 12, lg: 12, xl: 12 }}>
          <Row justify="end" align="middle">
            <>
              {isLoading ? (
                <>
                  <Skeleton.Button
                    style={{
                      width: "150px",
                      borderRadius: "var(--input-Border-Radius3)",
                    }}
                  />
                </>
              ) : (
                <Popover
                  placement="bottomLeft"
                  content={datePopoverContent}
                  trigger="click"
                >
                  <Button
                    size="large"
                    type="link"
                    className="button"
                    disabled={isError}
                  >
                    Custom Date <DownOutlined />
                  </Button>
                </Popover>
              )}
            </>
            <Button
              size="large"
              type="text"
              style={{ marginLeft: "10px", padding: "0px" }}
              onClick={() => {
                chartDownloadHandler();
              }}
            >
              <DownloadOutlined />
            </Button>
          </Row>
        </Col>
      </Row>
      <Row
        style={{
          width: "100%",
          marginTop: "10px",
          height: "100%",
          minHeight: "400px",
        }}
      >
        {isLoading ? (
          <>
            <Skeleton.Button
              active={true}
              block={true}
              style={{
                height: "100%",
                borderRadius: "var(--input-Border-Radius3)",
              }}
            />
          </>
        ) : (
          section()
        )}
      </Row>
      {/* <Row style={{ width: "100%", marginTop: "var(--sub-margin)" }}></Row> */}
    </Col>
  );
}

export default RightSection;
