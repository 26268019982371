import { createSlice } from "@reduxjs/toolkit";
import FactCheckInitialState from "../../../../Initial States/Engagements/FactCheckInitialState";

const EngFactCheckSlice = createSlice({
  name: "EngFactCheck",
  initialState: {
    engagement: FactCheckInitialState,
  },
  reducers: {
    setEngFactCheck: (state, action) => {
      state.engagement = action.payload;
    },
  },
});

export const { setEngFactCheck } = EngFactCheckSlice.actions;
export default EngFactCheckSlice.reducer;
