import { Col, Row, Button, Input, message } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { useVerifyForgotPasswordCodeMutation } from "../../Services/service";
import "./LoginSignup.css";
import Sidebar from "./Sidebar";
function ForgotPassword() {
  const history = useHistory();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    forgotCode: "",
    newPassword: "",
  });
  const { email } = useParams();

  useEffect(() => {
    setUserData({ ...userData, email: email });
  }, []);

  const HandleOnChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };
  const [_verifyForgetCode] = useVerifyForgotPasswordCodeMutation();
  const handleForgotPassword = async () => {
    setSubmitLoading(true);
    if (!userData.email || !userData.forgotCode || !userData.newPassword) {
      message.error("Please fill all the fields");
      setSubmitLoading(false);
    } else if (
      userData.newPassword.length < 8 &&
      userData.newPassword.length < 8
    ) {
      message.error("Password must be atleast 8 characters");
      setSubmitLoading(false);
    } else {
      const response = await _verifyForgetCode(userData);
 
      if (response?.error?.status === 401) {
        message.error("Invalid Verification Code");
        setSubmitLoading(false);
      } else if (response?.data.email) {
        message.success("Password Changed Successfully");
        setSubmitLoading(false);
        history.push("/login");
      } else {
        message.error("Something went wrong");
        setSubmitLoading(false);
      }
    }
  };
  return (
    <div className="LSParent">
      <Row className="LSCard">
        <Sidebar
          content={
            "Bridging the gap between empathy in your content and social action"
          }
        />
        <Col
          {...{ xs: 24, sm: 24, md: 24, lg: 24, xl: 16 }}
          className="LSMainDiv"
        >
          <Row
            justify="center"
            align="middle"
            style={{ width: "100%", height: "100%" }}
          >
            <Row>
              <Col span={24} style={{ marginTop: "10px" }}>
                <h1>Forgot Password</h1>
              </Col>
              <Col
                {...{ xs: 24, sm: 24, md: 20, lg: 20 }}
                style={{ marginTop: "10px" }}
              >
                <h2>
                  Please enter five digit code that is sent to{" "}
                  <h2 style={{ color: "var(--secondary-Text-Color)" }}>
                    {email}
                  </h2>
                </h2>
              </Col>
              <Row
                gutter={[20, 20]}
                style={{ marginTop: "20px", width: "100%" }}
              >
                <Col {...{ xs: 24, sm: 24, md: 24, lg: 24 }}>
                  <Row gutter={[10, 20]}>
                    <Col {...{ xs: 24, sm: 24, md: 12, lg: 12 }}>
                      <h4>Verification Code</h4>
                      <Input
                        style={{ marginTop: "5px" }}
                        type="number"
                        onChange={HandleOnChange}
                        name="forgotCode"
                        className="inputBx"
                      />
                    </Col>
                    <Col {...{ xs: 24, sm: 24, md: 12, lg: 12 }}>
                      <h4>New Password</h4>
                      <Input
                        style={{ marginTop: "5px" }}
                        type="password"
                        onChange={HandleOnChange}
                        name="newPassword"
                        className="inputBx"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Col
                {...{ xs: 24, sm: 24, md: 12, lg: 12 }}
                style={{ marginTop: "20px" }}
              >
                <Button
                  className="button"
                  type="primary"
                  loading={submitLoading}
                  size="large"
                  onClick={handleForgotPassword}
                  style={{
                    paddingRight: "20px",
                    paddingLeft: "20px",
                    fontWeight: "600",
                  }}
                >
                  Reset password
                </Button>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default ForgotPassword;
