export default {
  title: "Link Title",
  description: "Link Description",
  imageURL: "",
  actionData: {
    actionType: "link",
    buttonText: "",
    buttonLink: "",
  },
};
