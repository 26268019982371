import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Input, Form, Button, Upload, message } from "antd";
import { setActSignAPetition } from "../../../../redux/Slices/Flip Cards/Actions/ActSignAPetitionSlice";
import { AdminActionObject } from "../../../../redux/Slices/Flip Cards/AdminFlipCardSlice";
import { shouldActSecChangeSetter } from "../../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
import isValidHttpUrl from "../../../ExtraComponents/isValidHttpUrl";

import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { isImage } from "../../../ExtraComponents/isImage";
import ActionsTTI from "../../../ExtraComponents/Components/ActionsTTI";
function SignAPetition() {
  const [isPetitionValid, setIsPetitionValid] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.ActSignAPetition.action);

  // Pushing Code to Redux Store
  useEffect(() => {
    dispatch(setActSignAPetition(userData));
    dispatch(AdminActionObject(userData));
  }, []);

  // Checking if the required Fields are filled or not
  useEffect(() => {
    if (
      userData.title == "" ||
      userData.description == "" ||
      userData.actionData.petitionId == "" ||
      userData.imageURL == "" ||
      !isPetitionValid
    ) {
      dispatch(shouldActSecChangeSetter(false));
    } else {
      dispatch(shouldActSecChangeSetter(true));
    }
  }, [userData, isPetitionValid]);

  // Uploading Image
  const uploadImage = async (file) => {
    const fileType = file?.type.substring(file?.type.indexOf("/") + 1);
    const sizeofImg = file?.size;
    if (!(fileType === "jpeg" || fileType === "png" || fileType === "jpg")) {
      return message.error("Image type should be jpeg,png,jpg");
    }
    if (sizeofImg >= 1500000) {
      return message.error("Image size should be less than 1.5MB");
    }

    if (file?.status == "removed") {
      file = "";
    }
    let sentData = { ...userData, imageURL: file };
    dispatch(setActSignAPetition(sentData));
    dispatch(AdminActionObject(sentData));
  };

  useEffect(() => {
    if (userData.actionData.petitionId) {
      checkPetitionStatus();
    }
  }, [userData.actionData.petitionId]);

  const checkPetitionStatus = async () => {
    setIsPetitionValid(false);
    try {
      const response = await fetch(
        `https://petition.parliament.uk/petitions/${userData.actionData.petitionId}.json`
      );
      const data = await response.json();
      const status = data?.data?.attributes?.state;
      if (status) {
        message.info(`Petition is ${status}`);
        if (status === "open") {
          setIsPetitionValid(true);
        } else {
          setIsPetitionValid(false);
        }
      }
    } catch (error) {
      message.info(`Petition is Invalid`);
      setIsPetitionValid(false);
    }
  };

  // onChange Handler for all the fields
  const onChangeHandler = (e) => {
    let sentData = { ...userData };
    const { name, value } = e.target;
    if (name === "petitionId") {
      if (value <= 0) {
        setIsPetitionValid(false);
      }
      sentData = {
        ...sentData,
        actionData: {
          ...sentData.actionData,
          petitionId: value,
        },
      };
    } else {
      sentData = { ...sentData, [name]: value };
    }
    dispatch(setActSignAPetition(sentData));
    dispatch(AdminActionObject(sentData));
  };

  return (
    <Col span={24}>
      <Row style={{ width: "100%", marginTop: "var(--sub-margin)" }}>
        <Col span={24}>
          <Form.Item label="Title *">
            <Input
              type="text"
              placeholder="Petition Title"
              className="inputBx"
              value={userData.title}
              name="title"
              onChange={onChangeHandler}
            />
          </Form.Item>
          <Form.Item label="Description *">
            <Input
              type="text"
              placeholder="Petition Description"
              className="inputBx"
              value={userData.description}
              name="description"
              onChange={onChangeHandler}
            />
          </Form.Item>
          <Form.Item label="Petition Id *">
            <Input
              type="number"
              placeholder="Petition Id"
              className="inputBx"
              value={userData.actionData.petitionId}
              name="petitionId"
              onChange={onChangeHandler}
            />
          </Form.Item>
          <Form.Item label="Image * (maximum mage size should be 1.5MB and file format should be jpeg, png, jpg)">
            <Upload
              listType="picture-card"
              name="file"
              maxCount={1}
              onPreview={() => false}
              onChange={(e) => {
                uploadImage(e?.file);
              }}
              beforeUpload={() => false}
            >
              {isImage(userData?.imageURL) ? null : <PlusOutlined />}
            </Upload>
            {isValidHttpUrl(userData?.imageURL) && (
              <img
                src={userData?.imageURL}
                style={{
                  width: "100%",
                  height: "100%",
                  maxWidth: "200px",
                  marginTop: "var(--sub-margin)",
                  marginBottom: "var(--sub-margin)",
                }}
              />
            )}
          </Form.Item>
          <ActionsTTI />
        </Col>
      </Row>
    </Col>
  );
}

export default SignAPetition;
