import React from "react";
import { Button, Col, Form, Modal, Row, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import MessageBox from "../Actions/MessageBox";
import totalActionsArray from "../../../ExtraComponents/totalActionsArray";
import { currActionSetter } from "../../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
import SignAPetition from "../Actions/SignAPetition";
import NewsLetterSignup from "../Actions/NewsLetterSignup";
import Donation from "../Actions/Donation";
import Link from "../Actions/Link";
import DynamicForm from "../Actions/DynamicForm";
const { Option } = Select;
function NewAction() {
  const pathname = window.location.href.split("/")[3];

  const dispatch = useDispatch();
  const currAction = useSelector((state) => state.FlipCardEtc.currAction);
  const section = () => {
    switch (currAction) {
      case "messageBox":
        return <MessageBox />;
      case "signAPetition":
        return <SignAPetition />;
      case "newsLetterSignup":
        return <NewsLetterSignup />;
      case "donation":
        return <Donation />;
      case "link":
        return <Link />;
      case "dynamicForm":
        return <DynamicForm />;
      default:
        return <MessageBox />;
    }
  };

  return (
    <Form layout="vertical">
      <Row>
        <Col span={24}>
          <Row gutter={[0, 15]} align="middle" justify="space-between">
            <Col {...{ xs: 24, sm: 24, md: 16, lg: 16, xl: 16 }}>
              <h2>Action Type</h2>
              <p>Select an action type relevant to your engagement</p>
            </Col>
            <Col {...{ xs: 13, sm: 13, md: 7, lg: 7, xl: 7 }}>
              <Select
                className="antdSelect"
                size="large"
                bordered={false}
                defaultValue={currAction}
                value={currAction}
                disabled={pathname === "templateedit"}
                onChange={(e) => {
                  dispatch(currActionSetter(e));
                }}
              >
                {totalActionsArray.map((data, index) => {
                  return (
                    <Option key={index} value={data.value}>
                      {data.icon} {data.name}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          style={{
            marginTop: "var(--sub-margin)",
          }}
        >
          {section()}
        </Col>
      </Row>
    </Form>
  );
}

export default NewAction;
