import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, message, Row, Upload } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DynamicFormInitialState from "../../../../Initial States/Actions/DynamicFormInitialState";
import { setActDynamicForm } from "../../../../redux/Slices/Flip Cards/Actions/ActDynamicFormSlice";
import { AdminActionObject } from "../../../../redux/Slices/Flip Cards/AdminFlipCardSlice";
import {
  dynamicFormNumberOfIndexesSetter,
  shouldActSecChangeSetter,
} from "../../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
import ActionsTTI from "../../../ExtraComponents/Components/ActionsTTI";
import { isImage } from "../../../ExtraComponents/isImage";
import isValidHttpUrl from "../../../ExtraComponents/isValidHttpUrl";
import DynamicFormFields from "../EtcComponents/DynamicFormFields";

function DynamicForm() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.ActDynamicForm.action);
  const noOfFields = useSelector(
    (state) => state.FlipCardEtc.dynamicFormNumberOfIndexes
  );

  // Pushing Code to Redux Store
  useEffect(() => {
    dispatch(setActDynamicForm(userData));
    dispatch(AdminActionObject(userData));
  }, []);

  // Checking if the required Fields are filled or not
  useEffect(() => {
    if (
      userData.title == "" ||
      userData.description == "" ||
      userData.imageURL == ""
    ) {
      dispatch(shouldActSecChangeSetter(false));
    } else {
      dispatch(shouldActSecChangeSetter(true));
    }
  }, [userData]);

  // Uploading Image
  const uploadImage = async (file) => {
    const fileType = file?.type.substring(file?.type.indexOf("/") + 1);
    const sizeofImg = file?.size;
    if (!(fileType === "jpeg" || fileType === "png" || fileType === "jpg")) {
      return message.error("Image type should be jpeg,png,jpg");
    }
    if (sizeofImg >= 1500000) {
      return message.error("Image size should be less than 1.5MB");
    }

    if (file?.status == "removed") {
      file = "";
    }
    let sentData = { ...userData, imageURL: file };
    dispatch(setActDynamicForm(sentData));
    dispatch(AdminActionObject(sentData));
  };

  // onChange Handler for Inputs
  const onChangeHandler = (e) => {
    let sentData = { ...userData };
    const { name, value } = e.target;

    sentData = { ...sentData, [name]: value };
    dispatch(setActDynamicForm(sentData));
    dispatch(AdminActionObject(sentData));
  };

  const addNewFieldHandler = () => {
    let fieldsData = [...userData.actionData.fields];
    fieldsData.push(DynamicFormInitialState.actionData.fields[0]);
    let sentData = {
      ...userData,
      actionData: {
        ...userData.actionData,
        fields: fieldsData,
      },
    };

    dispatch(setActDynamicForm(sentData));
    dispatch(AdminActionObject(sentData));
    dispatch(dynamicFormNumberOfIndexesSetter(noOfFields + 1));
  };

  return (
    <Col span={24}>
      <Row style={{ width: "100%", marginTop: "var(--sub-margin)" }}>
        <Col span={24}>
          <Form.Item label="Title *">
            <Input
              type="text"
              placeholder="Link Title"
              className="inputBx"
              value={userData.title}
              name="title"
              onChange={onChangeHandler}
            />
          </Form.Item>
          <Form.Item label="Description *">
            <Input
              type="text"
              placeholder="Link Description"
              className="inputBx"
              value={userData.description}
              name="description"
              onChange={onChangeHandler}
            />
          </Form.Item>
          <Row
            gutter={[10, 0]}
            style={{
              marginTop: "var(--main-margin)",
            }}
          >
            {[...Array(noOfFields + 1).keys()].map((data, index) => {
              return <DynamicFormFields userData={userData} index={index} />;
            })}
          </Row>
          {noOfFields < 8 ? (
            <Button
              size="large"
              className="button"
              type="dashed"
              onClick={addNewFieldHandler}
              style={{
                width: "100%",
                height: "150px",
                marginBottom: "var(--main-margin)",
              }}
            >
              Add new field
            </Button>
          ) : null}

          <Form.Item label="Image * (maximum mage size should be 1.5MB and file format should be jpeg, png, jpg)">
            <Upload
              listType="picture-card"
              name="file"
              maxCount={1}
              onPreview={() => false}
              onChange={(e) => {
                uploadImage(e?.file);
              }}
              beforeUpload={() => false}
            >
              {isImage(userData?.imageURL) ? null : <PlusOutlined />}
            </Upload>
            {isValidHttpUrl(userData?.imageURL) && (
              <img
                src={userData?.imageURL}
                style={{
                  width: "100%",
                  height: "100%",
                  maxWidth: "200px",
                  marginTop: "var(--sub-margin)",
                  marginBottom: "var(--sub-margin)",
                }}
              />
            )}
          </Form.Item>
          <ActionsTTI />
        </Col>
      </Row>
    </Col>
  );
}

export default DynamicForm;
