import { createSlice } from "@reduxjs/toolkit";

const OnboardingSlice = createSlice({
  name: "Onboarding",
  initialState: {
    userType: "",
    topics: {
      contentCoverage: "",
      SDGs: [],
    },
    goals: {
      mainGoal: "",
      heardAboutBridged: [],
    },
    personalInformation: {
      fullname: "",
      logoURL: "",
    },
    companyDetails: {
      name: "",
      numberOfEmployees: 0,
      managementSystem: "",
      description: "",
      role: "",
    },
  },
  reducers: {
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    setTopics: (state, action) => {
      state.topics = action.payload;
    },
    setGoals: (state, action) => {
      state.goals = action.payload;
    },
    setCompanyDetails: (state, action) => {
      state.companyDetails = action.payload;
    },
    setPersonalInformation: (state, action) => {
      state.personalInformation = action.payload;
    },
  },
});

export const {
  setUserType,
  setTopics,
  setCompanyDetails,
  setGoals,
  setPersonalInformation,
} = OnboardingSlice.actions;
export default OnboardingSlice.reducer;
