import {
  Button,
  Col,
  Form,
  Input,
  message,
  Pagination,
  Row,
  Select,
  Skeleton,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActMessageBox } from "../../../../../redux/Slices/Flip Cards/Actions/ActMessageBoxSlice";
import {
  currActionSetter,
  dynamicFormNumberOfIndexesSetter,
  flipcardSDGSSetter,
} from "../../../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
import {
  FlipCardaction,
  FlipcardActionPage,
} from "../../../../../redux/Slices/Flip Cards/FlipCardSlice";
import { useFlipcardActionInventoriesQuery } from "../../../../../Services/service";
import totalActionsArray from "../../../../ExtraComponents/totalActionsArray";
import actionIconFinder from "../../../../ExtraComponents/actionIconFinder";
import { useDebounce } from "use-debounce";

import { setActSignAPetition } from "../../../../../redux/Slices/Flip Cards/Actions/ActSignAPetitionSlice";
import { setActDonation } from "../../../../../redux/Slices/Flip Cards/Actions/ActDonationSlice";
import { CloseOutlined } from "@ant-design/icons";
import { setActNewsLetterSignUp } from "../../../../../redux/Slices/Flip Cards/Actions/ActNewsLetterSignUpSlice";
import { setActLink } from "../../../../../redux/Slices/Flip Cards/Actions/ActLinkSlice";
import { setActDynamicForm } from "../../../../../redux/Slices/Flip Cards/Actions/ActDynamicFormSlice";
import { AdminActionObject } from "../../../../../redux/Slices/Flip Cards/AdminFlipCardSlice";
const { Option } = Select;
function SuggestedActions({
  iscreateNewAction,
  setCollapseActive,
  setInventory,
}) {
  const pathname = window.location.href.split("/")[3];
  const currAction = useSelector((state) => state.FlipCardEtc.currAction);
  const [currentPagination, setCurrentPagination] = useState(1);
  const dispatch = useDispatch();
  const FlipCardData = useSelector((state) => state.flipCard);
  const [totalLength, setTotalLength] = useState(0);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [AllSelector, setAllSelector] = useState(true);
  const flipcardActions = useSelector((state) => state.flipCard.actions);
  const flipcardActionPage = useSelector((state) => state.flipCard.actionPage);
  const adminFlipCardData = useSelector((state) => state.AdminFlipCard);
  const [suggestedActionData, setSuggestedActionData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedText] = useDebounce(searchQuery, 500);
  const [isLoading, setIsLoading] = useState(false);
  const [actionSwitcher, setActionSwitcher] = useState("all");
  const [userData, setUserData] = useState({
    title: "Are you ready to take action?",
    description: "You can be part of the solution. Take action now.",
  });

  const {
    data,
    isSuccess,
    isLoading: isFlipcardActionInventoriesLoading,
  } = useFlipcardActionInventoriesQuery({
    currentPagination,
    actionSwitcher,
  });

  useEffect(() => {
    setIsLoading(isFlipcardActionInventoriesLoading);
  }, [isFlipcardActionInventoriesLoading]);

  // --------- Switching between all and selected actions -----------
  useEffect(() => {
    if (AllSelector) {
      setActionSwitcher("all");
    } else {
      setActionSwitcher(currAction);
    }
  }, [AllSelector, currAction]);

  useEffect(() => {
    pathname === "templateedit" && setAllSelector(false);
  }, []);

  useEffect(() => {
    setSuggestedActionData(data?.data);
    setTotalLength(data?.totalLength);
    setDefaultPageSize(10);
  }, [isFlipcardActionInventoriesLoading, data, isSuccess]);

  // Adds Data to the ActionPage in Flipcard
  useEffect(() => {
    dispatch(FlipcardActionPage(userData));
  }, [userData]);

  // Adds data to redux in FLipcard Actions Array
  const actionsClickHandler = (data) => {
    const actionIds = [...flipcardActions];

    const isInclude = flipcardActions.filter((item) => {
      return item._id == data._id;
    });
    if (isInclude.length > 0) {
      actionIds.splice(actionIds.indexOf(data), 1);
      dispatch(FlipCardaction(actionIds));
    } else {
      if (flipcardActions?.length >= 3) {
        message.warn("You can only select 3 actions");
      } else {
        dispatch(FlipCardaction([...flipcardActions, data]));
      }
    }
  };
  // Adds Data to redux as per Action
  const actionsClickHandlerNewAction = (data) => {
    const { title, description, imageURL, actionData, inventory, _id } = data;
    const actionType = actionData?.actionType;
    dispatch(currActionSetter(actionType));
    setCollapseActive(3);
    let userData = {
      title,
      description,
      imageURL,
      actionData: {
        ...actionData,
      },
      _id,
    };
    if (inventory) {
      setInventory(inventory);
    }
    if (actionType == "messageBox") {
      dispatch(setActMessageBox(userData));
    }
    if (actionType == "signAPetition") {
      dispatch(setActSignAPetition(userData));
    }
    if (actionType == "donation") {
      dispatch(setActDonation(userData));
    }
    if (actionType == "newsLetterSignup") {
      dispatch(setActNewsLetterSignUp(userData));
    }
    if (actionType == "link") {
      dispatch(setActLink(userData));
    }
    if (actionType == "dynamicForm") {
      dispatch(setActDynamicForm(userData));
      dispatch(
        dynamicFormNumberOfIndexesSetter(userData.actionData.fields.length - 1)
      );
    }
    dispatch(AdminActionObject(userData));
  };

  // Filters Data as per actionType and searchQuery
  const filterDataHandler = async (actionType) => {
    if (searchQuery.length > 0) {
      setIsLoading(true);
      const response = await fetch(
        "https://searchaction.news-snap.com/search",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            searchText: searchQuery,
            searchType: actionType == "All" ? "all" : actionType,
          }),
        }
      );
      const data = await response.json();
      setDisplayData(data?.matches);
      setDefaultPageSize(0);
      setTotalLength(0);
      setIsLoading(false);
    } else if (AllSelector && searchQuery.length == 0) {
      setDisplayData(data?.data);
      setTotalLength(data?.totalLength);
      setDefaultPageSize(10);
    } else {
      const filterByActionType = suggestedActionData?.filter((data) => {
        return data.actionData.actionType === currAction;
      });
      setDisplayData(filterByActionType);
    }
  };

  // Runs when search query, Action type and suggestedActionData changes
  useEffect(() => {
    filterDataHandler(actionSwitcher);
  }, [debouncedText, isSuccess, suggestedActionData, data]);

  // Setting SDGs for actions
  useEffect(() => {
    let sdgs = [];
    FlipCardData?.actions?.forEach((data) => {
      data.inventory.SDGs.forEach((inventorySdgs) => {
        sdgs.push(inventorySdgs);
      });
    });
    // Remove dubplicates from array of numbers
    const uniqueSdgs = [...new Set(sdgs)];
    dispatch(flipcardSDGSSetter(uniqueSdgs));
  }, [FlipCardData]);

  useEffect(() => {
    setUserData({ ...flipcardActionPage });
  }, []);

  const isActiveHandler = (data) => {
    const value = flipcardActions?.filter((item) => {
      return item._id == data._id;
    });
    if (value.length > 0) {
      return "primary";
    } else {
      return "default";
    }
  };

  const isActiveHandlerNewAction = (data) => {
    if (adminFlipCardData?.actionObject?._id == data?._id) {
      return "primary";
    } else {
      return "default";
    }
  };

  const isActiveSwitcher = (data) => {
    if (iscreateNewAction) {
      return isActiveHandlerNewAction(data);
    } else {
      return isActiveHandler(data);
    }
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <p className="flipcardContentPanelSubHeading">
            Look up the cause you care about and select from our wide range of
            curated actions
          </p>
        </Col>
        <Col
          span={24}
          style={{
            marginTop: "var(--sub-margin)",
          }}
        >
          <Row>
            <Col span={24}>
              <Form>
                <Form.Item label="Action Page Title *">
                  <Input
                    type="text"
                    value={userData.title}
                    onChange={(e) =>
                      setUserData({ ...userData, title: e.target.value })
                    }
                    placeholder="Action Page Title"
                    className="inputBx"
                  />
                </Form.Item>
                <Form.Item label="Action Page Description *">
                  <Input
                    type="text"
                    value={userData.description}
                    onChange={(e) =>
                      setUserData({ ...userData, description: e.target.value })
                    }
                    placeholder="Action Page Description"
                    className="inputBx"
                  />
                </Form.Item>
              </Form>
            </Col>
            <Col span={24}>
              <p>Selected Actions</p>
              <Row>
                {flipcardActions.length > 0 ? (
                  <>
                    {flipcardActions.map((item) => {
                      let title = item.title;
                      return (
                        <Button
                          type="default"
                          shape="round"
                          style={{
                            marginRight: "var(--sub-margin)",
                            marginTop: "var(--sub-margin)",
                            marginBottom: "var(--sub-margin)",
                          }}
                        >
                          {title.substring(0, 30)}
                          {title.length > 30 ? " ..." : ""}
                          <CloseOutlined
                            onClick={() => {
                              if (iscreateNewAction) {
                                actionsClickHandlerNewAction(item);
                              } else {
                                actionsClickHandler(item);
                              }
                            }}
                          />
                        </Button>
                      );
                    })}
                  </>
                ) : (
                  <Button
                    type="primary"
                    shape="round"
                    style={{
                      marginRight: "var(--sub-margin)",
                      marginTop: "var(--sub-margin)",
                      marginBottom: "var(--sub-margin)",
                    }}
                  >
                    No Actions Selected
                  </Button>
                )}
              </Row>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: "var(--sub-margin)",
            }}
            gutter={[0, 15]}
            align="middle"
            justify="space-between"
          >
            <Col {...{ xs: 24, sm: 24, md: 18, lg: 18, xl: 18 }}>
              <Input
                type="text"
                size="large"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="inputBx"
                placeholder="Search..."
              />
            </Col>
            <Col {...{ xs: 8, sm: 8, md: 5, lg: 5, xl: 5 }}>
              <Select
                className="antdSelect"
                size="large"
                bordered={false}
                defaultValue={currAction}
                value={AllSelector ? "All" : currAction}
                disabled={pathname === "templateedit"}
                onChange={(e) => {
                  if (e == "All") {
                    setAllSelector(true);
                  } else {
                    dispatch(currActionSetter(e));
                    setAllSelector(false);
                  }
                  filterDataHandler(e);
                }}
              >
                <Option value="All">All</Option>
                {totalActionsArray.map((data, index) => {
                  return (
                    <Option key={index} value={data.value}>
                      {data.icon} {data.name}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          style={{
            marginTop: "var(--sub-margin)",
          }}
        >
          <Row gutter={[0, 10]} className="PreviousDataMain">
            {isLoading ? (
              <>
                <Skeleton.Button
                  block={true}
                  active={true}
                  style={{
                    marginTop: "var(--sub-margin)",
                    marginBottom: "var(--sub-margin)",
                    height: "100px",
                    borderRadius: "var(--input-Border-Radius3)",
                  }}
                />
              </>
            ) : (
              <>
                {displayData?.map((data, index) => {
                  const { title, actionData } = data;
                  const actionType = actionData?.actionType;
                  const titleLength = data?.title;
                  return (
                    <Col
                      key={index}
                      span={24}
                      style={{
                        marginTop: "var(--sub-margin)",
                      }}
                    >
                      <Row
                        gutter={[0, 10]}
                        justify="space-between"
                        align="middle"
                      >
                        <Col>
                          <p>
                            <span
                              style={{
                                marginRight: "5px",
                              }}
                              className="opacity05"
                            >
                              <span
                                style={{
                                  marginLeft: "5px",
                                }}
                              >
                                {actionIconFinder(actionType)}
                              </span>
                            </span>
                            <Tooltip title={title}>
                              {title?.substring(0, 30)}
                              {titleLength?.length > 30 ? "..." : ""}
                            </Tooltip>
                          </p>
                        </Col>
                        <Col>
                          <Row align="middle">
                            <Button
                              onClick={() => {
                                if (iscreateNewAction) {
                                  actionsClickHandlerNewAction(data);
                                } else {
                                  actionsClickHandler(data);
                                }
                              }}
                              style={{
                                marginRight: "var(--sub-margin)",
                              }}
                              shape="round"
                              type={isActiveSwitcher(data)}
                            >
                              {isActiveSwitcher(data) === "primary"
                                ? "Deselect"
                                : "Select"}
                            </Button>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
                <Col
                  span={24}
                  style={{
                    marginTop: "var(--sub-margin)",
                    marginBottom: "var(--sub-margin)",
                  }}
                >
                  <Row align="middle" justify="end">
                    <Pagination
                      className="dashboardPagnaition"
                      size="small"
                      defaultPageSize={defaultPageSize}
                      total={totalLength}
                      current={currentPagination}
                      onChange={(page) => {
                        setCurrentPagination(page);
                      }}
                    />
                  </Row>
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default SuggestedActions;
