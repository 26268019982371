import { LoadingOutlined } from "@ant-design/icons";
import { Col, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { auth, roleSetter } from "../../redux/Slices/Auth/authSlice";
import { FlipCardaction } from "../../redux/Slices/Flip Cards/FlipCardSlice";
import { useWordpressAuthQuery } from "../../Services/service";

function ChromePlugin() {
  const flipcardActions = useSelector((state) => state.flipCard.actions);
  const [userData, setUserData] = useState({ auth: {}, obj: [], routing: "" });
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation().search;
  const hrefData = new URLSearchParams(search).get("data");

  useEffect(() => {
    if (hrefData) {
      let decryptedData = JSON.parse(atob(hrefData));
      setUserData(decryptedData);
    }
  }, [hrefData]);

  const { data, isSuccess, isLoading, isError } = useWordpressAuthQuery(
    userData?.auth?.API_KEY
  );

  useEffect(() => {
    if (isSuccess) {
      if (data) {
        localStorage.setItem("accessToken", data?.accessToken);
        localStorage.setItem("refreshToken", data?.refreshToken);
        message.success("Login Successful");
        dispatch(roleSetter(data?.role));

        setTimeout(() => {
          const actions = [...userData?.obj];
          dispatch(FlipCardaction(actions));
          dispatch(auth(true));
          history.push(userData?.routing, { from: "chromePlugin" });
        }, 500);
      }
      if (isError) {
        message.error("Login Failed");
      }
    }
  }, [data, isSuccess, isLoading]);

  return (
    <Row justify="center" align="center">
      <Col
        span={24}
        style={{
          marginTop: "60px",
        }}
      >
        <Row justify="center" align="center">
          <h1>Redirecting you to Bridged...</h1>
        </Row>
      </Col>
      <Col
        span={24}
        style={{
          marginTop: "10px",
        }}
      >
        <Row justify="center" align="center">
          <h1>
            <LoadingOutlined />
          </h1>
        </Row>
      </Col>
    </Row>
  );
}

export default ChromePlugin;
