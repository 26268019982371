import { Col, Row } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";

function Topbar() {
  return (
    <Row
      gutter={[0, 10]}
      style={{
        height: "auto",
        padding: "30px 25px 30px 25px",
        borderRadius: "var(--input-Border-Radius2)",
      }}
      className="gradientBackGroundDark"
      justify="space-between"
      align="center"
    >
      <Col {...{ xs: 24, sm: 17, md: 17 }}>
        <Row style={{ width: "100%" }}>
          <Col span={24}>
            <h1 style={{ color: "#ffff" }}>
              <NavLink to="/" style={{ color: "#ffff" }}>
                Dashboard
              </NavLink>{" "}
              {`>`} Create new Flip Card
            </h1>
          </Col>
          <Col span={24}>
            <p style={{ color: "#ffff" }}>
              Wondering where to start ? Watch the tutorial
            </p>
          </Col>
        </Row>
      </Col>
      <Col {...{ xs: 24, sm: 6, md: 6 }}>
        <Row
          style={{ width: "100%", height: "100%" }}
          align="middle"
          justify="end"
        >
          <button
            style={{
              width: "100%",
              maxWidth: "160px",
              padding: "10px",
              color: "#fff",
              backdropFilter: "blur(16px) saturate(180%)",
              backgroundColor: "rgba(255, 255, 255, 0.20)",
              borderRadius: "12px",
              fontSize: "1rem",
              border: "none",
              outline: "none",
              cursor: "pointer",
            }}
          >
            <i className="fas fa-play" style={{ marginRight: "5px" }}></i>
            Watch Tutorial
          </button>
        </Row>
      </Col>
    </Row>
  );
}

export default Topbar;
