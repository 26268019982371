import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import actionNameConverter from "../ExtraComponents/actionNameConverter";
function ActionChart() {
  const [userChartData, setUserChartData] = useState();

  const actionChart = useSelector(
    (state) => state.NewFlipcardOverview.flipcardOverviewData?.actionChart
  );

  useEffect(() => {
    let processedData = [];

    if (actionChart.length > 0) {
      const actionTypes = actionChart.map((data) => {
        return data.actionType;
      });

      processedData = actionChart[0].chart.map((data) => {
        const date = moment(data.date).format("YY-MM-DD");
        let result = {
          date,
        };
        for (var element of actionTypes) {
          result[element] = 0;
        }

        return result;
      });

      for (let actionData of actionChart) {
        const actionType = actionData.actionType;
        actionData.chart.forEach((chartData) => {
          const dateIndex = processedData.find(
            (item) => item.date == moment(chartData.date).format("YY-MM-DD")
          );
          dateIndex[actionType] += chartData.value;
        });
      }
      setUserChartData(processedData);
    }
  }, [actionChart]);
  return (
    <>
      <Col
        span={24}
        style={{
          height: "400px",
        }}
      >
        <ResponsiveContainer>
          <LineChart data={userChartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            {actionChart.map((data, index) => {
              return (
                <Line
                  type="monotone"
                  dataKey={data.actionType}
                  stroke={`var(--chart-Color-${index + 1})`}
                  activeDot={{ r: 8 }}
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      </Col>
    </>
  );
}

export default ActionChart;
