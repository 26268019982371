import { Col, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { useWordpressAuthQuery } from "../../Services/service";
import { useDispatch } from "react-redux";
import { auth } from "../../redux/Slices/Auth/authSlice";
function WordpressAuth() {
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation().search;
  const queryApiKey = new URLSearchParams(search).get("apiKey");

  const { data, isSuccess, isLoading, isError } =
    useWordpressAuthQuery(queryApiKey);

  useEffect(() => {
    if (isSuccess) {
      if (data) {
        localStorage.setItem("accessToken", data?.accessToken);
        localStorage.setItem("refreshToken", data?.refreshToken);
        message.success("Login Successful");
        setTimeout(() => {
          dispatch(auth(true));
          history.push("/");
        }, 500);
      }
      if (isError) {
        message.error("Login Failed");
      }
    }
  }, [data, isSuccess, isLoading]);

  return (
    <Row justify="center" align="center">
      <Col
        span={24}
        style={{
          marginTop: "60px",
        }}
      >
        <Row justify="center" align="center">
          <h1>Redirecting you to Bridged...</h1>
        </Row>
      </Col>
      <Col
        span={24}
        style={{
          marginTop: "10px",
        }}
      >
        <Row justify="center" align="center">
          <h1>
            <LoadingOutlined />
          </h1>
        </Row>
      </Col>
    </Row>
  );
}

export default WordpressAuth;
