import { Button, Card, Col, Input, Row, Select, Skeleton } from "antd";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { setActDonation } from "../../../redux/Slices/Flip Cards/Actions/ActDonationSlice";
import { setActLink } from "../../../redux/Slices/Flip Cards/Actions/ActLinkSlice";
import { setActMessageBox } from "../../../redux/Slices/Flip Cards/Actions/ActMessageBoxSlice";
import { setActNewsLetterSignUp } from "../../../redux/Slices/Flip Cards/Actions/ActNewsLetterSignUpSlice";
import { setActSignAPetition } from "../../../redux/Slices/Flip Cards/Actions/ActSignAPetitionSlice";
import { setActDynamicForm } from "../../../redux/Slices/Flip Cards/Actions/ActDynamicFormSlice";
import { currActionSetter, dynamicFormNumberOfIndexesSetter } from "../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
import actionIconFinder from "../../ExtraComponents/actionIconFinder";
import actionNameConverter from "../../ExtraComponents/actionNameConverter";
import totalActionsArray from "../../ExtraComponents/totalActionsArray";
const { Option } = Select;

function SuperAdminSchedularService() {
  const history = useHistory();
  const [updateImgId, setUpdateImgId] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [actions, setActions] = useState([]);
  const dispatch = useDispatch();
  const currAction = useSelector((state) => state.FlipCardEtc.currAction);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedText] = useDebounce(searchQuery, 500);

  // Filters Data as per actionType and searchQuery
  const filterDataHandler = async () => {
    if (searchQuery.length > 0) {
      setIsLoading(true);
      const response = await fetch(
        "https://searchaction.news-snap.com/search",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            searchText: searchQuery,
            searchType: currAction,
          }),
        }
      );
      const data = await response.json();
      setActions(data?.matches);
      setIsLoading(false);
    }
  };

  // Runs when search query changes
  useEffect(() => {
    filterDataHandler();
  }, [debouncedText, isModalVisible, currAction]);

  const skeleton = () => {
    return (
      <div style={{ padding: "25px" }} className="dashboardMyActionCard">
        <Skeleton.Button active={true} shape="round" />
        <Skeleton
          active={true}
          paragraph={{ rows: 2 }}
          style={{
            marginTop: "var(--main-margin)",
          }}
        />
        <Skeleton
          active={true}
          paragraph={{ rows: 0 }}
          style={{
            marginTop: "var(--sub-margin)",
          }}
        />
      </div>
    );
  };

  const onCardClick = (data) => {
    setUpdateImgId(data._id);
    const { title, description, imageURL, actionData } = data;
    const actionType = actionData?.actionType;
    dispatch(currActionSetter(actionType));
    let userData = {
      title,
      description,
      imageURL,
      actionData: {
        ...actionData,
      },
    };
    if (actionType == "messageBox") {
      dispatch(setActMessageBox(userData));
    }
    if (actionType == "signAPetition") {
      dispatch(setActSignAPetition(userData));
    }
    if (actionType == "donation") {
      dispatch(setActDonation(userData));
    }
    if (actionType == "newsLetterSignup") {
      dispatch(setActNewsLetterSignUp(userData));
    }
    if (actionType == "link") {
      dispatch(setActLink(userData));
    }
    if (actionType == "dynamicForm") {
      dispatch(setActDynamicForm(userData));
      dispatch(
        dynamicFormNumberOfIndexesSetter(userData.actionData.fields.length - 1)
      );
    }
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Row>
        <Col
          {...{ xs: 24, sm: 24, md: 24, lg: 12, xl: 12 }}
          style={{
            marginBottom: "var(--main-margin)",
          }}
        >
          <Row
            style={{
              marginTop: "var(--sub-margin)",
            }}
            gutter={[0, 15]}
            align="middle"
            justify="space-between"
          >
            <Col {...{ xs: 24, sm: 24, md: 18, lg: 18, xl: 18 }}>
              <Input
                type="text"
                size="large"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="inputBx"
                placeholder="Search..."
              />
            </Col>
            <Col {...{ xs: 8, sm: 8, md: 5, lg: 5, xl: 5 }}>
              <Select
                className="antdSelect"
                size="large"
                bordered={false}
                defaultValue={currAction}
                value={currAction}
                onChange={(e) => {
                  dispatch(currActionSetter(e));
                  filterDataHandler(e);
                }}
              >
                {totalActionsArray.map((data, index) => {
                  return (
                    <Option key={index} value={data.value}>
                      {data.icon} {data.name}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          style={{
            marginBottom: "var(--main-margin)",
          }}
        >
          <Row gutter={[30, 30]} aign="middle" justify="start">
            {actions.map((data, index) => {
              const {
                title,
                createdDate,
                description,
                actionData,
                imageURL,
                _id,
              } = data;
              const actionType = actionData?.actionType;
              const titleLength = title?.substring(0, 40);
              return (
                <Col key={index} {...{ xs: 24, sm: 12, md: 12, lg: 12, xl: 6 }}>
                  {isLoading ? (
                    skeleton()
                  ) : (
                    <Card bordered={false} className="dashboardMyActionCard">
                      <Row style={{ width: "100%", height: "100%" }}>
                        <Col span={24}>
                          <Row align="middle" justify="space-between">
                            <Button shape="round">
                              {actionIconFinder(actionType)}
                              {actionNameConverter(actionType)}
                            </Button>
                          </Row>
                        </Col>
                        <Row
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            history.push(`/templateedit/${_id}/action`);
                            // onCardClick(data);
                          }}
                        >
                          <Col
                            span={24}
                            style={{
                              marginTop: "15px",
                            }}
                          ></Col>
                          <Row
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              marginTop: "var(--main-margin)",
                            }}
                          >
                            <Col span={24}>
                              <Row justify="start" align="middle">
                                <Col
                                  span={24}
                                  className="dashboardMyActionCardTitle"
                                >
                                  <h1
                                    style={{
                                      color: "#192b9d",
                                      fontSize: "1.7rem",
                                      fontWeight: "200",
                                      height: "85px",
                                      wordBreak: "break-all",
                                    }}
                                  >
                                    {title?.substring(0, 32)}
                                    {titleLength?.length > 32 ? "..." : ""}
                                  </h1>
                                </Col>
                                <Col
                                  span={24}
                                  style={{
                                    marginTop: "15px",
                                  }}
                                >
                                  <Row
                                    align="middle"
                                    justify="start"
                                    className="dashboardMyActionCardTitle"
                                  >
                                    <p>{description}</p>
                                  </Row>
                                </Col>
                                <Col
                                  style={{
                                    marginTop: "var(--sub-margin)",
                                  }}
                                >
                                  <img
                                    src={imageURL}
                                    style={{
                                      width: "100%",
                                      borderRadius:
                                        "var(--input-Border-Radius3)",
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Col
                            span={24}
                            style={{
                              marginTop: "var(--sub-margin)",
                            }}
                          >
                            <Row align="middle" justify="space-between">
                              <p>{moment(createdDate).format("MMM Do YYYY")}</p>
                              <p>{/* <UsergroupAddOutlined /> 30% */}</p>
                            </Row>
                          </Col>
                        </Row>
                      </Row>
                    </Card>
                  )}
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default SuperAdminSchedularService;
