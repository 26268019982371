import { Col, Form, Input, Row, Select, Button } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  optionsAdderSetter,
  shouldEngSecChangeSetter,
} from "../../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
import { setEngQuiz } from "../../../../redux/Slices/Flip Cards/Engagements/EngQuizSlice";
import { FlipCardengagement } from "../../../../redux/Slices/Flip Cards/FlipCardSlice";
import { AdminEngagementObject } from "../../../../redux/Slices/Flip Cards/AdminFlipCardSlice";
const { Option } = Select;
function Quiz() {
  const dispatch = useDispatch();
  const optionsAdder = useSelector((state) => state.FlipCardEtc.optionsAdder);
  const userData = useSelector((state) => state.EngQuiz.engagement);

  // Checking if the required Fields are filled or not
  useEffect(() => {
    if (
      userData.title == "" ||
      userData.description == "" ||
      userData.engagementData.options[0]?.title == "" ||
      userData.engagementData.options[1]?.title == "" ||
      userData.engagementData.options.find((options) => options.isAnswer) ==
        undefined
    ) {
      dispatch(shouldEngSecChangeSetter(false));
    } else {
      dispatch(shouldEngSecChangeSetter(true));
    }
  }, [userData]);

  useEffect(() => {
    dispatch(setEngQuiz(userData));
    dispatch(FlipCardengagement(userData));
    dispatch(AdminEngagementObject(userData));
  }, []);

  // onchange Handler listener for all the fields
  const onChangeHandler = (e) => {
    let sentData = { ...userData };
    const { name, value } = e.target;

    if (name.includes("options")) {
      //  Adds data to the options array
      if (name.includes(0)) {
        sentData = {
          ...sentData,
          engagementData: {
            ...sentData.engagementData,
            options: [
              { ...sentData.engagementData.options[0], title: value },
              ...sentData.engagementData.options.slice(1),
            ],
          },
        };
      } else if (name.includes(1)) {
        sentData = {
          ...sentData,
          engagementData: {
            ...sentData.engagementData,
            options: [
              ...sentData.engagementData.options.slice(0, 1),
              { ...sentData.engagementData.options[1], title: value },
            ],
          },
        };
      } else if (name.includes(2)) {
        sentData = {
          ...sentData,
          engagementData: {
            ...sentData.engagementData,
            options: [
              ...sentData.engagementData.options.slice(0, 2),
              { ...sentData.engagementData.options[2], title: value },
            ],
          },
        };
      } else if (name.includes(3)) {
        sentData = {
          ...sentData,
          engagementData: {
            ...sentData.engagementData,
            options: [
              ...sentData.engagementData.options.slice(0, 3),
              { ...sentData.engagementData.options[3], title: value },
            ],
          },
        };
      }
    } else {
      sentData[name] = value;
    }
    dispatch(setEngQuiz(sentData));
    dispatch(FlipCardengagement(sentData));
    dispatch(AdminEngagementObject(sentData));
  };

  return (
    <Col span={24}>
      <Row style={{ width: "100%", marginTop: "20px" }}>
        <Col span={24}>
          <Form.Item label="Title *">
            <Input
              type="text"
              placeholder="Quiz Title"
              maxLength={120}
              showCount
              name="title"
              className="inputBx"
              value={userData.title}
              onChange={onChangeHandler}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Description *">
            <Input
              type="text"
              placeholder="Description"
              maxLength={120}
              showCount
              name="description"
              className="inputBx"
              value={userData.description}
              onChange={onChangeHandler}
            />
          </Form.Item>
        </Col>
        <Row style={{ width: "100%" }} justify="space-between">
          <Col span={11}>
            <Form.Item label="Option 1 *">
              <Input
                type="text"
                placeholder="option 1"
                maxLength={40}
                showCount
                name="options0"
                className="inputBx"
                value={userData.engagementData.options[0]?.title}
                onChange={onChangeHandler}
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item label="Option 2 *">
              <Input
                type="text"
                placeholder="Option 2"
                maxLength={40}
                showCount
                name="options1"
                className="inputBx"
                value={userData.engagementData.options[1]?.title}
                onChange={onChangeHandler}
              />
            </Form.Item>
          </Col>
          {optionsAdder !== 0 ? (
            <Col span={11}>
              <Form.Item label="Option 3">
                {optionsAdder === 2 ? (
                  <Input
                    type="text"
                    placeholder="Option 3"
                    maxLength={40}
                    showCount
                    name="options2"
                    className="inputBx"
                    value={userData.engagementData.options[2]?.title}
                    onChange={onChangeHandler}
                  />
                ) : (
                  <Row style={{ width: "100%" }}>
                    <Col span={19}>
                      <Input
                        type="text"
                        placeholder="Option 3"
                        maxLength={40}
                        showCount
                        name="options2"
                        className="inputBx"
                        style={{
                          borderRight: "none",
                          borderRadius: "7px 0px 0px 7px",
                        }}
                        value={userData.engagementData.options[2]?.title}
                        onChange={onChangeHandler}
                      />
                    </Col>
                    <Col span={5}>
                      <button
                        onClick={() => {
                          dispatch(optionsAdderSetter(optionsAdder - 1));
                          const newArray =
                            userData?.engagementData?.options?.filter(
                              (options) =>
                                options?.title !==
                                userData?.engagementData?.options[2]?.title
                            );
                          let sentData = { ...userData };
                          sentData = {
                            ...sentData,
                            engagementData: {
                              ...sentData.engagementData,
                              options: newArray,
                            },
                          };
                          dispatch(setEngQuiz(sentData));
                          dispatch(FlipCardengagement(sentData));
                          dispatch(AdminEngagementObject(sentData));
                        }}
                        size="large"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "0px 7px 7px 0px",
                          cursor: "pointer",
                          outline: "none",
                          border: "1px solid var(--main-Border-Color)",
                          padding: "0px 0xp 0px 0px",
                          margin: "0px 0px 0px 0px",
                          backgroundColor: "var(--main-Background-Color)",
                          fontSize: "1rem",
                        }}
                      >
                        X
                      </button>
                    </Col>
                  </Row>
                )}
              </Form.Item>
            </Col>
          ) : (
            ""
          )}
          {optionsAdder == 2 ? (
            <Col span={11}>
              <Form.Item label="Option 4">
                <Row style={{ width: "100%" }}>
                  <Col span={19}>
                    <Input
                      type="text"
                      placeholder="Option 4"
                      maxLength={40}
                      showCount
                      className="inputBx"
                      style={{
                        borderRight: "none",
                        borderRadius: "7px 0px 0px 7px",
                      }}
                      name="options3"
                      value={userData.engagementData.options[3]?.title}
                      onChange={onChangeHandler}
                    />
                  </Col>
                  <Col span={5}>
                    <button
                      onClick={() => {
                        dispatch(optionsAdderSetter(optionsAdder - 1));
                        const newArray =
                          userData?.engagementData?.options?.filter(
                            (options) =>
                              options?.title !==
                              userData?.engagementData?.options[3]?.title
                          );
                        let sentData = { ...userData };
                        sentData = {
                          ...sentData,
                          engagementData: {
                            ...sentData.engagementData,
                            options: newArray,
                          },
                        };
                        dispatch(setEngQuiz(sentData));
                        dispatch(FlipCardengagement(sentData));
                        dispatch(AdminEngagementObject(sentData));
                      }}
                      size="large"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "0px 7px 7px 0px",
                        cursor: "pointer",
                        outline: "none",
                        border: "1px solid var(--main-Border-Color)",
                        padding: "0px 0xp 0px 0px",
                        margin: "0px 0px 0px 0px",
                        backgroundColor: "var(--main-Background-Color)",
                        fontSize: "1rem",
                      }}
                    >
                      X
                    </button>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          ) : (
            ""
          )}
          {optionsAdder == 2 ? (
            ""
          ) : (
            <Col span={11} style={{ opacity: 0.5 }}>
              <Form.Item label={`Add Option ${optionsAdder + 3}`}>
                <Button
                  size="large"
                  className="button"
                  type="dashed"
                  style={{ width: "100%" }}
                  onClick={() => {
                    dispatch(optionsAdderSetter(optionsAdder + 1));
                  }}
                >
                  Add
                </Button>
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Form.Item label="Correct Answer *" tooltip="Correct Answer">
              <Select
                size="large"
                bordered={false}
                value={
                  userData.engagementData.options.find(
                    (option) => option.isAnswer
                  )?.title
                }
                onChange={(value) => {
                  let sentData = { ...userData };
                  sentData = {
                    ...userData,
                    engagementData: {
                      ...userData.engagementData,
                      options: userData.engagementData.options.map((option) => {
                        if (option.title === value) {
                          return {
                            ...option,
                            isAnswer: true,
                          };
                        } else {
                          return {
                            ...option,
                            isAnswer: false,
                          };
                        }
                      }),
                    },
                  };
                  dispatch(setEngQuiz(sentData));
                  dispatch(FlipCardengagement(sentData));
                  dispatch(AdminEngagementObject(sentData));
                }}
                className="antdSelect"
              >
                {userData.engagementData.options.map((data, index) => {
                  if (data.title == "") {
                    return "";
                  } else {
                    return (
                      <Option key={index} value={data.title}>
                        {data.title}
                      </Option>
                    );
                  }
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Row>
    </Col>
  );
}

export default Quiz;
