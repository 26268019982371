import { Button, Col, Form, Input, Modal, Row, Slider, Image } from "antd";
import React from "react";
import { useState } from "react";

function TextToImageModal({ visible, onCancel }) {
  const [userData, setUserData] = useState({
    iprompt: "",
    itr: 50,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [imgFile, setImgFile] = useState("");

  const submitHandler = async () => {
    setIsLoading(true);
    const response = await fetch(
      `https://texttoimage.news-snap.com/generateImage/?iprompt=${userData.iprompt}&itr=${userData.itr}`
    );
    const data = await response.json();

    setIsLoading(false);
    if (data?.base64_image) {
      setImgFile(data.base64_image);
    }
  };

  function downloadBase64File() {
    const linkSource = imgFile;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = "Bridged_Media";
    downloadLink.click();
  }

  return (
    <Modal
      visible={visible}
      confirmLoading={false}
      centered={true}
      footer={null}
      onCancel={() => {
        onCancel(false);
      }}
    >
      <Form layout="vertical">
        <Row>
          <h2>Generate image</h2>
          <Col
            span={24}
            style={{
              marginTop: "var(--sub-margin)",
            }}
          >
            <Form.Item label="Image Description *">
              <Input
                type="text"
                placeholder="Image Description"
                className="inputBx"
                value={userData.iprompt}
                onChange={(e) => {
                  setUserData({ ...userData, iprompt: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item label="Image Quality *">
              <Slider
                min={50}
                max={100}
                tooltipVisible={false}
                onChange={(e) => {
                  setUserData({ ...userData, itr: e });
                }}
                value={userData.itr}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            {imgFile && (
              <img
                style={{
                  width: "100%",
                  borderRadius: "var(--input-Border-Radius3)",
                  marginBottom: "var(--sub-margin)",
                }}
                src={imgFile}
              />
            )}
          </Col>
          <Col span={24}>
            <Row align="middle" justify="space-between">
              <Col>
                {imgFile && (
                  <Button
                    type="primary"
                    size="large"
                    className="button"
                    onClick={downloadBase64File}
                  >
                    Download
                  </Button>
                )}
              </Col>
              <Button
                type="primary"
                size="large"
                className="button"
                onClick={submitHandler}
                loading={isLoading}
              >
                Submit
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default TextToImageModal;
