import React from "react";
import IframeComm from "react-iframe-comm";
import { useDispatch, useSelector } from "react-redux";
import { iframeLoadedSetter } from "../../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
function Iframe({ iframeData }) {
  const iframeLoadedSetterData = useSelector(
    (state) => state.FlipCardEtc.iframeLoaded
  );
  const dispatch = useDispatch();
  return (
    <>
      <IframeComm
        attributes={{
          src: `${process.env.REACT_APP_FLIPCARD_URL}?_id=create`,
        }}
        postMessageData={iframeData}
        handleReceiveMessage={(event) => {
          if (event?.data == `{"event":"appLoaded"}`) {
            dispatch(iframeLoadedSetter(!iframeLoadedSetterData));
          }
        }}
      />
    </>
  );
}

export default Iframe;
