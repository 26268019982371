import {
  Button,
  Col,
  Dropdown,
  Menu,
  Row,
  Popconfirm,
  message,
  Skeleton,
} from "antd";
import {
  EyeOutlined,
  LeftOutlined,
  PlayCircleOutlined,
  SettingOutlined,
  PauseOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import PreviewModal from "./PreviewModal";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  useFlipCardChangeStatusMutation,
  useFlipCardDeleteMutation,
} from "../../Services/service";
function TopBar({ flipCardOverviewInfo }) {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const etcData = useSelector(
    (state) => state.NewFlipcardOverview.flipcardOverviewDataStatus
  );
  const { isLoading, isError } = etcData;
  const _id = useSelector((state) => state.NewFlipcardOverview._id);
  const isActive = useSelector(
    (state) => state.NewFlipcardOverview.flipcardOverviewData.isActive
  );
  const [_deleteFlipcard] = useFlipCardDeleteMutation();
  const [_flipcardStatusChange] = useFlipCardChangeStatusMutation();

  const deleteConfirm = async (_id) => {
    try {
      const response = await _deleteFlipcard(_id);
      if (response?.error) {
        message.error("Something went wrong");
      }
      if (response?.data) {
        message.success("Flipcard deleted successfully");
        setTimeout(() => {
          history.push("/");
        }, 10);
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const pauseCardHandler = async (status) => {
    try {
      const response = await _flipcardStatusChange({ _id, status });
      if (response?.error) {
        message.error("Something went wrong");
      }
      if (response?.data) {
        message.success("Flipcard Status changed successfully");
        flipCardOverviewInfo();
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };
  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          pauseCardHandler(!isActive);
        }}
      >
        {isActive ? "Pause" : "Resume"}
      </Menu.Item>
      <Popconfirm
        title="Are you sure to delete this Flipcard?"
        onConfirm={() => {
          deleteConfirm(_id);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Menu.Item>Delete</Menu.Item>
      </Popconfirm>
    </Menu>
  );
  return (
    <>
      <Row style={{ width: "100%" }}>
        <Col {...{ xs: 24, sm: 24, md: 24, lg: 7 }}>
          <NavLink to="/">
            <Button
              className="buttonWhite"
              size="large"
              type="text"
              style={{ padding: "0px" }}
            >
              <LeftOutlined />
              Back to home page
            </Button>
          </NavLink>
        </Col>
        {isLoading ? (
          <Col {...{ xs: 24, sm: 24, md: 24, lg: 17 }}>
            <Row align="middle" justify="end">
              <Col>
                <Skeleton.Button
                  active={true}
                  style={{
                    borderRadius: "var( --input-Border-Radius3)",
                    width: "100px",
                    marginRight: "20px",
                  }}
                />
              </Col>
              <Col>
                <Skeleton.Button
                  active={true}
                  style={{
                    borderRadius: "var( --input-Border-Radius3)",
                    width: "100px",
                    marginRight: "20px",
                  }}
                />
              </Col>
              <Col>
                <Skeleton.Button
                  active={true}
                  style={{
                    borderRadius: "var( --input-Border-Radius3)",
                    width: "100px",
                  }}
                />
              </Col>
            </Row>
          </Col>
        ) : (
          <Col {...{ xs: 24, sm: 24, md: 24, lg: 17 }}>
            <Row align="middle" justify="end">
              <Col>
                <Button
                  disabled={isError}
                  size="large"
                  className="button"
                  type="text"
                  style={{
                    color: isActive ? "#66b644" : "#CB0000",
                  }}
                >
                  {isActive ? (
                    <>
                      Active <PlayCircleOutlined />
                    </>
                  ) : (
                    <>
                      Paused <PauseOutlined />
                    </>
                  )}
                </Button>
              </Col>
              <Col>
                <Dropdown overlay={menu} trigger={["click"]} disabled={isError}>
                  <Button size="large" className="button" type="text">
                    Settings
                    <SettingOutlined />
                  </Button>
                </Dropdown>
              </Col>
              <Col>
                <Button
                  disabled={isError}
                  size="large"
                  className="button"
                  type="text"
                  style={{ paddingRight: "0px" }}
                  onClick={() => {
                    setVisible(!visible);
                  }}
                >
                  Preview
                  <EyeOutlined />
                </Button>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <PreviewModal visible={visible} setVisible={setVisible} />
    </>
  );
}

export default TopBar;
