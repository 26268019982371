import { Button, Card, Col, Pagination, Row, Skeleton } from "antd";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setActDonation } from "../../../redux/Slices/Flip Cards/Actions/ActDonationSlice";
import { setActDynamicForm } from "../../../redux/Slices/Flip Cards/Actions/ActDynamicFormSlice";
import { setActLink } from "../../../redux/Slices/Flip Cards/Actions/ActLinkSlice";
import { setActMessageBox } from "../../../redux/Slices/Flip Cards/Actions/ActMessageBoxSlice";
import { setActNewsLetterSignUp } from "../../../redux/Slices/Flip Cards/Actions/ActNewsLetterSignUpSlice";
import { setActSignAPetition } from "../../../redux/Slices/Flip Cards/Actions/ActSignAPetitionSlice";
import { currActionSetter, dynamicFormNumberOfIndexesSetter } from "../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
import { useFlipcardGetAllActionsMadeManuallyQuery } from "../../../Services/service";
import actionIconFinder from "../../ExtraComponents/actionIconFinder";
import actionNameConverter from "../../ExtraComponents/actionNameConverter";
import DashboardSearchTitle from "../DashboardReusableFunctions/DashboardSearchTitle";
import DashboardSortingTitle from "../DashboardReusableFunctions/DashboardSortingTitle";

const SuperAdminManuallyCreatedCards = ({
  filterData,
  sortData,
  searchKeyword,
}) => {
  const history = useHistory();
  const [updateImgId, setUpdateImgId] = useState("");
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [paginationPage, setPaginationPage] = useState(0);
  const [paginationPageSize, setPaginationPageSize] = useState(8);
  const [actions, setActions] = useState([{}, {}, {}, {}, {}, {}, {}, {}]);
  const { isLoading, isSuccess, data } =
    useFlipcardGetAllActionsMadeManuallyQuery();
  useEffect(() => {
    if (isSuccess) {
      setActions(data);
    }
  }, [isSuccess, data]);

  let displayActions = actions.slice(
    paginationPage * paginationPageSize,
    (paginationPage + 1) * paginationPageSize
  );

  const paginationChange = (page, pageSize) => {
    setPaginationPage(page - 1);
    setPaginationPageSize(pageSize);
  };

  const filterDatahandler = (e) => {
    let filters = [];

    const engObj = {
      type: "engagementType",
      value: [...e.filterDataEngagement],
    };
    const actObj = {
      type: "actionTypes",
      value: [...e.filterDataAction],
    };
    const statObj = {
      type: "isActive",
      value: [...e.filterDataStatus],
    };

    // --------------------------------------
    if (e.filterDataEngagement.length !== 0) {
      filters.push(engObj);
    }
    if (e.filterDataAction.length !== 0) {
      filters.push(actObj);
    }
    if (e.filterDataStatus.length !== 0) {
      filters.push(statObj);
    }

    let filteredArr = [...data];

    for (let filter of filters) {
      filteredArr = filteredArr.filter((flipcart) => {
        if (typeof flipcart[filter.type] == "object") {
          filter.value.forEach((e) => {
            if (flipcart[filter.type].includes(e)) {
              return flipcart;
            }
          });
        }
        if (filter.value.includes(flipcart[filter.type])) {
          return flipcart;
        }
      });
    }

    setActions(filteredArr);
  };

  useEffect(() => {
    if (isSuccess) {
      const sortedData = DashboardSortingTitle(sortData?.sortData[0], data);
      setActions(sortedData);
    }
  }, [isSuccess, sortData]);

  useEffect(() => {
    if (isSuccess) {
      filterDatahandler(filterData);
    }
  }, [isSuccess, filterData]);

  useEffect(() => {
    if (isSuccess) {
      const searchResults = DashboardSearchTitle(searchKeyword, data);
      setActions(searchResults);
    }
  }, [isSuccess, searchKeyword]);

  const skeleton = () => {
    return (
      <div style={{ padding: "25px" }} className="dashboardMyActionCard">
        <Skeleton.Button active={true} shape="round" />
        <Skeleton
          active={true}
          paragraph={{ rows: 2 }}
          style={{
            marginTop: "var(--main-margin)",
          }}
        />
        <Skeleton
          active={true}
          paragraph={{ rows: 0 }}
          style={{
            marginTop: "var(--sub-margin)",
          }}
        />
      </div>
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onCardClick = (data) => {
    setUpdateImgId(data._id);
    const { title, description, imageURL, actionData } = data;
    const actionType = actionData?.actionType;
    dispatch(currActionSetter(actionType));
    let userData = {
      title,
      description,
      imageURL,
      actionData: {
        ...actionData,
      },
    };
    if (actionType == "messageBox") {
      dispatch(setActMessageBox(userData));
    }
    if (actionType == "signAPetition") {
      dispatch(setActSignAPetition(userData));
    }
    if (actionType == "donation") {
      dispatch(setActDonation(userData));
    }
    if (actionType == "newsLetterSignup") {
      dispatch(setActNewsLetterSignUp(userData));
    }
    if (actionType == "link") {
      dispatch(setActLink(userData));
    }
    if (actionType == "dynamicForm") {
      dispatch(setActDynamicForm(userData));
      dispatch(
        dynamicFormNumberOfIndexesSetter(userData.actionData.fields.length - 1)
      );
    }
    setIsModalVisible(true);
  };

  return (
    <>
      <Row
        style={{
          marginTop: "var(--main-margin)",
          marginBottom: "var(--main-margin)",
        }}
      >
        <Col
          span={24}
          style={{
            marginTop: "var(--sub-margin)",
          }}
        >
          <Row gutter={[30, 30]} aign="middle" justify="start">
            {displayActions.map((data, index) => {
              const {
                title,
                createdDate,
                description,
                actionData,
                imageURL,
                _id,
              } = data;
              const actionType = actionData?.actionType;
              const titleLength = title?.substring(0, 40);
              return (
                <Col key={index} {...{ xs: 24, sm: 12, md: 12, lg: 12, xl: 6 }}>
                  {isLoading ? (
                    skeleton()
                  ) : (
                    <Card bordered={false} className="dashboardMyActionCard">
                      <Row style={{ width: "100%", height: "100%" }}>
                        <Col span={24}>
                          <Row align="middle" justify="space-between">
                            <Button shape="round">
                              {actionIconFinder(actionType)}
                              {actionNameConverter(actionType)}
                            </Button>
                          </Row>
                        </Col>
                        <Row
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            history.push(`/templateedit/${_id}/action`);
                            // onCardClick(data);
                          }}
                        >
                          <Col
                            span={24}
                            style={{
                              marginTop: "15px",
                            }}
                          ></Col>
                          <Row
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              marginTop: "var(--main-margin)",
                            }}
                          >
                            <Col span={24}>
                              <Row justify="start" align="middle">
                                <Col
                                  span={24}
                                  className="dashboardMyActionCardTitle"
                                >
                                  <h1
                                    style={{
                                      color: "#192b9d",
                                      fontSize: "1.7rem",
                                      fontWeight: "200",
                                      height: "85px",
                                      wordBreak: "break-all",
                                    }}
                                  >
                                    {title?.substring(0, 32)}
                                    {title ? null : "Refresh The Page"}
                                    {titleLength?.length > 32 ? "..." : ""}
                                  </h1>
                                </Col>
                                <Col
                                  span={24}
                                  style={{
                                    marginTop: "15px",
                                  }}
                                >
                                  <Row
                                    align="middle"
                                    justify="start"
                                    className="dashboardMyActionCardTitle"
                                  >
                                    <p>{description}</p>
                                  </Row>
                                </Col>
                                <Col
                                  style={{
                                    marginTop: "var(--sub-margin)",
                                  }}
                                >
                                  <img
                                    src={imageURL}
                                    style={{
                                      width: "100%",
                                      borderRadius:
                                        "var(--input-Border-Radius3)",
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Col
                            span={24}
                            style={{
                              marginTop: "var(--sub-margin)",
                            }}
                          >
                            <Row align="middle" justify="space-between">
                              <p>{moment(createdDate).format("MMM Do YYYY")}</p>
                              <p>{/* <UsergroupAddOutlined /> 30% */}</p>
                            </Row>
                          </Col>
                        </Row>
                      </Row>
                    </Card>
                  )}
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      {actions.length > 8 ? (
        <Col
          span={24}
          style={{
            marginTop: "var(--main-margin)",
            marginBottom: "var(--main-margin)",
          }}
        >
          <Row align="middle" justify="end">
            <Pagination
              className="dashboardPagnaition"
              size="small"
              defaultPageSize={8}
              onChange={paginationChange}
              total={actions.length}
            />
          </Row>
        </Col>
      ) : (
        ""
      )}
    </>
  );
};

export default SuperAdminManuallyCreatedCards;
