import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  currentSectionSetter,
  shouldActSecChangeSetter,
} from "../../../../../redux/Slices/Flip Cards/Etc/FlipCardEtcSlice";
import SuggestedActions from "./SuggestedActions";
import NewAction from "../NewAction";
import NewActionPub from "./NewActionPub";
const { Panel } = Collapse;
function AddAction({
  iscreateNewAction,
  secPrev,
  secNext,
  setInventory,
  type,
}) {
  const pathname = window.location.href.split("/")[3];
  const [collapseActive, setCollapseActive] = useState(
    pathname === "templateedit" ? 3 : 1
  );
  const shouldActSecChange = useSelector(
    (state) => state.FlipCardEtc.shouldActSecChange
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const flipcardActions = useSelector((state) => state.flipCard.actions);
  const flipcardActionPage = useSelector((state) => state.flipCard.actionPage);

  // Checks if user will able to go to next step
  useEffect(() => {
    if (iscreateNewAction) {
    } else {
      if (
        flipcardActions?.length > 0 &&
        flipcardActionPage.title !== "" &&
        flipcardActionPage.description !== ""
      ) {
        dispatch(shouldActSecChangeSetter(true));
      } else {
        dispatch(shouldActSecChangeSetter(false));
      }
    }
  }, [flipcardActions, isModalVisible, flipcardActionPage]);
  const dispatch = useDispatch();

  return (
    <Row>
      <Col span={24} className="flipcardOutCompGrey">
        <Collapse
          accordion
          bordered={false}
          ghost
          expandIconPosition="right"
          defaultActiveKey={1}
          activeKey={collapseActive}
          onChange={(e) => {
            setCollapseActive(e);
          }}
        >
          <Panel
            header="Select from Suggested actions"
            key="1"
            className="flipcardInCompWhite flipcardContentPanel"
            style={{
              borderRadius: "var(--input-Border-Radius1)",
            }}
          >
            <SuggestedActions
              iscreateNewAction={iscreateNewAction}
              setCollapseActive={setCollapseActive}
              setInventory={setInventory}
            />
          </Panel>

          {/* As This component is resued this section will not be shown when user wants to create new actions */}
          {iscreateNewAction ? null : (
            <Panel
              header="Submit Request of new action"
              key="2"
              className="flipcardInCompWhite flipcardContentPanel"
              style={{
                marginTop: "var(--sub-margin)",
                borderRadius: "var(--input-Border-Radius1)",
              }}
            >
              <NewActionPub
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
              />
            </Panel>
          )}
          {/* As This component is resued this section will  be shown when user wants to create new actions */}
          {iscreateNewAction ? (
            <Panel
              header={
                pathname === "templateedit"
                  ? "Edit action"
                  : "Create new action"
              }
              key="3"
              className="flipcardInCompWhite flipcardContentPanel"
              style={{
                marginTop: "var(--sub-margin)",
                borderRadius: "var(--input-Border-Radius1)",
              }}
            >
              <Row>
                <Col span={24}>
                  <p className="flipcardContentPanelSubHeading">
                    Submit a request for new action and we'll create it for you
                  </p>
                </Col>
                <Col
                  span={24}
                  style={{
                    marginTop: "var(--main-margin)",
                  }}
                >
                  <NewAction />
                </Col>
              </Row>
            </Panel>
          ) : null}
        </Collapse>
      </Col>
      <Col
        span={24}
        style={{
          marginTop: "var(--main-margin)",
          marginBottom: "var(--main-margin)",
        }}
      >
        <Row justify="space-between" align="middle">
          {type ? (
            <div></div>
          ) : (
            <Button
              size="large"
              className="button"
              onClick={() => {
                dispatch(currentSectionSetter(secPrev));
              }}
            >
              <ArrowLeftOutlined /> Back
            </Button>
          )}

          <Button
            size="large"
            className="button"
            type="primary"
            disabled={!shouldActSecChange}
            onClick={() => {
              dispatch(currentSectionSetter(secNext));
            }}
          >
            Next <ArrowRightOutlined />
          </Button>
        </Row>
      </Col>
    </Row>
  );
}

export default AddAction;
