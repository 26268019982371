import { configureStore } from "@reduxjs/toolkit";
import { bridgedApi } from "../Services/service";
import authSlice from "./Slices/Auth/authSlice";
import FlipCardSlice from "./Slices/Flip Cards/FlipCardSlice";
import EngSliderPollSlice from "./Slices/Flip Cards/Engagements/EngSliderPollSlice";
import ActMessageBoxSlice from "./Slices/Flip Cards/Actions/ActMessageBoxSlice";
import EngQuizSlice from "./Slices/Flip Cards/Engagements/EngQuizSlice";
import EngFactCheckSlice from "./Slices/Flip Cards/Engagements/EngFactCheckSlice";
import FlipCardEtcSlice from "./Slices/Flip Cards/Etc/FlipCardEtcSlice";
import AdminFlipCardSlice from "./Slices/Flip Cards/AdminFlipCardSlice";
import ActSignAPetitionSlice from "./Slices/Flip Cards/Actions/ActSignAPetitionSlice";
import ActNewsLetterSignUpSlice from "./Slices/Flip Cards/Actions/ActNewsLetterSignUpSlice";
import ActDonationSlice from "./Slices/Flip Cards/Actions/ActDonationSlice";
import NewFlipcardOverviewSlice from "./Slices/Overview/NewFlipcardOverviewSlice";
import ActLinkSlice from "./Slices/Flip Cards/Actions/ActLinkSlice";
import AnalyticsSlice from "./Slices/Analytics/AnalyticsSlice";
import OnboardingSlice from "./Slices/OnBoarding/OnboardingSlice";
import userSlice from "./Slices/User/userSlice";
import ActDynamicFormSlice from "./Slices/Flip Cards/Actions/ActDynamicFormSlice";

export default configureStore({
  reducer: {
    auth: authSlice,
    flipCard: FlipCardSlice,
    AdminFlipCard: AdminFlipCardSlice,
    Analytics: AnalyticsSlice,
    OnBoard: OnboardingSlice,
    user: userSlice,

    // ------------------------ Engagements ------------------------
    EngSliderPoll: EngSliderPollSlice,
    EngQuiz: EngQuizSlice,
    EngFactCheck: EngFactCheckSlice,

    // ------------------------ Actions ------------------------
    ActMessageBox: ActMessageBoxSlice,
    ActSignAPetition: ActSignAPetitionSlice,
    ActNewsLetterSignUp: ActNewsLetterSignUpSlice,
    ActDonation: ActDonationSlice,
    ActLink: ActLinkSlice,
    ActDynamicForm: ActDynamicFormSlice,

    // ------------------------ Flipcard Etc Data ------------------------
    FlipCardEtc: FlipCardEtcSlice,

    NewFlipcardOverview: NewFlipcardOverviewSlice,
    [bridgedApi.reducerPath]: bridgedApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(bridgedApi.middleware),
});
