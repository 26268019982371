export default [
  {
    title: "Action Type",
    dataIndex: "actionType",
  },
  {
    title: "Email Address",
    dataIndex: "email",
  },
  {
    title: "Full Name",
    dataIndex: "fullname",
  },
  {
    title: "Notify",
    dataIndex: "notify",
  },
  {
    title: "Petition Id",
    dataIndex: "petitionId",
    ellipsis: true,
  },
  {
    title: "Source",
    dataIndex: "source",
    ellipsis: true,
  },
  {
    title: "Postal Code",
    dataIndex: "postalCode",
  },
  {
    title: "Date Added",
    dataIndex: "dateAdded",
  },
];
