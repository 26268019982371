import {
  DownOutlined,
  FileTextOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Col, Popover, Row, Skeleton, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrTableActionType,
  setSelectedTableData,
} from "../../redux/Slices/Overview/NewFlipcardOverviewSlice";
import actionNameConverter from "../ExtraComponents/actionNameConverter";
import ExportAudienceModal from "./ExportAudienceModal";
import SendEmailModal from "./SendEmail/SendEmailModal";

function TableSection() {
  const currentTableColumn = useSelector(
    (state) => state.NewFlipcardOverview.currentTableColumn
  );

  const actions = useSelector(
    (state) => state.NewFlipcardOverview.flipcardOverviewData.actionTypes
  );

  const originalTableData = useSelector(
    (state) => state.NewFlipcardOverview.originalTableData
  );

  const etcData = useSelector(
    (state) => state.NewFlipcardOverview.flipcardOverviewDataStatus
  );
  const { isLoading, isError } = etcData;

  const currtableActionType = useSelector(
    (state) => state.NewFlipcardOverview.currtableActionType
  );

  useEffect(() => {
    if (actions?.length == 1) {
      dispatch(setCurrTableActionType(actions[0]));
    }
  }, [actions]);

  const dispatch = useDispatch();
  const [exportVisible, setExportVisible] = useState(false);
  const [sendEmailVisible, setSendEmailVisible] = useState(false);
  const selectselectselectActionType = (
    <Row
      gutter={[0, 10]}
      style={{
        width: "100%",
        maxWidth: "200px",
      }}
    >
      <Col span={24}>
        <Checkbox
          value={"all"}
          checked={currtableActionType === "all"}
          onChange={() => {
            dispatch(setCurrTableActionType("all"));
          }}
        >
          All
        </Checkbox>
      </Col>
      {actions?.map((item, index) => {
        return (
          <Col key={index} span={24}>
            <Checkbox
              value={item}
              checked={currtableActionType === item}
              onChange={() => {
                dispatch(setCurrTableActionType(item));
              }}
            >
              {actionNameConverter(item)}
            </Checkbox>
          </Col>
        );
      })}
    </Row>
  );
  return (
    <>
      <Row
        gutter={[0, 10]}
        style={{
          marginTop: "90px",
          width: "100%",
        }}
      >
        <Col {...{ xs: 24, sm: 24, md: 24, lg: 7, xl: 7 }}>
          <Row
            align="middle"
            justify="start"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <h1>Action Takers</h1>
          </Row>
        </Col>
        <Col {...{ xs: 24, sm: 24, md: 24, lg: 17, xl: 17 }}>
          <Row
            gutter={[0, 10]}
            align="middle"
            justify="end"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <>
              <Col
                style={{
                  marginRight: "var(--main-margin)",
                }}
              >
                <Popover
                  placement="bottomLeft"
                  content={selectselectselectActionType}
                  trigger="click"
                >
                  <Button size="large" className="button" disabled={isError}>
                    Action Type <DownOutlined />
                  </Button>
                </Popover>
              </Col>
              <Col
                style={{
                  marginRight: "var(--main-margin)",
                }}
              >
                <Button
                  type="primary"
                  disabled={isError}
                  size="large"
                  className="button"
                  onClick={() => setExportVisible(!exportVisible)}
                >
                  <FileTextOutlined />
                  Export Audience
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  disabled={isError}
                  size="large"
                  className="button"
                  onClick={() => setSendEmailVisible(!sendEmailVisible)}
                >
                  <SendOutlined />
                  Send Email
                </Button>
              </Col>
            </>
          </Row>
        </Col>
      </Row>
      {isLoading ? (
        <>
          <Skeleton.Button
            active
            block
            style={{
              marginTop: "var(--main-margin)",
              marginBottom: "50px",
              width: "100%",
              height: "200px",
            }}
          />
        </>
      ) : (
        <div
          style={{
            marginTop: "var(--main-margin)",
            marginBottom: "50px",
            width: "100%",
          }}
        >
          <Table
            className="antDesigntable"
            columns={currentTableColumn}
            rowSelection={{
              type: "checkbox",
              onChange: (selectedRowKeys, selectedRows) => {
                dispatch(setSelectedTableData(selectedRows));
              },
            }}
            dataSource={originalTableData}
            pagination={false}
            scroll={{ x: 1300 }}
          />
        </div>
      )}

      <ExportAudienceModal
        exportVisible={exportVisible}
        setExportVisible={setExportVisible}
      />
      <SendEmailModal
        sendEmailVisible={sendEmailVisible}
        setSendEmailVisible={setSendEmailVisible}
      />
    </>
  );
}

export default TableSection;
