import { Col, Table } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import countryCodes from "../../ExtraComponents/countryCodes";

const tableColumn = [
  {
    title: "Name",
    dataIndex: "name",
    ellipsis: true,
  },
  {
    title: "Total Views",
    dataIndex: "totalView",
  },
  {
    title: "Engagement Take",
    dataIndex: "engagementTake",
  },
  {
    title: "Action Take",
    dataIndex: "actionTake",
  },
];

function WhereAreUsers() {
  const flipCardData = useSelector((state) => state.Analytics.data);

  const [chartData, setChartData] = useState([]);
  const COLORS = ["#001493", "#4b5bbb", "#8c98e3"];

  useEffect(() => {
    const data = flipCardData?.countries?.map((item) => {
      const name = countryCodes.find((country) => country.code === item.name);
      return {
        name: name?.name,
        actionTake: item?.overallReport?.actionTake,
        engagementTake: item?.overallReport?.engagementTake,
        totalView: item?.overallReport?.totalView,
      };
    });
    setChartData(data);
  }, [flipCardData]);

  return (
    <Col span={24}>
      {/* <ResponsiveContainer width="100%" height="100%">
        <AreaChart width={500} height={400} data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="actionTake"
            stackId="1"
            stroke="#001493"
            fill="#001493"
          />
          <Area
            type="monotone"
            dataKey="engagementTake"
            stackId="1"
            stroke="#4b5bbb"
            fill="#4b5bbb"
          />
          <Area
            type="monotone"
            dataKey="totalView"
            stackId="1"
            stroke="#8c98e3"
            fill="#8c98e3"
          />
        </AreaChart>
      </ResponsiveContainer> */}

      {/* <ResponsiveContainer>
        <PieChart width={800} height={400}>
          <Pie
            data={chartData}
            cx="50%"
            cy="50%"
            innerRadius={110}
            outerRadius={150}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="totalView"
            labelLine={false}
          >
            {flipCardData?.sources?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer> */}

      <Table
        className="analyticsTable"
        columns={tableColumn}
        dataSource={chartData}
        size="small"
        pagination={{
          size: "small",
          pageSize: 9,
        }}
      />

      {/* <ResponsiveContainer>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="totalView"
            stackId="a"
            fill="var(--chart-Color-1)"
          />
          <Bar
            dataKey="engagementTake"
            stackId="a"
            fill="var(--chart-Color-2)"
          />
          <Bar
            dataKey="actionTake"
            stackId="a"
            fill="var(--chart-Color-3)"
          />
        </BarChart>
      </ResponsiveContainer> */}
    </Col>
  );
}

export default WhereAreUsers;
